// com.degineo.metier.producer_subscription.service.ProducerSubscriptionTunnelServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { DegineoUserBundleV2 } from '../entities/DegineoUserBundleV2';
import { ProducerSignupStep8DTO } from '../entities/ProducerSignupStep8DTO';
import { ProducerSignupStep2DTO } from '../entities/ProducerSignupStep2DTO';
import { ProducerSignupStep9DTO } from '../entities/ProducerSignupStep9DTO';
import { ProducerSignupStep4DTO } from '../entities/ProducerSignupStep4DTO';
import { LegalStatus } from '../entities/LegalStatus';
import { ProducerSignupStep5DTO } from '../entities/ProducerSignupStep5DTO';
import { AddressNEW } from '../entities/AddressNEW';

@Injectable({
  providedIn: 'root',
})
export class ProducerSubscriptionTunnelService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getInfosStep2(): RpcRequestBuilder<ProducerSignupStep2DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.getInfosStep2';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep2DTO');
  }

  saveStep2(firstName: string, lastName: string, job: string, phoneNumber: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.saveStep2';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(job, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(phoneNumber, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep3(): RpcRequestBuilder<ProducerSignupStep4DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.getInfosStep3';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep4DTO');
  }

  saveStep3(name: string, legalStatus: LegalStatus, capital: number, RCS: string, RNANumber: string, billingAddress: AddressNEW): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.saveStep3';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(legalStatus, 'com.degineo.metier.producer.entities.LegalStatus', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(capital, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(RCS, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(RNANumber, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(billingAddress, 'com.degineo.metier.address.entities.AddressNEW', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep4(): RpcRequestBuilder<ProducerSignupStep5DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.getInfosStep4';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep5DTO');
  }

  saveStep4(shippingAddress: AddressNEW): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.saveStep4';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(shippingAddress, 'com.degineo.metier.address.entities.AddressNEW', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep5(): RpcRequestBuilder<ProducerSignupStep8DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.getInfosStep5';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep8DTO');
  }

  saveStep5(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.saveStep5';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep6(): RpcRequestBuilder<ProducerSignupStep9DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.getInfosStep6';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep9DTO');
  }

  saveStep6(iban: string, bic: string, stripePaymentMethodId: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.saveStep6';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(iban, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(bic, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(stripePaymentMethodId, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep7(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.getInfosStep7';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveStep7(idPlan: number): RpcRequestBuilder<DegineoUserBundleV2> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionTunnelService.saveStep7';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPlan, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.tools.DegineoUserBundleV2');
  }

}
