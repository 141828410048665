// ENTITY com.degineo.metier.order.dto.kanban.panel.inprep.InPrepPPCODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class InPrepPPCODTO {

  idProducerPrinterConsumableOption: number = 0;
  name: string = '';
  color: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.inprep.InPrepPPCODTO'] = InPrepPPCODTO;

reverseMapping[InPrepPPCODTO.name] = 'com.degineo.metier.order.dto.kanban.panel.inprep.InPrepPPCODTO';

fields['com.degineo.metier.order.dto.kanban.panel.inprep.InPrepPPCODTO']  = {
    idProducerPrinterConsumableOption: 'java.lang.Integer',
    name: 'java.lang.String',
    color: 'java.lang.String'
};
