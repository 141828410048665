<div class="container-fluid pb-5">
  <div class="row justify-content-center text-center" *ngIf="!modeEvolutionPlan">
    <div class="mt-4 w-100 ml-2 position-relative text-primary text-left">
      <a class="fs-4 pointer" style="left: 0; top: 0" (click)="back()"><i class="fas fa-long-arrow-alt-left pl-3 mr-2"></i>Retour</a>
    </div>
    <h1 class="text-primary mb-5 mt-3 col-12" *ngIf="legalStatus === null">Choisissez l'abonnement adapté à vos besoins</h1>
    <h1 class="text-primary mb-5 mt-3 col-12" *ngIf="legalStatus !== null">Vous devez choisir un abonnement adapté aux {{ legalStatus.lib }}</h1>
    <p class="col-12 mb-5 fs-4 fw-700" style="line-height: 2" *ngIf="nbPrinterFixe === null">
      Je suis
      <select class="form-control d-inline mt-3" style="width: 15rem" [(ngModel)]="producerTarget" (change)="getPlans()">
        <!--FABLAB Désactivé en dur ici-->
        <option [disabled]="pt.id == 3" *ngFor="let pt of producerTargets" [ngValue]="pt">{{ pt }}</option>
      </select>
      qui possède
      <input
        class="form-control d-inline text-center fw-700 text-primary"
        type="number"
        min="1"
        step="1"
        [(ngModel)]="nbPrinters"
        (keyup)="updateSelectedPlan()"
        (focus)="$event.target.select()"
        style="width: 4rem"
      />
      imprimante{{ nbPrintersCalcul > 1 ? 's' : '' }}.
    </p>
    <p class="col-12 mb-5 fs-4 fw-700" style="line-height: 2" *ngIf="nbPrinterFixe !== null">
      Vous possedez <span class="text-primary fw-700">{{ nbPrinterFixe }}</span> imprimante{{ nbPrinterFixe > 1 ? 's' : '' }}.
    </p>
  </div>

  <!--Desktop View-->
  <table *ngIf="plans.length > 0 && !mobile" class="m-auto text-center table-hover" style="border-collapse: separate; border-spacing: 3rem 0">
    <thead>
      <tr>
        <th>
          <img src="../../assets/fusee-abonnements.svg" style="max-width: 15rem" alt="Logo abonnements" />
        </th>
        <th *ngFor="let p of getPlansFiltered()" class="shadow-2-top mr-5 px-3 rounded-top" style="width: 15rem" [class.disabled]="isDisabled(p)">
          <h2 class="text-primary fs-5 fw-400 my-4">{{ p.plan.name }}</h2>
          <div class="mb-3">
            <span class="fs-5 fw-700">{{ p.plan.baseSubscriptionPriceHT + p.plan.printerSubscriptionPriceHT * nbPrintersCalcul }}€&nbsp;</span>/mois (HT)
          </div>
          <div class="mb-4 fw-400" *ngIf="p.plan.producerTarget !== producerTargetEnum.MICRO_ENTREPRISE">
            <div class="fs-2 fw-300">Prix de base : {{ p.plan.baseSubscriptionPriceHT }}€ <span class="fs-1">HT</span></div>
            <div class="fs-2 fw-300">+ {{ p.plan.printerSubscriptionPriceHT }}€ <span class="fs-1">HT</span> par imprimante 3D</div>
            <div>
              soit : {{ p.plan.baseSubscriptionPriceHT }}€ <span class="fs-1">HT</span> + <span class="text-primary fw-700">{{ nbPrintersCalcul }}</span>
              x
              {{ p.plan.printerSubscriptionPriceHT }}€ <span class="fs-1">HT</span>
            </div>
          </div>
          <div class="mb-4">
            <ng-container *ngIf="!p.plan.planButton">
              <button
                class="btn btn-success w-100 px-0"
                (click)="planClicked($event, p)"
                [innerHTML]="idPreviousPlan !== null && idPreviousPlan === p.plan.id ? 'Continuer avec<br /> cet abonnement' : 'Choisir cet abonnement'"
              ></button>
            </ng-container>
            <ng-container *ngIf="p.plan.planButton && legalStatus !== null">
              <button class="btn btn-success w-100 px-0" (click)="planClicked($event, p)">Nous contacter</button>
            </ng-container>
            <ng-container *ngIf="p.plan.planButton && legalStatus === null">
              <app-calendly [disable]="isDisabled(p)" [inputClass]="'btn btn-success w-100'" [text]="'Nous contacter'"> </app-calendly>
            </ng-container>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="p-3 rounded fw-500">Nombre max. d'utilisateurs</td>
        <td *ngFor="let p of getPlansFiltered()" [class.disabled]="isDisabled(p)">
          <ng-container *ngIf="p.plan.nbMaxUsers > 0">
            {{ p.plan.nbMaxUsers }}
          </ng-container>
          <ng-container *ngIf="p.plan.nbMaxUsers == 0"> Illimité </ng-container>
        </td>
      </tr>
      <tr>
        <td class="p-3 rounded fw-500">Nombre max. d'imprimantes connectées</td>
        <td *ngFor="let p of getPlansFiltered()" [class.disabled]="isDisabled(p)">
          <ng-container *ngIf="p.plan.nbMaxPrinter > 0">
            {{ p.plan.nbMaxPrinter }}
          </ng-container>
          <ng-container *ngIf="p.plan.nbMaxPrinter == 0"> Illimité </ng-container>
        </td>
      </tr>
      <tr>
        <td class="p-3 rounded fw-500">Frais de fonctionnement</td>
        <td *ngFor="let p of getPlansFiltered()" [class.disabled]="isDisabled(p)">{{ p.plan.comissionPercent }} % de commission</td>
      </tr>
      <tr *ngFor="let fname of functionalities; let i = index">
        <td class="p-3 rounded fw-500">{{ fname.functionality.name }}</td>
        <td *ngFor="let p of getPlansFiltered()" [class.disabled]="isDisabled(p)">
          <ng-container *ngIf="p.functionalities[i].value">
            <i class="fas fa-check text-success"></i>
          </ng-container>
          <ng-container *ngIf="!p.functionalities[i].value">
            <i class="fas fa-times text-danger"></i>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>

  <!--Mobile View-->

  <div *ngIf="mobile" class="container d-flex flex-column align-items-center">
    <div class="row justify-content-center mb-5">
      <ng-container *ngFor="let p of getPlansFiltered(); let i = index">
        <ng-container *ngIf="!isDisabled(p)">
          <div class="d-inline mx-2 border-primary pointer fs-4" [class.border-bottom-2px]="p.plan.id == selectedPlan.plan.id" (click)="selectPlan(i)">
            {{ p.plan.name }}
          </div>
        </ng-container>
        <ng-container *ngIf="isDisabled(p)">
          <div class="d-inline mx-2 border-primary pointer text-light fs-4" [class.border-bottom-2px]="p.plan.id == selectedPlan.plan.id">
            {{ p.plan.name }}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="row d-flex flex-column align-items-center position-relative w-100" style="max-width: 40rem; min-width: 18rem">
      <div class="position-absolute pointer" style="left: 2rem; z-index: 10; margin-top: 2.3rem" (click)="previousPlan(1)">
        <i class="fas fa-chevron-left fa-3x text-primary chevrons"></i>
      </div>
      <div class="position-absolute pointer" style="right: 2rem; z-index: 10; margin-top: 2.3rem" (click)="nextPlan(1)">
        <i class="fas fa-chevron-right fa-3x text-primary chevrons"></i>
      </div>
      <div *ngIf="selectedPlan" class="rounded shadow-2 p-3 text-center" style="width: 18rem" [class.disabled]="isDisabled(selectedPlan)">
        <h2 class="text-primary fs-5 fw-400 my-4">{{ selectedPlan.plan.name }}</h2>
        <div class="mb-3">
          <span class="fs-5 fw-700"
            >{{ selectedPlan.plan.baseSubscriptionPriceHT + selectedPlan.plan.printerSubscriptionPriceHT * nbPrintersCalcul }}€&nbsp;</span
          >/mois (HT)
        </div>
        <div class="mb-4 fw-400">
          <div class="fs-2 fw-300">Prix de base : {{ selectedPlan.plan.baseSubscriptionPriceHT }}€ <span class="fs-1">HT</span></div>
          <div class="fs-2 fw-300">+ {{ selectedPlan.plan.printerSubscriptionPriceHT }}€ <span class="fs-1">HT</span> par imprimante 3D</div>
          <div>
            soit : {{ selectedPlan.plan.baseSubscriptionPriceHT }}€ <span class="fs-1">HT</span> +
            <span class="text-primary fw-700">{{ nbPrintersCalcul }}</span>
            x
            {{ selectedPlan.plan.printerSubscriptionPriceHT }}€ <span class="fs-1">HT</span>
          </div>
        </div>
        <div class="mb-4">
          <ng-container *ngIf="!selectedPlan.plan.planButton">
            <button
              class="btn btn-success w-100 px-0"
              (click)="planClicked($event, selectedPlan)"
              [innerHTML]="
                idPreviousPlan !== null && idPreviousPlan === selectedPlan.plan.id ? 'Continuer avec<br /> cet abonnement' : 'Choisir cet abonnement'
              "
            ></button>
          </ng-container>
          <ng-container *ngIf="selectedPlan.plan.planButton && legalStatus !== null">
            <button class="btn btn-success w-100 px-0" (click)="planClicked($event, selectedPlan)">Nous contacter</button>
          </ng-container>
          <ng-container *ngIf="selectedPlan.plan.planButton && legalStatus === null">
            <app-calendly [disable]="isDisabled(selectedPlan)" [inputClass]="'btn btn-success w-100'" [text]="'Nous contacter'"> </app-calendly>
          </ng-container>
        </div>

        <div class="mb-3">
          <ng-container *ngIf="selectedPlan.plan.nbMaxUsers > 0">
            <span class="fw-700">{{ selectedPlan.plan.nbMaxUsers }}</span> utilisateurs
          </ng-container>
          <ng-container *ngIf="selectedPlan.plan.nbMaxUsers == 0"> Utilisateurs <span class="fw-700">illimités</span> </ng-container>
        </div>
        <div class="mb-3">
          <ng-container *ngIf="selectedPlan.plan.nbMaxPrinter > 0">
            <span class="fw-700">{{ selectedPlan.plan.nbMaxPrinter }}</span> imprimantes
          </ng-container>
          <ng-container *ngIf="selectedPlan.plan.nbMaxPrinter == 0"> Imprimantes <span class="fw-700">illimités</span> </ng-container>
        </div>
        <div class="mb-3">
          <span class="fw-700">{{ selectedPlan.plan.comissionPercent }} %</span> de frais de commission
        </div>
        <div *ngFor="let f of selectedPlan.functionalities; let i = index" class="mb-3 text-left row">
          <div class="col-1">
            <ng-container *ngIf="f.value">
              <i class="fas fa-check text-success"></i>
            </ng-container>
            <ng-container *ngIf="!f.value">
              <i class="fas fa-times text-danger"></i>
            </ng-container>
          </div>
          <span class="col">{{ functionalities[i].functionality.name }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
