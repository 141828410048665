import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProducerSVDTO } from '../../ic2/entities/entities';
import { ProducerSVService } from '../../ic2/services/services';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProducerResolverService implements Resolve<ProducerSVDTO> {
  editMode: boolean = true;

  constructor(@Inject(ProducerSVService) public producerSVService: ProducerSVService, private authService: AuthService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ProducerSVDTO> | Promise<ProducerSVDTO> | ProducerSVDTO {
    return new Observable((obs) => {
      let id = +route.paramMap.get('id');
      if (id === undefined || id === 0) {
        id = this.authService.userBundle.idProducer;
      }
      this.producerSVService.getProducer(id, this.editMode).subscribe(
        (data) => {
          obs.next(data);
          obs.complete();
        },
        (err) => {
          this.router.navigate(['/annuaire']);
          obs.error(err);
          obs.complete();
        }
      );
    });
  }
}
