import { Component, Input, Output, EventEmitter, ContentChildren, HostListener, QueryList, AfterViewInit } from '@angular/core';
import { OrderBy } from '../../ic2/entities/OrderBy';
import { FilterOrder } from '../../ic2/entities/entities';
import { MyColumn } from './my-column/my-column.component';
import { MyFooter } from './my-footer/my-footer.component';

@Component({
  selector: 'degineo-my-table',
  templateUrl: './my-table.component.html',
  styleUrls: ['./my-table.component.scss'],
})
export class MyTableComponent<T> implements AfterViewInit {
  OrderBy: typeof OrderBy = OrderBy;
  private ctrl: boolean = false;
  @Input()
  rows: T[];
  @Input()
  rowLink: string[] = [];
  @Input()
  orderCache: object = {};
  @Input()
  rowIdExtractor: (row: T) => any = (row: any) => row.id;
  @Input()
  builtFiltersOrder: FilterOrder[] = [];
  @Output()
  orderChange: EventEmitter<FilterOrder[]> = new EventEmitter<FilterOrder[]>();
  @Output()
  rowClick = new EventEmitter();
  @ContentChildren(MyColumn) columns: QueryList<MyColumn<T>>;
  @ContentChildren(MyFooter) footer: QueryList<MyFooter<T>>;

  @Input()
  pointer: boolean = true;
  @Input()
  hoverable: boolean = true;

  @HostListener('document:keydown.Control', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.ctrl = true;
  }
  @HostListener('document:keyup.Control', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.ctrl = false;
  }

  footerTab = [];

  constructor() {}

  ngAfterViewInit() {
    if (this.footer && this.footer.first && this.footer.first.columns) {
      let footerCols = this.footer.first.columns;
      this.columns.map((a, index) => {
        let col = footerCols.find((e) => e.name === a.name);
        this.footerTab[index] = col;
      });
    }

    if (this.orderCache && Object.keys(this.orderCache).length > 0)
      this.columns.map((a) => {
        if (this.orderCache[a.name]) {
          a.selected = true;
          a.sortOrder = this.orderCache[a.name].sortOrder;
          a.orderIndex = this.orderCache[a.name].orderIndex;
        } else {
          a.selected = false;
          a.orderIndex = 1;
        }
      });
    this.buildFilterOrder(false);
  }

  buildFilterOrder(shouldEmitOrderChangeEvent: boolean) {
    if (this.builtFiltersOrder === null || this.builtFiltersOrder === undefined) {
      console.error('builtFiltersOrder is', this.builtFiltersOrder);
    }
    let copy = this.columns.map((a) => a);
    copy.sort((a, b) => a.orderIndex - b.orderIndex);
    let res: FilterOrder[] = copy
      .filter((col) => col.selected)
      .map((col) => {
        let fo = new FilterOrder();
        fo.name = col.orderName;
        fo.order = col.sortOrder;
        return fo;
      });
    this.builtFiltersOrder.splice(0, this.builtFiltersOrder.length);
    res.forEach((element) => {
      this.builtFiltersOrder.push(element);
    });
    if (shouldEmitOrderChangeEvent) this.orderChange.emit(this.builtFiltersOrder);
  }

  getLink(row: T) {
    if (this.rowLink.length === 0) return null;
    let copy = this.rowLink.slice();
    copy.push(this.rowIdExtractor(row));
    return copy;
  }

  onRowClick(row: T) {
    this.rowClick.emit(row);
  }

  changeOrder(column) {
    if (column.disableOrder) return;

    if (this.ctrl) {
      let colClicked = this.columns.find((col) => col === column);
      let nbColDejaSelect = this.columns.filter((col) => col.selected).length;
      colClicked.toggleSortOrder();
      colClicked.selected = true;
      colClicked.orderIndex = nbColDejaSelect + 1;
      if (this.orderCache) this.orderCache[colClicked.name] = { sortOrder: colClicked.sortOrder, orderIndex: colClicked.orderIndex };
    } else {
      this.columns.forEach((col) => {
        col.selected = false;
        if (this.orderCache) delete this.orderCache[col.name];
      });
      this.columns.filter((col) => col !== column).forEach((col) => (col.sortOrder = OrderBy.DESC));
      let colClicked = this.columns.find((col) => col === column);
      colClicked.toggleSortOrder();
      if (this.orderCache) this.orderCache[colClicked.name] = { sortOrder: colClicked.sortOrder, orderIndex: colClicked.orderIndex };
      colClicked.selected = true;
    }
    this.buildFilterOrder(true);
  }

  inversColOrder(order, orderName) {
    return order.forEach((o) => {
      if (o.row === orderName) {
        if (o.type === OrderBy.DESC) {
          o.type = OrderBy.ASC;
        } else {
          o.type = OrderBy.DESC;
        }
      }
    });
  }

  isColored(column: MyColumn<T>, sortOrder: OrderBy) {
    if (column.selected)
      if (column.sortOrder === sortOrder) {
        return null;
      }

    return 'medium';
  }
}
