// com.degineo.metier.plan.service.SubscriptionPlanSVServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { PlanDTO } from '../entities/PlanDTO';
import { FunctionalityDTO } from '../entities/FunctionalityDTO';
import { ProducerTarget } from '../entities/ProducerTarget';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlanSVService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPlansWithTarget(pt: ProducerTarget): RpcRequestBuilder<PlanDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SubscriptionPlanSVService.getPlansWithTarget';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(pt, 'com.degineo.metier.plan.entities.ProducerTarget', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getFunctionalities(): RpcRequestBuilder<FunctionalityDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SubscriptionPlanSVService.getFunctionalities';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
