// ENTITY com.degineo.metier.consumable.dto.bo.ConsumableBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ConsumableOptionBODTO } from './ConsumableOptionBODTO';
import { ConsumableAssociatedPrinterBODTO } from './ConsumableAssociatedPrinterBODTO';
import { ConsumableCharacteristicValueNEW } from './ConsumableCharacteristicValueNEW';
import { PrinterTechnology } from './PrinterTechnology';
import { ConsumableAssociatedProducerBODTO } from './ConsumableAssociatedProducerBODTO';
import { ConsumableNEW } from './ConsumableNEW';
import { CharacteristicNEW } from './CharacteristicNEW';

export class ConsumableBODTO {

  consumable: ConsumableNEW = null;
  MaterialTypeName: string = '';
  materialPrinterTechnology: PrinterTechnology = null;
  options: ConsumableOptionBODTO[] = null;
  characs: CharacteristicNEW[] = null;
  consuChara: ConsumableCharacteristicValueNEW[] = null;
  assocPrinter: ConsumableAssociatedPrinterBODTO[] = null;
  assocProducer: ConsumableAssociatedProducerBODTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.consumable.dto.bo.ConsumableBODTO'] = ConsumableBODTO;

reverseMapping[ConsumableBODTO.name] = 'com.degineo.metier.consumable.dto.bo.ConsumableBODTO';

fields['com.degineo.metier.consumable.dto.bo.ConsumableBODTO']  = {
    consumable: 'com.degineo.metier.metadata.entities.ConsumableNEW',
    MaterialTypeName: 'java.lang.String',
    materialPrinterTechnology: 'com.degineo.metier.metadata.entities.PrinterTechnology',
    options: 'java.util.List<com.degineo.metier.consumable.dto.bo.ConsumableOptionBODTO>',
    characs: 'java.util.List<com.degineo.metier.metadata.entities.CharacteristicNEW>',
    consuChara: 'java.util.List<com.degineo.metier.metadata.entities.ConsumableCharacteristicValueNEW>',
    assocPrinter: 'java.util.List<com.degineo.metier.consumable.dto.bo.ConsumableAssociatedPrinterBODTO>',
    assocProducer: 'java.util.List<com.degineo.metier.consumable.dto.bo.ConsumableAssociatedProducerBODTO>'
};
