// com.degineo.metier.producer.service.ProducerCompanyInfoServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerCompanyInfosDTO } from '../entities/ProducerCompanyInfosDTO';
import { LegalStatus } from '../entities/LegalStatus';
import { AddressNEW } from '../entities/AddressNEW';

@Injectable({
  providedIn: 'root',
})
export class ProducerCompanyInfoService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateBillingInformations(stripePaymentMethodId: string, iban: string, bic: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateBillingInformations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(stripePaymentMethodId, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(iban, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(bic, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateProductionDelay(nbDays: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateProductionDelay';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(nbDays, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateCapital(capital: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateCapital';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(capital, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateLegalStatus(legalStatus: LegalStatus): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateLegalStatus';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(legalStatus, 'com.degineo.metier.producer.entities.LegalStatus', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateRCS(RCS: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateRCS';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(RCS, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateRNANumber(RNANumber: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateRNANumber';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(RNANumber, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateUseVAT(useVAT: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateUseVAT';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(useVAT, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfos(): RpcRequestBuilder<ProducerCompanyInfosDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.getInfos';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerCompanyInfosDTO');
  }

  updateName(name: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateName';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateDenomination(denomination: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateDenomination';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(denomination, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateBillingAddress(address: AddressNEW): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateBillingAddress';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(address, 'com.degineo.metier.address.entities.AddressNEW', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateCollectAddress(address: AddressNEW): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.updateCollectAddress';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(address, 'com.degineo.metier.address.entities.AddressNEW', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getStripeClientSecret(): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerCompanyInfoService.getStripeClientSecret';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

}
