// ENTITY com.degineo.metier.basket.dto.PrintStep1DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ArticleTypePrintStep1DTO } from './ArticleTypePrintStep1DTO';
import { AssociatedProducerDTO } from './AssociatedProducerDTO';

export class PrintStep1DTO {

  articles: ArticleTypePrintStep1DTO[] = null;
  producers: AssociatedProducerDTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.basket.dto.PrintStep1DTO'] = PrintStep1DTO;

reverseMapping[PrintStep1DTO.name] = 'com.degineo.metier.basket.dto.PrintStep1DTO';

fields['com.degineo.metier.basket.dto.PrintStep1DTO']  = {
    articles: 'java.util.List<com.degineo.metier.basket.dto.ArticleTypePrintStep1DTO>',
    producers: 'java.util.List<com.degineo.metier.basket.dto.AssociatedProducerDTO>'
};
