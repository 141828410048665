// com.degineo.metier.order.service.OrderBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { OrderListItemBODTO } from '../entities/OrderListItemBODTO';
import { OrderBODTO } from '../entities/OrderBODTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';

@Injectable({
  providedIn: 'root',
})
export class OrderBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getOrder(idOrder: number): RpcRequestBuilder<OrderBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrderBOService.getOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.bo.OrderBODTO');
  }

  getOrders(filter: GenericFilter): RpcRequestBuilder<ListWithCount<OrderListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrderBOService.getOrders';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  saveDegineoNote(idOrder: number, note: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrderBOService.saveDegineoNote';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(note, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  checkOrder(idOrder: number, check: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrderBOService.checkOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(check, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  payOrder(idOrder: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrderBOService.payOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateOrderModel(idOrder: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrderBOService.updateOrderModel';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getOrderCount(idClient: number): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OrderBOService.getOrderCount';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idClient, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

}
