// ENTITY com.degineo.metier.metadata.entities.ConsumableCharacteristicValueNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ConsumableCharacteristicValueNEW {

  id: number = 0;
  idConsumable: number = 0;
  idCharacteristic: number = 0;
  valueStr: string = '';
  valueDouble: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.ConsumableCharacteristicValueNEW'] = ConsumableCharacteristicValueNEW;

reverseMapping[ConsumableCharacteristicValueNEW.name] = 'com.degineo.metier.metadata.entities.ConsumableCharacteristicValueNEW';

fields['com.degineo.metier.metadata.entities.ConsumableCharacteristicValueNEW']  = {
    id: 'java.lang.Integer',
    idConsumable: 'java.lang.Integer',
    idCharacteristic: 'java.lang.Integer',
    valueStr: 'java.lang.String',
    valueDouble: 'java.lang.Double'
};
