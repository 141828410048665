// ENTITY com.degineo.metier.address.entities.AddressNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class AddressNEW {

  id: number = 0;
  idOldAddress: number = 0;
  societeOrPerson: string = '';
  line1: string = '';
  line2: string = '';
  line3: string = '';
  zipCode: string = '';
  city: string = '';
  country: string = '';
  dateCreated: Date = null;
  principale: boolean = false;
  facturation: boolean = false;
  livraison: boolean = false;
  collecte: boolean = false;
  latitude: number = 0;
  longitude: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.address.entities.AddressNEW'] = AddressNEW;

reverseMapping[AddressNEW.name] = 'com.degineo.metier.address.entities.AddressNEW';

fields['com.degineo.metier.address.entities.AddressNEW']  = {
    id: 'java.lang.Integer',
    idOldAddress: 'java.lang.Integer',
    societeOrPerson: 'java.lang.String',
    line1: 'java.lang.String',
    line2: 'java.lang.String',
    line3: 'java.lang.String',
    zipCode: 'java.lang.String',
    city: 'java.lang.String',
    country: 'java.lang.String',
    dateCreated: 'java.time.LocalDateTime',
    principale: 'java.lang.Boolean',
    facturation: 'java.lang.Boolean',
    livraison: 'java.lang.Boolean',
    collecte: 'java.lang.Boolean',
    latitude: 'java.lang.Double',
    longitude: 'java.lang.Double'
};
