// DB ENUM com.degineo.metier.invoice.dto.producer.InvoiceProducerStatus generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class InvoiceProducerStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly PAYMENT_OK = new InvoiceProducerStatus(1, "Paiement Ã  jour", "PAYMENT_OK");
  static readonly DELAYED_PAYMENT_WAITING = new InvoiceProducerStatus(2, "Paiement fin de mois en attente", "DELAYED_PAYMENT_WAITING");
  static readonly LATE_PAYMENT = new InvoiceProducerStatus(3, "Paiement en retard", "LATE_PAYMENT");
  static readonly ORDER_PENDING = new InvoiceProducerStatus(4, "Commande en cours", "ORDER_PENDING");
  static readonly INVOICE_CANCELLED = new InvoiceProducerStatus(5, "Commande annulÃ©e", "INVOICE_CANCELLED");
  static readonly WAITING_PAYMENT = new InvoiceProducerStatus(6, "Paiement en attente", "WAITING_PAYMENT");

  static readonly values = [
    InvoiceProducerStatus.PAYMENT_OK, 
    InvoiceProducerStatus.DELAYED_PAYMENT_WAITING, 
    InvoiceProducerStatus.LATE_PAYMENT, 
    InvoiceProducerStatus.ORDER_PENDING, 
    InvoiceProducerStatus.INVOICE_CANCELLED, 
    InvoiceProducerStatus.WAITING_PAYMENT 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of InvoiceProducerStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of InvoiceProducerStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.invoice.dto.producer.InvoiceProducerStatus'] = InvoiceProducerStatus;
reverseMapping[InvoiceProducerStatus.name] = 'com.degineo.metier.invoice.dto.producer.InvoiceProducerStatus';
