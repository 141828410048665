// com.degineo.metier.consumable.service.ConsumableBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ConsumableBODTO } from '../entities/ConsumableBODTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { ConsumableListItemBODTO } from '../entities/ConsumableListItemBODTO';
import { CharacteristicNEW } from '../entities/CharacteristicNEW';

@Injectable({
  providedIn: 'root',
})
export class ConsumableBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getMaterialTypeCharacteristic(idMaterialType: number): RpcRequestBuilder<CharacteristicNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ConsumableBOService.getMaterialTypeCharacteristic';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idMaterialType, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getConsumables(filter: GenericFilter): RpcRequestBuilder<ListWithCount<ConsumableListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ConsumableBOService.getConsumables';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getConsumableDetail(idConsumable: number): RpcRequestBuilder<ConsumableBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ConsumableBOService.getConsumableDetail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idConsumable, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.consumable.dto.bo.ConsumableBODTO');
  }

  updateConsomable(consomable: ConsumableBODTO): RpcRequestBuilder<ConsumableBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ConsumableBOService.updateConsomable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(consomable, 'com.degineo.metier.consumable.dto.bo.ConsumableBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.consumable.dto.bo.ConsumableBODTO');
  }

  deleteConsomable(idConsumable: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ConsumableBOService.deleteConsomable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idConsumable, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  newConsomable(consumable: ConsumableBODTO): RpcRequestBuilder<ConsumableBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ConsumableBOService.newConsomable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(consumable, 'com.degineo.metier.consumable.dto.bo.ConsumableBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.consumable.dto.bo.ConsumableBODTO');
  }

}
