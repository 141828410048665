// com.degineo.metier.order.service.ClientMarqueBlancheServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ArticleTypeNEW } from '../entities/ArticleTypeNEW';
import { SizeDTO } from '../entities/SizeDTO';
import { PrintDTO } from '../entities/PrintDTO';
import { ProducerMarqueBlancheInfosDTO } from '../entities/ProducerMarqueBlancheInfosDTO';

@Injectable({
  providedIn: 'root',
})
export class ClientMarqueBlancheService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getArticles(idProducteur: number): RpcRequestBuilder<ArticleTypeNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientMarqueBlancheService.getArticles';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducteur, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getInfos(idProducer: number): RpcRequestBuilder<ProducerMarqueBlancheInfosDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientMarqueBlancheService.getInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.ProducerMarqueBlancheInfosDTO');
  }

  createOrder(fileHashes: string[], name: string, description: string, idProducer: number, idArticleType: number, idMaterial: number, idColor: number, multicolor: boolean, idFinish: number, sizeInMm: SizeDTO, quantity: number, wishedDeliveryDate: Date): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientMarqueBlancheService.createOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fileHashes, 'java.util.List<java.lang.String>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(description, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(multicolor, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(quantity, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(wishedDeliveryDate, 'java.time.Instant', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  createDevis(fileHash: string, name: string, description: string, idProducer: number, idArticleType: number, idMaterial: number, idColor: number, multicolor: boolean, idFinish: number, sizeInMm: SizeDTO, quantity: number, wishedDeliveryDate: Date): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientMarqueBlancheService.createDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fileHash, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(description, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(multicolor, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(quantity, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(wishedDeliveryDate, 'java.time.Instant', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getSVPrint(token: string): RpcRequestBuilder<PrintDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientMarqueBlancheService.getSVPrint';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.vitrine.dto.PrintDTO');
  }

}
