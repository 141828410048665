// com.degineo.metier.invoice.service.ClientInvoiceServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { InvoiceListItemDTO } from '../entities/InvoiceListItemDTO';
import { ProducerDelayedOrdersDTO } from '../entities/ProducerDelayedOrdersDTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { BillerDTO } from '../entities/BillerDTO';
import { ClientInvoiceDTO } from '../entities/ClientInvoiceDTO';

@Injectable({
  providedIn: 'root',
})
export class ClientInvoiceService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getDelayedInvoice(idInvoice: number): RpcRequestBuilder<ClientInvoiceDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientInvoiceService.getDelayedInvoice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInvoice, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.invoice.dto.ClientInvoiceDTO');
  }

  getProducerDelayedOrders(idProducer: number): RpcRequestBuilder<ProducerDelayedOrdersDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientInvoiceService.getProducerDelayedOrders';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.invoice.dto.ProducerDelayedOrdersDTO');
  }

  getMyBillers(text: string): RpcRequestBuilder<BillerDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientInvoiceService.getMyBillers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(text, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  markInvoicePaid(idInvoice: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientInvoiceService.markInvoicePaid';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInvoice, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInvoices(filter: GenericFilter): RpcRequestBuilder<ListWithCount<InvoiceListItemDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientInvoiceService.getInvoices';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
