// com.degineo.metier.metadata.service.PrinterBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { PrinterListItemBODTO } from '../entities/PrinterListItemBODTO';
import { PrinterBODTO } from '../entities/PrinterBODTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';

@Injectable({
  providedIn: 'root',
})
export class PrinterBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPrinters(filter: GenericFilter): RpcRequestBuilder<ListWithCount<PrinterListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrinterBOService.getPrinters';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  deletePrinter(id: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrinterBOService.deletePrinter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getPrinterDetail(id: number): RpcRequestBuilder<PrinterBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrinterBOService.getPrinterDetail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.metadata.dto.PrinterBODTO');
  }

  uploadImg(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrinterBOService.uploadImg';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updatePrinter(printer: PrinterBODTO, idPrinter: number): RpcRequestBuilder<PrinterBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrinterBOService.updatePrinter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(printer, 'com.degineo.metier.metadata.dto.PrinterBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.metadata.dto.PrinterBODTO');
  }

  newPrinter(printer: PrinterBODTO): RpcRequestBuilder<PrinterBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrinterBOService.newPrinter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(printer, 'com.degineo.metier.metadata.dto.PrinterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.metadata.dto.PrinterBODTO');
  }

}
