// ENTITY com.degineo.metier.producer_printer.dto.PPATPFullDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class PPATPFullDTO {

  idProducerPrinterArticleTypePrice: number = 0;
  name: string = '';
  unitPrice: number = 0;
  advisedUnitPrice: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.PPATPFullDTO'] = PPATPFullDTO;

reverseMapping[PPATPFullDTO.name] = 'com.degineo.metier.producer_printer.dto.PPATPFullDTO';

fields['com.degineo.metier.producer_printer.dto.PPATPFullDTO']  = {
    idProducerPrinterArticleTypePrice: 'java.lang.Integer',
    name: 'java.lang.String',
    unitPrice: 'java.lang.Double',
    advisedUnitPrice: 'java.lang.Double'
};
