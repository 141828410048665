// DB ENUM com.degineo.metier.producer.entities.LegalStatus generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class LegalStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly STATUS_SA = new LegalStatus(1, "SA", "STATUS_SA");
  static readonly STATUS_SAS = new LegalStatus(2, "SAS", "STATUS_SAS");
  static readonly STATUS_SARL = new LegalStatus(3, "SARL", "STATUS_SARL");
  static readonly STATUS_ASSOCIATION = new LegalStatus(9, "Association dÃ©clarÃ©e", "STATUS_ASSOCIATION");
  static readonly STATUS_AE = new LegalStatus(4, "AE â¢ Auto & Micro-entrepreneur", "STATUS_AE");
  static readonly STATUS_EI = new LegalStatus(5, "EI â¢ Entreprise individuelle", "STATUS_EI");
  static readonly STATUS_EIRL = new LegalStatus(6, "EIRL", "STATUS_EIRL");
  static readonly STATUS_EURL = new LegalStatus(7, "EURL", "STATUS_EURL");
  static readonly STATUS_SASU = new LegalStatus(8, "SASU", "STATUS_SASU");

  static readonly values = [
    LegalStatus.STATUS_SA, 
    LegalStatus.STATUS_SAS, 
    LegalStatus.STATUS_SARL, 
    LegalStatus.STATUS_ASSOCIATION, 
    LegalStatus.STATUS_AE, 
    LegalStatus.STATUS_EI, 
    LegalStatus.STATUS_EIRL, 
    LegalStatus.STATUS_EURL, 
    LegalStatus.STATUS_SASU 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of LegalStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of LegalStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.producer.entities.LegalStatus'] = LegalStatus;
reverseMapping[LegalStatus.name] = 'com.degineo.metier.producer.entities.LegalStatus';
