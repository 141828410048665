import { Component, OnInit, Output, Input, EventEmitter, Host } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { DateStringFr } from '../../dateStringFr';
import { FilterComponent } from '../../filter.component';
import { Filter } from '../../../../ic2/entities/Filter';

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}

@Component({
  selector: 'degineo-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Input()
  name: string;
  @Input()
  filterName: string;
  @Input()
  filterSetter: (item: any, filter: Filter) => void = (item, filter) => {
    filter.dateValues = [new Date(item.singleDate.jsDate)];
    if (this.host.cache) this.host.cache[this.filterName] = item.singleDate.jsDate;
  };
  @Input()
  Filter: Filter;
  @Output()
  change: EventEmitter<Filter> = new EventEmitter<Filter>();

  private _required: boolean;
  @Input()
  get required() {
    return this._required;
  }
  set required(value: any) {
    this._required = coerceBooleanProperty(value);
  }

  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  value: IMyDateModel = null;

  public INgxMyDpOptions: IAngularMyDpOptions = DateStringFr.INgxMyDpOptions;

  constructor(@Host() private host: FilterComponent) {}

  ngOnInit() {
    if (this.host.cache && this.host.cache[this.filterName] !== undefined && this.host.cache[this.filterName] !== null) {
      if (this.host.cache[this.filterName]) this.value = { isRange: false, singleDate: { jsDate: this.host.cache[this.filterName] } };
    } else if (this.host.default && this.host.default[this.filterName]) {
      this.value = { isRange: false, singleDate: { jsDate: this.host.default[this.filterName] } };
    }
    this.update();
  }

  clear() {
    this.value = null;
    this.update();
  }

  reset() {
    if (this.host.default && this.host.default[this.filterName]) {
      this.value = { isRange: false, singleDate: { jsDate: this.host.default[this.filterName] } };
    } else {
      this.value = null;
    }
    this.update();
  }

  update() {
    if (this.host.cache) {
      if (this.value && this.value !== null) this.host.cache[this.filterName] = this.value.singleDate.jsDate;
      else this.host.cache[this.filterName] = null;
    }

    if (this.value) {
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(this.value, this.Filter);
    } else {
      this.Filter = null;
    }

    this.change.emit(this.Filter);
  }

  filterChanges(value: any) {
    if (value) {
      if (this.host.cache) {
        this.host.cache[this.filterName] = value.singleDate.jsDate;
      }
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(value, this.Filter);
    } else {
      this.Filter = null;
      if (this.host.cache) {
        this.host.cache[this.filterName] = null;
      }
    }
    this.change.emit(this.Filter);
  }
}
