// ENTITY com.degineo.metier.devis.dto.ClientDevisDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DevisStatus } from './DevisStatus';

export class ClientDevisDTO {

  idDevis: number = 0;
  numDevisProducteur: number = 0;
  status: DevisStatus = null;
  name: string = '';
  producerName: string = '';
  articleTypeName: string = '';
  tokenPreview: string = '';
  token3DModel: string = '';
  filename: string = '';
  sizeInMmX: number = 0;
  sizeInMmY: number = 0;
  sizeInMmZ: number = 0;
  materialTypeName: string = '';
  colorName: string = '';
  finishName: string = '';
  quantity: number = 0;
  clientComment: string = '';
  clientModificationComment: string = '';
  clientRefusalComment: string = '';
  producerRefusalComment: string = '';
  wishedDeliveryDate: Date = null;
  proposedDeliveryDate: Date = null;
  alreadyInBasket: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.devis.dto.ClientDevisDTO'] = ClientDevisDTO;

reverseMapping[ClientDevisDTO.name] = 'com.degineo.metier.devis.dto.ClientDevisDTO';

fields['com.degineo.metier.devis.dto.ClientDevisDTO']  = {
    idDevis: 'java.lang.Integer',
    numDevisProducteur: 'java.lang.Integer',
    status: 'com.degineo.metier.devis.entities.DevisStatus',
    name: 'java.lang.String',
    producerName: 'java.lang.String',
    articleTypeName: 'java.lang.String',
    tokenPreview: 'java.lang.String',
    token3DModel: 'java.lang.String',
    filename: 'java.lang.String',
    sizeInMmX: 'java.lang.Double',
    sizeInMmY: 'java.lang.Double',
    sizeInMmZ: 'java.lang.Double',
    materialTypeName: 'java.lang.String',
    colorName: 'java.lang.String',
    finishName: 'java.lang.String',
    quantity: 'java.lang.Integer',
    clientComment: 'java.lang.String',
    clientModificationComment: 'java.lang.String',
    clientRefusalComment: 'java.lang.String',
    producerRefusalComment: 'java.lang.String',
    wishedDeliveryDate: 'java.time.LocalDateTime',
    proposedDeliveryDate: 'java.time.LocalDateTime',
    alreadyInBasket: 'java.lang.Boolean'
};
