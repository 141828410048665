// ENTITY com.degineo.metier.devis.dto.ClientDevisListItemDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DevisStatus } from './DevisStatus';

export class ClientDevisListItemDTO {

  idDevis: number = 0;
  status: DevisStatus = null;
  name: string = '';
  creationDate: Date = null;
  validUntil: Date = null;
  acceptDate: Date = null;
  sentDate: Date = null;
  clientModificationCommentDate: Date = null;
  clientRefusalCommentDate: Date = null;
  producerRefusalCommentDate: Date = null;
  modelePNGToken: string = '';
  producerLogoToken: string = '';
  alreadyInBasket: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.devis.dto.ClientDevisListItemDTO'] = ClientDevisListItemDTO;

reverseMapping[ClientDevisListItemDTO.name] = 'com.degineo.metier.devis.dto.ClientDevisListItemDTO';

fields['com.degineo.metier.devis.dto.ClientDevisListItemDTO']  = {
    idDevis: 'java.lang.Integer',
    status: 'com.degineo.metier.devis.entities.DevisStatus',
    name: 'java.lang.String',
    creationDate: 'java.time.LocalDateTime',
    validUntil: 'java.time.LocalDateTime',
    acceptDate: 'java.time.LocalDateTime',
    sentDate: 'java.time.LocalDateTime',
    clientModificationCommentDate: 'java.time.LocalDateTime',
    clientRefusalCommentDate: 'java.time.LocalDateTime',
    producerRefusalCommentDate: 'java.time.LocalDateTime',
    modelePNGToken: 'java.lang.String',
    producerLogoToken: 'java.lang.String',
    alreadyInBasket: 'java.lang.Boolean'
};
