<select [disabled]="disabled" [compareWith]="compareFn" class="form-control" [(ngModel)]="value"
  (change)="filterChanges(value)">
  <option [ngValue]="null">-- {{name}} --</option>
  <option *ngFor="let v of values" [ngValue]="v">{{presenter(v)}}</option>
</select>
<div class="input-group-append" *ngIf="value">
  <button type="button" class="btn btn-outline-secondary" (click)="clear()">
    <i class="fas fa-times"></i>
  </button>
</div>
