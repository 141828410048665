import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DegineoRight } from '../ic2/entities/DegineoRight';
import { Observable } from 'rxjs';

@Injectable()
export class SubscribedAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {
    return new Observable((observer) => {
      this.authService.isLoggedIn().subscribe(
        (data) => {
          observer.next(this.authService.userBundle.subscribed);
          if (!this.authService.userBundle.subscribed) {
            if (url === '/') url = 'dashboard';
            this.router.navigate(['/abonnement/' + url]);
          }
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
