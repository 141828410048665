import { Component, OnInit } from '@angular/core';
import { LandingChoiceService } from '../../landing-choice.service';
import { CookiesService } from 'ngx-utils-cookies-port';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isMenuCollapsed: boolean = true;
  producerUrl: string = environment.producerUrl;

  constructor(public landingChoiceService: LandingChoiceService, private cookiesService: CookiesService) {}

  ngOnInit() {}

  onResize(event: Event) {
    const w = event.target as Window;
    w.innerWidth > 767 ? (this.isMenuCollapsed = true) : '';
  }

  removeCookies() {
    this.landingChoiceService.choice = null;
    this.cookiesService.remove('landingChoice');
  }
}
