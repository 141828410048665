<ng-template #entreprisePresenter let-obj>
  <div class="container">
    <div class="row">
      <img
        class="align-self-center rounded"
        style="width: 2.3rem; height: 2.3rem"
        src="{{ rpcHost }}companylogo/{{ obj.logoToken }}/logo.png?size=small"
        alt="Logo"
      />
      <div class="container col">
        <div class="row my-2">
          <div class="col-12 text-left fw-500" [innerHTML]="obj.name | highlight: searchText"></div>
          <div class="col-12 text-left fs-2">
            <span [innerHTML]="obj.city | highlight: searchText"></span>
            <span *ngIf="obj.businessType">&nbsp;-&nbsp;{{ obj.businessType }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ic2-textassist
  class="col px-0"
  [placeholder]="placeholder"
  [values]="producersSearch"
  [presenter]="presenterName"
  [templatePresenter]="entreprisePresenter"
  [maxItem]="maxItems"
  [showClearButton]="false"
  [showSearchIcon]="true"
  [searchIconClass]="focused ? 'shadow-2' : 'shadow-1'"
  [inputClass]="focused ? 'form-control search shadow-2' : 'form-control search shadow-1'"
  (textChanges)="entrepriseTextChange($event)"
  (objectChange)="entrepriseChanged($event)"
  [(ngModel)]="searchText"
  (mousedown)="focused = true"
  (focusout)="focused = false"
></ic2-textassist>
