// ENTITY com.degineo.metier.metadata.entities.CharacteristicNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { CharacteristicDisplayType } from './CharacteristicDisplayType';
import { CharacteristicValueType } from './CharacteristicValueType';

export class CharacteristicNEW {

  id: number = 0;
  title: string = '';
  description: string = '';
  characType: CharacteristicValueType = null;
  prefix: string = '';
  suffix: string = '';
  displayType: CharacteristicDisplayType = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.CharacteristicNEW'] = CharacteristicNEW;

reverseMapping[CharacteristicNEW.name] = 'com.degineo.metier.metadata.entities.CharacteristicNEW';

fields['com.degineo.metier.metadata.entities.CharacteristicNEW']  = {
    id: 'java.lang.Integer',
    title: 'java.lang.String',
    description: 'java.lang.String',
    characType: 'com.degineo.metier.metadata.entities.CharacteristicValueType',
    prefix: 'java.lang.String',
    suffix: 'java.lang.String',
    displayType: 'com.degineo.metier.metadata.entities.CharacteristicDisplayType'
};
