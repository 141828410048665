// ENTITY com.degineo.metier.producer.dto.ProducerClientDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ProducerClientPaymentStatus } from './ProducerClientPaymentStatus';
import { AddressNEW } from './AddressNEW';

export class ProducerClientDTO {

  idClient: number = 0;
  companyName: string = '';
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  email: string = '';
  billingAddress: AddressNEW = null;
  paymentStatus: ProducerClientPaymentStatus = null;
  invitationAccepted: boolean = false;
  dateInvitation: Date = null;
  dateInvitationLastRelance: Date = null;
  allowedDelayedPayment: boolean = false;
  dateLastOrder: Date = null;
  totalCAHT: number = 0;
  nbOrder: number = 0;
  nbQuantity: number = 0;
  avgOrderTotalPrice: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerClientDTO'] = ProducerClientDTO;

reverseMapping[ProducerClientDTO.name] = 'com.degineo.metier.producer.dto.ProducerClientDTO';

fields['com.degineo.metier.producer.dto.ProducerClientDTO']  = {
    idClient: 'java.lang.Integer',
    companyName: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    phoneNumber: 'java.lang.String',
    email: 'java.lang.String',
    billingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    paymentStatus: 'com.degineo.metier.producer.dto.ProducerClientPaymentStatus',
    invitationAccepted: 'java.lang.Boolean',
    dateInvitation: 'java.time.Instant',
    dateInvitationLastRelance: 'java.time.Instant',
    allowedDelayedPayment: 'java.lang.Boolean',
    dateLastOrder: 'java.time.Instant',
    totalCAHT: 'java.lang.Double',
    nbOrder: 'java.lang.Integer',
    nbQuantity: 'java.lang.Integer',
    avgOrderTotalPrice: 'java.lang.Double'
};
