// ENTITY com.degineo.metier.producer.dto.ProducerUserDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { RoleInCompany } from './RoleInCompany';

export class ProducerUserDTO {

  idUser: number = 0;
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  role: RoleInCompany = null;
  accountCreationFinished: boolean = false;
  logoToken: string = '';
  job: string = '';
  disabled: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerUserDTO'] = ProducerUserDTO;

reverseMapping[ProducerUserDTO.name] = 'com.degineo.metier.producer.dto.ProducerUserDTO';

fields['com.degineo.metier.producer.dto.ProducerUserDTO']  = {
    idUser: 'java.lang.Integer',
    email: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    role: 'com.degineo.metier.user.entities.RoleInCompany',
    accountCreationFinished: 'java.lang.Boolean',
    logoToken: 'java.lang.String',
    job: 'java.lang.String',
    disabled: 'java.lang.Boolean'
};
