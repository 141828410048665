// ENTITY com.degineo.metier.order.dto.ProducerMarqueBlancheInfosDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerMarqueBlancheInfosDTO {

  idProducer: number = 0;
  logoToken: string = '';
  name: string = '';
  marqueBlancheDevis: boolean = false;
  marqueBlanchePrint: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.ProducerMarqueBlancheInfosDTO'] = ProducerMarqueBlancheInfosDTO;

reverseMapping[ProducerMarqueBlancheInfosDTO.name] = 'com.degineo.metier.order.dto.ProducerMarqueBlancheInfosDTO';

fields['com.degineo.metier.order.dto.ProducerMarqueBlancheInfosDTO']  = {
    idProducer: 'java.lang.Integer',
    logoToken: 'java.lang.String',
    name: 'java.lang.String',
    marqueBlancheDevis: 'java.lang.Boolean',
    marqueBlanchePrint: 'java.lang.Boolean'
};
