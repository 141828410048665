// com.degineo.metier.basket.service.BasketServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { SizeDTO } from '../entities/SizeDTO';
import { BasketDTO } from '../entities/BasketDTO';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  toggleBasketArticle(idBasketArticle: number, checked: boolean): RpcRequestBuilder<BasketDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BasketService.toggleBasketArticle';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBasketArticle, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(checked, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.basket.dto.BasketDTO');
  }

  changeQuantity(idBasketArticle: number, newQuantity: number): RpcRequestBuilder<BasketDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BasketService.changeQuantity';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBasketArticle, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(newQuantity, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.basket.dto.BasketDTO');
  }

  deleteArticle(idBasketArticle: number): RpcRequestBuilder<BasketDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BasketService.deleteArticle';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBasketArticle, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.basket.dto.BasketDTO');
  }

  addBasket(fileHashes: string[], name: string, description: string, idProducer: number, idArticleType: number, idMaterial: number, idColor: number, multicolor: boolean, idFinish: number, sizeInMm: SizeDTO, quantity: number, wishedDeliveryDate: Date, fromMarqueBlancheOrSiteVitrine: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BasketService.addBasket';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fileHashes, 'java.util.List<java.lang.String>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(description, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(multicolor, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(quantity, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(wishedDeliveryDate, 'java.time.Instant', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(fromMarqueBlancheOrSiteVitrine, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getBasket(): RpcRequestBuilder<BasketDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BasketService.getBasket';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.basket.dto.BasketDTO');
  }

}
