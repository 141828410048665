// com.degineo.metier.invoice.service.ProducerInvoiceServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { BillFromDegineoDTO } from '../entities/BillFromDegineoDTO';
import { WaitingBillDTO } from '../entities/WaitingBillDTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { InvoiceProducerDTO } from '../entities/InvoiceProducerDTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerInvoiceService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getWaitingBills(): RpcRequestBuilder<WaitingBillDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerInvoiceService.getWaitingBills';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  invoicePaid(idInvoice: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerInvoiceService.invoicePaid';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInvoice, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getBills(filter: GenericFilter): RpcRequestBuilder<ListWithCount<InvoiceProducerDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerInvoiceService.getBills';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getBillsFromDegineo(page: number, nbPerPage: number): RpcRequestBuilder<ListWithCount<BillFromDegineoDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerInvoiceService.getBillsFromDegineo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
