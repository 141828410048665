// ENTITY com.degineo.metier.producer.dto.ProducerUserSignupDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerUserSignupDTO {

  firstName: string = '';
  lastName: string = '';
  companyName: string = '';
  email: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerUserSignupDTO'] = ProducerUserSignupDTO;

reverseMapping[ProducerUserSignupDTO.name] = 'com.degineo.metier.producer.dto.ProducerUserSignupDTO';

fields['com.degineo.metier.producer.dto.ProducerUserSignupDTO']  = {
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    companyName: 'java.lang.String',
    email: 'java.lang.String'
};
