import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DegineoRight } from '../ic2/entities/DegineoRight';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;

    return this.checkLogin(route, url);
  }

  checkLogin(route: ActivatedRouteSnapshot, url: string): Observable<boolean> {
    return Observable.create((observer) => {
      this.authService.isLoggedIn().subscribe(
        (loggedIn) => {
          observer.next(loggedIn);
          //console.log(this.authService.userBundle);
          //console.log('login',loggedIn);
          //console.log(route);
          if (!loggedIn) {
            // Store the attempted URL for redirecting
            this.authService.redirectUrl = url;

            // Navigate to the login page with extras
            this.router.navigate(['/connexion']);
          }
          /*
          else if (
            this.authService.has(DegineoRight.CLIENT) &&
            !this.authService.userBundle.du.signupFinished &&
            route.routeConfig.path !== 'configuration-compte'
          ) {
            //console.log('SHOULD NAVIGATE');
            this.router.navigate(['/configuration-compte']);
          }
          */
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
