// ENTITY com.degineo.metier.invoice.dto.bo.InvoiceBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class InvoiceBODTO {

  invoiceId: number = 0;
  date: Date = null;
  reference: string = '';
  transmitter: string = '';
  receiver: string = '';
  totalHT: number = 0;
  totalTTC: number = 0;
  fileToken: string = '';
  idType: number = 0;
  idCancelledInvoice: number = 0;
  cancelled: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.bo.InvoiceBODTO'] = InvoiceBODTO;

reverseMapping[InvoiceBODTO.name] = 'com.degineo.metier.invoice.dto.bo.InvoiceBODTO';

fields['com.degineo.metier.invoice.dto.bo.InvoiceBODTO']  = {
    invoiceId: 'java.lang.Integer',
    date: 'java.time.Instant',
    reference: 'java.lang.String',
    transmitter: 'java.lang.String',
    receiver: 'java.lang.String',
    totalHT: 'java.lang.Double',
    totalTTC: 'java.lang.Double',
    fileToken: 'java.lang.String',
    idType: 'java.lang.Integer',
    idCancelledInvoice: 'java.lang.Integer',
    cancelled: 'java.lang.Boolean'
};
