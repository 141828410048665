// ENTITY com.degineo.metier.producer_printer.dto.ConsumableOptionColorChoiceDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ConsumableOptionColorChoiceDTO {

  idConsumableOption: number = 0;
  colorName: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.ConsumableOptionColorChoiceDTO'] = ConsumableOptionColorChoiceDTO;

reverseMapping[ConsumableOptionColorChoiceDTO.name] = 'com.degineo.metier.producer_printer.dto.ConsumableOptionColorChoiceDTO';

fields['com.degineo.metier.producer_printer.dto.ConsumableOptionColorChoiceDTO']  = {
    idConsumableOption: 'java.lang.Integer',
    colorName: 'java.lang.String'
};
