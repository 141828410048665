// ENTITY com.degineo.metier.vitrine.dto.ProducerSVDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PrinterSVDTO } from './PrinterSVDTO';
import { ProducerOpenDays } from './ProducerOpenDays';
import { ProducerEmployeeSVDTO } from './ProducerEmployeeSVDTO';
import { BusinessType } from './BusinessType';
import { BusinessTargetLevel2 } from './BusinessTargetLevel2';
import { AddressNEW } from './AddressNEW';
import { PrinterTechnology } from './PrinterTechnology';

export class ProducerSVDTO {

  id: number = 0;
  subscribed: boolean = false;
  registered: boolean = false;
  name: string = '';
  logoToken: string = '';
  enterpriseType: BusinessType = null;
  presentation: string = '';
  impressionTechnologies: PrinterTechnology[] = null;
  applicationDomains: BusinessTargetLevel2[] = null;
  impressionTechnologiesLoadedFromPrinters: boolean = false;
  printers: PrinterSVDTO[] = null;
  employees: ProducerEmployeeSVDTO[] = null;
  schedule: ProducerOpenDays[] = null;
  address: AddressNEW = null;
  contactEmail: string = '';
  contactTel: string = '';
  facebook: string = '';
  linkedin: string = '';
  twitter: string = '';
  website: string = '';
  youtube: string = '';
  instagram: string = '';
  visible: boolean = false;
  price: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.vitrine.dto.ProducerSVDTO'] = ProducerSVDTO;

reverseMapping[ProducerSVDTO.name] = 'com.degineo.metier.vitrine.dto.ProducerSVDTO';

fields['com.degineo.metier.vitrine.dto.ProducerSVDTO']  = {
    id: 'java.lang.Integer',
    subscribed: 'java.lang.Boolean',
    registered: 'java.lang.Boolean',
    name: 'java.lang.String',
    logoToken: 'java.lang.String',
    enterpriseType: 'com.degineo.metier.producer.entities.BusinessType',
    presentation: 'java.lang.String',
    impressionTechnologies: 'java.util.List<com.degineo.metier.metadata.entities.PrinterTechnology>',
    applicationDomains: 'java.util.List<com.degineo.metier.client.entities.BusinessTargetLevel2>',
    impressionTechnologiesLoadedFromPrinters: 'java.lang.Boolean',
    printers: 'java.util.List<com.degineo.metier.vitrine.dto.PrinterSVDTO>',
    employees: 'java.util.List<com.degineo.metier.vitrine.dto.ProducerEmployeeSVDTO>',
    schedule: 'java.util.List<com.degineo.metier.producer.entities.ProducerOpenDays>',
    address: 'com.degineo.metier.address.entities.AddressNEW',
    contactEmail: 'java.lang.String',
    contactTel: 'java.lang.String',
    facebook: 'java.lang.String',
    linkedin: 'java.lang.String',
    twitter: 'java.lang.String',
    website: 'java.lang.String',
    youtube: 'java.lang.String',
    instagram: 'java.lang.String',
    visible: 'java.lang.Boolean',
    price: 'java.lang.Double'
};
