// ENTITY com.degineo.metier.order.dto.kanban.panel.InProductionOrderPanelDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';
import { ClientInfosForKanbanPopupDTO } from './ClientInfosForKanbanPopupDTO';

export class InProductionOrderPanelDTO {

  idOrder: number = 0;
  clientChoosedThisProducer: boolean = false;
  client: ClientInfosForKanbanPopupDTO = null;
  wishedDeliveryDate: Date = null;
  dateStartPrinting: Date = null;
  printerName: string = '';
  printerBrand: string = '';
  consumableName: string = '';
  colorName: string = '';
  colorHex: string = '';
  comment: string = '';
  noteDegineo: string = '';
  quantity: number = 0;
  finishName: string = '';
  sizeXMm: number = 0;
  sizeYMm: number = 0;
  sizeZMm: number = 0;
  dental: boolean = false;
  estimatedProductionTimeInMin: number = 0;
  machineUsagePerPiece: number = 0;
  materialQuantityPerPiece: number = 0;
  token3DModelFinal: string = '';
  filenameFinal: string = '';
  shippingAddress: AddressNEW = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.InProductionOrderPanelDTO'] = InProductionOrderPanelDTO;

reverseMapping[InProductionOrderPanelDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.InProductionOrderPanelDTO';

fields['com.degineo.metier.order.dto.kanban.panel.InProductionOrderPanelDTO']  = {
    idOrder: 'java.lang.Integer',
    clientChoosedThisProducer: 'java.lang.Boolean',
    client: 'com.degineo.metier.order.dto.kanban.panel.ClientInfosForKanbanPopupDTO',
    wishedDeliveryDate: 'java.time.Instant',
    dateStartPrinting: 'java.time.Instant',
    printerName: 'java.lang.String',
    printerBrand: 'java.lang.String',
    consumableName: 'java.lang.String',
    colorName: 'java.lang.String',
    colorHex: 'java.lang.String',
    comment: 'java.lang.String',
    noteDegineo: 'java.lang.String',
    quantity: 'java.lang.Integer',
    finishName: 'java.lang.String',
    sizeXMm: 'java.lang.Double',
    sizeYMm: 'java.lang.Double',
    sizeZMm: 'java.lang.Double',
    dental: 'java.lang.Boolean',
    estimatedProductionTimeInMin: 'java.lang.Double',
    machineUsagePerPiece: 'java.lang.Double',
    materialQuantityPerPiece: 'java.lang.Double',
    token3DModelFinal: 'java.lang.String',
    filenameFinal: 'java.lang.String',
    shippingAddress: 'com.degineo.metier.address.entities.AddressNEW'
};
