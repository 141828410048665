// ENTITY com.degineo.metier.shipping.entities.ShippingBox generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ShippingBox {

  id: number = 0;
  idProducer: number = 0;
  sizeXCm: number = 0;
  sizeYCm: number = 0;
  sizeZCm: number = 0;
  quantity: number = 0;
  del: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.shipping.entities.ShippingBox'] = ShippingBox;

reverseMapping[ShippingBox.name] = 'com.degineo.metier.shipping.entities.ShippingBox';

fields['com.degineo.metier.shipping.entities.ShippingBox']  = {
    id: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    sizeXCm: 'java.lang.Double',
    sizeYCm: 'java.lang.Double',
    sizeZCm: 'java.lang.Double',
    quantity: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
