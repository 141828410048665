// ENTITY com.degineo.metier.invoice.dto.producer.InvoiceProducerOrderDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';

export class InvoiceProducerOrderDTO {

  orderId: number = 0;
  orderName: string = '';
  printerName: string = '';
  printerBrand: string = '';
  consumableName: string = '';
  colorName: string = '';
  colorHex: string = '';
  finishName: string = '';
  sizeXmm: number = 0;
  sizeYmm: number = 0;
  sizeZmm: number = 0;
  quantity: number = 0;
  machineUsagePerPiece: number = 0;
  materialQuantityPerPiece: number = 0;
  shippingAddress: AddressNEW = null;
  dateChecked: Date = null;
  userCheck: string = '';
  dateProducerAccepted: Date = null;
  userProducerAccepted: string = '';
  dateStartPrinting: Date = null;
  userStartPrinting: string = '';
  dateShippingPreparation: Date = null;
  userShippingPreparation: string = '';
  datePackagePreparation: Date = null;
  userPackagePreparation: string = '';
  dateShippingProgress: Date = null;
  userShippingProgress: string = '';
  dateCarrierTransport: Date = null;
  userCarrierTransport: string = '';
  dateArticleReceived: Date = null;
  userArticleReceived: string = '';
  shipmentCreated: boolean = false;
  producerDeliver: boolean = false;
  token3DModelFinal: string = '';
  filenameFinal: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.producer.InvoiceProducerOrderDTO'] = InvoiceProducerOrderDTO;

reverseMapping[InvoiceProducerOrderDTO.name] = 'com.degineo.metier.invoice.dto.producer.InvoiceProducerOrderDTO';

fields['com.degineo.metier.invoice.dto.producer.InvoiceProducerOrderDTO']  = {
    orderId: 'java.lang.Integer',
    orderName: 'java.lang.String',
    printerName: 'java.lang.String',
    printerBrand: 'java.lang.String',
    consumableName: 'java.lang.String',
    colorName: 'java.lang.String',
    colorHex: 'java.lang.String',
    finishName: 'java.lang.String',
    sizeXmm: 'java.lang.Double',
    sizeYmm: 'java.lang.Double',
    sizeZmm: 'java.lang.Double',
    quantity: 'java.lang.Integer',
    machineUsagePerPiece: 'java.lang.Double',
    materialQuantityPerPiece: 'java.lang.Double',
    shippingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    dateChecked: 'java.time.Instant',
    userCheck: 'java.lang.String',
    dateProducerAccepted: 'java.time.Instant',
    userProducerAccepted: 'java.lang.String',
    dateStartPrinting: 'java.time.Instant',
    userStartPrinting: 'java.lang.String',
    dateShippingPreparation: 'java.time.Instant',
    userShippingPreparation: 'java.lang.String',
    datePackagePreparation: 'java.time.Instant',
    userPackagePreparation: 'java.lang.String',
    dateShippingProgress: 'java.time.Instant',
    userShippingProgress: 'java.lang.String',
    dateCarrierTransport: 'java.time.Instant',
    userCarrierTransport: 'java.lang.String',
    dateArticleReceived: 'java.time.Instant',
    userArticleReceived: 'java.lang.String',
    shipmentCreated: 'java.lang.Boolean',
    producerDeliver: 'java.lang.Boolean',
    token3DModelFinal: 'java.lang.String',
    filenameFinal: 'java.lang.String'
};
