// ENTITY com.degineo.metier.user.dto.ProducerInvitationInfoDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerInvitationInfoDTO {

  idProducer: number = 0;
  producerName: string = '';
  producerLogoToken: string = '';
  email: string = '';
  name: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.user.dto.ProducerInvitationInfoDTO'] = ProducerInvitationInfoDTO;

reverseMapping[ProducerInvitationInfoDTO.name] = 'com.degineo.metier.user.dto.ProducerInvitationInfoDTO';

fields['com.degineo.metier.user.dto.ProducerInvitationInfoDTO']  = {
    idProducer: 'java.lang.Integer',
    producerName: 'java.lang.String',
    producerLogoToken: 'java.lang.String',
    email: 'java.lang.String',
    name: 'java.lang.String'
};
