// ENTITY com.degineo.metier.client.dto.FileDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class FileDTO {

  id: number = 0;
  fileName: string = '';
  tokenPNG: string = '';
  tokenGIF: string = '';
  tokenModele: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.client.dto.FileDTO'] = FileDTO;

reverseMapping[FileDTO.name] = 'com.degineo.metier.client.dto.FileDTO';

fields['com.degineo.metier.client.dto.FileDTO']  = {
    id: 'java.lang.Integer',
    fileName: 'java.lang.String',
    tokenPNG: 'java.lang.String',
    tokenGIF: 'java.lang.String',
    tokenModele: 'java.lang.String'
};
