// com.degineo.metier.articleType.service.ArticleTypeBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ArticleTypeNEW } from '../entities/ArticleTypeNEW';
import { ArticleTypeBODTO } from '../entities/ArticleTypeBODTO';

@Injectable({
  providedIn: 'root',
})
export class ArticleTypeBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  add(newAT: ArticleTypeBODTO): RpcRequestBuilder<ArticleTypeBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ArticleTypeBOService.add';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(newAT, 'com.degineo.metier.articleType.dto.ArticleTypeBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.articleType.dto.ArticleTypeBODTO');
  }

  getAll(): RpcRequestBuilder<ArticleTypeBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ArticleTypeBOService.getAll';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getArticleType(id: number): RpcRequestBuilder<ArticleTypeNEW> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ArticleTypeBOService.getArticleType';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.articleType.entities.ArticleTypeNEW');
  }

  updateAT(newAT: ArticleTypeBODTO): RpcRequestBuilder<ArticleTypeBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ArticleTypeBOService.updateAT';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(newAT, 'com.degineo.metier.articleType.dto.ArticleTypeBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.articleType.dto.ArticleTypeBODTO');
  }

}
