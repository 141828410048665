<input
  angular-mydatepicker
  class="form-control"
  [disabled]="disabled"
  [required]="required"
  [options]="IMyDrpOptions"
  [placeholder]="name"
  (dateChanged)="filterChanges($event)"
  [(ngModel)]="value"
  (click)="dp.toggleCalendar()"
  #dp="angular-mydatepicker"
/>
<div class="input-group-append">
  <button *ngIf="value" type="button" class="btn btn-outline-secondary" (click)="clear()">
    <i class="fas fa-times"></i>
  </button>
  <button type="button" class="btn btn-outline-secondary" (click)="dp.toggleCalendar()">
    <i class="far fa-calendar-alt"></i>
  </button>
</div>
