// ENTITY com.ic2.entity.User generated by ic2 3.0.0-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class User {

  idUser: number = 0;
  login: string = '';
  password: string = '';
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  loginAttemptsFailed: number = 0;
  lockedUntil: Date = null;
  del: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.entity.User'] = User;

reverseMapping[User.name] = 'com.ic2.entity.User';

fields['com.ic2.entity.User']  = {
    idUser: 'java.lang.Integer',
    login: 'java.lang.String',
    password: 'java.lang.String',
    email: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    loginAttemptsFailed: 'java.lang.Integer',
    lockedUntil: 'java.time.Instant',
    del: 'java.lang.Boolean'
};
