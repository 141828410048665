import { Component, OnInit, Input, Output, EventEmitter, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { Filter } from '../../ic2/entities/Filter';

@Component({
  selector: 'degineo-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, AfterContentInit {
  @Input()
  cache = null;
  @Input()
  default = null;
  @Input()
  builtFilters: Filter[] = [];
  @Output()
  filterChanged: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();
  @ContentChildren('f', { descendants: true })
  filters: QueryList<any>;
  shouldEmit: boolean = true;

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.filters.map((f) => {
      if (f.Filter) this.builtFilters.push(f.Filter);
      f.change.subscribe((value) => this.changes());
    });
  }

  clear() {
    this.shouldEmit = false;
    this.filters.map((c) => {
      c.clear();
    });
    this.shouldEmit = true;
    this.changes();
  }
  resetToDefault() {
    console.log('resetToDefault');
    this.shouldEmit = false;
    this.filters.map((c) => {
      c.reset();
    });
    this.shouldEmit = true;
    this.changes();
  }

  changes() {
    this.builtFilters.splice(0, this.builtFilters.length);
    this.filters.map((f) => {
      if (f.Filter) this.builtFilters.push(f.Filter);
    });
    if (this.shouldEmit) this.filterChanged.emit(this.builtFilters);
  }
}
