<div
  class="fileUploadContainer text-center pt-3"
  (drop)="drop($event)"
  (dragover)="dragover($event)"
  (dragexit)="dragexit($event)"
  (dragenter)="dragenter($event)"
  (click)="fileinput.click()"
>
  <input type="file" name="articleModelFile" [accept]="accept" class="hiddeninput" #fileinput [multiple]="multi" (change)="fileUpload($event)" />
  <img src="assets/fileuploader-dragdrop-icon.png" class="mt-5 mb-4" />
  <h6 class="text-primary" *ngIf="!dragging">Glissez {{ multi ? 'votre ou vos fichiers' : 'votre fichier' }} {{ text }} ici</h6>
  <h6 class="text-primary" *ngIf="dragging">DÃ©posez {{ multi ? 'votre ou vos fichiers' : 'votre fichier' }} {{ text }} ici</h6>
  <p class="mb-2 fw-500 text-secondary">ou</p>
  <button class="btn btn-primary">Parcourez vos fichiers</button>
</div>
