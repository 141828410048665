// com.degineo.metier.devis.service.ProducerDevisServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerDevisDTO } from '../entities/ProducerDevisDTO';
import { ProducerDevisListItemDTO } from '../entities/ProducerDevisListItemDTO';
import { Finish } from '../entities/Finish';

@Injectable({
  providedIn: 'root',
})
export class ProducerDevisService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getListDevis(): RpcRequestBuilder<ProducerDevisListItemDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDevisService.getListDevis';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getDevis(id: number): RpcRequestBuilder<ProducerDevisDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDevisService.getDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.devis.dto.producer.ProducerDevisDTO');
  }

  refuseDevis(id: number, reason: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDevisService.refuseDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(reason, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  hideDevis(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDevisService.hideDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveDevis(id: number, wishedDeliveryDate: Date, validityDate: Date, idSelectedCompanyPrinterConsumable: number, idFinish: number, materialQuantityInCm3: number, printTimeInMin: number, unitPriceHT: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDevisService.saveDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(wishedDeliveryDate, 'java.time.Instant', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(validityDate, 'java.time.Instant', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idSelectedCompanyPrinterConsumable, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(materialQuantityInCm3, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(printTimeInMin, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(unitPriceHT, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  sendDevis(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDevisService.sendDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getFinishFor(idProducerPrinter: number): RpcRequestBuilder<Finish[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDevisService.getFinishFor';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
