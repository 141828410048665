// ENTITY com.degineo.metier.metadata.dto.FinishBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { Finish } from './Finish';
import { FinishMaterialType } from './FinishMaterialType';

export class FinishBODTO {

  finish: Finish = null;
  materialTypes: FinishMaterialType[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.dto.FinishBODTO'] = FinishBODTO;

reverseMapping[FinishBODTO.name] = 'com.degineo.metier.metadata.dto.FinishBODTO';

fields['com.degineo.metier.metadata.dto.FinishBODTO']  = {
    finish: 'com.degineo.metier.metadata.entities.Finish',
    materialTypes: 'java.util.List<com.degineo.metier.metadata.entities.FinishMaterialType>'
};
