import { Component, OnInit, ViewEncapsulation, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProducerSVDTO, ProducerOpenDays, BusinessTargetLevel2, PrinterTechnology } from '../../../ic2/entities/entities';
import { ProducerSVService, ProducerPageService } from '../../../ic2/services/services';

import { L } from 'ic2-lib';
import { Title, Meta } from '@angular/platform-browser';
import { SlugService } from '../../../services/slug.service';
import { BusinessTarget, BusinessTargets } from '../../../tools/BusinessTargets';

@Component({
  selector: 'lib-producer-edit',
  templateUrl: './producer-edit.component.html',
  styleUrls: ['./producer-edit.component.scss'],
})
export class ProducerEditComponent implements OnInit {
  readonly SCHEDULE_OPENING_1 = 1;
  readonly SCHEDULE_CLOSING_1 = 2;
  readonly SCHEDULE_OPENING_2 = 3;
  readonly SCHEDULE_CLOSING_2 = 4;

  private sub: any;

  producer: ProducerSVDTO = null;
  idProducer: number = null;
  days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  loading: boolean = false;

  id: number;
  loaded: boolean = false;
  data: ProducerSVDTO = null;
  originalData: ProducerSVDTO = null;
  @Input()
  editMode: boolean = false;
  @Input()
  rpc: string = null;
  @Input()
  svUrl;
  @Output() changePage = new EventEmitter<boolean>();

  file: File = null;
  progressPercent = null;

  numberToForceImgReload = 1;
  types: BusinessTarget[] = BusinessTargets;
  PrinterTechnology: typeof PrinterTechnology = PrinterTechnology;
  inBackoffice: boolean = false;

  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(Title) private titleService: Title,
    @Inject(Meta) private metaService: Meta,
    private location: Location,
    @Inject(ProducerSVService) private producerSVService: ProducerSVService,
    @Inject(ProducerPageService) private producerPageService: ProducerPageService,
    @Inject(SlugService) public slugService: SlugService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data.editMode) this.editMode = true;
      if (data.rpcHost) this.rpc = data.rpcHost;
      if (data.svUrl) this.svUrl = data.svUrl;
    });

    if (this.route.snapshot.data.producer) this.dataLoaded(this.route.snapshot.data.producer);
    else
      this.sub = this.route.params.subscribe((params) => {
        if (params['id'] === undefined) return;

        this.id = +params['id'];
        L.v(this.id);
        this.producerSVService.getProducer(this.id, this.editMode).subscribe(
          (data) => {
            this.dataLoaded(data);
          },
          (err) => {
            this.loading = false;
            L.e(err);
          }
        );
      });
    this.inBackoffice = this.route.snapshot.routeConfig.path === 'producers/edit/:name/:id/:page';
  }

  dataLoaded(data: ProducerSVDTO) {
    this.data = data;
    if (data.applicationDomains === null) data.applicationDomains = [];
    if (data.impressionTechnologies === null) data.impressionTechnologies = [];
    this.originalData = JSON.parse(JSON.stringify(this.data));
    this.initScheduleEdit();
    this.loaded = true;
    this.loading = false;
    this.titleService.setTitle(this.data.name + ' â¢ ' + this.data.enterpriseType);
    let desc =
      (this.data.applicationDomains
        ? this.data.applicationDomains
            .map((ad) => ad.lib)
            .join(',')
            .replace(new RegExp(',', 'g'), ' -')
        : '') +
      ' - ' +
      (this.data.impressionTechnologies
        ? this.data.impressionTechnologies
            .map((it) => it.lib)
            .join(',')
            .replace(new RegExp(',', 'g'), ' -')
        : '') +
      ' - ' +
      this.data.address.line1 +
      ', ' +
      this.data.address.zipCode +
      ' ' +
      this.data.address.city +
      ' ' +
      this.data.address.country;
    this.metaService.updateTag({ property: 'description', content: desc });
    this.metaService.updateTag({ property: 'og:description', content: desc });
    this.metaService.updateTag({ property: 'og:title', content: this.data.name + ' â¢ ' + this.data.enterpriseType });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({ property: 'og:locale', content: 'fr_FR' });
    this.metaService.updateTag({ property: 'og:site_name', content: 'Degineo' });
    this.metaService.updateTag({ property: 'og:image', content: this.getLogo() });
    this.metaService.updateTag({
      property: 'og:url',
      content:
        this.svUrl +
        '/' +
        (this.data.enterpriseType === null ? 'type' : this.slugService.string_to_slug(this.data.enterpriseType.lib)) +
        '/' +
        this.slugService.string_to_slug(this.data.address.city) +
        '/' +
        this.slugService.string_to_slug(this.data.name) +
        '/' +
        this.data.id,
    });
  }

  public static normalize(str: string) {
    return str.toLowerCase().replace(new RegExp(' ', 'g'), '-').replace(new RegExp('(', 'g'), '').replace(new RegExp(')', 'g'), '');
  }

  getLogo() {
    return this.rpc + 'companylogo/' + this.data.logoToken + '/logo.png?size=large?r=' + this.numberToForceImgReload;
  }

  getAvatar(i: number) {
    return this.rpc + 'userLogo/' + this.data.employees[i].logoToken + '/logo.' + this.data.employees[i].logoExtension;
  }

  selectLast(name: string) {
    setTimeout(() => {
      let children = document.querySelectorAll('#' + name + ' > select');
      (<HTMLElement>children[children.length - 1]).focus();
    }, 100);
  }
  trackByFn(index: any, item: any) {
    return index;
  }

  isEqual(array1, array2) {
    if (array1 && array2) {
      return array1.length === array2.length && array1.every((value, i) => value === array2[i]);
    }
  }

  initScheduleEdit() {
    if (this.data.schedule.length === 0) {
      //On a pas de schedule on crÃ©Ã© les 7 schedule
      for (var i = 1; i < 8; i++) {
        var od = new ProducerOpenDays();
        od.day = i;
        od.open = i < 6;
        od.schedule2Open = null;
        od.schedule2End = null;
        this.data.schedule.push(od);
      }
    }
  }

  isScheduleEqual(array1: ProducerOpenDays[], array2: ProducerOpenDays[]) {
    if (!array1 || !array2) return false;
    return JSON.stringify(array1) === JSON.stringify(array2);
  }

  isScheduleValid() {
    let regex = /^((2[0-3])|([0-1][0-9]))([0-5][0-9])$/;
    let isValid: boolean = true;
    if (this.data.schedule)
      this.data.schedule.forEach((e) => {
        if (e.open) {
          if (e.schedule1Open && e.schedule1End && e.schedule1Open.length > 0 && e.schedule1End.length > 0) {
            if (!regex.test(e.schedule1Open) || !regex.test(e.schedule1End)) isValid = false;
          } else isValid = false;
          if (e.schedule2Open || e.schedule2End)
            if (e.schedule2Open.length > 0 && e.schedule2End.length > 0) {
              if (!regex.test(e.schedule2Open) || !regex.test(e.schedule2End)) isValid = false;
            } else isValid = false;
        }
      });
    return isValid;
  }

  isPhoneValid() {
    let regex = /^0[1-9][0-9]{8}$/;
    let isValid: boolean = true;
    if (this.data.contactTel) if (!regex.test(this.data.contactTel)) isValid = false;

    return isValid;
  }

  handleFileInput(event) {
    let files: FileList = event.target.files;
    (this.file = files[0]), console.log(files, files[0].size / 1024 / 1024 + 'MB', this.file.name.split('.').pop());
    this.producerPageService
      .uploadPicture(this.data.id)
      .withFile(this.file)
      .subscribeWithProgress(
        (data) => {
          //console.log('SAVE LOGO', data);
          setTimeout(() => {
            this.progressPercent = null;
            this.file = null;
            this.numberToForceImgReload++;
          }, 2000);
        },
        (loaded, total) => {
          //console.log('SAVE LOGO progress', loaded, total);
          this.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (error) => {
          this.progressPercent = null;
          L.e('SAVE LOGO err', error);
        }
      );
  }

  savePresentation(next?) {
    this.producerPageService
      .updateProducerPresentation(
        this.data.id,
        this.data.presentation,
        this.data.website,
        this.data.facebook,
        this.data.twitter,
        this.data.linkedin,
        this.data.youtube,
        this.data.instagram
      )
      .subscribe(
        (data) => {
          this.originalData.presentation = this.data.presentation;
          this.originalData.website = this.data.website;
          this.originalData.facebook = this.data.facebook;
          this.originalData.twitter = this.data.twitter;
          this.originalData.linkedin = this.data.linkedin;
          this.originalData.youtube = this.data.youtube;
          this.originalData.instagram = this.data.instagram;
          if (next) next();
        },
        (err) => {
          this.restorePresentation();
          L.e(err);
        }
      );
  }

  restorePresentation() {
    this.data.presentation = this.originalData.presentation;
    this.data.website = this.originalData.website;
    this.data.facebook = this.originalData.facebook;
    this.data.twitter = this.originalData.twitter;
    this.data.linkedin = this.originalData.linkedin;
    this.data.youtube = this.originalData.youtube;
    this.data.instagram = this.originalData.instagram;
  }

  restoreDomain() {
    this.data.applicationDomains = this.originalData.applicationDomains;
  }

  saveTech(next) {
    this.data.impressionTechnologies = this.data.impressionTechnologies.filter((it) => it !== null);
    this.producerPageService.updateProducerImpressionTechnologies(this.data.id, this.data.impressionTechnologies).subscribe(
      (data) => {
        this.originalData.impressionTechnologies = this.data.impressionTechnologies;
        next();
      },
      (err) => {
        this.restoreTech();
        L.w(err);
      }
    );
  }

  restoreTech() {
    this.data.impressionTechnologies = this.originalData.impressionTechnologies;
  }

  saveSchedule(next) {
    if (this.isScheduleValid()) {
      this.producerPageService.updateProducerSchedule(this.data.id, this.data.schedule).subscribe(
        (data) => {
          this.originalData.schedule = JSON.parse(JSON.stringify(this.data.schedule));
          next();
        },
        (err) => {
          this.restoreSchedule();
          L.w(err);
        }
      );
    } else next();
  }

  restoreSchedule() {
    this.data.schedule = JSON.parse(JSON.stringify(this.originalData.schedule));
  }

  saveContact(next) {
    if (this.isPhoneValid())
      this.producerPageService.updateProducerContact(this.data.id, this.data.contactEmail, this.data.contactTel).subscribe(
        (data) => {
          this.originalData.contactEmail = this.data.contactEmail;
          this.originalData.contactTel = this.data.contactTel;
          next();
        },
        (err) => {
          this.restoreContact();
          L.w(err);
        }
      );
  }

  restoreContact() {
    this.data.contactEmail = this.originalData.contactEmail;
    this.data.contactTel = this.originalData.contactTel;
  }

  saveAll() {
    if (
      this.data.presentation !== this.originalData.presentation ||
      this.data.website !== this.originalData.website ||
      this.data.facebook !== this.originalData.facebook ||
      this.data.twitter !== this.originalData.twitter ||
      this.data.linkedin !== this.originalData.linkedin ||
      this.data.youtube !== this.originalData.youtube ||
      this.data.instagram !== this.originalData.instagram
    )
      this.savePresentation(() => this.save2());
    else this.save2();
  }

  save2() {
    if (!this.isEqual(this.data.applicationDomains, this.originalData.applicationDomains)) {
      this.data.applicationDomains = this.data.applicationDomains.filter((ad) => ad !== null);
      this.producerPageService.updateProducerApplicationDomains(this.data.id, this.data.applicationDomains).subscribe(
        (data) => {
          this.originalData.applicationDomains = this.data.applicationDomains;
          this.save3();
        },
        (err) => {
          this.restoreDomain();
          L.w(err);
        }
      );
    } else this.save3();
  }

  save3() {
    if (!this.isEqual(this.data.impressionTechnologies, this.originalData.impressionTechnologies)) this.saveTech(() => this.save4());
    else this.save4();
  }

  save4() {
    if (!this.isScheduleEqual(this.data.schedule, this.originalData.schedule)) this.saveSchedule(() => this.save5());
    else this.save5();
  }

  save5() {
    if (this.data.contactEmail !== this.originalData.contactEmail || this.data.contactTel !== this.originalData.contactTel)
      this.saveContact(() => this.save6());
    else this.save6();
  }
  save6() {
    if (this.router.url !== '/ma-page-degineo/edit') {
      this.changePage.emit();
    } else this.router.navigate(['/ma-page-degineo']);
  }

  cancelAll() {
    if (
      this.data.presentation !== this.originalData.presentation ||
      this.data.website !== this.originalData.website ||
      this.data.facebook !== this.originalData.facebook ||
      this.data.twitter !== this.originalData.twitter ||
      this.data.linkedin !== this.originalData.linkedin ||
      this.data.youtube !== this.originalData.youtube
    )
      this.restorePresentation();
    if (!this.isEqual(this.data.applicationDomains, this.originalData.applicationDomains)) this.restoreDomain();
    if (!this.isEqual(this.data.impressionTechnologies, this.originalData.impressionTechnologies)) this.restoreTech();
    if (!this.isScheduleEqual(this.data.schedule, this.originalData.schedule)) this.restoreSchedule();
    if (this.data.contactEmail !== this.originalData.contactEmail || this.data.contactTel !== this.originalData.contactTel) this.restoreContact();

    if (this.router.url !== '/ma-page-degineo/edit') {
      this.changePage.emit();
    } else this.router.navigate(['/ma-page-degineo']);
  }

  back() {
    this.location.back();
  }
}
