// ENTITY com.ic2.utils.ListWithCount generated by ic2 3.0.0-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ListWithCount<T> {

  count: number = 0;
  data: T[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.utils.ListWithCount'] = ListWithCount;

reverseMapping[ListWithCount.name] = 'com.ic2.utils.ListWithCount';

fields['com.ic2.utils.ListWithCount']  = {
    count: 'java.lang.Long',
    data: 'java.util.Collection<T>'
};
