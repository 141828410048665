import { FormGroup, AbstractControl, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { L } from 'ic2-lib';

export class UserEditValidators {
  static siretValide(control: AbstractControl) {
    let siret = control.value;
    var estValide;
    if (siret === null || siret.length === 0)
      //valide si non saisi
      return null;
    if (siret.length != 14 || isNaN(siret)) estValide = false;
    else {
      // Donc le SIRET est un numÃ©rique Ã  14 chiffres
      // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
      // correspondent au numÃ©ro d'Ã©tablissement
      // et enfin le dernier chiffre est une clef de LUHN.
      var somme = 0;
      var tmp;
      for (var cpt = 0; cpt < siret.length; cpt++) {
        if (cpt % 2 == 0) {
          // Les positions impaires : 1er, 3Ã¨, 5Ã¨, etc...
          tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
          if (tmp > 9) tmp -= 9; // Si le rÃ©sultat est supÃ©rieur Ã  9, on lui soustrait 9
        } else tmp = siret.charAt(cpt);
        somme += parseInt(tmp);
      }
      if (somme % 10 == 0) estValide = true;
      // Si la somme est un multiple de 10 alors le SIRET est valide
      else estValide = false;
    }
    return estValide ? null : { invalidSiret: true };
  }

  // @dynamic
  static checkPasswords(name1: string, name2: string) {
    let method = (group: FormGroup) => {
      if (!group.controls) return { formNotInitialized: true };
      let pass = group.controls[name1].value;
      let confirmPass = group.controls[name2].value;
      if (pass === confirmPass) return null;
      group.controls[name2].setErrors({ notSame: true });
      return { notSame: true };
    };
    return method;
  }

  // prettier-ignore
  static phoneNumber = Validators.pattern("^((\\+)33|0)[1-9](\\d{2}){4}$");

  static passwordDifficulty = Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\-\+#$@!%&*?]{6,}$/);

  static conditionalValidator(predicate: () => boolean, validator: ValidatorFn[] | ValidatorFn) {
    return (formControl: FormControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        if (validator instanceof Function) {
          return validator(formControl);
        } else if (validator instanceof Array) {
          let obj = {};
          for (const v of validator) {
            const res = v(formControl);
            obj = { ...obj, ...res };
          }
          return obj;
        } else {
          L.e('Not handled validator type ' + typeof validator);
        }
      }
      return null;
    };
  }
}
