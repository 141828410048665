// Facade exporting all classes for simple loading
// use export * from "./services/services"; in your module file
export * from './ClientNotifService';
export * from './ClientBOService';
export * from './LoginService';
export * from './ProducerSVService';
export * from './ClientProducerAssociationService';
export * from './ProducerSubscriptionTunnelService';
export * from './ReceivedOrderService';
export * from './ProducerSignupService';
export * from './ProducerBOService';
export * from './CommonBOService';
export * from './ShareInvoiceService';
export * from './MaterialTypeCharacteristicBOService';
export * from './PrinterBOService';
export * from './BasketService';
export * from './ProducerPrinterService';
export * from './ProducerCompanyInfoService';
export * from './ConsumablePrinterService';
export * from './ResetPwdService';
export * from './ProducerTakeControlService';
export * from './BrandPrinterService';
export * from './SubscriptionPlanSVService';
export * from './CharacteristicService';
export * from './PaymentService';
export * from './MaterialTypeService';
export * from './PrintService';
export * from './OrderBOService';
export * from './ClientMarqueBlancheService';
export * from './ArticleTypeBOService';
export * from './InPreparationOrderService';
export * from './UserBOService';
export * from './ClientInvoiceService';
export * from './DevisBOService';
export * from './ProducerMarqueBlancheManagmentService';
export * from './BrandConsumableService';
export * from './ProducerPageService';
export * from './PriceService';
export * from './ProducerUserSignupService';
export * from './AddressService';
export * from './KanbanService';
export * from './ProducerPlanService';
export * from './ShippingBoxService';
export * from './InProductionOrderService';
export * from './ColorService';
export * from './CodeNafBOService';
export * from './ProducerDevisService';
export * from './WaitingCarrierShipmentService';
export * from './SubscriptionPlanBOService';
export * from './SiteVitrineService';
export * from './ConsumableBOService';
export * from './ConsumableService';
export * from './MyPrintsService';
export * from './RegisterService';
export * from './PrinterService';
export * from './ProducerDashboardService';
export * from './ProducerClientService';
export * from './ProducerUserService';
export * from './FinishBOService';
export * from './InvoiceBOService';
export * from './ArticleService';
export * from './ProducerInvoiceService';
export * from './UserProducerService';
export * from './InPreparationShipmentService';
export * from './DumpService';
export * from './MyFilesService';
export * from './ClientDevisService';
export * from './ProducerSubscriptionService';
export * from './ClientService';
