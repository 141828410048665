// com.degineo.metier.order.service.kanban.InProductionOrderServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { InProductionOrderPanelDTO } from '../entities/InProductionOrderPanelDTO';

@Injectable({
  providedIn: 'root',
})
export class InProductionOrderService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getProductionOrder(idOrder: number): RpcRequestBuilder<InProductionOrderPanelDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InProductionOrderService.getProductionOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.kanban.panel.InProductionOrderPanelDTO');
  }

  cancelProductionOrder(idOrder: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InProductionOrderService.cancelProductionOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  finishProduction(idOrder: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InProductionOrderService.finishProduction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
