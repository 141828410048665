<div class="rounded table-responsive pb-1">
  <table class="table table-striped table-center mb-0">
    <thead>
      <tr class="thead-tr">
        <ng-container *ngFor="let column of columns">
          <th
            *ngIf="!column.hideHeader"
            [ngClass]="{ pointer: !column.disableOrder, 'text-left': column.align === 'left', 'text-right': column.align === 'right' }"
            (click)="changeOrder(column)"
          >
            <span class="col sortIconContainer pl-0" *ngIf="!column.disableOrder">
              <i [ngClass]="isColored(column, OrderBy.ASC)" class="sortIcon fas fa-sort-up" style="top: 1px;"></i>
              <i [ngClass]="isColored(column, OrderBy.DESC)" class="sortIcon fas fa-sort-down" style="bottom: 2px;"></i>
            </span>

            <span class="inline">{{ column.name }}</span>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows" [ngClass]="{ pointer: pointer, hoverable: hoverable }" (click)="onRowClick(row)" [routerLink]="getLink(row)">
        <ng-container *ngFor="let column of columns">
          <ng-container [ngTemplateOutlet]="column.cv.columnValueTemplate" [ngTemplateOutletContext]="{ $implicit: row }"> </ng-container>
        </ng-container>
      </tr>

      <tr *ngIf="footer.first" class="footer">
        <ng-container *ngFor="let column of footerTab">
          <ng-container *ngIf="column === undefined">
            <td></td>
          </ng-container>
          <ng-container *ngIf="column !== undefined" [ngTemplateOutlet]="column.cv.columnValueTemplate"
            ><!--[ngTemplateOutletContext]="{$implicit:row}"-->
          </ng-container>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
