import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, Host } from '@angular/core';
import { FilterComponent } from '../../filter.component';
import { Filter } from '../../../../ic2/entities/Filter';

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}

@Component({
  selector: 'degineo-textassist-filter',
  templateUrl: './textassist-filter.component.html',
  styleUrls: ['./textassist-filter.component.scss'],
})
export class TextassistFilterComponent<T> implements OnInit {
  TextassistFilterComponent: typeof TextassistFilterComponent = TextassistFilterComponent;
  text: string = '';
  delayTimer: any;
  selectedItem: T = null;

  private _required: boolean;
  @Input()
  get required() {
    return this._required;
  }
  set required(value: any) {
    this._required = coerceBooleanProperty(value);
  }

  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input()
  alwaysShowEverything: boolean = false;
  @Input()
  name: string = '';
  @Input()
  filterName: string = '';
  @Input()
  maxChoiceNumber: number = null;
  @Input()
  values: T[] = [];
  @Input()
  presenter: (obj: T) => string = (obj) => obj.toString();
  @Input()
  contentFilter: (text: string, obj: T, presenter: (obj: T) => string) => boolean = (text, obj, p) =>
    p(obj).toLocaleLowerCase().includes(text.toLocaleLowerCase());
  @Input()
  filterSetter: (item: T, filter: Filter) => void = (item, filter) => {
    if (typeof item === 'string') {
      filter.stringValues = [item];
      if (this.host.cache) this.host.cache[this.filterName] = item;
    } else {
      if ((<any>item).id === undefined) throw new Error('Impossible to set the filter value for ' + this.name + ' for item ' + JSON.stringify(item));
      filter.intValues = [parseInt((<any>item).id)];
      if (this.host.cache) this.host.cache[this.filterName] = item;
    }
  };
  @Input()
  Filter: Filter;
  @Output()
  change: EventEmitter<Filter> = new EventEmitter<Filter>();
  @Output()
  textChanges: EventEmitter<string> = new EventEmitter<string>();

  constructor(@Host() private host: FilterComponent) {}

  ngOnInit() {
    if (this.host.cache && this.host.cache[this.filterName] !== undefined && this.host.cache[this.filterName] !== null) {
      if (this.host.cache[this.filterName]) {
        this.selectedItem = this.host.cache[this.filterName];
        this.Filter = new Filter();
        this.Filter.name = this.filterName;
        this.filterSetter(this.selectedItem, this.Filter);
      }
    } else if (this.host.default && this.host.default[this.filterName]) {
      this.selectedItem = this.host.default[this.filterName];
    }
    if (this.selectedItem) this.text = this.presenter(this.selectedItem);
  }

  clear() {
    this.text = '';
    if (this.host.cache) {
      this.host.cache[this.filterName] = null;
    }
    this.Filter = null;
    this.textChanges.emit('');
    this.change.emit(this.Filter);
  }

  reset() {
    if (this.host.default && this.host.default[this.filterName]) {
      this.selectedItem = this.host.default[this.filterName];
      this.text = this.presenter(this.selectedItem);
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(this.selectedItem, this.Filter);
    } else {
      this.Filter = null;
      this.text = '';
    }
  }

  buildFilter() {
    if (this.selectedItem) {
      if (this.host.cache) {
        this.host.cache[this.filterName] = this.selectedItem;
      }
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(this.selectedItem, this.Filter);
    } else {
      this.Filter = null;
      if (this.host.cache) {
        this.host.cache[this.filterName] = null;
      }
    }
    this.change.emit(this.Filter);
  }

  changed(item) {
    this.selectedItem = item;
    this.buildFilter();
  }

  textChanged(text) {
    if (this.text == null || this.text === '') {
      clearTimeout(this.delayTimer);
      this.textChanges.emit('');
    } else if (this.values === undefined) {
      clearTimeout(this.delayTimer);
      this.textChanges.emit(this.text);
    } else {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.textChanges.emit(this.text);
      }, 500);
    }
  }
}
