// DB ENUM com.degineo.metier.client.entities.ClientProducerAssociationStatus generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class ClientProducerAssociationStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ACTIVATED = new ClientProducerAssociationStatus(1, "actived", "ACTIVATED");
  static readonly WAITING_CLIENT_ACCEPTATION = new ClientProducerAssociationStatus(2, "waiting_client_acceptation", "WAITING_CLIENT_ACCEPTATION");
  static readonly DELETE_BY_PRODUCER = new ClientProducerAssociationStatus(3, "delete_by_producer", "DELETE_BY_PRODUCER");
  static readonly DELETE_BY_CLIENT = new ClientProducerAssociationStatus(4, "delete_by_client", "DELETE_BY_CLIENT");

  static readonly values = [
    ClientProducerAssociationStatus.ACTIVATED, 
    ClientProducerAssociationStatus.WAITING_CLIENT_ACCEPTATION, 
    ClientProducerAssociationStatus.DELETE_BY_PRODUCER, 
    ClientProducerAssociationStatus.DELETE_BY_CLIENT 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ClientProducerAssociationStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ClientProducerAssociationStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.client.entities.ClientProducerAssociationStatus'] = ClientProducerAssociationStatus;
reverseMapping[ClientProducerAssociationStatus.name] = 'com.degineo.metier.client.entities.ClientProducerAssociationStatus';
