import { Component, OnInit, Input, Output, EventEmitter, Host } from '@angular/core';
import { FilterComponent } from '../../filter.component';
import { Filter } from '../../../../ic2/entities/Filter';

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}

@Component({
  selector: 'degineo-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
})
export class CheckboxFilterComponent implements OnInit {
  @Input()
  name: string;
  @Input()
  filterName: string;

  @Input()
  color: string;

  @Input()
  Filter: Filter;
  @Output()
  change: EventEmitter<Filter> = new EventEmitter<Filter>();

  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  value: boolean = false;

  constructor(@Host() private host: FilterComponent) {}

  ngOnInit() {
    if (this.host.cache && this.host.cache[this.filterName] !== undefined && this.host.cache[this.filterName] !== null) {
      this.value = this.host.cache[this.filterName];
    } else if (this.host.default && this.host.default[this.filterName]) {
      this.value = this.host.default[this.filterName];
    }
    this.update();
    if (!this.color) this.color = 'primary';
  }

  clear() {
    this.value = false;
    this.update();
  }

  reset() {
    if (this.host.default && this.host.default[this.filterName]) {
      this.value = this.host.default[this.filterName];
    } else {
      this.value = false;
    }
    this.update();
  }

  update() {
    if (this.host.cache) {
      this.host.cache[this.filterName] = this.value;
    }
    if (this.value) {
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
    } else {
      this.Filter = null;
    }

    this.change.emit(this.Filter);
  }

  onClick() {
    this.update();
  }

  getInnerStyle(color) {
    if (color) if (color.charAt(0) == '#') return { color: color };
    return null;
  }

  getClass(color) {
    if (color) if (color.charAt(0) !== '#') return 'text-' + color;
    return null;
  }
}
