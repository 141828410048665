import { Component, OnInit, Inject, HostListener, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ProducerTarget, PlanDTO, FunctionalityDTO, SubscriptionPlanSVService, LegalStatus, Plan } from 'degineo-common';
import { L } from 'ic2-lib';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit, OnChanges {
  nbPrinters: number = 1;
  nbPrintersCalcul: number = 1;
  producerTargets: ProducerTarget[] = ProducerTarget.values;
  producerTarget: ProducerTarget = ProducerTarget.ENTREPRISE;
  producerTargetEnum: typeof ProducerTarget = ProducerTarget;
  functionalities: FunctionalityDTO[] = [];
  plans: PlanDTO[] = [];
  selectedPlan: PlanDTO;
  innerWidth: number;

  mobile: boolean = false;
  mobilePlanNumber: number = 0;
  producerUrl: string = environment.producerUrl;

  @Input()
  legalStatus: LegalStatus = null;
  @Input()
  nbPrinterFixe: number = null;
  @Input()
  nbUserFixe: number = null;
  @Input()
  modeEvolutionPlan: boolean = false;
  @Input()
  idPreviousPlan: number = null;
  @Input() //false is user
  evolutionCauseIsPrinter: boolean;
  @Output()
  previous: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  planSelected: EventEmitter<Plan> = new EventEmitter<Plan>();

  legalStatusToProducerTarget = new Map<LegalStatus, ProducerTarget>([
    [LegalStatus.STATUS_AE, ProducerTarget.MICRO_ENTREPRISE],
    [LegalStatus.STATUS_EI, ProducerTarget.MICRO_ENTREPRISE],
    [LegalStatus.STATUS_EIRL, ProducerTarget.ENTREPRISE],
    [LegalStatus.STATUS_EURL, ProducerTarget.ENTREPRISE],
    [LegalStatus.STATUS_SA, ProducerTarget.ENTREPRISE],
    [LegalStatus.STATUS_SARL, ProducerTarget.ENTREPRISE],
    [LegalStatus.STATUS_SAS, ProducerTarget.ENTREPRISE],
    [LegalStatus.STATUS_SASU, ProducerTarget.ENTREPRISE],
    [LegalStatus.STATUS_ASSOCIATION, ProducerTarget.ENTREPRISE],
  ]);

  constructor(@Inject(SubscriptionPlanSVService) private subscriptionPlanSVService: SubscriptionPlanSVService, private location: Location) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.mobileView();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.subscriptionPlanSVService.getFunctionalities().subscribe(
      (data) => {
        this.functionalities = data;
      },
      (err) => {
        L.e(err);
      }
    );
    this.getPlans();
  }

  ngOnChanges(changes) {
    //console.log('changes', changes);
    if (changes.nbPrinterFixe) {
      this.nbPrinters = this.nbPrinterFixe;
      this.nbPrintersCalcul = this.nbPrinterFixe;
    }
  }

  getPlans() {
    let target = this.producerTarget;
    if (this.legalStatus !== null) target = this.legalStatusToProducerTarget.get(this.legalStatus);
    this.subscriptionPlanSVService.getPlansWithTarget(target).subscribe(
      (data) => {
        this.plans = data;
        this.selectedPlan = data[0];
        this.mobilePlanNumber = 0;
        this.mobileView();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  getPlansFiltered() {
    if (this.modeEvolutionPlan)
      return this.plans.filter((p) => {
        //console.log(p.plan.nbMaxPrinter, '>=', this.nbPrinterFixe, '&&', p.plan.nbMaxUsers, '>=', this.nbUserFixe, '&&', p.plan.id, '!==', this.idPreviousPlan);
        return (
          ((this.evolutionCauseIsPrinter ? p.plan.nbMaxPrinter > this.nbPrinterFixe : p.plan.nbMaxPrinter >= this.nbPrinterFixe) ||
            p.plan.nbMaxPrinter === 0) && //
          ((this.evolutionCauseIsPrinter ? p.plan.nbMaxUsers >= this.nbUserFixe : p.plan.nbMaxUsers > this.nbUserFixe) || p.plan.nbMaxUsers === 0) && //
          p.plan.id !== this.idPreviousPlan
        );
      });
    return this.plans;
  }

  isDisabled(p: PlanDTO) {
    if (this.nbPrinterFixe !== null) return p.plan.nbMaxPrinter < this.nbPrinterFixe && p.plan.nbMaxPrinter !== 0;
    return p.plan.nbMaxPrinter < this.nbPrinters && p.plan.nbMaxPrinter !== 0;
  }

  planClicked(event: Event, plan: PlanDTO) {
    //event.preventDefault();
    if (this.isDisabled(plan)) return;
    if (this.planSelected.observers.length !== 0) this.planSelected.emit(plan.plan);
    else window.location.assign(this.producerUrl + 'creation-compte/create/' + plan.plan.id);
  }

  mobileView() {
    if (this.plans.length * 15 * 15 + (this.plans.length + 1) * 45 + 215 > this.innerWidth) this.mobile = true;
    else this.mobile = false;
  }

  updateSelectedPlan() {
    if (this.nbPrinters <= 0 || isNaN(this.nbPrinters)) this.nbPrintersCalcul = 1;
    else this.nbPrintersCalcul = this.nbPrinters;

    if (this.selectedPlan.plan.nbMaxPrinter < this.nbPrintersCalcul && this.selectedPlan.plan.nbMaxPrinter != 0) {
      let i = 0;
      while (this.selectedPlan.plan.nbMaxPrinter < this.nbPrintersCalcul && i < this.plans.length && this.selectedPlan.plan.nbMaxPrinter != 0) {
        if (this.plans[i].plan.nbMaxPrinter >= this.nbPrintersCalcul || this.plans[i].plan.nbMaxPrinter == 0) this.selectedPlan = this.plans[i];
        i++;
      }
    }
  }

  selectPlan(i: number) {
    this.mobilePlanNumber = i;
    this.selectedPlan = this.plans[i];
    // this.selectedPlan = this.plans.find((x) => x.plan.id == id);
  }

  previousPlan(tries: number) {
    if (this.mobilePlanNumber == 0) {
      this.mobilePlanNumber = this.plans.length - 1;
    } else this.mobilePlanNumber -= 1;
    if (this.plans[this.mobilePlanNumber].plan.nbMaxPrinter >= this.nbPrintersCalcul || this.plans[this.mobilePlanNumber].plan.nbMaxPrinter == 0)
      this.selectedPlan = this.plans[this.mobilePlanNumber];
    else if (tries < this.plans.length) this.previousPlan(tries + 1);
  }

  nextPlan(tries: number) {
    if (this.mobilePlanNumber == this.plans.length - 1) {
      this.mobilePlanNumber = 0;
    } else this.mobilePlanNumber += 1;
    if (this.plans[this.mobilePlanNumber].plan.nbMaxPrinter >= this.nbPrintersCalcul || this.plans[this.mobilePlanNumber].plan.nbMaxPrinter == 0)
      this.selectedPlan = this.plans[this.mobilePlanNumber];
    else if (tries < this.plans.length) this.nextPlan(tries + 1);
  }

  back() {
    if (this.previous.observers.length !== 0) this.previous.emit();
    else this.location.back();
  }
}
