import { Component, OnInit } from '@angular/core';
import { LandingChoiceService } from '../landing-choice.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { CookiesService } from 'ngx-utils-cookies-port';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  year: number = new Date().getFullYear();
  urlClient: string = null;
  urlProducer: string = null;

  constructor(public landingChoiceService: LandingChoiceService, private router: Router, private cookiesService: CookiesService) {}

  ngOnInit() {
    this.urlClient = environment.clientUrl + 'connexion';
    this.urlProducer = environment.producerUrl + 'connexion';
  }

  setClient() {
    this.landingChoiceService.setClient();
    this.router.navigate(['/espace-client']);
  }

  setProducer() {
    this.landingChoiceService.setProducer();
    this.router.navigate(['/imprimeur-3D']);
  }
}
