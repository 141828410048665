// ENTITY com.degineo.metier.metadata.entities.ColorNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ColorNEW {

  id: number = 0;
  hexCode: string = '';
  name: string = '';
  position: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.ColorNEW'] = ColorNEW;

reverseMapping[ColorNEW.name] = 'com.degineo.metier.metadata.entities.ColorNEW';

fields['com.degineo.metier.metadata.entities.ColorNEW']  = {
    id: 'java.lang.Integer',
    hexCode: 'java.lang.String',
    name: 'java.lang.String',
    position: 'java.lang.Integer'
};
