import { Injectable } from '@angular/core';

const allowedExtensions: string[] = [
  '*.3DS',
  '*.3MF',
  '*.ASE',
  '*.BLEND',
  '*.COB',
  '*.DAE',
  '*.Collada',
  '*.DXF',
  '*.FBX',
  '*.IFC-STEP',
  '*.LWO',
  '*.MD2',
  '*.MD3',
  '*.MD5',
  '*.OBJ',
  '*.OFF',
  '*.PLY',
  '*.STP',
  '*.STL',
  '*.DFX',
];

@Injectable({
  providedIn: 'root',
})
export class AllowedModelExtensionsService {
  constructor() {}

  getExtensions() {
    return allowedExtensions.map((ext) => ext.substring(1).toLocaleLowerCase());
  }
}
