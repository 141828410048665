// ENTITY com.degineo.metier.order.dto.kanban.KanbanDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { InProductionOrderDTO } from './InProductionOrderDTO';
import { InPreparationOrderDTO } from './InPreparationOrderDTO';
import { ReceivedOrderDTO } from './ReceivedOrderDTO';
import { InPreparationShipmentDTO } from './InPreparationShipmentDTO';
import { WaitingCarrierShipmentDTO } from './WaitingCarrierShipmentDTO';

export class KanbanDTO {

  receivedOrders: ReceivedOrderDTO[] = null;
  inPreparationOrders: InPreparationOrderDTO[] = null;
  inProductionOrders: InProductionOrderDTO[] = null;
  inPreparationShipments: InPreparationShipmentDTO[] = null;
  waitingCarrierShipments: WaitingCarrierShipmentDTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.KanbanDTO'] = KanbanDTO;

reverseMapping[KanbanDTO.name] = 'com.degineo.metier.order.dto.kanban.KanbanDTO';

fields['com.degineo.metier.order.dto.kanban.KanbanDTO']  = {
    receivedOrders: 'java.util.List<com.degineo.metier.order.dto.kanban.ReceivedOrderDTO>',
    inPreparationOrders: 'java.util.List<com.degineo.metier.order.dto.kanban.InPreparationOrderDTO>',
    inProductionOrders: 'java.util.List<com.degineo.metier.order.dto.kanban.InProductionOrderDTO>',
    inPreparationShipments: 'java.util.List<com.degineo.metier.order.dto.kanban.InPreparationShipmentDTO>',
    waitingCarrierShipments: 'java.util.List<com.degineo.metier.order.dto.kanban.WaitingCarrierShipmentDTO>'
};
