// ENTITY com.degineo.metier.tools.filter.FilterOrder generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { OrderBy } from './OrderBy';

export class FilterOrder {

  name: string = '';
  order: OrderBy = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.tools.filter.FilterOrder'] = FilterOrder;

reverseMapping[FilterOrder.name] = 'com.degineo.metier.tools.filter.FilterOrder';

fields['com.degineo.metier.tools.filter.FilterOrder']  = {
    name: 'java.lang.String',
    order: 'com.ic2.db.entityManager.utils.OrderBy'
};
