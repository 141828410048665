// ENTITY com.degineo.metier.order.dto.kanban.panel.inprep.InPrepProducerPrinterDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { InPrepPPCODTO } from './InPrepPPCODTO';

export class InPrepProducerPrinterDTO {

  idPrinter: number = 0;
  idProducerPrinter: number = 0;
  name: string = '';
  consumablesOptions: InPrepPPCODTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.inprep.InPrepProducerPrinterDTO'] = InPrepProducerPrinterDTO;

reverseMapping[InPrepProducerPrinterDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.inprep.InPrepProducerPrinterDTO';

fields['com.degineo.metier.order.dto.kanban.panel.inprep.InPrepProducerPrinterDTO']  = {
    idPrinter: 'java.lang.Integer',
    idProducerPrinter: 'java.lang.Integer',
    name: 'java.lang.String',
    consumablesOptions: 'java.util.List<com.degineo.metier.order.dto.kanban.panel.inprep.InPrepPPCODTO>'
};
