// ENTITY com.degineo.metier.producer.dto.ProducerSignupStep2DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerSignupStep2DTO {

  firstname: string = '';
  lastname: string = '';
  phoneNumber: string = '';
  job: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerSignupStep2DTO'] = ProducerSignupStep2DTO;

reverseMapping[ProducerSignupStep2DTO.name] = 'com.degineo.metier.producer.dto.ProducerSignupStep2DTO';

fields['com.degineo.metier.producer.dto.ProducerSignupStep2DTO']  = {
    firstname: 'java.lang.String',
    lastname: 'java.lang.String',
    phoneNumber: 'java.lang.String',
    job: 'java.lang.String'
};
