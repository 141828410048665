// com.degineo.metier.order.service.kanban.InPreparationOrderServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { InPreparationOrderPanelDTO } from '../entities/InPreparationOrderPanelDTO';
import { OrderFileStatus } from '../entities/OrderFileStatus';

@Injectable({
  providedIn: 'root',
})
export class InPreparationOrderService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateOrderModel(idOrder: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationOrderService.updateOrderModel';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getPreparation(idOrder: number): RpcRequestBuilder<InPreparationOrderPanelDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationOrderService.getPreparation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.kanban.panel.InPreparationOrderPanelDTO');
  }

  change3DFileStatus(idOrder: number, producerFileStatus: OrderFileStatus): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationOrderService.change3DFileStatus';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(producerFileStatus, 'com.degineo.metier.order.entities.OrderFileStatus', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  changePrintMethod(idOrder: number, idProducerPrinterConsumableOption: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationOrderService.changePrintMethod';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterConsumableOption, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  cancelOrder(idOrder: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationOrderService.cancelOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  launchInProduction(idOrder: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationOrderService.launchInProduction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
