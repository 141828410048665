// ENTITY com.degineo.metier.producer.dto.dashboard.DashboardPrinterWarningDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class DashboardPrinterWarningDTO {

  idProducerPrinter: number = 0;
  name: string = '';
  brand: string = '';
  noProductionCost: boolean = false;
  noMaterialCost: boolean = false;
  noConsumableOptions: boolean = false;
  noMargins: boolean = false;
  noFinish: boolean = false;
  noArticleTypePrice: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.dashboard.DashboardPrinterWarningDTO'] = DashboardPrinterWarningDTO;

reverseMapping[DashboardPrinterWarningDTO.name] = 'com.degineo.metier.producer.dto.dashboard.DashboardPrinterWarningDTO';

fields['com.degineo.metier.producer.dto.dashboard.DashboardPrinterWarningDTO']  = {
    idProducerPrinter: 'java.lang.Integer',
    name: 'java.lang.String',
    brand: 'java.lang.String',
    noProductionCost: 'java.lang.Boolean',
    noMaterialCost: 'java.lang.Boolean',
    noConsumableOptions: 'java.lang.Boolean',
    noMargins: 'java.lang.Boolean',
    noFinish: 'java.lang.Boolean',
    noArticleTypePrice: 'java.lang.Boolean'
};
