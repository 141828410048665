// ENTITY com.degineo.metier.producer_printer.entities.ProducerPrinter generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerPrinter {

  id: number = 0;
  idPrinter: number = 0;
  idProducer: number = 0;
  defaultProductionCost: number = 0;
  currentProductionCost: number = 0;
  creationDate: Date = null;
  deactivationDate: Date = null;
  updateDate: Date = null;
  firstCheckedDate: Date = null;
  active: boolean = false;
  checked: boolean = false;
  producerDeleted: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.entities.ProducerPrinter'] = ProducerPrinter;

reverseMapping[ProducerPrinter.name] = 'com.degineo.metier.producer_printer.entities.ProducerPrinter';

fields['com.degineo.metier.producer_printer.entities.ProducerPrinter']  = {
    id: 'java.lang.Integer',
    idPrinter: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    defaultProductionCost: 'java.lang.Double',
    currentProductionCost: 'java.lang.Double',
    creationDate: 'java.time.Instant',
    deactivationDate: 'java.time.Instant',
    updateDate: 'java.time.Instant',
    firstCheckedDate: 'java.time.Instant',
    active: 'java.lang.Boolean',
    checked: 'java.lang.Boolean',
    producerDeleted: 'java.lang.Boolean'
};
