// ENTITY com.degineo.metier.producer.dto.ImportProducerResultDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ImportProducerResultDTO {

  success: number = 0;
  total: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ImportProducerResultDTO'] = ImportProducerResultDTO;

reverseMapping[ImportProducerResultDTO.name] = 'com.degineo.metier.producer.dto.ImportProducerResultDTO';

fields['com.degineo.metier.producer.dto.ImportProducerResultDTO']  = {
    success: 'java.lang.Integer',
    total: 'java.lang.Integer'
};
