// ENTITY com.degineo.metier.producer.dto.bo.ProducerInfoBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { Producer } from './Producer';
import { AddressNEW } from './AddressNEW';
import { Plan } from './Plan';

export class ProducerInfoBODTO {

  logoToken: string = '';
  producer: Producer = null;
  nbPrinter: number = 0;
  DateLastLog: Date = null;
  firstNameLastLog: string = '';
  lastNameLastLog: string = '';
  adminFirstName: string = '';
  adminLastName: string = '';
  adminTel: string = '';
  adminEmail: string = '';
  address: AddressNEW = null;
  isRegister: boolean = false;
  autologToken: string = '';
  plan: Plan = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.bo.ProducerInfoBODTO'] = ProducerInfoBODTO;

reverseMapping[ProducerInfoBODTO.name] = 'com.degineo.metier.producer.dto.bo.ProducerInfoBODTO';

fields['com.degineo.metier.producer.dto.bo.ProducerInfoBODTO']  = {
    logoToken: 'java.lang.String',
    producer: 'com.degineo.metier.producer.entities.Producer',
    nbPrinter: 'java.lang.Integer',
    DateLastLog: 'java.time.Instant',
    firstNameLastLog: 'java.lang.String',
    lastNameLastLog: 'java.lang.String',
    adminFirstName: 'java.lang.String',
    adminLastName: 'java.lang.String',
    adminTel: 'java.lang.String',
    adminEmail: 'java.lang.String',
    address: 'com.degineo.metier.address.entities.AddressNEW',
    isRegister: 'java.lang.Boolean',
    autologToken: 'java.lang.String',
    plan: 'com.degineo.metier.plan.entities.Plan'
};
