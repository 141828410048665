// com.degineo.metier.dump.service.DumpServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { DumpDTO } from '../entities/DumpDTO';

@Injectable({
  providedIn: 'root',
})
export class DumpService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  dump(dumpName: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DumpService.dump';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dumpName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getDumps(): RpcRequestBuilder<DumpDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DumpService.getDumps';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  deleteZip(zipName: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DumpService.deleteZip';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(zipName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  restore(dumpName: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DumpService.restore';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dumpName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  renameZip(zipName: string, newZipName: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DumpService.renameZip';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(zipName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(newZipName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  importFixtures(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DumpService.importFixtures';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
