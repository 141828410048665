<div *ngIf="data" class="container-fluid">
  <div class="row">
    <div class="col-12 bg-medium" style="height: 7.7rem">
      <div class="row text-white mt-3">
        <div class="col-lg-10 offset-lg-2 col-10 offset-1">
          <div class="row px-md-5 px-0 justify-content-center">
            <div class="position-relative logo-container" (click)="logoInput.click()">
              <img
                *ngIf="!progressPercent"
                class="rounded-lg shadow-2 logo"
                height=" 140px"
                width="140px"
                style="object-fit: fill"
                [src]="getLogo()"
                alt="Logo entreprise"
              />
              <input #logoInput class="d-none" type="file" id="file" style="display: none" accept="image/png" #fileInput (change)="handleFileInput($event)" />
              <div *ngIf="!progressPercent" class="logo-text position-absolute text-center">
                <div class="text-white">Modifier le logo</div>
              </div>
              <div class="mt-2" *ngIf="progressPercent" style="height: 140px; width: 140px">
                <div class="progress-bar text-center" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
              </div>
            </div>
            <div class="col d-md-flex flex-column ml-4 mt-3 p-0 d-none">
              <h2 class="my-2" style="font-size: 1.6rem; font-weight: 400">{{ data.name }}</h2>
              {{ data.enterpriseType }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3 text-center text-medium" style="min-height: 3rem; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)">
      <div class="col d-flex flex-column mt-5 mb-3 d-md-none">
        <h2 class="mb-2" style="font-size: 1.6rem; font-weight: 400">{{ data.name }}</h2>
        {{ data.enterpriseType }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 offset-lg-2 col-10 offset-1 order-2 order-lg-1">
      <!--PrÃ©sentation-->
      <div class="row mb-4">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3 class="mb-3"><i class="fas fa-align-left position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>PrÃ©sentation</h3>
          <div class="ml-3">
            <textarea name="prez" class="w-100 form-control" [(ngModel)]="data.presentation" rows="10" maxlength="600"></textarea>
            <div class="row mt-4">
              <div class="col-4 align-self-center font-weight-bold">Site web&nbsp;:</div>
              <div class="col-8"><input type="text" [(ngModel)]="data.website" class="form-control d-inline" /></div>
            </div>
            <div class="row mt-4">
              <div class="col-4 align-self-center font-weight-bold">Facebook&nbsp;:</div>
              <div class="col-8"><input type="text" [(ngModel)]="data.facebook" class="form-control d-inline" /></div>
            </div>
            <div class="row mt-4">
              <div class="col-4 align-self-center font-weight-bold">Twitter&nbsp;:</div>
              <div class="col-8"><input type="text" [(ngModel)]="data.twitter" class="form-control d-inline" /></div>
            </div>
            <div class="row mt-4">
              <div class="col-4 align-self-center font-weight-bold">LinkedIn&nbsp;:</div>
              <div class="col-8"><input type="text" [(ngModel)]="data.linkedin" class="form-control d-inline" /></div>
            </div>
            <div class="row mt-4">
              <div class="col-4 align-self-center font-weight-bold">Youtube&nbsp;:</div>
              <div class="col-8"><input type="text" [(ngModel)]="data.youtube" class="form-control d-inline" /></div>
            </div>
            <div class="row mt-4">
              <div class="col-4 align-self-center font-weight-bold">Instagram&nbsp;:</div>
              <div class="col-8"><input type="text" [(ngModel)]="data.instagram" class="form-control d-inline" /></div>
            </div>
          </div>
        </div>
      </div>
      <!--Domaines d'application-->
      <div class="row mb-4">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3 class="mb-3"><i class="fas fa-crosshairs position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>Domaines d'application</h3>
          <div class="alert alert-info my-3" *ngIf="data.impressionTechnologiesLoadedFromPrinters && !inBackoffice">
            <i class="far fa-question-circle mr-2"></i>Vos domaines d'application sont ajoutÃ©s automatiquement en fonction des imprimantes et consommables que
            vous avez enregistrÃ© dans l'onglet <a routerLink="/mes-imprimantes">Mes&nbsp;imprimantes</a>.
          </div>

          <ng-container *ngIf="data.impressionTechnologiesLoadedFromPrinters">
            <div class="ml-3">
              <ng-container *ngIf="data.applicationDomains !== null && data.applicationDomains.length > 0">
                <div class="row justify-content-start">
                  <div class="ml-3 mt-3 text-center" *ngFor="let ad of data.applicationDomains">
                    <div class="px-4 py-2 bg-light-30p rounded align-self-center">{{ ad }}</div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="!data.impressionTechnologiesLoadedFromPrinters">
            <div id="applicationDomains" class="mb-2 ml-3" *ngFor="let da of data.applicationDomains; let index = index; trackBy: trackByFn">
              <select
                class="form-control w-75 d-inline"
                [(ngModel)]="data.applicationDomains[index]"
                [disabled]="data.impressionTechnologiesLoadedFromPrinters"
              >
                <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
                <optgroup [label]="target1.level1.lib" *ngFor="let target1 of types">
                  <option *ngFor="let target2 of target1.children" [ngValue]="target2">{{ target2.lib }}</option>
                </optgroup>
              </select>
              <i class="far fa-times-circle pointer text-danger ml-2 fs-3" aria-hidden="true" (click)="data.applicationDomains.splice(index, 1)"></i>
            </div>
            <div class="pl-4">
              <p
                class="font-weight-bold text-primary pointer d-inline-block"
                style="font-size: 1rem"
                (click)="data.applicationDomains.push(null); selectLast('applicationDomains')"
                ngbTooltip="Ajouter un autre domaine d'application"
                placement="bottom"
                tooltipClass="custom-tooltip"
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                Ajouter
              </p>
            </div>
          </ng-container>
        </div>
      </div>
      <!--Technologies d'impression-->
      <div class="row mb-4">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3 class="mb-3"><i class="fas fa-microchip position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>Technologies d'impression</h3>
          <div class="alert alert-info my-3" *ngIf="data.impressionTechnologiesLoadedFromPrinters && !inBackoffice">
            <i class="far fa-question-circle mr-2"></i>Vos technologies d'impression sont ajoutÃ©s automatiquement en fonction des imprimantes et consommables
            que vous avez enregistrÃ© dans l'onglet <a routerLink="/mes-imprimantes">Mes&nbsp;imprimantes</a>.
          </div>

          <ng-container *ngIf="data.impressionTechnologiesLoadedFromPrinters">
            <div class="ml-3">
              <ng-container *ngIf="data.impressionTechnologies !== null && data.impressionTechnologies.length > 0">
                <div class="row justify-content--start">
                  <div class="ml-3 mt-3 text-center" *ngFor="let it of data.impressionTechnologies">
                    <div class="px-4 py-2 bg-light rounded align-self-center">{{ it }}</div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="!data.impressionTechnologiesLoadedFromPrinters">
            <div id="impressionTechnologies" class="mb-2 ml-3" *ngFor="let da of data.impressionTechnologies; let index = index; trackBy: trackByFn">
              <select
                class="form-control w-75 d-inline"
                [(ngModel)]="data.impressionTechnologies[index]"
                [disabled]="data.impressionTechnologiesLoadedFromPrinters"
              >
                <option [ngValue]="null">-- Technologie --</option>
                <option *ngFor="let tech of PrinterTechnology.values" [ngValue]="tech">{{ tech.lib }}</option>
              </select>
              <i class="far fa-times-circle text-danger ml-2 fs-3" aria-hidden="true" (click)="data.impressionTechnologies.splice(index, 1)"></i>
            </div>

            <div class="pl-4">
              <p
                class="font-weight-bold text-primary pointer d-inline-block"
                style="font-size: 1rem"
                (click)="data.impressionTechnologies.push(null); selectLast('impressionTechnologies')"
                ngbTooltip="Ajouter une autre technologie d'impression 3D"
                placement="bottom"
                tooltipClass="custom-tooltip"
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                Ajouter
              </p>
            </div>
          </ng-container>
        </div>
      </div>
      <!--Adresse-->
      <div class="row mb-4">
        <div class="col-12 rounded shadow-2" style="overflow: hidden">
          <div class="row">
            <div class="col-md-6 col-12 px-5 py-4" style="z-index: 1">
              <h3 class="mb-3"><i class="fas fa-map-marker-alt position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>Adresse</h3>
              <div class="ml-3">
                <span style="font-weight: bold">{{ data.name }}</span
                ><br />
                {{ data.address.line1 }}<br />
                <ng-container *ngIf="data.address.line2"> <br />{{ data.address.line2 }} </ng-container>
                <ng-container *ngIf="data.address.line3"> <br />{{ data.address.line3 }} </ng-container>
                {{ data.address.zipCode }}&nbsp;{{ data.address.city }}<br />
                {{ data.address.country }}
              </div>
            </div>
            <div class="col-md-6 col-12 p-0" style="min-height: 19rem">
              <agm-map
                style="height: 100%"
                [zoom]="12"
                [latitude]="data.address.latitude + 0.01"
                [longitude]="data.address.longitude"
                [fullscreenControl]="true"
              >
                <agm-marker [latitude]="data.address.latitude" [longitude]="data.address.longitude"> </agm-marker>
              </agm-map>
            </div>
          </div>
        </div>
      </div>
      <!--Horaires & contacts-->
      <div class="row mb-4">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3 class="mb-3"><i class="far fa-clock position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>Horaires &&nbsp;Contacts</h3>
          <div class="mb-3 ml-3">
            <div class="row mt-3 mb-1 align-items-center" *ngFor="let s of data.schedule; let i = index">
              <div class="col-3" style="font-weight: 500">{{ days[s.day - 1] }}</div>
              <div class="col-2">
                <ui-switch size="small" [(ngModel)]="s.open" color="#00c462" defaultBgColor="#D82929"></ui-switch>
              </div>
              <div class="col-7">
                <input
                  type="text"
                  style="width: 70px"
                  minlength="4"
                  maxlength="5"
                  [ngbTooltip]="open"
                  [(ngModel)]="s.schedule1Open"
                  [disabled]="!s.open"
                  mask="00:00"
                  class="form-control d-inline dashed-when-disabled"
                />
                -
                <input
                  type="text"
                  style="width: 70px"
                  minlength="4"
                  maxlength="5"
                  [ngbTooltip]="close"
                  [(ngModel)]="s.schedule1End"
                  [disabled]="!s.open"
                  mask="00:00"
                  class="form-control d-inline dashed-when-disabled"
                />
                <i
                  class="fa fa-plus-circle text-primary pointer ml-2 fs-3"
                  aria-hidden="true"
                  (click)="s.schedule2Open = ''"
                  *ngIf="s.schedule2Open === null"
                  title="Ajouter un 2&#7497; crÃ©neau horaire"
                ></i>
              </div>
              <div class="col-7 offset-5 mt-1" *ngIf="s.schedule2Open !== null">
                <input
                  type="text"
                  style="width: 70px"
                  minlength="4"
                  maxlength="5"
                  [ngbTooltip]="open"
                  [(ngModel)]="s.schedule2Open"
                  [disabled]="!s.open"
                  mask="00:00"
                  class="form-control d-inline dashed-when-disabled"
                />
                -
                <input
                  type="text"
                  style="width: 70px"
                  minlength="4"
                  maxlength="5"
                  [ngbTooltip]="close"
                  [(ngModel)]="s.schedule2End"
                  [disabled]="!s.open"
                  mask="00:00"
                  class="form-control d-inline dashed-when-disabled"
                />
                <i class="far fa-times-circle pointer text-danger ml-2 fs-3" aria-hidden="true" (click)="s.schedule2Open = null; s.schedule2End = null"></i>
              </div>
            </div>
          </div>

          <div class="row ml-0 py-3" style="border-top: 1px solid rgba(0, 0, 0, 0.1)">
            <div class="col-4 align-self-center font-weight-bold">E-mail&nbsp;:</div>
            <div class="col-8"><input type="text" [(ngModel)]="data.contactEmail" class="form-control d-inline" /></div>
          </div>
          <div class="row ml-0">
            <div class="col-4 align-self-center font-weight-bold">TÃ©lÃ©phone&nbsp;:</div>
            <div class="col-8">
              <input type="tel" [(ngModel)]="data.contactTel" class="form-control d-inline" pattern="^0[1-9][0-9]{8}$" />
            </div>
          </div>
        </div>
      </div>
      <!--L'Ã©quipe-->
      <div class="row mb-5">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3 class="mb-3"><i class="fas fa-user-friends position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>L'Ã©quipe</h3>
          <ng-container *ngIf="data.subscribed && data.employees != null && data.employees.length > 0">
            <div class="row">
              <div class="col-12 col-md-6 mb-3" *ngFor="let emp of data.employees; let i = index">
                <div class="row flex-nowrap ml-3">
                  <img
                    [img-preloader]="getAvatar(i)"
                    [onErrorImage]="'assets/image-profil-default.svg'"
                    alt="Photo employÃ©"
                    style="height: 3.75rem; width: 3.75rem"
                    class="rounded-circle mr-md-4 mr-3"
                  />
                  <div class="d-flex flex-column">
                    <div style="font-weight: bold">{{ emp.firstName }} {{ emp.lastName }}</div>
                    {{ emp.job }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!data.subscribed || data.employees == null || data.employees.length == 0">
            <div class="text-light font-weight-bold" style="font-size: 1.2rem">Non renseignÃ©</div>
          </ng-container>
        </div>
      </div>
    </div>
    <!--Side menu-->
    <div class="position-relative order-1 order-lg-2 col-10 offset-1 col-lg-3 offset-lg-0 side-menu mb-3 px-0">
      <div class="position-sticky ml-lg-3 ml-xl-5" style="top: 50px">
        <div *ngIf="editMode" class="shadow-2 rounded mb-3 text-center bg-white" style="overflow: hidden">
          <div class="bg-primary text-white text-center py-2 fs-4 fw-500">Modifier votre page Degineo</div>
          <div class="p-3">
            <button class="btn btn-success mb-2 w-100 px-1" (click)="saveAll()">Enregistrer les modifications</button>
            <button class="btn btn-danger w-100 px-1" (click)="cancelAll()">Annuler les modifications en cours</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!data && loaded">
    <h2 class="text-center">Ce producteur n'existe pas</h2>
  </div>
</div>

<ng-template #open>
  Heure d'ouverture <br />
  ex : 08:30
</ng-template>

<ng-template #close>
  Heure de fermeture <br />
  ex : 18:00
</ng-template>
