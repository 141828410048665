<div *ngIf="data" class="container-fluid position-relative">
  <div class="row" style="overflow: auto">
    <div class="col-12 bg-medium" style="height: 7.7rem">
      <div class="row text-white mt-3">
        <div class="col-lg-10 offset-lg-2 col-10 offset-1">
          <div class="row px-md-5 px-0 justify-content-center">
            <img
              class="rounded-lg shadow-2"
              height="140px"
              width="140px"
              style="z-index: 999; object-fit: fill; min-width: 140px; min-height: 140px"
              [src]="getLogo()"
              alt="Logo entreprise"
            />
            <div class="col d-md-flex flex-column ml-4 mt-3 p-0 d-none">
              <h2 class="my-2" style="font-size: 1.6rem; font-weight: 400">{{ data.name }}</h2>
              {{ data.enterpriseType }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3 text-center text-medium" style="min-height: 3rem; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)">
      <div class="col d-flex flex-column mt-5 mb-3 d-md-none">
        <h2 class="mb-2" style="font-size: 1.6rem; font-weight: 400">{{ data.name }}</h2>
        {{ data.enterpriseType }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 offset-lg-2 col-10 offset-1 order-2 order-lg-1">
      <!--PrÃ©sentation-->
      <div class="row mb-4">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3 class="mb-3"><i class="fas fa-align-left position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>PrÃ©sentation</h3>
          <div class="ml-3">
            <ng-container
              *ngIf="
                (data.subscribed || data.registered || degineo || isProducer) &&
                (data.presentation || data.website || data.facebook || data.twitter || data.linkedin || data.youtube || data.instagram)
              "
            >
              <div class="mb-3">{{ data.presentation }}</div>
              <a *ngIf="data.website" href="{{ data.website }}" target="_blank">{{ data.website }}</a>
              <div *ngIf="data.facebook || data.twitter || data.linkedin || data.youtube || data.instagram" class="mt-3">
                <a *ngIf="data.facebook" class="mr-3" href="{{ data.facebook }}" target="_blank"
                  ><i class="fab fa-facebook-square social-media" style="font-size: 2.2rem"></i
                ></a>
                <a *ngIf="data.twitter" class="mr-3" href="{{ data.twitter }}" target="_blank"
                  ><i class="fab fa-twitter-square social-media" style="font-size: 2.2rem"></i
                ></a>
                <a *ngIf="data.linkedin" class="mr-3" href="{{ data.linkedin }}" target="_blank"
                  ><i class="fab fa-linkedin social-media" style="font-size: 2.2rem"></i
                ></a>
                <a *ngIf="data.youtube" class="mr-3" href="{{ data.youtube }}" target="_blank"
                  ><i class="fab fa-youtube-square social-media" style="font-size: 2.2rem"></i
                ></a>
                <a *ngIf="data.instagram" class="mr-3" href="{{ data.instagram }}" target="_blank"
                  ><i class="fab fa-instagram-square social-media" style="font-size: 2.2rem"></i
                ></a>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                (!data.subscribed && !data.registered && !degineo && !isProducer) ||
                (!data.presentation && !data.website && !data.facebook && !data.twitter && !data.linkedin && !data.youtube && !data.instagram)
              "
            >
              <div class="text-light font-weight-bold" style="font-size: 1.2rem">Non renseignÃ©</div>
            </ng-container>
          </div>
        </div>
      </div>
      <!--Domaines d'application-->
      <div class="row mb-4">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3><i class="fas fa-crosshairs position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>Domaines d'application</h3>
          <div class="ml-3">
            <ng-container
              *ngIf="(data.subscribed || data.registered || degineo || isProducer) && data.applicationDomains != null && data.applicationDomains.length > 0"
            >
              <div class="row justify-content-start">
                <div class="ml-3 mt-3 text-center" *ngFor="let ad of data.applicationDomains">
                  <div class="px-4 py-2 bg-light-30p rounded align-self-center">{{ ad }}</div>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container
            *ngIf="(!data.subscribed && !data.registered && !degineo && !isProducer) || data.applicationDomains == null || data.applicationDomains.length == 0"
          >
            <div class="mt-3 text-light font-weight-bold" style="font-size: 1.2rem">Non renseignÃ©</div>
          </ng-container>
        </div>
      </div>
      <!--Technologies d'impression-->
      <div class="row mb-4">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3><i class="fas fa-microchip position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>Technologies d'impression</h3>
          <div class="ml-3">
            <ng-container
              *ngIf="
                (data.subscribed || data.registered || degineo || isProducer) && data.impressionTechnologies != null && data.impressionTechnologies.length > 0
              "
            >
              <div class="row justify-content--start">
                <div class="ml-3 mt-3 text-center" *ngFor="let it of data.impressionTechnologies">
                  <div class="px-4 py-2 bg-light rounded align-self-center">{{ it }}</div>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container
            *ngIf="
              (!data.subscribed && !data.registered && !degineo && !isProducer) ||
              data.impressionTechnologies == null ||
              data.impressionTechnologies.length == 0
            "
          >
            <div class="mt-3 text-light font-weight-bold" style="font-size: 1.2rem">Non renseignÃ©</div>
          </ng-container>
        </div>
      </div>
      <!--Adresse-->
      <div class="row mb-4">
        <div class="col-12 rounded shadow-2" style="overflow: hidden">
          <div class="row">
            <div class="col-md-6 col-12 px-5 py-4" style="z-index: 1">
              <h3 class="mb-3"><i class="fas fa-map-marker-alt position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>Adresse</h3>
              <div class="ml-3">
                <span style="font-weight: bold">{{ data.name }}</span
                ><br />
                {{ data.address.line1 }}<br />
                <ng-container *ngIf="data.address.line2"> <br />{{ data.address.line2 }} </ng-container>
                <ng-container *ngIf="data.address.line3"> <br />{{ data.address.line3 }} </ng-container>
                {{ data.address.zipCode }}&nbsp;{{ data.address.city }}<br />
                {{ data.address.country }}
              </div>
            </div>
            <div class="col-md-6 col-12 p-0" style="min-height: 19rem">
              <agm-map
                *ngIf="!ssr"
                style="height: 100%"
                [zoom]="12"
                [latitude]="data.address.latitude + 0.01"
                [longitude]="data.address.longitude"
                [fullscreenControl]="true"
              >
                <agm-marker [latitude]="data.address.latitude" [longitude]="data.address.longitude"> </agm-marker>
              </agm-map>
            </div>
          </div>
        </div>
      </div>
      <!--Horaires & contacts-->
      <div class="row mb-4">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3 class="mb-3"><i class="far fa-clock position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>Horaires &&nbsp;Contacts</h3>
          <div class="ml-xl-3 ml-lg-0 ml-sm-3 ml-0">
            <ng-container
              *ngIf="
                (data.subscribed || data.registered || degineo || isProducer) &&
                ((data.schedule != null && data.schedule.length > 0) || data.contactEmail || data.contactTel)
              "
            >
              <div class="row">
                <div *ngIf="data.schedule != null && data.schedule.length > 0" class="col-md-6 col-12">
                  <ng-container *ngFor="let day of data.schedule">
                    <div class="row mb-3">
                      <div class="col-4" style="font-weight: bold">
                        {{ days[day.day - 1] }}
                      </div>
                      <ng-container *ngIf="day.open">
                        <div class="col-8 text-right">
                          {{ day.schedule1Open | mask: '00:00' }} -
                          {{ day.schedule1End | mask: '00:00' }}
                        </div>
                        <div *ngIf="day.schedule2Open && day.schedule2End" class="col-8 offset-4 text-right">
                          {{ day.schedule2Open | mask: '00:00' }} -
                          {{ day.schedule2End | mask: '00:00' }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!day.open">
                        <div class="col-8 text-right">
                          <span class="text-light">FermÃ©</span>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
                <div *ngIf="data.schedule == null || data.schedule.length == 0" class="col-md-6 col-12">
                  <div class="text-light font-weight-bold" style="font-size: 1.2rem">Non renseignÃ©</div>
                </div>
                <div *ngIf="data.contactTel || data.contactEmail" class="col-md-6 pl-md-5 pt-md-0 col-12 pt-3 contact-border">
                  <ng-container *ngIf="data.contactTel">
                    <div style="font-weight: bold">Par&nbsp;tÃ©lÃ©phone&nbsp;:</div>
                    <div class="mb-4">{{ data.contactTel | mask: '00 00 00 00 00' }}</div>
                  </ng-container>
                  <ng-container *ngIf="data.contactEmail">
                    <div style="font-weight: bold">Par&nbsp;e-mail&nbsp;:</div>
                    {{ data.contactEmail }}
                  </ng-container>
                </div>
                <div
                  *ngIf="(data.contactTel == null || data.contactTel.length == 0) && (data.contactEmail == null || data.contactEmail.length == 0)"
                  class="col-md-6 pl-md-5 pt-md-0 col-12 pl-0 pt-3 contact-border"
                >
                  <div class="text-light font-weight-bold" style="font-size: 1.2rem">Non renseignÃ©</div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                (!data.subscribed && !data.registered && !degineo && !isProducer) ||
                ((data.schedule == null || data.schedule.length == 0) && !data.contactEmail && !data.contactTel)
              "
            >
              <div class="text-light font-weight-bold" style="font-size: 1.2rem">Non renseignÃ©</div>
            </ng-container>
          </div>
        </div>
      </div>
      <!--L'Ã©quipe-->
      <div class="row mb-5">
        <div class="col-12 px-5 py-4 shadow-2 rounded">
          <h3 class="mb-3"><i class="fas fa-user-friends position-absolute text-light" style="left: 0.72rem; font-size: 1.4rem"></i>L'Ã©quipe</h3>
          <ng-container *ngIf="(data.subscribed || data.registered || degineo || isProducer) && data.employees != null && data.employees.length > 0">
            <div class="row">
              <div class="col-12 col-md-6 mb-3" *ngFor="let emp of data.employees; let i = index">
                <div class="row flex-nowrap ml-3">
                  <img
                    [img-preloader]="getAvatar(i)"
                    [onErrorImage]="'assets/image-profil-default.svg'"
                    alt="Photo employÃ©"
                    style="height: 3.75rem; width: 3.75rem"
                    class="rounded-circle mr-md-4 mr-3"
                  />
                  <div class="d-flex flex-column">
                    <div style="font-weight: bold">{{ emp.firstName }} {{ emp.lastName }}</div>
                    {{ emp.job }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="(!data.subscribed && !data.registered && !degineo && !isProducer) || data.employees == null || data.employees.length == 0">
            <div class="text-light font-weight-bold" style="font-size: 1.2rem">Non renseignÃ©</div>
          </ng-container>
        </div>
      </div>
    </div>

    <!--Side menu-->
    <ng-container *ngIf="editMode">
      <div class="position-relative order-1 order-lg-2 col-10 offset-1 col-lg-3 offset-lg-0 side-menu mb-3 px-0">
        <div class="position-sticky ml-0 ml-lg-3 ml-xl-5" style="top: 100px">
          <div class="shadow-2 rounded">
            <div class="bg-primary text-white text-center py-2 rounded-top fs-4 fw-500">
              <div>Modifier votre page Degineo</div>
            </div>
            <div class="px-5 py-4">
              <div *ngIf="degineo" class="text-center mb-3">
                <span class="fw-500 mr-2" [class.text-success]="data.visible" [class.text-danger]="!data.visible">Page activÃ©e</span>
                <div class="d-inline-block position-relative">
                  <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#D82929" [checked]="data.visible"> </ui-switch>
                  <div
                    (click)="updateCompanyActive(); $event.stopPropagation()"
                    class="pointer position-absolute h-100"
                    style="right: 0; top: 0; width: 100%"
                  ></div>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-success w-100" (click)="redirect()">Modifier</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!editMode && (colors.length > 0 || data.subscribed || !data.registered)">
      <div class="position-relative order-1 order-lg-2 col-10 offset-1 col-lg-3 offset-lg-0 side-menu mb-3 px-0">
        <div class="position-sticky ml-0 ml-lg-3 ml-xl-5" style="top: 100px">
          <div class="shadow-2 rounded">
            <div class="bg-primary text-white text-center py-2 rounded-top fs-4 fw-500">
              <div class="px-2">
                Imprimez en ligne avec<br />
                <div>{{ data.name }}</div>
              </div>
            </div>

            <div class="py-4 px-5" *ngIf="colors.length == 0">
              <ng-container *ngIf="data.subscribed">
                <div class="text-center">
                  <button class="btn btn-success" (click)="print()">Imprimer un fichier&nbsp;3D</button>
                </div>
              </ng-container>
              <ng-container *ngIf="!data.registered">
                <div>
                  {{ data.name }} n'a pas encore de service d'impression en ligne Degineo. Si vous souhaitez accÃ©der Ã  l'impression&nbsp;3D professionnelle en
                  ligne rendez-vous sur&nbsp;:
                </div>
                <div class="text-center fs-4 fw-500 mb-2">
                  <a routerLink="/espace-client">degineo.com</a><br />
                  <hr />
                  <div class="fw-700 my-2">Vous Ãªtes {{ data.name }}&nbsp;?</div>

                  <a class="btn btn-success" href="{{ producerUrl }}inscription/{{ id }}" style="line-height: 1.5">
                    Prenez le contrÃ´le de votre page Degineo
                  </a>
                </div>
              </ng-container>
            </div>

            <div class="py-4 px-4" *ngIf="colors.length > 0">
              <!--Menu Passer commande -->
              <div class="d-flex flex-column align-items-center">
                <a class="text-success fs-4 fw-500 mb-3 pointer" (click)="orderClicked()"><i class="fas fa-shopping-cart mr-2"></i>Passer&nbsp;commande</a>
                <div class="position-relative">
                  <button
                    class="d-flex align-items-center py-1 pl-3 rounded border-light bg-white btn-colour"
                    style="width: 11.25rem; border: 1px solid"
                    (click)="colorMenuOpened = !colorMenuOpened"
                    (focusout)="colorMenuOpened = false"
                  >
                    <div class="color-box d-inline-block mr-3" [style.backgroundColor]="'#' + selectedColor.hexCode"></div>
                    <span class="pr-1">{{ selectedColor.name }}</span>
                    <span class="position-absolute text-medium" style="right: 0.5rem"><i class="fas fa-chevron-down fa-xs"></i></span>
                  </button>
                </div>

                <div *ngIf="colorMenuOpened" class="position-relative" style="width: 11.25rem">
                  <div class="menu position-absolute border border-medium bg-white" style="width: 11.25rem">
                    <ul>
                      <li class="d-flex align-items-center py-1 disabled">
                        <div class="ml-3">-- Choisir couleur --</div>
                      </li>
                      <li
                        class="text-left d-flex align-items-center py-1"
                        [class.active]="selectedColor.id === color.id"
                        *ngFor="let color of colors"
                        (mousedown)="updateColor(color)"
                      >
                        <div class="color-box d-inline-block mx-3" [style.backgroundColor]="'#' + color.hexCode"></div>
                        <span>{{ color.name }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <p class="m-0 fs-4 mt-3 fw-500">Prix&nbsp;: {{ selectedColor.price | number: '1.2-2' }}&nbsp;â¬&nbsp;<span class="fs-3">HT</span></p>
              </div>
            </div>
            <!---->
          </div>
        </div>
      </div>
    </ng-container>

    <!-- end side menu-->
  </div>
</div>
<div *ngIf="!data && loaded">
  <h2 class="text-center">Ce producteur n'existe pas</h2>
</div>
