// ENTITY com.degineo.metier.devis.entities.DevisNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DevisStatus } from './DevisStatus';

export class DevisNEW {

  id: number = 0;
  status: DevisStatus = null;
  idUser: number = 0;
  idClient: number = 0;
  idProducer: number = 0;
  creationDate: Date = null;
  validUntil: Date = null;
  acceptDate: Date = null;
  paymentDate: Date = null;
  clientComment: string = '';
  clientModificationComment: string = '';
  clientModificationCommentDate: Date = null;
  clientRefusalComment: string = '';
  clientRefusalCommentDate: Date = null;
  producerRefusalComment: string = '';
  producerRefusalCommentDate: Date = null;
  wishedDeliveryDate: Date = null;
  proposedDeliveryDate: Date = null;
  idArticleType: number = 0;
  idFile: number = 0;
  sizeInMmX: number = 0;
  sizeInMmY: number = 0;
  sizeInMmZ: number = 0;
  idMaterialType: number = 0;
  idFinish: number = 0;
  idColor: number = 0;
  advisedPrintTimeInMin: number = 0;
  printTimeInMin: number = 0;
  advisedMaterialQuantityInCm3: number = 0;
  materialQuantityInCm3: number = 0;
  advisedUnitPriceHT: number = 0;
  unitPriceHT: number = 0;
  idSelectedCompanyPrinterConsumableOption: number = 0;
  clientDeleted: boolean = false;
  producerDeleted: boolean = false;
  idAdvisedCompanyPrinterConsumableOption: number = 0;
  quantity: number = 0;
  sentDate: Date = null;
  name: string = '';
  numDevisProducteur: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.devis.entities.DevisNEW'] = DevisNEW;

reverseMapping[DevisNEW.name] = 'com.degineo.metier.devis.entities.DevisNEW';

fields['com.degineo.metier.devis.entities.DevisNEW']  = {
    id: 'java.lang.Integer',
    status: 'com.degineo.metier.devis.entities.DevisStatus',
    idUser: 'java.lang.Integer',
    idClient: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    creationDate: 'java.time.LocalDateTime',
    validUntil: 'java.time.LocalDateTime',
    acceptDate: 'java.time.LocalDateTime',
    paymentDate: 'java.time.LocalDateTime',
    clientComment: 'java.lang.String',
    clientModificationComment: 'java.lang.String',
    clientModificationCommentDate: 'java.time.LocalDateTime',
    clientRefusalComment: 'java.lang.String',
    clientRefusalCommentDate: 'java.time.LocalDateTime',
    producerRefusalComment: 'java.lang.String',
    producerRefusalCommentDate: 'java.time.LocalDateTime',
    wishedDeliveryDate: 'java.time.LocalDateTime',
    proposedDeliveryDate: 'java.time.LocalDateTime',
    idArticleType: 'java.lang.Integer',
    idFile: 'java.lang.Integer',
    sizeInMmX: 'java.lang.Double',
    sizeInMmY: 'java.lang.Double',
    sizeInMmZ: 'java.lang.Double',
    idMaterialType: 'java.lang.Integer',
    idFinish: 'java.lang.Integer',
    idColor: 'java.lang.Integer',
    advisedPrintTimeInMin: 'java.lang.Double',
    printTimeInMin: 'java.lang.Double',
    advisedMaterialQuantityInCm3: 'java.lang.Double',
    materialQuantityInCm3: 'java.lang.Double',
    advisedUnitPriceHT: 'java.lang.Double',
    unitPriceHT: 'java.lang.Double',
    idSelectedCompanyPrinterConsumableOption: 'java.lang.Integer',
    clientDeleted: 'java.lang.Boolean',
    producerDeleted: 'java.lang.Boolean',
    idAdvisedCompanyPrinterConsumableOption: 'java.lang.Integer',
    quantity: 'java.lang.Integer',
    sentDate: 'java.time.LocalDateTime',
    name: 'java.lang.String',
    numDevisProducteur: 'java.lang.Integer'
};
