// ENTITY com.degineo.metier.metadata.entities.PrinterNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ProducerIntendedType } from './ProducerIntendedType';
import { PrinterTechnology } from './PrinterTechnology';

export class PrinterNEW {

  id: number = 0;
  allowMulticolor: boolean = false;
  brand: string = '';
  minimalPrintingSize: number = 0;
  name: string = '';
  sellingPrice: number = 0;
  printerTechnology: PrinterTechnology = null;
  printerVerticalSpeed: number = 0;
  sizeXmm: number = 0;
  sizeYmm: number = 0;
  sizeZmm: number = 0;
  stillSold: boolean = false;
  category: ProducerIntendedType = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.PrinterNEW'] = PrinterNEW;

reverseMapping[PrinterNEW.name] = 'com.degineo.metier.metadata.entities.PrinterNEW';

fields['com.degineo.metier.metadata.entities.PrinterNEW']  = {
    id: 'java.lang.Integer',
    allowMulticolor: 'java.lang.Boolean',
    brand: 'java.lang.String',
    minimalPrintingSize: 'java.lang.Double',
    name: 'java.lang.String',
    sellingPrice: 'java.lang.Double',
    printerTechnology: 'com.degineo.metier.metadata.entities.PrinterTechnology',
    printerVerticalSpeed: 'java.lang.Double',
    sizeXmm: 'java.lang.Double',
    sizeYmm: 'java.lang.Double',
    sizeZmm: 'java.lang.Double',
    stillSold: 'java.lang.Boolean',
    category: 'com.degineo.metier.metadata.entities.ProducerIntendedType'
};
