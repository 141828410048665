// ENTITY com.degineo.metier.vitrine.dto.PrintDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ColorNEW } from './ColorNEW';
import { SizeDTO } from './SizeDTO';
import { ArticleTypePrintStep1DTO } from './ArticleTypePrintStep1DTO';
import { BusinessTargetLevel1 } from './BusinessTargetLevel1';
import { AssociatedProducerDTO } from './AssociatedProducerDTO';
import { Finish } from './Finish';
import { MaterialTypeNEW } from './MaterialTypeNEW';

export class PrintDTO {

  token: string = '';
  target: BusinessTargetLevel1 = null;
  article: ArticleTypePrintStep1DTO = null;
  material: MaterialTypeNEW = null;
  color: ColorNEW = null;
  producer: AssociatedProducerDTO = null;
  sizeInMm: SizeDTO = null;
  finish: Finish = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.vitrine.dto.PrintDTO'] = PrintDTO;

reverseMapping[PrintDTO.name] = 'com.degineo.metier.vitrine.dto.PrintDTO';

fields['com.degineo.metier.vitrine.dto.PrintDTO']  = {
    token: 'java.lang.String',
    target: 'com.degineo.metier.client.entities.BusinessTargetLevel1',
    article: 'com.degineo.metier.basket.dto.ArticleTypePrintStep1DTO',
    material: 'com.degineo.metier.metadata.entities.MaterialTypeNEW',
    color: 'com.degineo.metier.metadata.entities.ColorNEW',
    producer: 'com.degineo.metier.basket.dto.AssociatedProducerDTO',
    sizeInMm: 'com.degineo.metier.order.dto.SizeDTO',
    finish: 'com.degineo.metier.metadata.entities.Finish'
};
