// ENTITY com.degineo.metier.invoice.dto.InvoiceListItemDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class InvoiceListItemDTO {

  idInvoice: number = 0;
  date: Date = null;
  reference: string = '';
  transmitter: string = '';
  totalHT: number = 0;
  totalTTC: number = 0;
  fileToken: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.InvoiceListItemDTO'] = InvoiceListItemDTO;

reverseMapping[InvoiceListItemDTO.name] = 'com.degineo.metier.invoice.dto.InvoiceListItemDTO';

fields['com.degineo.metier.invoice.dto.InvoiceListItemDTO']  = {
    idInvoice: 'java.lang.Integer',
    date: 'java.time.Instant',
    reference: 'java.lang.String',
    transmitter: 'java.lang.String',
    totalHT: 'java.lang.Double',
    totalTTC: 'java.lang.Double',
    fileToken: 'java.lang.String'
};
