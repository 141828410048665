// ENTITY com.degineo.metier.invoice.dto.producer.BillFromDegineoDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { InvoiceType } from './InvoiceType';

export class BillFromDegineoDTO {

  reference: string = '';
  totalHT: number = 0;
  totalTTC: number = 0;
  date: Date = null;
  invoiceId: number = 0;
  invoiceType: InvoiceType = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.producer.BillFromDegineoDTO'] = BillFromDegineoDTO;

reverseMapping[BillFromDegineoDTO.name] = 'com.degineo.metier.invoice.dto.producer.BillFromDegineoDTO';

fields['com.degineo.metier.invoice.dto.producer.BillFromDegineoDTO']  = {
    reference: 'java.lang.String',
    totalHT: 'java.lang.Double',
    totalTTC: 'java.lang.Double',
    date: 'java.time.Instant',
    invoiceId: 'java.lang.Integer',
    invoiceType: 'com.degineo.metier.invoice.entities.InvoiceType'
};
