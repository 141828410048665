// ENTITY com.degineo.metier.metadata.dto.PrinterAssociatedCompanyBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class PrinterAssociatedCompanyBODTO {

  id: number = 0;
  name: string = '';
  zipCode: string = '';
  city: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.dto.PrinterAssociatedCompanyBODTO'] = PrinterAssociatedCompanyBODTO;

reverseMapping[PrinterAssociatedCompanyBODTO.name] = 'com.degineo.metier.metadata.dto.PrinterAssociatedCompanyBODTO';

fields['com.degineo.metier.metadata.dto.PrinterAssociatedCompanyBODTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    zipCode: 'java.lang.String',
    city: 'java.lang.String'
};
