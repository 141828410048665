// ENTITY com.degineo.metier.devis.dto.bo.DevisListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DevisStatus } from './DevisStatus';

export class DevisListItemBODTO {

  id: number = 0;
  name: string = '';
  numDevisProducteur: number = 0;
  status: DevisStatus = null;
  dateStatus: Date = null;
  userFirstName: string = '';
  userLastName: string = '';
  idUser: number = 0;
  clientCompanyName: string = '';
  idClientCompany: number = 0;
  producerCompanyName: string = '';
  idProducerCompany: number = 0;
  creationDate: Date = null;
  quantity: number = 0;
  unitPriceHT: number = 0;
  unitPriceTTC: number = 0;
  advisedUnitPriceHT: number = 0;
  advisedUnitPriceTTC: number = 0;
  printTimeInMin: number = 0;
  materialQuantityInCm3: number = 0;
  advisedPrintTimeInMin: number = 0;
  advisedMaterialQuantityInCm3: number = 0;
  producerDeleted: boolean = false;
  clientDeleted: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.devis.dto.bo.DevisListItemBODTO'] = DevisListItemBODTO;

reverseMapping[DevisListItemBODTO.name] = 'com.degineo.metier.devis.dto.bo.DevisListItemBODTO';

fields['com.degineo.metier.devis.dto.bo.DevisListItemBODTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    numDevisProducteur: 'java.lang.Integer',
    status: 'com.degineo.metier.devis.entities.DevisStatus',
    dateStatus: 'java.time.Instant',
    userFirstName: 'java.lang.String',
    userLastName: 'java.lang.String',
    idUser: 'java.lang.Integer',
    clientCompanyName: 'java.lang.String',
    idClientCompany: 'java.lang.Integer',
    producerCompanyName: 'java.lang.String',
    idProducerCompany: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    quantity: 'java.lang.Integer',
    unitPriceHT: 'java.lang.Double',
    unitPriceTTC: 'java.lang.Double',
    advisedUnitPriceHT: 'java.lang.Double',
    advisedUnitPriceTTC: 'java.lang.Double',
    printTimeInMin: 'java.lang.Double',
    materialQuantityInCm3: 'java.lang.Double',
    advisedPrintTimeInMin: 'java.lang.Double',
    advisedMaterialQuantityInCm3: 'java.lang.Double',
    producerDeleted: 'java.lang.Boolean',
    clientDeleted: 'java.lang.Boolean'
};
