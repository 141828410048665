// DB ENUM com.degineo.metier.user.entities.ProducerSignupStep generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class ProducerSignupStep {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly STEP_1_PWD = new ProducerSignupStep(1, "Password", "STEP_1_PWD");
  static readonly STEP_2_USER = new ProducerSignupStep(2, "User", "STEP_2_USER");
  static readonly STEP_3_SIRET = new ProducerSignupStep(3, "Siret", "STEP_3_SIRET");
  static readonly STEP_4_COMPANY = new ProducerSignupStep(4, "Company", "STEP_4_COMPANY");
  static readonly STEP_5_SHIPPING = new ProducerSignupStep(5, "Shipping", "STEP_5_SHIPPING");
  static readonly STEP_6_PRINTERS = new ProducerSignupStep(6, "Printers", "STEP_6_PRINTERS");
  static readonly STEP_7_PLAN = new ProducerSignupStep(7, "Plan", "STEP_7_PLAN");
  static readonly STEP_8_CONTRACTS = new ProducerSignupStep(8, "Contrats", "STEP_8_CONTRACTS");
  static readonly STEP_9_BANKACCOUNTS = new ProducerSignupStep(9, "Bank accounts", "STEP_9_BANKACCOUNTS");
  static readonly STEP_10_SIGN = new ProducerSignupStep(10, "Sign", "STEP_10_SIGN");
  static readonly STEP_11_BRAVO = new ProducerSignupStep(11, "Bravo", "STEP_11_BRAVO");

  static readonly values = [
    ProducerSignupStep.STEP_1_PWD, 
    ProducerSignupStep.STEP_2_USER, 
    ProducerSignupStep.STEP_3_SIRET, 
    ProducerSignupStep.STEP_4_COMPANY, 
    ProducerSignupStep.STEP_5_SHIPPING, 
    ProducerSignupStep.STEP_6_PRINTERS, 
    ProducerSignupStep.STEP_7_PLAN, 
    ProducerSignupStep.STEP_8_CONTRACTS, 
    ProducerSignupStep.STEP_9_BANKACCOUNTS, 
    ProducerSignupStep.STEP_10_SIGN, 
    ProducerSignupStep.STEP_11_BRAVO 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ProducerSignupStep.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ProducerSignupStep.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.user.entities.ProducerSignupStep'] = ProducerSignupStep;
reverseMapping[ProducerSignupStep.name] = 'com.degineo.metier.user.entities.ProducerSignupStep';
