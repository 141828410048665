// DB ENUM com.degineo.metier.metadata.entities.ProducerIntendedType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class ProducerIntendedType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly INTENDED_TYPE_INDIVUDUAL_AND_MAKER = new ProducerIntendedType(1, "Particulier & Maker", "INTENDED_TYPE_INDIVUDUAL_AND_MAKER");
  static readonly INTENDED_TYPE_PROFESSIONAL = new ProducerIntendedType(2, "Professionnel", "INTENDED_TYPE_PROFESSIONAL");
  static readonly INTENDED_TYPE_INDUSTRY = new ProducerIntendedType(3, "Industrie", "INTENDED_TYPE_INDUSTRY");
  static readonly INTENDED_TYPE_SPECIFIC_ACTIVITY = new ProducerIntendedType(4, "SpÃ©cifique", "INTENDED_TYPE_SPECIFIC_ACTIVITY");

  static readonly values = [
    ProducerIntendedType.INTENDED_TYPE_INDIVUDUAL_AND_MAKER, 
    ProducerIntendedType.INTENDED_TYPE_PROFESSIONAL, 
    ProducerIntendedType.INTENDED_TYPE_INDUSTRY, 
    ProducerIntendedType.INTENDED_TYPE_SPECIFIC_ACTIVITY 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ProducerIntendedType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ProducerIntendedType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.metadata.entities.ProducerIntendedType'] = ProducerIntendedType;
reverseMapping[ProducerIntendedType.name] = 'com.degineo.metier.metadata.entities.ProducerIntendedType';
