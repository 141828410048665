// ENTITY com.degineo.metier.order.dto.bo.OrderBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';
import { OrderNEW } from './OrderNEW';

export class OrderBODTO {

  order: OrderNEW = null;
  priceHT: number = 0;
  priceTTC: number = 0;
  clientFirstName: string = '';
  clientLastName: string = '';
  clientPhoneNumber: string = '';
  clientEmail: string = '';
  clientCompanyName: string = '';
  producerName: string = '';
  billingAddress: AddressNEW = null;
  shippingAddress: AddressNEW = null;
  clientFileToken: string = '';
  clientFileName: string = '';
  degineoFileToken: string = '';
  degineoFileName: string = '';
  producerFileToken: string = '';
  producerFileName: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.bo.OrderBODTO'] = OrderBODTO;

reverseMapping[OrderBODTO.name] = 'com.degineo.metier.order.dto.bo.OrderBODTO';

fields['com.degineo.metier.order.dto.bo.OrderBODTO']  = {
    order: 'com.degineo.metier.order.entities.OrderNEW',
    priceHT: 'java.lang.Double',
    priceTTC: 'java.lang.Double',
    clientFirstName: 'java.lang.String',
    clientLastName: 'java.lang.String',
    clientPhoneNumber: 'java.lang.String',
    clientEmail: 'java.lang.String',
    clientCompanyName: 'java.lang.String',
    producerName: 'java.lang.String',
    billingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    shippingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    clientFileToken: 'java.lang.String',
    clientFileName: 'java.lang.String',
    degineoFileToken: 'java.lang.String',
    degineoFileName: 'java.lang.String',
    producerFileToken: 'java.lang.String',
    producerFileName: 'java.lang.String'
};
