// ENTITY com.degineo.metier.dump.dto.DumpDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class DumpDTO {

  name: string = '';
  createdDate: Date = null;
  dlToken: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.dump.dto.DumpDTO'] = DumpDTO;

reverseMapping[DumpDTO.name] = 'com.degineo.metier.dump.dto.DumpDTO';

fields['com.degineo.metier.dump.dto.DumpDTO']  = {
    name: 'java.lang.String',
    createdDate: 'java.time.Instant',
    dlToken: 'java.lang.String'
};
