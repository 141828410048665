// ENTITY com.degineo.metier.plan.dto.FunctionalityDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { Functionality } from './Functionality';

export class FunctionalityDTO {

  functionality: Functionality = null;
  value: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.plan.dto.FunctionalityDTO'] = FunctionalityDTO;

reverseMapping[FunctionalityDTO.name] = 'com.degineo.metier.plan.dto.FunctionalityDTO';

fields['com.degineo.metier.plan.dto.FunctionalityDTO']  = {
    functionality: 'com.degineo.metier.plan.entities.Functionality',
    value: 'java.lang.Boolean'
};
