// ENTITY com.degineo.metier.order.dto.ModelData generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ModelData {

  triangleCount: number = 0;
  verticeCount: number = 0;
  meshCount: number = 0;
  x: number = 0;
  y: number = 0;
  z: number = 0;
  xl: number = 0;
  yl: number = 0;
  zl: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.ModelData'] = ModelData;

reverseMapping[ModelData.name] = 'com.degineo.metier.order.dto.ModelData';

fields['com.degineo.metier.order.dto.ModelData']  = {
    triangleCount: 'java.lang.Integer',
    verticeCount: 'java.lang.Integer',
    meshCount: 'java.lang.Integer',
    x: 'java.lang.Float',
    y: 'java.lang.Float',
    z: 'java.lang.Float',
    xl: 'java.lang.Float',
    yl: 'java.lang.Float',
    zl: 'java.lang.Float'
};
