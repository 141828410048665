<div class="container-fluid mb-5 text-center" style="max-width: 1200px">
  <form [formGroup]="form">
    <div class="row justify-content-center mb-2">
      <div class="col-lg col-12 text-left mb-2 align-items-center directory-side">
        <div class="container">
          <div class="row">
            <label for="sector" class="col-form-label pl-2 pr-0 col-md-6 col-12 directory-side">Votre secteur d'activité&nbsp;:</label>
            <div class="col-md-6 col-12 directory-side" *ngIf="targets === null">
              <i class="fas fa-spinner spinning"></i>
            </div>
            <select
              class="form-control col-md-6 col-12 directory-side dashed-when-disabled"
              formControlName="target"
              id="sector"
              (change)="enableTypes()"
              *ngIf="targets !== null"
            >
              <option [ngValue]="null" disabled="disabled">-- Choisir secteur d'activité --</option>
              <option *ngFor="let t of targets" [ngValue]="t">{{ t }}</option>
            </select>
          </div>
        </div>
      </div>

      <i class="fas fa-long-arrow-alt-right fa-2x d-none d-lg-block d-directory"></i>

      <div class="col-lg col-12 text-left mb-2 align-items-center directory-side">
        <div class="container">
          <div class="row">
            <label for="types" class="col-form-label pl-2 pr-0 col-md-6 col-12 directory-side">Type de pièce à imprimer&nbsp;:</label>
            <select class="form-control col-md-6 col-12 directory-side dashed-when-disabled" formControlName="types" id="type" (change)="enableFile()">
              <option [ngValue]="null" disabled="disabled">-- Choisir type de pièce --</option>
              <option *ngFor="let type of types" [ngValue]="type.id">{{ type.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mb-2">
      <div class="col-lg col-12 text-left mb-2 align-items-center directory-side">
        <div class="container">
          <div class="row">
            <label for="file" class="col-form-label pl-2 pr-0 col-md-6 col-12 directory-side">Choisissez le fichier 3D à imprimer&nbsp;:</label>
            <input
              type="file"
              id="file"
              formControlName="file"
              style="display: none"
              #fileInput
              [disabled]="file != null"
              [accept]="allowedModelExtensionsService.getExtensions()"
              (change)="fileUpload($event)"
            />
            <button
              *ngIf="file == null"
              class="btn p-0 text-primary col-md-6 col-12 directory-side text-left"
              id="btnFile"
              style="font-size: 0.9rem; letter-spacing: normal"
              [ngClass]="this.form.controls['file'].status == 'DISABLED' ? 'text-muted' : 'text-primary'"
              [disabled]="this.form.controls['file'].status == 'DISABLED'"
              (click)="fileInput.click()"
            >
              <i
                class="fas fa-upload text-primary pointer mr-2"
                [ngClass]="this.form.controls['file'].status == 'DISABLED' ? 'text-muted' : 'text-primary'"
                style="font-size: 18px"
              ></i>
              Parcourez vos fichiers
            </button>

            <div *ngIf="file != null" class="container-fluid col-md-6 col-12 directory-side px-0 align-self-center">
              <div class="row mx-0">
                <degineo-progress-bar class="col-11 px-0" [progress]="file.progress" [color-degraded]="{ '0': '#06c', '99': '#00c462' }">
                  {{ file.name }}</degineo-progress-bar
                >
                <i class="fa fa-times-circle text-danger pointer align-self-center" [class.d-none]="file.progress < 100" (click)="fileDeleted(file)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <i class="fas fa-long-arrow-alt-right fa-2x d-none d-lg-block d-directory"></i>

      <div class="col-lg col-12 text-left mb-2 align-items-center directory-side">
        <div class="container">
          <div class="row">
            <label for="sizes" class="col-form-label pl-2 pr-0 col-md-6 col-12 directory-side">Dimensions de l'impression&nbsp;3D&nbsp;:</label>
            <div class="col-md-6 col-12 directory-side px-0">
              <div class="container">
                <div class="row">
                  <input
                    formControlName="sizeX"
                    lang="fr"
                    type="number"
                    inputmode="decimal"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    class="form-control col px-0 text-center dashed-when-disabled"
                    step=" 0.01"
                    min="0"
                    placeholder="L"
                    id="sizeX"
                    style="min-width: 50px"
                    (input)="changeSize('x')"
                  /><span class="align-self-center mx-1">x</span>
                  <input
                    formControlName="sizeY"
                    lang="fr"
                    type="number"
                    inputmode="decimal"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    class="form-control col px-0 text-center dashed-when-disabled"
                    step="0.01"
                    min="0"
                    placeholder="l"
                    id="sizeY"
                    style="min-width: 50px"
                    (input)="changeSize('y')"
                  /><span class="align-self-center mx-1">x</span>
                  <input
                    formControlName="sizeZ"
                    lang="fr"
                    type="number"
                    inputmode="decimal"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    class="form-control col px-0 text-center dashed-when-disabled"
                    step="0.01"
                    min="0"
                    placeholder="h"
                    id="sizeZ"
                    style="min-width: 50px"
                    (input)="changeSize('z')"
                  /><span class="align-self-center ml-1">mm</span>
                  <ng-template [ngIf]="sizeModified == false" [ngIfElse]="restore">
                    <i
                      class="fas fa-info-circle ml-2 text-primary align-self-center"
                      style="font-size: 1.5rem"
                      ngbTooltip="Les dimensions actuelles sont celles du fichier 3D."
                    ></i>
                  </ng-template>
                  <ng-template #restore>
                    <i
                      class="fas fa-sync-alt ml-2 text-primary align-self-center pointer"
                      style="font-size: 1.5rem"
                      ngbTooltip="Remettre les dimensions du fichier 3D."
                      (click)="restoreSize()"
                    ></i>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mb-2">
      <div class="col-lg col-12 text-left mb-2 directory-side">
        <div class="container">
          <div class="row">
            <label for="sector" class="col-form-label pl-2 pr-0 col-md-6 col-12 directory-side">Matière souhaitée&nbsp;:</label>
            <div class="col-md-6 col-12 directory-side px-0">
              <div class="container">
                <div class="row">
                  <select class="form-control dashed-when-disabled col" id="material" formControlName="materials" (change)="enableColour()">
                    <option [ngValue]="null" disabled>-- Choisir matière --</option>
                    <!--  <optgroup *ngFor="let parentMaterial of getMaterial(0)" label="{{parentMaterial.name}}">
                      <option *ngFor="let material of getMaterial(parentMaterial.id), index as i"
                        [ngValue]="material.id">
                        {{material.name}}
                      </option>
                    </optgroup>-->
                    <ng-container *ngFor="let parentMaterial of getMaterial(0)">
                      <ng-container *ngIf="getMaterial(parentMaterial.id).length > 0">
                        <optgroup label="{{ parentMaterial.name }}">
                          <option *ngFor="let material of getMaterial(parentMaterial.id); index as i" [ngValue]="material.id">
                            {{ material.name }}
                          </option>
                        </optgroup>
                      </ng-container>
                      <ng-container *ngIf="getMaterial(parentMaterial.id).length == 0">
                        <option [ngValue]="parentMaterial.id" class="parentMaterial">
                          {{ parentMaterial.name }}
                        </option>
                      </ng-container>
                    </ng-container>
                    <!--<option *ngFor="let material of materials" [ngValue]="material.id"
                      [class.parentMaterial]="material.idParent==0" [class.childMaterial]="material.idParent!=0"
                      [disabled]="getMaterial(material.id).length>0">
                      {{material.name}}
                    </option>-->
                  </select>
                  <i
                    class="fas fa-exclamation-triangle text-danger align-self-center ml-2"
                    style="font-size: 1.5rem"
                    *ngIf="materials.length === 0 && !form.controls['sizeX'].disabled"
                    ngbTooltip="Aucune machine disponible pour ces dimensions liées à ce type de pièce"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <i class="fas fa-long-arrow-alt-right fa-2x d-none d-lg-block d-directory"></i>

      <div class="col-lg col-12 text-left mb-2 align-items-center directory-side">
        <div class="container">
          <div class="row">
            <label for="sector" class="col-form-label pl-2 pr-0 col-md-6 col-12 directory-side">Couleur souhaitée&nbsp;:</label>
            <select class="form-control col-md-6 col-12 directory-side dashed-when-disabled" id="colour" formControlName="colours">
              <option [ngValue]="null" disabled>-- Choisir couleur --</option>
              <option *ngFor="let colour of colours" [ngValue]="colour.id">{{ colour.name }}</option>
              <option value="0">Sans importance</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-success mt-3"
      style="padding: 0.75em 2.5em"
      (click)="next()"
      [disabled]="!form.valid || form.controls['materials'].disabled"
    >
      Recherche
    </button>
  </form>
</div>
