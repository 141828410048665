// ENTITY com.degineo.metier.vitrine.dto.ArticleSVDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ArticleSVDTO {

  id: number = 0;
  averageMatterQuantity: number = 0;
  name: string = '';
  defaultPrice: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.vitrine.dto.ArticleSVDTO'] = ArticleSVDTO;

reverseMapping[ArticleSVDTO.name] = 'com.degineo.metier.vitrine.dto.ArticleSVDTO';

fields['com.degineo.metier.vitrine.dto.ArticleSVDTO']  = {
    id: 'java.lang.Integer',
    averageMatterQuantity: 'java.lang.Double',
    name: 'java.lang.String',
    defaultPrice: 'java.lang.Double'
};
