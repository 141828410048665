import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.scss'],
})
export class CalendlyComponent implements OnInit {
  @Input()
  text: string = 'Ajouter mon entreprise';
  @Input()
  inputClass: any = 'btn btn-success';
  @Input()
  disable: boolean = false;
  constructor() {}

  ngOnInit() {}
}
