// com.degineo.metier.naf.service.CodeNafBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { BusinessTargetLevel2 } from '../entities/BusinessTargetLevel2';
import { CodeNaf } from '../entities/CodeNaf';

@Injectable({
  providedIn: 'root',
})
export class CodeNafBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCodesNaf(): RpcRequestBuilder<CodeNaf[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CodeNafBOService.getCodesNaf';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  updateClientBusinessTarget(section: string, division: string, groupe: string, classe: string, sousClasse: string, target: BusinessTargetLevel2): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CodeNafBOService.updateClientBusinessTarget';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(section, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(division, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(groupe, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(classe, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sousClasse, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel2', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
