// com.degineo.metier.backoffice.service.CommonBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class CommonBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getNbCompanyPrinterAndConsomableWarn(idCompany: number): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CommonBOService.getNbCompanyPrinterAndConsomableWarn';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  getNbPrinterAndConsomableWarn(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CommonBOService.getNbPrinterAndConsomableWarn';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  getNbBillToProd(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CommonBOService.getNbBillToProd';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  getNbBillToDegineo(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CommonBOService.getNbBillToDegineo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  getNbRedOrder(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CommonBOService.getNbRedOrder';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  getNbOrangeOrder(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CommonBOService.getNbOrangeOrder';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

}
