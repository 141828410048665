// ENTITY com.degineo.metier.plan.entities.Plan generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { InvoiceTypePlan } from './InvoiceTypePlan';
import { ProducerTarget } from './ProducerTarget';

export class Plan {

  id: number = 0;
  name: string = '';
  producerTarget: ProducerTarget = null;
  invoiceType: InvoiceTypePlan = null;
  baseSubscriptionPriceHT: number = 0;
  printerSubscriptionPriceHT: number = 0;
  comissionPercent: number = 0;
  nbOrderPerMonth: number = 0;
  nbMaxPrinter: number = 0;
  nbMaxUsers: number = 0;
  active: boolean = false;
  planButton: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.plan.entities.Plan'] = Plan;

reverseMapping[Plan.name] = 'com.degineo.metier.plan.entities.Plan';

fields['com.degineo.metier.plan.entities.Plan']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    producerTarget: 'com.degineo.metier.plan.entities.ProducerTarget',
    invoiceType: 'com.degineo.metier.plan.entities.InvoiceTypePlan',
    baseSubscriptionPriceHT: 'java.lang.Double',
    printerSubscriptionPriceHT: 'java.lang.Double',
    comissionPercent: 'java.lang.Double',
    nbOrderPerMonth: 'java.lang.Integer',
    nbMaxPrinter: 'java.lang.Integer',
    nbMaxUsers: 'java.lang.Integer',
    active: 'java.lang.Boolean',
    planButton: 'java.lang.Boolean'
};
