// ENTITY com.degineo.metier.basket.dto.IbanBicDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class IbanBicDTO {

  iban: string = '';
  bic: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.basket.dto.IbanBicDTO'] = IbanBicDTO;

reverseMapping[IbanBicDTO.name] = 'com.degineo.metier.basket.dto.IbanBicDTO';

fields['com.degineo.metier.basket.dto.IbanBicDTO']  = {
    iban: 'java.lang.String',
    bic: 'java.lang.String'
};
