// ENTITY com.degineo.metier.producer_printer.dto.PPFFullDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class PPFFullDTO {

  idProducerPrinterFinish: number = 0;
  idFinish: number = 0;
  name: string = '';
  price: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.PPFFullDTO'] = PPFFullDTO;

reverseMapping[PPFFullDTO.name] = 'com.degineo.metier.producer_printer.dto.PPFFullDTO';

fields['com.degineo.metier.producer_printer.dto.PPFFullDTO']  = {
    idProducerPrinterFinish: 'java.lang.Integer',
    idFinish: 'java.lang.Integer',
    name: 'java.lang.String',
    price: 'java.lang.Double'
};
