// ENTITY com.degineo.metier.basket.dto.BasketArticleClientDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { SizeDTO } from './SizeDTO';

export class BasketArticleClientDTO {

  idBasketArticle: number = 0;
  name: string = '';
  articleTypeName: string = '';
  dentaire: boolean = false;
  sizeInMm: SizeDTO = null;
  materialName: string = '';
  colorName: string = '';
  finishName: string = '';
  quantity: number = 0;
  unitVATAmount: number = 0;
  unitPriceTTC: number = 0;
  tokenModelPng: string = '';
  checked: boolean = false;
  producerName: string = '';
  idDevis: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.basket.dto.BasketArticleClientDTO'] = BasketArticleClientDTO;

reverseMapping[BasketArticleClientDTO.name] = 'com.degineo.metier.basket.dto.BasketArticleClientDTO';

fields['com.degineo.metier.basket.dto.BasketArticleClientDTO']  = {
    idBasketArticle: 'java.lang.Integer',
    name: 'java.lang.String',
    articleTypeName: 'java.lang.String',
    dentaire: 'java.lang.Boolean',
    sizeInMm: 'com.degineo.metier.order.dto.SizeDTO',
    materialName: 'java.lang.String',
    colorName: 'java.lang.String',
    finishName: 'java.lang.String',
    quantity: 'java.lang.Integer',
    unitVATAmount: 'java.lang.Double',
    unitPriceTTC: 'java.lang.Double',
    tokenModelPng: 'java.lang.String',
    checked: 'java.lang.Boolean',
    producerName: 'java.lang.String',
    idDevis: 'java.lang.Integer'
};
