import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  SiteVitrineService,
  PrintService,
  SizeDTO,
  MaterialTypeNEW,
  AllowedModelExtensionsService,
  ArticleSVDTO,
  ColorNEW,
  ProducerSVService,
  BusinessTargetLevel1,
} from 'degineo-common';
import { L } from 'ic2-lib';
import { CustomerFormService } from '../../customer-form.service';
import { SVUploadedFile } from '../../SVUploadedFile';
import { Router } from '@angular/router';
@Component({
  selector: 'app-customer-area-form',
  templateUrl: './customer-area-form.component.html',
  styleUrls: ['./customer-area-form.component.scss'],
})
export class CustomerAreaFormComponent implements OnInit {
  private readonly STEP_CHOOSE_ARTICLE_TYPE = 1;
  private readonly STEP_REMOVE_MODELS = 2;

  private readonly PAGE = 1;
  private readonly ITEMPERPAGE = 20;

  @Output()
  searched: EventEmitter<any> = new EventEmitter();

  targets: BusinessTargetLevel1[] = null;

  types: ArticleSVDTO[] = [];
  file: SVUploadedFile = null;
  size: SizeDTO = null;
  materials: MaterialTypeNEW[] = [];
  colours: ColorNEW[] = [];

  sizeModified: boolean = false;
  form: FormGroup;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(SiteVitrineService) private siteVitrineService: SiteVitrineService,
    @Inject(ProducerSVService) private producerSVService: ProducerSVService,
    @Inject(PrintService) private printService: PrintService,
    @Inject(AllowedModelExtensionsService) public allowedModelExtensionsService: AllowedModelExtensionsService,
    @Inject(Router) public router: Router,
    private customerFormService: CustomerFormService
  ) {}

  ngOnInit() {
    this.form = this.customerFormService.form;

    this.loadBusinessTargets();

    if (!this.form.controls['types'].disabled) this.enableTypes();

    if (!this.form.controls['sizeX'].disabled) this.hasSizeChanged();
    if (!this.form.controls['colours'].disabled) this.getColours();

    if (this.customerFormService.files.length > 0) this.file = this.customerFormService.files[0];
  }

  loadBusinessTargets() {
    this.siteVitrineService.getBusinessTargets().subscribe(
      (data) => {
        this.targets = data;
        this.form.controls['target'].enable();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  fileUpload($event) {
    for (let f of $event.target.files) {
      let file: File = f;

      if (this.customerFormService.files.length === 1) {
        this.fileDeleted(this.customerFormService.files[0]);
      }
      let uf = new SVUploadedFile();
      uf.file = file;
      uf.name = file.name;
      this.customerFormService.files.push(uf);
      this.printService
        .uploadFile()
        .withFile(uf.file)
        .subscribeWithProgress(
          (data) => {
            uf.fileHash = data;
            this.printService.getFileDimensions(uf.fileHash).subscribe(
              (data) => {
                uf.modelData = data;
                uf.good = true;
                uf.progress = 100;
                this.setSize(uf);

                this.file = uf;
                this.form.controls['file'].disable();
              },
              (err) => {
                L.e(err);
              }
            );
          },
          (loaded, total) => {
            uf.progress = ~~(((loaded / total) * 100) / 2);
          },
          (err) => {
            L.e(err);
          }
        );
    }
  }

  fileDeleted(file: SVUploadedFile) {
    this.customerFormService.files.splice(this.customerFormService.files.indexOf(file), 1);
    this.printService.deleteFile(file.fileHash).subscribe(
      (data) => {
        this.resetForm(this.STEP_REMOVE_MODELS);
        this.customerFormService.files = [];
      },
      (err) => {
        L.e(err);
      }
    );
  }

  enableTypes() {
    this.siteVitrineService.getArticles(this.form.get('target').value).subscribe(
      (data) => {
        this.types = data;
        this.form.controls['types'].enable();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  enableFile() {
    this.resetForm(this.STEP_CHOOSE_ARTICLE_TYPE);
    this.form.controls['file'].enable();
  }

  getMaterials() {
    if (this.form.get('types').value && this.customerFormService.size)
      this.printService.getPossibleMaterials(0, this.form.get('target').value, this.form.get('types').value, this.customerFormService.size).subscribe(
        (data) => {
          this.materials = data;
          if (this.materials.length > 0) this.form.controls['materials'].enable();
          else {
            this.form.controls['materials'].disable();
            this.form.controls['materials'].reset();
            this.form.controls['colours'].disable();
            this.form.controls['colours'].reset();
          }
        },
        (err) => {
          L.e(err);
        }
      );
  }

  getMaterial(idParent: number) {
    return this.materials.filter((m) => m.idParent === idParent);
  }

  enableColour() {
    this.getColours();
    this.form.controls['colours'].reset();
    this.form.controls['colours'].enable();
  }

  getColours() {
    this.printService
      .getPossibleColors(0, this.form.get('target').value, +this.form.get('types').value, +this.form.get('materials').value, this.customerFormService.size)
      .subscribe(
        (data) => {
          this.colours = data.colors;
        },
        (err) => {
          L.e(err);
        }
      );
  }

  resetForm(step: number) {
    if (step === this.STEP_CHOOSE_ARTICLE_TYPE) {
      if (!this.form.controls['materials'].disabled || this.form.controls['file'] != null) {
        this.form.controls['materials'].reset();
        this.getMaterials();
      }
    }
    if (step === this.STEP_CHOOSE_ARTICLE_TYPE || this.STEP_REMOVE_MODELS) {
      this.form.controls['colours'].reset();
      this.form.controls['colours'].disable();
    }
    if (step === this.STEP_REMOVE_MODELS) {
      this.file = null;
      this.form.controls['file'].enable();
      this.form.controls['file'].reset();

      this.form.controls['sizeX'].reset();
      this.form.controls['sizeY'].reset();
      this.form.controls['sizeZ'].reset();
      this.form.controls['sizeX'].disable();
      this.form.controls['sizeY'].disable();
      this.form.controls['sizeZ'].disable();

      this.form.controls['materials'].reset();
      this.form.controls['materials'].disable();
    }
  }

  setSize(file: SVUploadedFile) {
    this.customerFormService.defaultSizeDTO = new SizeDTO();

    this.customerFormService.defaultSizeDTO.x = Math.round(file.modelData.x * 100) / 100;
    this.customerFormService.defaultSizeDTO.y = Math.round(file.modelData.y * 100) / 100;
    this.customerFormService.defaultSizeDTO.z = Math.round(file.modelData.z * 100) / 100;

    this.form.controls['sizeX'].enable();
    this.form.controls['sizeY'].enable();
    this.form.controls['sizeZ'].enable();
    this.form.controls['sizeX'].setValue(this.customerFormService.defaultSizeDTO.x);
    this.form.controls['sizeY'].setValue(this.customerFormService.defaultSizeDTO.y);
    this.form.controls['sizeZ'].setValue(this.customerFormService.defaultSizeDTO.z);

    this.customerFormService.size = new SizeDTO();
    this.customerFormService.size.x = +this.form.get('sizeX').value;
    this.customerFormService.size.y = +this.form.get('sizeY').value;
    this.customerFormService.size.z = +this.form.get('sizeZ').value;

    this.getMaterials();
  }

  restoreSize() {
    this.sizeModified = false;
    this.form.controls['sizeX'].setValue(this.customerFormService.defaultSizeDTO.x);
    this.form.controls['sizeY'].setValue(this.customerFormService.defaultSizeDTO.y);
    this.form.controls['sizeZ'].setValue(this.customerFormService.defaultSizeDTO.z);

    this.customerFormService.size.x = this.form.controls['sizeX'].value;
    this.customerFormService.size.y = this.form.controls['sizeY'].value;
    this.customerFormService.size.z = this.form.controls['sizeZ'].value;

    this.getMaterials();
  }

  changeSize(axe: string) {
    let ratio: number;

    switch (axe) {
      case 'x':
        ratio = this.form.controls['sizeX'].value / this.customerFormService.defaultSizeDTO.x;
        this.form.controls['sizeY'].setValue((this.customerFormService.defaultSizeDTO.y * ratio).toFixed(2));
        this.form.controls['sizeZ'].setValue((this.customerFormService.defaultSizeDTO.z * ratio).toFixed(2));
        if (this.form.controls['sizeX'].value != null) this.customerFormService.size.x = this.form.controls['sizeX'].value;
        else this.customerFormService.size.x = 0;
        this.customerFormService.size.y = this.customerFormService.defaultSizeDTO.y * ratio;
        this.customerFormService.size.z = this.customerFormService.defaultSizeDTO.z * ratio;
        break;
      case 'y':
        ratio = this.form.controls['sizeY'].value / this.customerFormService.defaultSizeDTO.y;
        this.form.controls['sizeX'].setValue((this.customerFormService.defaultSizeDTO.x * ratio).toFixed(2));
        this.form.controls['sizeZ'].setValue((this.customerFormService.defaultSizeDTO.z * ratio).toFixed(2));
        this.customerFormService.size.x = this.customerFormService.defaultSizeDTO.x * ratio;
        if (this.form.controls['sizeY'].value != null) this.customerFormService.size.x = this.form.controls['sizeY'].value;
        else this.customerFormService.size.y = 0;
        this.customerFormService.size.z = this.customerFormService.defaultSizeDTO.z * ratio;
        break;
      case 'z':
        ratio = this.form.controls['sizeZ'].value / this.customerFormService.defaultSizeDTO.z;
        this.form.controls['sizeX'].setValue((this.customerFormService.defaultSizeDTO.x * ratio).toFixed(2));
        this.form.controls['sizeY'].setValue((this.customerFormService.defaultSizeDTO.y * ratio).toFixed(2));
        this.customerFormService.size.x = this.customerFormService.defaultSizeDTO.x * ratio;
        this.customerFormService.size.y = this.customerFormService.defaultSizeDTO.y * ratio;
        if (this.form.controls['sizeZ'].value != null) this.customerFormService.size.x = this.form.controls['sizeZ'].value;
        else this.customerFormService.size.z = 0;
        break;

      default:
        break;
    }

    this.hasSizeChanged();
  }

  hasSizeChanged() {
    if (this.form.controls['sizeX'].status != 'DISABLED')
      this.form.controls['sizeX'].value == this.customerFormService.defaultSizeDTO.x ? (this.sizeModified = false) : (this.sizeModified = true);
    this.getMaterials();
  }

  getProducers() {
    var colour = null;
    if (+this.form.get('colours').value >= 0) {
      colour = +this.form.get('colours').value;
    } else colour = 0;

    this.producerSVService
      .getProducers(
        this.PAGE,
        this.ITEMPERPAGE,
        this.form.get('target').value,
        this.form.get('types').value,
        this.form.get('materials').value,
        colour,
        false,
        this.customerFormService.size
      )
      .subscribe(
        (data) => {
          this.customerFormService.producers = data.data;
          this.customerFormService.count = data.count;
          this.customerFormService.nameSearch = '';
          if (!this.form.get('colours').value) this.form.controls['colours'].setValue(colour);

          if (colour === 0) this.customerFormService.loadColourLowestPrice();
          else this.customerFormService.loadColour(data.data[0].colors.find((x) => x.id === colour));
          this.router.navigate(['/annuaire']);
          this.searched.emit();
        },
        (err) => {
          L.e(err);
        }
      );
  }

  next() {
    this.customerFormService.fromForm = true;
    this.getProducers();
  }
}
