// ENTITY com.degineo.metier.articleType.entities.ArticleTypeBusinessTarget generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { BusinessTargetLevel2 } from './BusinessTargetLevel2';

export class ArticleTypeBusinessTarget {

  idArticleType: number = 0;
  target: BusinessTargetLevel2 = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.articleType.entities.ArticleTypeBusinessTarget'] = ArticleTypeBusinessTarget;

reverseMapping[ArticleTypeBusinessTarget.name] = 'com.degineo.metier.articleType.entities.ArticleTypeBusinessTarget';

fields['com.degineo.metier.articleType.entities.ArticleTypeBusinessTarget']  = {
    idArticleType: 'java.lang.Integer',
    target: 'com.degineo.metier.client.entities.BusinessTargetLevel2'
};
