// ENTITY com.degineo.metier.invoice.dto.producer.WaitingBillDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class WaitingBillDTO {

  client: string = '';
  referenceInvoice: string = '';
  idOrder: number = 0;
  totalHT: number = 0;
  totalTTC: number = 0;
  useVAT: boolean = false;
  date: Date = null;
  paidByClient: boolean = false;
  invoiceId: number = 0;
  token: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.producer.WaitingBillDTO'] = WaitingBillDTO;

reverseMapping[WaitingBillDTO.name] = 'com.degineo.metier.invoice.dto.producer.WaitingBillDTO';

fields['com.degineo.metier.invoice.dto.producer.WaitingBillDTO']  = {
    client: 'java.lang.String',
    referenceInvoice: 'java.lang.String',
    idOrder: 'java.lang.Integer',
    totalHT: 'java.lang.Double',
    totalTTC: 'java.lang.Double',
    useVAT: 'java.lang.Boolean',
    date: 'java.time.Instant',
    paidByClient: 'java.lang.Boolean',
    invoiceId: 'java.lang.Integer',
    token: 'java.lang.String'
};
