import { Component, OnInit, Input, Output, EventEmitter, Host } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { DateStringFr } from '../../dateStringFr';
import { FilterComponent } from '../../filter.component';
import { Filter } from '../../../../ic2/entities/Filter';

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}
@Component({
  selector: 'degineo-daterange-filter',
  templateUrl: './daterange-filter.component.html',
  styleUrls: ['./daterange-filter.component.scss'],
})
export class DaterangeFilterComponent implements OnInit {
  @Input()
  name: string;
  @Input()
  filterName: string;
  @Input()
  filterSetter: (item: any, filter: Filter) => void = (item, filter) => {
    filter.dateValues = [item.dateRange.beginJsDate, item.dateRange.endJsDate];
    if (this.host.cache)
      this.host.cache[this.filterName] = {
        beginDate: item.dateRange.beginJsDate,
        endDate: item.dateRange.endJsDate,
      };
  };
  @Input()
  Filter: Filter;
  @Output()
  change: EventEmitter<Filter> = new EventEmitter<Filter>();

  private _required: boolean;
  @Input()
  get required() {
    return this._required;
  }
  set required(value: any) {
    this._required = coerceBooleanProperty(value);
  }

  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  value: IMyDateModel = null;

  public IMyDrpOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: 'dd/mm/yyyy',
    dayLabels: DateStringFr.INgxMyDpOptions.dayLabels,
    monthLabels: DateStringFr.INgxMyDpOptions.monthLabels,
    //selectBeginDateTxt: 'SÃ©lectionnez Date de dÃ©but',
    //selectEndDateTxt: 'SÃ©lectionnez la date de fin',
  };

  constructor(@Host() private host: FilterComponent) {}

  ngOnInit() {
    if (this.host.cache && this.host.cache[this.filterName] !== undefined && this.host.cache[this.filterName] !== null) {
      if (this.host.cache[this.filterName])
        this.value = {
          isRange: true,
          dateRange: {
            beginDate: {
              year: this.host.cache[this.filterName].beginDate.getFullYear(),
              month: this.host.cache[this.filterName].beginDate.getMonth() + 1,
              day: this.host.cache[this.filterName].beginDate.getDate(),
            },
            endDate: {
              year: this.host.cache[this.filterName].endDate.getFullYear(),
              month: this.host.cache[this.filterName].endDate.getMonth() + 1,
              day: this.host.cache[this.filterName].endDate.getDate(),
            },
          },
        };
    } else if (this.host.default && this.host.default[this.filterName]) {
      this.value = {
        isRange: true,
        dateRange: {
          beginDate: {
            year: this.host.default[this.filterName].beginDate.getFullYear(),
            month: this.host.default[this.filterName].beginDate.getMonth() + 1,
            day: this.host.default[this.filterName].beginDate.getDate(),
          },
          endDate: {
            year: this.host.default[this.filterName].endDate.getFullYear(),
            month: this.host.default[this.filterName].endDate.getMonth() + 1,
            day: this.host.default[this.filterName].endDate.getDate(),
          },
        },
      };
    }
    this.update();
  }

  clear() {
    this.value = null;
    this.update();
  }

  reset() {
    if (this.host.default && this.host.default[this.filterName]) {
      this.value = {
        isRange: true,
        dateRange: {
          beginDate: {
            year: this.host.default[this.filterName].beginDate.getFullYear(),
            month: this.host.default[this.filterName].beginDate.getMonth() + 1,
            day: this.host.default[this.filterName].beginDate.getDate(),
          },
          endDate: {
            year: this.host.default[this.filterName].endDate.getFullYear(),
            month: this.host.default[this.filterName].endDate.getMonth() + 1,
            day: this.host.default[this.filterName].endDate.getDate(),
          },
        },
      };
    } else {
      this.value = null;
    }
    this.update();
  }

  update() {
    if (this.host.cache) {
      if (this.value && this.value !== null) {
        this.host.cache[this.filterName] = this.value;
      } else this.host.cache[this.filterName] = null;
    }

    if (this.value) {
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(this.value, this.Filter);
    } else {
      this.Filter = null;
    }

    this.change.emit(this.Filter);
  }

  filterChanges(value: any) {
    if (value && value.dateRange.beginJsDate && value.dateRange.endJsDate) {
      if (this.host.cache) {
        this.host.cache[this.filterName] = value;
      }
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(value, this.Filter);
    } else {
      this.Filter = null;
      if (this.host.cache) {
        this.host.cache[this.filterName] = null;
      }
    }
    this.change.emit(this.Filter);
  }
}
