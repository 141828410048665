// com.degineo.metier.producer_printer.service.ProducerPrinterServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ArticleTypeNEW } from '../entities/ArticleTypeNEW';
import { ProducerPrinterClientTypeMargin } from '../entities/ProducerPrinterClientTypeMargin';
import { PPATPFullDTO } from '../entities/PPATPFullDTO';
import { ConsumableOptionBrandChoiceDTO } from '../entities/ConsumableOptionBrandChoiceDTO';
import { BusinessTargetLevel1 } from '../entities/BusinessTargetLevel1';
import { PrinterBrandChoiceDTO } from '../entities/PrinterBrandChoiceDTO';
import { PPFFullDTO } from '../entities/PPFFullDTO';
import { Finish } from '../entities/Finish';
import { ProducerPrinterFullDTO } from '../entities/ProducerPrinterFullDTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerPrinterService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPrinters(): RpcRequestBuilder<ProducerPrinterFullDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.getPrinters';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getConsumableOptionChoices(idProducerPrinter: number): RpcRequestBuilder<ConsumableOptionBrandChoiceDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.getConsumableOptionChoices';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getPrinterChoices(): RpcRequestBuilder<PrinterBrandChoiceDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.getPrinterChoices';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getArticleTypeChoices(idProducerPrinter: number): RpcRequestBuilder<ArticleTypeNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.getArticleTypeChoices';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  addPrinter(idPrinter: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.addPrinter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  changeProductionCost(idProducerPrinter: number, value: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.changeProductionCost';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(value, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  changeActiveStatus(idProducerPrinter: number, active: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.changeActiveStatus';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(active, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deletePrinter(idProducerPrinter: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.deletePrinter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  addConsumable(idProducerPrinter: number, idConsumableOption: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.addConsumable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idConsumableOption, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  changeConsumableCost(idProducerPrinterConsumableOption: number, value: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.changeConsumableCost';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterConsumableOption, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(value, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  changeConsumableActiveStatus(idProducerPrinterConsumableOption: number, active: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.changeConsumableActiveStatus';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterConsumableOption, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(active, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteConsumable(idProducerPrinterConsumableOption: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.deleteConsumable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterConsumableOption, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  addMargin(idProducerPrinter: number, ct: BusinessTargetLevel1, margin: number): RpcRequestBuilder<ProducerPrinterClientTypeMargin> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.addMargin';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(ct, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(margin, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer_printer.entities.ProducerPrinterClientTypeMargin');
  }

  editMargin(idProducerPrinterClientTypeMargin: number, margin: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.editMargin';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterClientTypeMargin, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(margin, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteMargin(idProducerPrinterClientTypeMargin: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.deleteMargin';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterClientTypeMargin, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  massChangeActiveStatusConsumable(idConsumableOption: number, active: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.massChangeActiveStatusConsumable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idConsumableOption, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(active, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  massDeleteConsumable(idConsumableOption: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.massDeleteConsumable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idConsumableOption, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  addArticleTypePrice(idProducerPrinter: number, idArticleType: number, price: number): RpcRequestBuilder<PPATPFullDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.addArticleTypePrice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(price, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer_printer.dto.PPATPFullDTO');
  }

  changeArticleTypePrice(idProducerPrinterArticleTypePrice: number, price: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.changeArticleTypePrice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterArticleTypePrice, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(price, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteArticleTypePrice(idProducerPrinterArticleTypePrice: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.deleteArticleTypePrice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterArticleTypePrice, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  addFinishPrice(idProducerPrinter: number, idFinish: number, price: number): RpcRequestBuilder<PPFFullDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.addFinishPrice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(price, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer_printer.dto.PPFFullDTO');
  }

  changeFinishPrice(idProducerPrinterFinish: number, price: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.changeFinishPrice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(price, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteFinishPrice(idProducerPrinterFinish: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.deleteFinishPrice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterFinish, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getFinishChoices(idProducerPrinter: number): RpcRequestBuilder<Finish[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPrinterService.getFinishChoices';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
