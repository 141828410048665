// ENTITY com.degineo.metier.metadata.entities.ConsumableNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ConsumableNEW {

  id: number = 0;
  idMaterialType: number = 0;
  name: string = '';
  description: string = '';
  brand: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.ConsumableNEW'] = ConsumableNEW;

reverseMapping[ConsumableNEW.name] = 'com.degineo.metier.metadata.entities.ConsumableNEW';

fields['com.degineo.metier.metadata.entities.ConsumableNEW']  = {
    id: 'java.lang.Integer',
    idMaterialType: 'java.lang.Integer',
    name: 'java.lang.String',
    description: 'java.lang.String',
    brand: 'java.lang.String'
};
