// ENTITY com.degineo.metier.vitrine.dto.PrinterSVDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class PrinterSVDTO {

  id: number = 0;
  printerName: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.vitrine.dto.PrinterSVDTO'] = PrinterSVDTO;

reverseMapping[PrinterSVDTO.name] = 'com.degineo.metier.vitrine.dto.PrinterSVDTO';

fields['com.degineo.metier.vitrine.dto.PrinterSVDTO']  = {
    id: 'java.lang.Integer',
    printerName: 'java.lang.String'
};
