// ENTITY com.degineo.metier.order.dto.kanban.panel.ClientInfosForKanbanPopupDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ClientInfosForKanbanPopupDTO {

  clientName: string = '';
  clientUserName: string = '';
  phoneNumber: string = '';
  email: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.ClientInfosForKanbanPopupDTO'] = ClientInfosForKanbanPopupDTO;

reverseMapping[ClientInfosForKanbanPopupDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.ClientInfosForKanbanPopupDTO';

fields['com.degineo.metier.order.dto.kanban.panel.ClientInfosForKanbanPopupDTO']  = {
    clientName: 'java.lang.String',
    clientUserName: 'java.lang.String',
    phoneNumber: 'java.lang.String',
    email: 'java.lang.String'
};
