// ENTITY com.degineo.metier.producer.dto.dashboard.DashboardStatsDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class DashboardStatsDTO {

  nbClientActifs: number = 0;
  nbFichier3DModifie: number = 0;
  nbPieceProduites: number = 0;
  nbCommandesTraites: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.dashboard.DashboardStatsDTO'] = DashboardStatsDTO;

reverseMapping[DashboardStatsDTO.name] = 'com.degineo.metier.producer.dto.dashboard.DashboardStatsDTO';

fields['com.degineo.metier.producer.dto.dashboard.DashboardStatsDTO']  = {
    nbClientActifs: 'java.lang.Integer',
    nbFichier3DModifie: 'java.lang.Integer',
    nbPieceProduites: 'java.lang.Integer',
    nbCommandesTraites: 'java.lang.Integer'
};
