// ENTITY com.degineo.metier.producer.dto.dashboard.DashboardWarningsDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DashboardPrinterWarningDTO } from './DashboardPrinterWarningDTO';

export class DashboardWarningsDTO {

  noPrinter: boolean = false;
  noAvailablePrinter: boolean = false;
  noPresentation: boolean = false;
  printerWarnings: DashboardPrinterWarningDTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.dashboard.DashboardWarningsDTO'] = DashboardWarningsDTO;

reverseMapping[DashboardWarningsDTO.name] = 'com.degineo.metier.producer.dto.dashboard.DashboardWarningsDTO';

fields['com.degineo.metier.producer.dto.dashboard.DashboardWarningsDTO']  = {
    noPrinter: 'java.lang.Boolean',
    noAvailablePrinter: 'java.lang.Boolean',
    noPresentation: 'java.lang.Boolean',
    printerWarnings: 'java.util.List<com.degineo.metier.producer.dto.dashboard.DashboardPrinterWarningDTO>'
};
