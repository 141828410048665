// com.degineo.metier.metadata.service.FinishBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { FinishBODTO } from '../entities/FinishBODTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { Finish } from '../entities/Finish';

@Injectable({
  providedIn: 'root',
})
export class FinishBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFinish(filter: GenericFilter): RpcRequestBuilder<ListWithCount<Finish>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FinishBOService.getFinish';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getFinishDetail(id: number): RpcRequestBuilder<FinishBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FinishBOService.getFinishDetail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.metadata.dto.FinishBODTO');
  }

  newFinish(dto: FinishBODTO): RpcRequestBuilder<FinishBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FinishBOService.newFinish';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'com.degineo.metier.metadata.dto.FinishBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.metadata.dto.FinishBODTO');
  }

  updateFinish(dto: FinishBODTO): RpcRequestBuilder<FinishBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FinishBOService.updateFinish';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'com.degineo.metier.metadata.dto.FinishBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.metadata.dto.FinishBODTO');
  }

  deleteFinish(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FinishBOService.deleteFinish';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
