import { Component, OnInit, Input, Output, EventEmitter, Host } from '@angular/core';
import { FilterComponent } from '../../filter.component';
import { Filter } from '../../../../ic2/entities/Filter';

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}
@Component({
  selector: 'degineo-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss'],
})
export class TextFilterComponent<String> implements OnInit {
  @Input()
  name: string;

  @Input()
  filterName: string;

  @Input()
  filterSetter: (item: string, filter: Filter) => void = (item, filter) => {
    filter.stringValues = [<any>item];
    if (this.host.cache) this.host.cache[this.filterName] = item;
  };

  @Input()
  Filter: Filter;

  @Input()
  type: string = 'text';

  @Output()
  change: EventEmitter<Filter> = new EventEmitter<Filter>();

  private _required: boolean;
  @Input()
  get required() {
    return this._required;
  }
  set required(value: any) {
    this._required = coerceBooleanProperty(value);
  }

  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  value: any = null;
  delayTimer: any;

  constructor(@Host() private host: FilterComponent) {}

  ngOnInit() {
    if (this.host.cache && this.host.cache[this.filterName] !== undefined && this.host.cache[this.filterName] !== null) {
      if (this.host.cache[this.filterName]) this.value = this.host.cache[this.filterName];
    } else if (this.host.default && this.host.default[this.filterName]) {
      this.value = this.host.default[this.filterName];
    }
    this.update();
  }

  clear() {
    this.value = null;
    this.update();
  }

  reset() {
    if (this.host.default && this.host.default[this.filterName]) {
      this.value = this.host.default[this.filterName];
    } else {
      this.value = null;
    }
    this.update();
  }

  update() {
    if (this.host.cache) {
      if (this.value) {
        this.host.cache[this.filterName] = this.value;
      } else this.host.cache[this.filterName] = null;
    }

    if (this.value) {
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(this.value, this.Filter);
    } else {
      this.Filter = null;
    }

    this.change.emit(this.Filter);
  }

  filterChanges(event: Event) {
    const value: string = (<HTMLInputElement>event.target).value;
    if (!value) {
      clearTimeout(this.delayTimer);
      this.Filter = null;
      if (this.host.cache) {
        this.host.cache[this.filterName] = null;
      }
      this.change.emit(this.Filter);
    } else {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        if (this.host.cache) {
          this.host.cache[this.filterName] = value;
        }
        this.Filter = new Filter();
        this.Filter.name = this.filterName;
        this.filterSetter(value, this.Filter);
        this.change.emit(this.Filter);
      }, 500);
    }
  }
}
