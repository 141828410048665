// ENTITY com.degineo.metier.producer.entities.Producer generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { LegalStatus } from './LegalStatus';
import { BusinessTargetLevel2 } from './BusinessTargetLevel2';
import { BusinessType } from './BusinessType';
import { PrinterTechnology } from './PrinterTechnology';

export class Producer {

  id: number = 0;
  idPlan: number = 0;
  name: string = '';
  denomination: string = '';
  nextInvoiceNumber: number = 0;
  nextDevisNumber: number = 0;
  marqueBlancheDevis: boolean = false;
  marqueBlanchePrint: boolean = false;
  capital: number = 0;
  RNANumber: string = '';
  dateCreated: Date = null;
  fileLogo: string = '';
  fileLogoProfile: string = '';
  legalStatus: LegalStatus = null;
  siret: string = '';
  RCS: string = '';
  useVAT: boolean = false;
  dateCreationEtablissement: Date = null;
  siege: boolean = false;
  presentation: string = '';
  applicationDomains: BusinessTargetLevel2[] = null;
  contactEmail: string = '';
  contactTel: string = '';
  facebook: string = '';
  impressionTechnologies2: PrinterTechnology[] = null;
  linkedin: string = '';
  twitter: string = '';
  website: string = '';
  youtube: string = '';
  instagram: string = '';
  iban: string = '';
  bic: string = '';
  bankAccountCountry: string = '';
  bankAccountOwner: string = '';
  noteDegineo: string = '';
  productionDelay: number = 0;
  dateContractSigned: Date = null;
  dateLastSubscriptionChange: Date = null;
  subscribed: boolean = false;
  registered: boolean = false;
  dateDiscountEnd: Date = null;
  discountPercent: number = 0;
  stripeCustomerId: string = '';
  stripeSetupIntentId: string = '';
  stripePaymentMethodId: string = '';
  stripeIbanLast4Digits: string = '';
  stripeMandateRef: string = '';
  businessType: BusinessType = null;
  dateFirstSignupVisit: Date = null;
  visible: boolean = false;
  dateLastSubscriptionTunnelVisit: Date = null;
  shareInvoiceEmails: string[] = null;
  shareInvoiceDayOfMonth: number = 0;
  shareBills: boolean = false;
  shareFinancialReport: boolean = false;
  shareDegineoInvoices: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.entities.Producer'] = Producer;

reverseMapping[Producer.name] = 'com.degineo.metier.producer.entities.Producer';

fields['com.degineo.metier.producer.entities.Producer']  = {
    id: 'java.lang.Integer',
    idPlan: 'java.lang.Integer',
    name: 'java.lang.String',
    denomination: 'java.lang.String',
    nextInvoiceNumber: 'java.lang.Integer',
    nextDevisNumber: 'java.lang.Integer',
    marqueBlancheDevis: 'java.lang.Boolean',
    marqueBlanchePrint: 'java.lang.Boolean',
    capital: 'java.lang.Double',
    RNANumber: 'java.lang.String',
    dateCreated: 'java.time.Instant',
    fileLogo: 'java.lang.String',
    fileLogoProfile: 'java.lang.String',
    legalStatus: 'com.degineo.metier.producer.entities.LegalStatus',
    siret: 'java.lang.String',
    RCS: 'java.lang.String',
    useVAT: 'java.lang.Boolean',
    dateCreationEtablissement: 'java.time.LocalDate',
    siege: 'java.lang.Boolean',
    presentation: 'java.lang.String',
    applicationDomains: 'java.util.List<com.degineo.metier.client.entities.BusinessTargetLevel2>',
    contactEmail: 'java.lang.String',
    contactTel: 'java.lang.String',
    facebook: 'java.lang.String',
    impressionTechnologies2: 'java.util.List<com.degineo.metier.metadata.entities.PrinterTechnology>',
    linkedin: 'java.lang.String',
    twitter: 'java.lang.String',
    website: 'java.lang.String',
    youtube: 'java.lang.String',
    instagram: 'java.lang.String',
    iban: 'java.lang.String',
    bic: 'java.lang.String',
    bankAccountCountry: 'java.lang.String',
    bankAccountOwner: 'java.lang.String',
    noteDegineo: 'java.lang.String',
    productionDelay: 'java.lang.Integer',
    dateContractSigned: 'java.time.Instant',
    dateLastSubscriptionChange: 'java.time.Instant',
    subscribed: 'java.lang.Boolean',
    registered: 'java.lang.Boolean',
    dateDiscountEnd: 'java.time.Instant',
    discountPercent: 'java.lang.Double',
    stripeCustomerId: 'java.lang.String',
    stripeSetupIntentId: 'java.lang.String',
    stripePaymentMethodId: 'java.lang.String',
    stripeIbanLast4Digits: 'java.lang.String',
    stripeMandateRef: 'java.lang.String',
    businessType: 'com.degineo.metier.producer.entities.BusinessType',
    dateFirstSignupVisit: 'java.time.Instant',
    visible: 'java.lang.Boolean',
    dateLastSubscriptionTunnelVisit: 'java.time.Instant',
    shareInvoiceEmails: 'java.util.List<java.lang.String>',
    shareInvoiceDayOfMonth: 'java.lang.Integer',
    shareBills: 'java.lang.Boolean',
    shareFinancialReport: 'java.lang.Boolean',
    shareDegineoInvoices: 'java.lang.Boolean'
};
