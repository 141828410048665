// com.degineo.metier.producer.service.ShareInvoiceServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerShareInvoiceDTO } from '../entities/ProducerShareInvoiceDTO';

@Injectable({
  providedIn: 'root',
})
export class ShareInvoiceService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateShare(dto: ProducerShareInvoiceDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ShareInvoiceService.updateShare';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'com.degineo.metier.producer.dto.ProducerShareInvoiceDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getShareInfos(): RpcRequestBuilder<ProducerShareInvoiceDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ShareInvoiceService.getShareInfos';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerShareInvoiceDTO');
  }

}
