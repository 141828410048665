// com.degineo.metier.client.service.ClientProducerAssociationServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { CPADTO } from '../entities/CPADTO';

@Injectable({
  providedIn: 'root',
})
export class ClientProducerAssociationService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getClientProducerAssociations(): RpcRequestBuilder<CPADTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientProducerAssociationService.getClientProducerAssociations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  hasPendingClientProducerAssociations(): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientProducerAssociationService.hasPendingClientProducerAssociations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  acceptCPA(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientProducerAssociationService.acceptCPA';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  refuseCPA(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientProducerAssociationService.refuseCPA';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  removeCPA(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientProducerAssociationService.removeCPA';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
