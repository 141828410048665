// ENTITY com.degineo.metier.order.dto.SizeDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class SizeDTO {

  x: number = 0;
  y: number = 0;
  z: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.SizeDTO'] = SizeDTO;

reverseMapping[SizeDTO.name] = 'com.degineo.metier.order.dto.SizeDTO';

fields['com.degineo.metier.order.dto.SizeDTO']  = {
    x: 'java.lang.Double',
    y: 'java.lang.Double',
    z: 'java.lang.Double'
};
