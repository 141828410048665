// com.degineo.metier.client.service.ClientBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ClientBODTO } from '../entities/ClientBODTO';
import { ClientListItemBODTO } from '../entities/ClientListItemBODTO';
import { BusinessTargetLevel2 } from '../entities/BusinessTargetLevel2';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { Client } from '../entities/Client';

@Injectable({
  providedIn: 'root',
})
export class ClientBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getClients(filter: GenericFilter): RpcRequestBuilder<ListWithCount<ClientListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientBOService.getClients';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  updateClientBusinessTarget(id: number, target: BusinessTargetLevel2): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientBOService.updateClientBusinessTarget';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel2', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveDegineoNote(id: number, note: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientBOService.saveDegineoNote';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(note, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getClientDetail(id: number): RpcRequestBuilder<ClientBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientBOService.getClientDetail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.client.dto.ClientBODTO');
  }

  searchClients(text: string, numberOfResults: number): RpcRequestBuilder<Client[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientBOService.searchClients';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(text, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(numberOfResults, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  updateClientActive(id: number, inactive: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientBOService.updateClientActive';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(inactive, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteClient(id: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientBOService.deleteClient';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateSiret(id: number, siret: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientBOService.updateSiret';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
