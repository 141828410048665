// com.degineo.metier.metadata.service.MaterialTypeServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { MaterialTypeNEW } from '../entities/MaterialTypeNEW';

@Injectable({
  providedIn: 'root',
})
export class MaterialTypeService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getAllMaterialTypes(): RpcRequestBuilder<MaterialTypeNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MaterialTypeService.getAllMaterialTypes';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getAllChildsMaterialTypes(): RpcRequestBuilder<MaterialTypeNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MaterialTypeService.getAllChildsMaterialTypes';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getAllParentsMaterialTypes(): RpcRequestBuilder<MaterialTypeNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MaterialTypeService.getAllParentsMaterialTypes';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
