// com.degineo.metier.plan.service.SubscriptionPlanBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { PlanDTO } from '../entities/PlanDTO';
import { Plan } from '../entities/Plan';
import { FunctionalityDTO } from '../entities/FunctionalityDTO';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlanBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getActivePlansSimple(): RpcRequestBuilder<Plan[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SubscriptionPlanBOService.getActivePlansSimple';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getPlans(): RpcRequestBuilder<PlanDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SubscriptionPlanBOService.getPlans';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  createPlan(plan: PlanDTO): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SubscriptionPlanBOService.createPlan';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(plan, 'com.degineo.metier.plan.dto.PlanDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  updateActive(idPlan: number, active: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SubscriptionPlanBOService.updateActive';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPlan, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(active, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getFunctionalities(): RpcRequestBuilder<FunctionalityDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SubscriptionPlanBOService.getFunctionalities';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
