// ENTITY com.degineo.metier.order.dto.ColorListDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ColorNEW } from './ColorNEW';

export class ColorListDTO {

  colors: ColorNEW[] = null;
  canPrintInMulticolor: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.ColorListDTO'] = ColorListDTO;

reverseMapping[ColorListDTO.name] = 'com.degineo.metier.order.dto.ColorListDTO';

fields['com.degineo.metier.order.dto.ColorListDTO']  = {
    colors: 'java.util.List<com.degineo.metier.metadata.entities.ColorNEW>',
    canPrintInMulticolor: 'java.lang.Boolean'
};
