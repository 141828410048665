<div class="container-fluid shadow-5">
  <div class="row bg-primary justify-content-center py-2">
    <div class="col text-white fs-4 fw-700 text-center"><i class="fas fa-unlock-alt mr-3" style="font-size: 1.4rem"></i>RÃ©initialisation du mot de passe</div>
  </div>
  <div class="row bg-white justify-content-center">
    <div class="col inner-padding">
      <ng-container *ngIf="!confirm">
        <form [formGroup]="form" [class.show-errors]="submitted && !form.valid" (submit)="reinitializePwd()">
          <div class="row px-5">
            <div class="col">
              <input type="email" class="form-control" placeholder="E-mail" formControlName="email" />
            </div>
          </div>
          <div class="row mt-3 px-5" *ngIf="submitted && form.invalid">
            <div class="col">
              <div class="alert alert-danger">
                <ul>
                  <li *ngIf="form.hasError('required', 'email')">E-mail requis</li>
                  <li *ngIf="form.hasError('email', 'email')">E-mail non valide</li>
                  <li *ngIf="form.hasError('non-existant', 'email')">Cet e-mail n'existe pas dans nos bases de donnÃ©es</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row mt-3 px-5">
            <div class="col-12 col-md-6">
              <button class="btn btn-secondary text-white text-uppercase w-100" (click)="close()">Annuler</button>
            </div>
            <div class="col-12 col-md-6 mt-2 mt-md-0">
              <button type="submit" class="btn btn-success text-uppercase w-100">RÃ©initialiser</button>
            </div>
          </div>
        </form>
        <div class="row mt-3 px-5">
          <div class="col text-justify">
            <ol>
              <li>AprÃ¨s avoir entrÃ© votre adresse e-mail ci-dessus, <span class="fw-700">vous allez recevoir un e-mail</span> avec un lien.</li>
              <!--prettier-ignore-->
              <li class="mt-3">En cliquant sur ce lien, vous arriverez sur une nouvelle page oÃ¹ vous pourrez choisir votre
                    <span class="fw-700">nouveau mot de passe</span>.
                  </li>
              <li class="mt-3">Une fois ce nouveau mot de passe validÃ©, vous pourrez <span class="fw-700">vous connecter</span> avec celui-ci.</li>
            </ol>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="confirm">
        <div class="row mt-4 px-5">
          <div class="col text-justify">
            <p>Nous venons de vous envoyer un e-mail avec un lien cliquable Ã  l'adresse suivante :</p>
          </div>
        </div>
        <div class="row mt-3 px-5">
          <div class="col text-center">
            <p class="fw-700">{{ form.get('email').value }}</p>
          </div>
        </div>
        <div class="row mt-3 px-5">
          <div class="col text-justify">
            <p class="mb-0">
              Si vous ne recevez pas notre e-mail, vÃ©rifiez vos e-mails indÃ©sirables.<br />MalgrÃ© cela si vous n'avez toujours pas reÃ§u l'e-mail dans les 30min,
              veuillez <a href="https://degineo.com/nous-contacter/">nous contacter.</a>
            </p>
          </div>
        </div>
        <div class="row my-4 px-5">
          <div class="col">
            <button class="btn btn-success text-uppercase w-100" style="width: 165px" (click)="close()">ok</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
