// ENTITY com.degineo.metier.producer.dto.ProducerTakeControlStep4DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { LegalStatus } from './LegalStatus';
import { AddressNEW } from './AddressNEW';

export class ProducerTakeControlStep4DTO {

  billingAddress: AddressNEW = null;
  name: string = '';
  legalStatus: LegalStatus = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerTakeControlStep4DTO'] = ProducerTakeControlStep4DTO;

reverseMapping[ProducerTakeControlStep4DTO.name] = 'com.degineo.metier.producer.dto.ProducerTakeControlStep4DTO';

fields['com.degineo.metier.producer.dto.ProducerTakeControlStep4DTO']  = {
    billingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    name: 'java.lang.String',
    legalStatus: 'com.degineo.metier.producer.entities.LegalStatus'
};
