import { Component, OnInit, ViewEncapsulation, Inject, Input, Output, EventEmitter, PLATFORM_ID, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, isPlatformBrowser } from '@angular/common';
import { ProducerSVDTO, ColorPriceSVDTO, DegineoRight } from '../../ic2/entities/entities';
import { ProducerSVService, ProducerPageService } from '../../ic2/services/services';

import { L } from 'ic2-lib';
import { Title, Meta } from '@angular/platform-browser';
import { AuthService } from '../../services/auth.service';
import { SlugService } from '../../services/slug.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'lib-producer-display',
  templateUrl: './producer-display.component.html',
  styleUrls: ['./producer-display.component.scss'],
})
export class ProducerDisplayComponent implements OnInit {
  private sub: any;

  producer: ProducerSVDTO = null;
  days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  loading: boolean = false;
  degineo: boolean = false;
  isProducer: boolean = false;
  colorMenuOpened: boolean = false;

  id: number;
  loaded: boolean = false;
  data: ProducerSVDTO = null;
  originalData: ProducerSVDTO = null;
  @Input()
  editMode: boolean = false;
  @Input()
  rpc: string = null;
  @Input()
  svUrl;
  @Input()
  clientUrl;
  @Input()
  producerUrl;
  @Input()
  idProducer: number = null;
  @Input()
  colors: ColorPriceSVDTO[] = [];
  @Input()
  selectedColor: ColorPriceSVDTO = null;

  @Output()
  changePage = new EventEmitter<boolean>();
  @Output()
  order = new EventEmitter<ColorPriceSVDTO>();

  numberToForceImgReload = 1;

  userAgent: string = null;
  ssr: boolean = false;
  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(Title) private titleService: Title,
    @Inject(Meta) private metaService: Meta,
    private location: Location,
    @Inject(ProducerSVService) private producerSVService: ProducerSVService,
    @Inject(ProducerPageService) private producerPageService: ProducerPageService,
    @Inject(AuthService) private authService: AuthService,
    @Inject(SlugService) public slugService: SlugService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
    private _injector: Injector
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.userAgent = window.navigator.userAgent;
    } else {
      // We cannot use window in SSR, so we get the userAgent from the REQUEST
      const req = this._injector.get(REQUEST);
      this.userAgent = req.headers['user-agent'];
      this.ssr = true;
    }
    this.route.data.subscribe((data) => {
      if (data.editMode) this.editMode = true;
      if (data.rpcHost) this.rpc = data.rpcHost;
      if (data.svUrl) this.svUrl = data.svUrl;
      if (data.clientUrl) this.clientUrl = data.clientUrl;
      if (data.producerUrl) this.producerUrl = data.producerUrl;
      if (data.idProducer) this.idProducer = data.idProducer;
      if (data.colors) this.colors = data.colors;
      if (data.selectedColor) this.selectedColor = data.selectedColor;
    });

    if (this.route.snapshot.data.producer) this.dataLoaded(this.route.snapshot.data.producer);
    else
      this.sub = this.route.params.subscribe((params) => {
        if (params['id'] === undefined && !this.idProducer) return;
        if (this.idProducer) this.id = this.idProducer;
        else this.id = +params['id'];
        L.v(this.id);
        this.producerSVService.getProducer(this.id, this.editMode).subscribe(
          (data) => {
            this.dataLoaded(data);
          },
          (err) => {
            this.loading = false;
            L.e(err);
          }
        );
      });
    this.authService.isLoggedIn().subscribe((data) => {
      if (this.authService.has(DegineoRight.ADMIN)) this.degineo = true;
      else if (this.authService.has(DegineoRight.PRODUCER)) this.isProducer = true;
    });
  }

  dataLoaded(data: ProducerSVDTO) {
    this.data = data;
    if (data.applicationDomains === null) data.applicationDomains = [];
    if (data.impressionTechnologies === null) data.impressionTechnologies = [];
    this.originalData = Object.assign({}, this.data);
    this.loaded = true;
    this.loading = false;

    this.titleService.setTitle(this.data.name + (this.data.enterpriseType !== null ? ' â¢ ' + this.data.enterpriseType.lib : ''));
    let desc =
      (this.data.applicationDomains
        ? this.data.applicationDomains
            .map((ad) => ad.lib)
            .join(',')
            .replace(new RegExp(',', 'g'), ' -')
        : '') +
      ' - ' +
      (this.data.impressionTechnologies
        ? this.data.impressionTechnologies
            .map((it) => it.lib)
            .join(',')
            .replace(new RegExp(',', 'g'), ' -')
        : '') +
      ' - ' +
      this.data.address.line1 +
      ', ' +
      this.data.address.zipCode +
      ' ' +
      this.data.address.city +
      ' ' +
      this.data.address.country;
    this.metaService.updateTag({ property: 'description', content: desc });
    this.metaService.updateTag({ property: 'og:description', content: desc });
    this.metaService.updateTag({
      property: 'og:title',
      content: this.data.name + (this.data.enterpriseType !== null ? ' â¢ ' + this.data.enterpriseType.lib : ''),
    });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({ property: 'og:locale', content: 'fr_FR' });
    this.metaService.updateTag({ property: 'og:site_name', content: 'Degineo' });
    this.metaService.updateTag({ property: 'og:image', content: this.getLogo() });
    this.metaService.updateTag({
      property: 'og:url',
      content:
        this.svUrl +
        '/' +
        (this.data.enterpriseType === null ? 'type' : this.slugService.string_to_slug(this.data.enterpriseType.lib)) +
        '/' +
        this.slugService.string_to_slug(this.data.address.city) +
        '/' +
        this.slugService.string_to_slug(this.data.name) +
        '/' +
        this.data.id,
    });
    if (this.route.snapshot.routeConfig.path !== 'ma-page-degineo' && this.route.snapshot.routeConfig.path !== 'producers/edit/:name/:id/:page') {
      //avoid registering visit if backoffice or producer
      this.newVisit();
    }
  }

  newVisit() {
    this.producerSVService.newVisitOrClick(this.data.id, this.userAgent, true).subscribe(
      (data) => {
        console.log('added new connection');
      },
      (err) => {
        L.e(err);
      }
    );
  }

  print() {
    this.producerSVService.newVisitOrClick(this.data.id, this.userAgent, false).subscribe(
      (data) => {
        console.log('added new click, redirect to print');
        window.location.href = this.clientUrl + this.slugService.string_to_slug(this.data.name) + '/' + this.data.id + '/impression-en-ligne';
      },
      (err) => {
        L.e(err);
      }
    );
  }

  public static normalize(str: string) {
    return str.toLowerCase().replace(new RegExp(' ', 'g'), '-').replace(new RegExp('(', 'g'), '').replace(new RegExp(')', 'g'), '');
  }

  getLogo() {
    return this.rpc + 'companylogo/' + this.data.logoToken + '/logo.png?size=large?r=' + this.numberToForceImgReload;
  }

  getAvatar(i: number) {
    if (this.data.employees[i].logoToken && this.data.employees[i].logoExtension)
      return this.rpc + 'userLogo/' + this.data.employees[i].logoToken + '/logo.' + this.data.employees[i].logoExtension;
  }

  updateCompanyActive() {
    this.producerPageService.updateProducerVisibility(this.data.id, !this.data.visible).subscribe(
      (data) => {
        this.data.visible = !this.data.visible;
        this.originalData.visible = this.data.visible;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  redirect() {
    if (this.router.url !== '/ma-page-degineo') {
      this.changePage.emit();
    } else this.router.navigate(['/ma-page-degineo/edit']);
  }

  updateColor(color: ColorPriceSVDTO) {
    this.selectedColor = color;
    this.colorMenuOpened = false;
  }

  orderClicked() {
    this.order.emit(this.selectedColor);
  }
  back() {
    this.location.back();
  }
}
