// ENTITY com.degineo.metier.order.dto.kanban.panel.InPreparationOrderPanelDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { InPrepProducerPrinterDTO } from './InPrepProducerPrinterDTO';
import { AddressNEW } from './AddressNEW';
import { ClientInfosForKanbanPopupDTO } from './ClientInfosForKanbanPopupDTO';
import { OrderFileStatus } from './OrderFileStatus';

export class InPreparationOrderPanelDTO {

  idOrder: number = 0;
  clientChoosedThisProducer: boolean = false;
  client: ClientInfosForKanbanPopupDTO = null;
  wishedDeliveryDate: Date = null;
  dateAccepted: Date = null;
  printerName: string = '';
  printerBrand: string = '';
  consumableName: string = '';
  colorName: string = '';
  colorHex: string = '';
  comment: string = '';
  noteDegineo: string = '';
  quantity: number = 0;
  finishName: string = '';
  sizeXMm: number = 0;
  sizeYMm: number = 0;
  sizeZMm: number = 0;
  dental: boolean = false;
  estimatedProductionTimeInMin: number = 0;
  machineUsagePerPiece: number = 0;
  materialQuantityPerPiece: number = 0;
  token3DModelFinal: string = '';
  filenameFinal: string = '';
  degineoFile: boolean = false;
  token3DModel: string = '';
  filename: string = '';
  token3DModelProducer: string = '';
  filenameProducer: string = '';
  producerFileStatus: OrderFileStatus = null;
  canChangePrinter: boolean = false;
  printerConsumablePossibilities: InPrepProducerPrinterDTO[] = null;
  shippingAddress: AddressNEW = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.InPreparationOrderPanelDTO'] = InPreparationOrderPanelDTO;

reverseMapping[InPreparationOrderPanelDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.InPreparationOrderPanelDTO';

fields['com.degineo.metier.order.dto.kanban.panel.InPreparationOrderPanelDTO']  = {
    idOrder: 'java.lang.Integer',
    clientChoosedThisProducer: 'java.lang.Boolean',
    client: 'com.degineo.metier.order.dto.kanban.panel.ClientInfosForKanbanPopupDTO',
    wishedDeliveryDate: 'java.time.Instant',
    dateAccepted: 'java.time.Instant',
    printerName: 'java.lang.String',
    printerBrand: 'java.lang.String',
    consumableName: 'java.lang.String',
    colorName: 'java.lang.String',
    colorHex: 'java.lang.String',
    comment: 'java.lang.String',
    noteDegineo: 'java.lang.String',
    quantity: 'java.lang.Integer',
    finishName: 'java.lang.String',
    sizeXMm: 'java.lang.Double',
    sizeYMm: 'java.lang.Double',
    sizeZMm: 'java.lang.Double',
    dental: 'java.lang.Boolean',
    estimatedProductionTimeInMin: 'java.lang.Double',
    machineUsagePerPiece: 'java.lang.Double',
    materialQuantityPerPiece: 'java.lang.Double',
    token3DModelFinal: 'java.lang.String',
    filenameFinal: 'java.lang.String',
    degineoFile: 'java.lang.Boolean',
    token3DModel: 'java.lang.String',
    filename: 'java.lang.String',
    token3DModelProducer: 'java.lang.String',
    filenameProducer: 'java.lang.String',
    producerFileStatus: 'com.degineo.metier.order.entities.OrderFileStatus',
    canChangePrinter: 'java.lang.Boolean',
    printerConsumablePossibilities: 'java.util.List<com.degineo.metier.order.dto.kanban.panel.inprep.InPrepProducerPrinterDTO>',
    shippingAddress: 'com.degineo.metier.address.entities.AddressNEW'
};
