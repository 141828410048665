// com.degineo.metier.order.service.KanbanServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { KanbanDTO } from '../entities/KanbanDTO';

@Injectable({
  providedIn: 'root',
})
export class KanbanService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getKanban(): RpcRequestBuilder<KanbanDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'KanbanService.getKanban';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.kanban.KanbanDTO');
  }

}
