// ENTITY com.degineo.metier.producer.dto.ProducerSignupStep8DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';

export class ProducerSignupStep8DTO {

  degineoSepaIdentifier: string = '';
  degineoAddress: AddressNEW = null;
  billingAddress: AddressNEW = null;
  name: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerSignupStep8DTO'] = ProducerSignupStep8DTO;

reverseMapping[ProducerSignupStep8DTO.name] = 'com.degineo.metier.producer.dto.ProducerSignupStep8DTO';

fields['com.degineo.metier.producer.dto.ProducerSignupStep8DTO']  = {
    degineoSepaIdentifier: 'java.lang.String',
    degineoAddress: 'com.degineo.metier.address.entities.AddressNEW',
    billingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    name: 'java.lang.String'
};
