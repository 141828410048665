// DB ENUM com.degineo.metier.notif.dto.NotifType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class NotifType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly DELAYED_PAYMENT_WIRETRANSFERT_CLIENT_DONE_WAITING_PRODUCER_APPROVAL = new NotifType(1, "Attente de validation de paiement producteur", "DELAYED_PAYMENT_WIRETRANSFERT_CLIENT_DONE_WAITING_PRODUCER_APPROVAL");
  static readonly DELAYED_INVOICE_TO_PAY = new NotifType(2, "Facture fin de mois Ã  payer", "DELAYED_INVOICE_TO_PAY");
  static readonly DELAYED_INVOICE_INCOMMING = new NotifType(3, "Facture fin de mois prÃ©vue", "DELAYED_INVOICE_INCOMMING");
  static readonly CLIENT_PRODUCER_ASSOCIATION_WAITING = new NotifType(4, "Imprimeur partenaire", "CLIENT_PRODUCER_ASSOCIATION_WAITING");

  static readonly values = [
    NotifType.DELAYED_PAYMENT_WIRETRANSFERT_CLIENT_DONE_WAITING_PRODUCER_APPROVAL, 
    NotifType.DELAYED_INVOICE_TO_PAY, 
    NotifType.DELAYED_INVOICE_INCOMMING, 
    NotifType.CLIENT_PRODUCER_ASSOCIATION_WAITING 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of NotifType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of NotifType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.notif.dto.NotifType'] = NotifType;
reverseMapping[NotifType.name] = 'com.degineo.metier.notif.dto.NotifType';
