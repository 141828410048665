// ENTITY com.degineo.metier.producer_printer.dto.ProducerPrinterFullDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ProducerPrinterClientTypeMargin } from './ProducerPrinterClientTypeMargin';
import { PPATPFullDTO } from './PPATPFullDTO';
import { PPCOFullDTO } from './PPCOFullDTO';
import { PPFFullDTO } from './PPFFullDTO';

export class ProducerPrinterFullDTO {

  idProducerPrinter: number = 0;
  idPrinter: number = 0;
  printerBrand: string = '';
  printerName: string = '';
  defaultProductionCost: number = 0;
  currentProductionCost: number = 0;
  active: boolean = false;
  checked: boolean = false;
  ppcos: PPCOFullDTO[] = null;
  ppctms: ProducerPrinterClientTypeMargin[] = null;
  hasUnitPieces: boolean = false;
  ppatps: PPATPFullDTO[] = null;
  ppfs: PPFFullDTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.ProducerPrinterFullDTO'] = ProducerPrinterFullDTO;

reverseMapping[ProducerPrinterFullDTO.name] = 'com.degineo.metier.producer_printer.dto.ProducerPrinterFullDTO';

fields['com.degineo.metier.producer_printer.dto.ProducerPrinterFullDTO']  = {
    idProducerPrinter: 'java.lang.Integer',
    idPrinter: 'java.lang.Integer',
    printerBrand: 'java.lang.String',
    printerName: 'java.lang.String',
    defaultProductionCost: 'java.lang.Double',
    currentProductionCost: 'java.lang.Double',
    active: 'java.lang.Boolean',
    checked: 'java.lang.Boolean',
    ppcos: 'java.util.List<com.degineo.metier.producer_printer.dto.PPCOFullDTO>',
    ppctms: 'java.util.List<com.degineo.metier.producer_printer.entities.ProducerPrinterClientTypeMargin>',
    hasUnitPieces: 'java.lang.Boolean',
    ppatps: 'java.util.List<com.degineo.metier.producer_printer.dto.PPATPFullDTO>',
    ppfs: 'java.util.List<com.degineo.metier.producer_printer.dto.PPFFullDTO>'
};
