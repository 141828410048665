// ENTITY com.degineo.metier.producer_printer.entities.ProducerPrinterClientTypeMargin generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { BusinessTargetLevel1 } from './BusinessTargetLevel1';

export class ProducerPrinterClientTypeMargin {

  id: number = 0;
  idProducerPrinter: number = 0;
  idProducer: number = 0;
  idPrinter: number = 0;
  clientType: BusinessTargetLevel1 = null;
  producerMargin: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.entities.ProducerPrinterClientTypeMargin'] = ProducerPrinterClientTypeMargin;

reverseMapping[ProducerPrinterClientTypeMargin.name] = 'com.degineo.metier.producer_printer.entities.ProducerPrinterClientTypeMargin';

fields['com.degineo.metier.producer_printer.entities.ProducerPrinterClientTypeMargin']  = {
    id: 'java.lang.Integer',
    idProducerPrinter: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    idPrinter: 'java.lang.Integer',
    clientType: 'com.degineo.metier.client.entities.BusinessTargetLevel1',
    producerMargin: 'java.lang.Double'
};
