// ENTITY com.degineo.metier.basket.dto.ArticleTypePrintStep1DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ArticleTypePrintStep1DTO {

  id: number = 0;
  name: string = '';
  dental: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.basket.dto.ArticleTypePrintStep1DTO'] = ArticleTypePrintStep1DTO;

reverseMapping[ArticleTypePrintStep1DTO.name] = 'com.degineo.metier.basket.dto.ArticleTypePrintStep1DTO';

fields['com.degineo.metier.basket.dto.ArticleTypePrintStep1DTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    dental: 'java.lang.Boolean'
};
