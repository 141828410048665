// ENTITY com.degineo.metier.producer.dto.ProducerShareInvoiceDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerShareInvoiceDTO {

  shareInvoiceEmails: string[] = null;
  shareInvoiceDayOfMonth: number = 0;
  shareBills: boolean = false;
  shareFinancialReport: boolean = false;
  shareDegineoInvoices: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerShareInvoiceDTO'] = ProducerShareInvoiceDTO;

reverseMapping[ProducerShareInvoiceDTO.name] = 'com.degineo.metier.producer.dto.ProducerShareInvoiceDTO';

fields['com.degineo.metier.producer.dto.ProducerShareInvoiceDTO']  = {
    shareInvoiceEmails: 'java.util.List<java.lang.String>',
    shareInvoiceDayOfMonth: 'java.lang.Integer',
    shareBills: 'java.lang.Boolean',
    shareFinancialReport: 'java.lang.Boolean',
    shareDegineoInvoices: 'java.lang.Boolean'
};
