// DB ENUM com.degineo.metier.invoice.entities.PaymentType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class PaymentType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly STRIPE = new PaymentType(1, "Carte bancaire", "STRIPE");
  static readonly PAYPAL = new PaymentType(2, "Paypal", "PAYPAL");
  static readonly WIRETRANSFERT = new PaymentType(3, "Virement", "WIRETRANSFERT");
  static readonly UNKOWN = new PaymentType(4, "Inconnu", "UNKOWN");

  static readonly values = [
    PaymentType.STRIPE, 
    PaymentType.PAYPAL, 
    PaymentType.WIRETRANSFERT, 
    PaymentType.UNKOWN 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of PaymentType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of PaymentType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.invoice.entities.PaymentType'] = PaymentType;
reverseMapping[PaymentType.name] = 'com.degineo.metier.invoice.entities.PaymentType';
