// ENTITY com.degineo.metier.client.entities.Client generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { BusinessTargetLevel2 } from './BusinessTargetLevel2';

export class Client {

  id: number = 0;
  target: BusinessTargetLevel2 = null;
  invited: boolean = false;
  idProducerFirstInvite: number = 0;
  dateCreated: Date = null;
  name: string = '';
  denomination: string = '';
  noteDegineo: string = '';
  siret: string = '';
  idCodeNaf: number = 0;
  categorieEntreprise: string = '';
  dateCreationEtablissement: Date = null;
  siege: boolean = false;
  idUserOwner: number = 0;
  stripeCustomerId: string = '';
  deleted: boolean = false;
  inactive: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.client.entities.Client'] = Client;

reverseMapping[Client.name] = 'com.degineo.metier.client.entities.Client';

fields['com.degineo.metier.client.entities.Client']  = {
    id: 'java.lang.Integer',
    target: 'com.degineo.metier.client.entities.BusinessTargetLevel2',
    invited: 'java.lang.Boolean',
    idProducerFirstInvite: 'java.lang.Integer',
    dateCreated: 'java.time.Instant',
    name: 'java.lang.String',
    denomination: 'java.lang.String',
    noteDegineo: 'java.lang.String',
    siret: 'java.lang.String',
    idCodeNaf: 'java.lang.Integer',
    categorieEntreprise: 'java.lang.String',
    dateCreationEtablissement: 'java.time.LocalDate',
    siege: 'java.lang.Boolean',
    idUserOwner: 'java.lang.Integer',
    stripeCustomerId: 'java.lang.String',
    deleted: 'java.lang.Boolean',
    inactive: 'java.lang.Boolean'
};
