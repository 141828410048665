<ng-container *ngIf="!showNotFound">
  <div class="container-small" style="margin-top: 25vh">
    <div class="row">
      <div class="col text-center">
        <img src="assets/header/logo_degineo_bleu_vert.svg" style="max-height: 6rem" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <h3>Recherchez ici si votre entreprise est référencée sur Degineo</h3>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col text-center">
        <app-customer-area-search [searchEmpty]="search" placeholder="Tapez le nom de votre entreprise ici"></app-customer-area-search>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button class="btn btn-success" (click)="search()">Rechercher</button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showNotFound">
  <div class="container" style="margin-top: 25vh">
    <div class="row">
      <div class="col text-center">
        <i class="far fa-sad-tear text-dark" style="font-size: 10rem"></i>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center fs-4">
        Vous n'avez pas trouvé votre entreprise&nbsp;?<br />
        C'est qu'elle n'est pas encore référencée sur Degineo.<br />
        Pas de panique, vous pouvez l'enregistrer vous-même et gratuitement en 2 min.
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <a href="{{ producerUrl }}inscription" class="btn btn-success">Enregistrer mon entreprise</a>
      </div>
    </div>
  </div>
</ng-container>
