// ENTITY com.degineo.metier.order.dto.bo.OrderListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PaymentType } from './PaymentType';
import { OrderNEWStatus } from './OrderNEWStatus';
import { OrderFileStatus } from './OrderFileStatus';

export class OrderListItemBODTO {

  id: number = 0;
  dateCreated: Date = null;
  clientName: string = '';
  paymentType: PaymentType = null;
  delayed: boolean = false;
  status: OrderNEWStatus = null;
  fileStatus: OrderFileStatus = null;
  dateLastStatusChange: Date = null;
  priceHT: number = 0;
  priceTTC: number = 0;
  noteDegineo: string = '';
  producerSelected: boolean = false;
  checked: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.bo.OrderListItemBODTO'] = OrderListItemBODTO;

reverseMapping[OrderListItemBODTO.name] = 'com.degineo.metier.order.dto.bo.OrderListItemBODTO';

fields['com.degineo.metier.order.dto.bo.OrderListItemBODTO']  = {
    id: 'java.lang.Integer',
    dateCreated: 'java.time.Instant',
    clientName: 'java.lang.String',
    paymentType: 'com.degineo.metier.invoice.entities.PaymentType',
    delayed: 'java.lang.Boolean',
    status: 'com.degineo.metier.order.entities.OrderNEWStatus',
    fileStatus: 'com.degineo.metier.order.entities.OrderFileStatus',
    dateLastStatusChange: 'java.time.Instant',
    priceHT: 'java.lang.Double',
    priceTTC: 'java.lang.Double',
    noteDegineo: 'java.lang.String',
    producerSelected: 'java.lang.Boolean',
    checked: 'java.lang.Boolean'
};
