// com.degineo.metier.client.service.MyFilesServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { FileDTO } from '../entities/FileDTO';
import { LastPrintConfigDTO } from '../entities/LastPrintConfigDTO';

@Injectable({
  providedIn: 'root',
})
export class MyFilesService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateName(id: number, newName: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MyFilesService.updateName';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(newName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getMyFiles(): RpcRequestBuilder<FileDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MyFilesService.getMyFiles';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  removeFile(idFile: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MyFilesService.removeFile';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFile, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getLastPrintConfig(idFile: number): RpcRequestBuilder<LastPrintConfigDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MyFilesService.getLastPrintConfig';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFile, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.client.dto.LastPrintConfigDTO');
  }

}
