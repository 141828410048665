// ENTITY com.degineo.metier.order.dto.CharacteristicsDisplayDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class CharacteristicsDisplayDTO {

  title: string = '';
  value: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.CharacteristicsDisplayDTO'] = CharacteristicsDisplayDTO;

reverseMapping[CharacteristicsDisplayDTO.name] = 'com.degineo.metier.order.dto.CharacteristicsDisplayDTO';

fields['com.degineo.metier.order.dto.CharacteristicsDisplayDTO']  = {
    title: 'java.lang.String',
    value: 'java.lang.String'
};
