// com.degineo.metier.producer.service.ProducerBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { LegalStatus } from '../entities/LegalStatus';
import { ProducerPageListItemBODTO } from '../entities/ProducerPageListItemBODTO';
import { BusinessType } from '../entities/BusinessType';
import { Plan } from '../entities/Plan';
import { ProducerUserBODTO } from '../entities/ProducerUserBODTO';
import { ProducerPrinterListItemBODTO } from '../entities/ProducerPrinterListItemBODTO';
import { ProducerCompanyListItemBODTO } from '../entities/ProducerCompanyListItemBODTO';
import { ProducerPrinterConsumableOptionArticleType } from '../entities/ProducerPrinterConsumableOptionArticleType';
import { UserWithDUDTO } from '../entities/UserWithDUDTO';
import { ProducerInfoBODTO } from '../entities/ProducerInfoBODTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { ImportProducerResultDTO } from '../entities/ImportProducerResultDTO';
import { newProducerBODTO } from '../entities/newProducerBODTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getProducers(genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<ProducerCompanyListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.getProducers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  updateMarqueBlancheDevis(idCompany: number, devis: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateMarqueBlancheDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(devis, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateMarqueBlanchePrint(idCompany: number, print: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateMarqueBlanchePrint';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(print, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveDegineoNote(id: number, note: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.saveDegineoNote';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(note, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateProducerPrinterConsumableChecked(idProducerPrinterConsumableOption: number, checked: boolean): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateProducerPrinterConsumableChecked';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterConsumableOption, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(checked, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  updateCompanyPrinterConsumableType(idType: number, idProducerPrinterConsumableOption: number, idCompanyPrinterConsumableArticleType: number): RpcRequestBuilder<ProducerPrinterConsumableOptionArticleType> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateCompanyPrinterConsumableType';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterConsumableOption, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idCompanyPrinterConsumableArticleType, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOptionArticleType');
  }

  updateProducerPrinterPrice(idProducerPrinter: number, price: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateProducerPrinterPrice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(price, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateProducerPrinterConsumablePrice(idProducerPrinterConsumableOption: number, price: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateProducerPrinterConsumablePrice';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinterConsumableOption, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(price, 'java.lang.Double', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getProducerPages(genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<ProducerPageListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.getProducerPages';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  newProducer(producer: newProducerBODTO): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.newProducer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(producer, 'com.degineo.metier.producer.dto.bo.newProducerBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  newProducerHolder(u: UserWithDUDTO, idCompany: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.newProducerHolder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(u, 'com.degineo.metier.user.dto.UserWithDUDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  importProducers(): RpcRequestBuilder<ImportProducerResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.importProducers';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ImportProducerResultDTO');
  }

  updateBusinessType(idCompany: number, bt: BusinessType): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateBusinessType';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(bt, 'com.degineo.metier.producer.entities.BusinessType', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updatePlan(idCompany: number, idPlan: number): RpcRequestBuilder<Plan> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updatePlan';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idPlan, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.plan.entities.Plan');
  }

  getProducerDetails(idCompany: number): RpcRequestBuilder<ProducerInfoBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.getProducerDetails';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.bo.ProducerInfoBODTO');
  }

  getUsersProducer(id: number, genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<ProducerUserBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.getUsersProducer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  uploadLogo(idCompany: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.uploadLogo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateSubscribeStatus(idCompany: number, subscribed: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateSubscribeStatus';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(subscribed, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateRemise(idCompany: number, discountPercent: number, dateDiscountEnd: Date): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateRemise';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(discountPercent, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(dateDiscountEnd, 'java.time.Instant', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateBilling(idCompany: number, denomination: string, legalStatus: LegalStatus, capital: number, RNANumber: string, RCS: string, siret: string): RpcRequestBuilder<ProducerInfoBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateBilling';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(denomination, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(legalStatus, 'com.degineo.metier.producer.entities.LegalStatus', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(capital, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(RNANumber, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(RCS, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.bo.ProducerInfoBODTO');
  }

  getProducerPrinterConsumable(idCompany: number, genericFilter: GenericFilter): RpcRequestBuilder<ProducerPrinterListItemBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.getProducerPrinterConsumable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompany, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  updateProducerPrinterChecked(idCompanyPrinter: number, checked: boolean): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerBOService.updateProducerPrinterChecked';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCompanyPrinter, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(checked, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

}
