// ENTITY com.degineo.metier.invoice.dto.bo.InvoiceToProducerListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class InvoiceToProducerListItemBODTO {

  invoiceId: number = 0;
  date: Date = null;
  reference: string = '';
  idProducer: number = 0;
  producerName: string = '';
  iban: string = '';
  bic: string = '';
  bankAccountCountry: string = '';
  bankAccountOwner: string = '';
  price: number = 0;
  closed: boolean = false;
  paid: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.bo.InvoiceToProducerListItemBODTO'] = InvoiceToProducerListItemBODTO;

reverseMapping[InvoiceToProducerListItemBODTO.name] = 'com.degineo.metier.invoice.dto.bo.InvoiceToProducerListItemBODTO';

fields['com.degineo.metier.invoice.dto.bo.InvoiceToProducerListItemBODTO']  = {
    invoiceId: 'java.lang.Integer',
    date: 'java.time.Instant',
    reference: 'java.lang.String',
    idProducer: 'java.lang.Integer',
    producerName: 'java.lang.String',
    iban: 'java.lang.String',
    bic: 'java.lang.String',
    bankAccountCountry: 'java.lang.String',
    bankAccountOwner: 'java.lang.String',
    price: 'java.lang.Double',
    closed: 'java.lang.Boolean',
    paid: 'java.lang.Boolean'
};
