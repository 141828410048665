// ENTITY com.degineo.metier.order.entities.OrderNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PaymentType } from './PaymentType';
import { OrderNEWStatus } from './OrderNEWStatus';
import { OrderFileStatus } from './OrderFileStatus';

export class OrderNEW {

  id: number = 0;
  idBasketArticle: number = 0;
  idDevis: number = 0;
  status: OrderNEWStatus = null;
  name: string = '';
  dateLastStatusChange: Date = null;
  idUserLastModif: number = 0;
  dateCreated: Date = null;
  dateChecked: Date = null;
  idUserCheck: number = 0;
  dateProducerAccepted: Date = null;
  idUserProducerAccepted: number = 0;
  dateStartPrinting: Date = null;
  idUserStartPrinting: number = 0;
  dateShippingPreparation: Date = null;
  idUserShippingPreparation: number = 0;
  datePackagePreparation: Date = null;
  idUserPackagePreparation: number = 0;
  dateShippingProgress: Date = null;
  idUserShippingProgress: number = 0;
  dateCarrierTransport: Date = null;
  idUserCarrierTransport: number = 0;
  dateArticleReceived: Date = null;
  idUserArticleReceived: number = 0;
  noteDegineo: string = '';
  checked: boolean = false;
  delayed: boolean = false;
  paid: boolean = false;
  idUserMarkAsPaid: number = 0;
  dateMarkAsPaid: Date = null;
  charged: boolean = false;
  paymentType: PaymentType = null;
  idColor: number = 0;
  idMaterialType: number = 0;
  idFinish: number = 0;
  idArticleType: number = 0;
  sizeX: number = 0;
  sizeY: number = 0;
  sizeZ: number = 0;
  wishedDeliveryDate: Date = null;
  idShippingAddress: number = 0;
  idBillingAddress: number = 0;
  idClient: number = 0;
  idUserClient: number = 0;
  idProducer: number = 0;
  idFileClient: number = 0;
  idFileDegineo: number = 0;
  idFileProducer: number = 0;
  producerFileStatus: OrderFileStatus = null;
  quantity: number = 0;
  clientUnitPriceHT: number = 0;
  producerSelected: boolean = false;
  idCompanyPrinterConsumableOption: number = 0;
  finalProducerUnitPriceHT: number = 0;
  subcontractingUnitPriceHT: number = 0;
  margeDegineo: number = 0;
  averageMatterQuantityByCm3: number = 0;
  estimatedProductionTimeInMin: number = 0;
  volumeClutterCm3: number = 0;
  materialCost: number = 0;
  productionCost: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.entities.OrderNEW'] = OrderNEW;

reverseMapping[OrderNEW.name] = 'com.degineo.metier.order.entities.OrderNEW';

fields['com.degineo.metier.order.entities.OrderNEW']  = {
    id: 'java.lang.Integer',
    idBasketArticle: 'java.lang.Integer',
    idDevis: 'java.lang.Integer',
    status: 'com.degineo.metier.order.entities.OrderNEWStatus',
    name: 'java.lang.String',
    dateLastStatusChange: 'java.time.Instant',
    idUserLastModif: 'java.lang.Integer',
    dateCreated: 'java.time.Instant',
    dateChecked: 'java.time.Instant',
    idUserCheck: 'java.lang.Integer',
    dateProducerAccepted: 'java.time.Instant',
    idUserProducerAccepted: 'java.lang.Integer',
    dateStartPrinting: 'java.time.Instant',
    idUserStartPrinting: 'java.lang.Integer',
    dateShippingPreparation: 'java.time.Instant',
    idUserShippingPreparation: 'java.lang.Integer',
    datePackagePreparation: 'java.time.Instant',
    idUserPackagePreparation: 'java.lang.Integer',
    dateShippingProgress: 'java.time.Instant',
    idUserShippingProgress: 'java.lang.Integer',
    dateCarrierTransport: 'java.time.Instant',
    idUserCarrierTransport: 'java.lang.Integer',
    dateArticleReceived: 'java.time.Instant',
    idUserArticleReceived: 'java.lang.Integer',
    noteDegineo: 'java.lang.String',
    checked: 'java.lang.Boolean',
    delayed: 'java.lang.Boolean',
    paid: 'java.lang.Boolean',
    idUserMarkAsPaid: 'java.lang.Integer',
    dateMarkAsPaid: 'java.time.Instant',
    charged: 'java.lang.Boolean',
    paymentType: 'com.degineo.metier.invoice.entities.PaymentType',
    idColor: 'java.lang.Integer',
    idMaterialType: 'java.lang.Integer',
    idFinish: 'java.lang.Integer',
    idArticleType: 'java.lang.Integer',
    sizeX: 'java.lang.Double',
    sizeY: 'java.lang.Double',
    sizeZ: 'java.lang.Double',
    wishedDeliveryDate: 'java.time.Instant',
    idShippingAddress: 'java.lang.Integer',
    idBillingAddress: 'java.lang.Integer',
    idClient: 'java.lang.Integer',
    idUserClient: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    idFileClient: 'java.lang.Integer',
    idFileDegineo: 'java.lang.Integer',
    idFileProducer: 'java.lang.Integer',
    producerFileStatus: 'com.degineo.metier.order.entities.OrderFileStatus',
    quantity: 'java.lang.Integer',
    clientUnitPriceHT: 'java.lang.Double',
    producerSelected: 'java.lang.Boolean',
    idCompanyPrinterConsumableOption: 'java.lang.Integer',
    finalProducerUnitPriceHT: 'java.lang.Double',
    subcontractingUnitPriceHT: 'java.lang.Double',
    margeDegineo: 'java.lang.Double',
    averageMatterQuantityByCm3: 'java.lang.Double',
    estimatedProductionTimeInMin: 'java.lang.Double',
    volumeClutterCm3: 'java.lang.Double',
    materialCost: 'java.lang.Double',
    productionCost: 'java.lang.Double'
};
