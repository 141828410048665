// com.degineo.metier.invoice.service.InvoiceBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerLiteBODTO } from '../entities/ProducerLiteBODTO';
import { InvoiceRowPaimentBODTO } from '../entities/InvoiceRowPaimentBODTO';
import { InvoiceToDegineoListItemBODTO } from '../entities/InvoiceToDegineoListItemBODTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { PaymentType } from '../entities/PaymentType';
import { InvoiceToDegineoBODTO } from '../entities/InvoiceToDegineoBODTO';
import { InvoiceToProducerListItemBODTO } from '../entities/InvoiceToProducerListItemBODTO';
import { InvoiceBODTO } from '../entities/InvoiceBODTO';

@Injectable({
  providedIn: 'root',
})
export class InvoiceBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getClientOrProducerAssist(text: string): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.getClientOrProducerAssist';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(text, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getBills(filter: GenericFilter): RpcRequestBuilder<ListWithCount<InvoiceBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.getBills';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getBillToProducer(filter: GenericFilter): RpcRequestBuilder<ListWithCount<InvoiceToProducerListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.getBillToProducer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getBillToDegineo(filter: GenericFilter): RpcRequestBuilder<ListWithCount<InvoiceToDegineoListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.getBillToDegineo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getBillInfoToDegineo(filter: GenericFilter, invoiceId: number): RpcRequestBuilder<InvoiceToDegineoBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.getBillInfoToDegineo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(invoiceId, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.invoice.dto.bo.InvoiceToDegineoBODTO');
  }

  payInvoiceToDegineo(invoiceId: number, total: number, paymentType: PaymentType, paiments: InvoiceRowPaimentBODTO[]): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.payInvoiceToDegineo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(invoiceId, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(total, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(paymentType, 'com.degineo.metier.invoice.entities.PaymentType', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(paiments, 'java.util.List<com.degineo.metier.invoice.dto.bo.InvoiceRowPaimentBODTO>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  payInvoiceToProducer(invoiceId: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.payInvoiceToProducer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(invoiceId, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getProducersWaitingForPaymentFromDegineo(): RpcRequestBuilder<ProducerLiteBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.getProducersWaitingForPaymentFromDegineo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getProducersWhereDegineoWaitsForPayment(): RpcRequestBuilder<ProducerLiteBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.getProducersWhereDegineoWaitsForPayment';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  forceCreateDelayedInvoices(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InvoiceBOService.forceCreateDelayedInvoices';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

}
