<div class="bar d-flex justify-content-center" [class.top]="!showAtBottom" [class.bottom]="showAtBottom" *ngIf="showConsent">
  <div class="flex-grow-1 d-flex" style="max-width: 1140px">
    <div class="fs-2 text-dark flex-grow-1 mr-3">
      Ce site utilise des cookies pour faciliter votre navigation, ainsi que pour l'analyse. En continuant à naviguer sur ce site, vous acceptez cette
      utilisation.
    </div>
    <div class="fs-2 mr-3">
      <a routerLink="/cookies">En savoir plus</a>
    </div>
  </div>
  <div class="pointer d-flex px-2" (click)="hide()">
    <i class="fas fa-times text-secondary hover-dark align-self-center"></i>
  </div>
</div>
