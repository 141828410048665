// com.degineo.metier.metadata.service.BrandConsumableServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { PrinterTechnology } from '../entities/PrinterTechnology';

@Injectable({
  providedIn: 'root',
})
export class BrandConsumableService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getAllConsumableBrand(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BrandConsumableService.getAllConsumableBrand';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getAllConsumableBrandByPrinterTechnology(compatiblePrinterTechnology: PrinterTechnology): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BrandConsumableService.getAllConsumableBrandByPrinterTechnology';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(compatiblePrinterTechnology, 'com.degineo.metier.metadata.entities.PrinterTechnology', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
