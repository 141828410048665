// ENTITY com.degineo.metier.producer.dto.bo.ProducerPageListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';

export class ProducerPageListItemBODTO {

  id: number = 0;
  name: string = '';
  address: AddressNEW = null;
  logoToken: string = '';
  unsub: boolean = false;
  register: boolean = false;
  etat: boolean = false;
  nbVisitTotal: number = 0;
  nbVisitMonth: number = 0;
  nbClickTotal: number = 0;
  nbClickMonth: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.bo.ProducerPageListItemBODTO'] = ProducerPageListItemBODTO;

reverseMapping[ProducerPageListItemBODTO.name] = 'com.degineo.metier.producer.dto.bo.ProducerPageListItemBODTO';

fields['com.degineo.metier.producer.dto.bo.ProducerPageListItemBODTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    address: 'com.degineo.metier.address.entities.AddressNEW',
    logoToken: 'java.lang.String',
    unsub: 'java.lang.Boolean',
    register: 'java.lang.Boolean',
    etat: 'java.lang.Boolean',
    nbVisitTotal: 'java.lang.Integer',
    nbVisitMonth: 'java.lang.Integer',
    nbClickTotal: 'java.lang.Integer',
    nbClickMonth: 'java.lang.Integer'
};
