// ENTITY com.degineo.metier.metadata.dto.ConsumableOptionWithConsumableDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ConsumableOption } from './ConsumableOption';
import { ConsumableNEW } from './ConsumableNEW';

export class ConsumableOptionWithConsumableDTO {

  co: ConsumableOption = null;
  consumable: ConsumableNEW = null;
  colorName: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.dto.ConsumableOptionWithConsumableDTO'] = ConsumableOptionWithConsumableDTO;

reverseMapping[ConsumableOptionWithConsumableDTO.name] = 'com.degineo.metier.metadata.dto.ConsumableOptionWithConsumableDTO';

fields['com.degineo.metier.metadata.dto.ConsumableOptionWithConsumableDTO']  = {
    co: 'com.degineo.metier.metadata.entities.ConsumableOption',
    consumable: 'com.degineo.metier.metadata.entities.ConsumableNEW',
    colorName: 'java.lang.String'
};
