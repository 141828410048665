<input
  [disabled]="disabled"
  [required]="required"
  class="form-control"
  [type]="type"
  [(ngModel)]="value"
  [placeholder]="name"
  #text
  (input)="filterChanges($event)"
/>
<div class="input-group-append" *ngIf="text.value">
  <button type="button" class="btn btn-outline-secondary" (click)="clear()">
    <i class="fas fa-times"></i>
  </button>
</div>
