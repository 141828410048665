import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
  selector: '[srcOver]',
})
export class SrcOverDirective {
  @Input() srcOver: string;
  defaultSrc: string;
  constructor(private el: ElementRef) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.defaultSrc = this.el.nativeElement.src;
    this.el.nativeElement.src = this.srcOver;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.el.nativeElement.src = this.defaultSrc;
  }
}
