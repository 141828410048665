// ENTITY com.degineo.metier.producer_printer.dto.ConsumableOptionConsumableNameChoice generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ConsumableOptionColorChoiceDTO } from './ConsumableOptionColorChoiceDTO';

export class ConsumableOptionConsumableNameChoice {

  name: string = '';
  colors: ConsumableOptionColorChoiceDTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.ConsumableOptionConsumableNameChoice'] = ConsumableOptionConsumableNameChoice;

reverseMapping[ConsumableOptionConsumableNameChoice.name] = 'com.degineo.metier.producer_printer.dto.ConsumableOptionConsumableNameChoice';

fields['com.degineo.metier.producer_printer.dto.ConsumableOptionConsumableNameChoice']  = {
    name: 'java.lang.String',
    colors: 'java.util.List<com.degineo.metier.producer_printer.dto.ConsumableOptionColorChoiceDTO>'
};
