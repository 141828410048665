// com.degineo.metier.producer.service.ProducerSignupServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerSignupStep } from '../entities/ProducerSignupStep';
import { DegineoUserBundleV2 } from '../entities/DegineoUserBundleV2';
import { ProducerSignupStep8DTO } from '../entities/ProducerSignupStep8DTO';
import { ProducerSignupStep2DTO } from '../entities/ProducerSignupStep2DTO';
import { ProducerSignupStep9DTO } from '../entities/ProducerSignupStep9DTO';
import { ProducerSignupStep4DTO } from '../entities/ProducerSignupStep4DTO';
import { LegalStatus } from '../entities/LegalStatus';
import { ProducerSignupStep7DTO } from '../entities/ProducerSignupStep7DTO';
import { ProducerSignupStep5DTO } from '../entities/ProducerSignupStep5DTO';
import { PrinterBrandChoiceDTO } from '../entities/PrinterBrandChoiceDTO';
import { AddressNEW } from '../entities/AddressNEW';

@Injectable({
  providedIn: 'root',
})
export class ProducerSignupService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPrinterChoices(): RpcRequestBuilder<PrinterBrandChoiceDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getPrinterChoices';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getInfosStep2(token: string): RpcRequestBuilder<ProducerSignupStep2DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep2';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep2DTO');
  }

  saveStep2(token: string, firstName: string, lastName: string, job: string, phoneNumber: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep2';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(job, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(phoneNumber, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep3(token: string): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep3';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  saveStep3(token: string, siret: string, idPlan: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep3';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idPlan, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep4(token: string): RpcRequestBuilder<ProducerSignupStep4DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep4';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep4DTO');
  }

  saveStep4(token: string, name: string, legalStatus: LegalStatus, capital: number, RCS: string, RNANumber: string, billingAddress: AddressNEW): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep4';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(legalStatus, 'com.degineo.metier.producer.entities.LegalStatus', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(capital, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(RCS, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(RNANumber, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(billingAddress, 'com.degineo.metier.address.entities.AddressNEW', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep5(token: string): RpcRequestBuilder<ProducerSignupStep5DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep5';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep5DTO');
  }

  saveStep5(token: string, shippingAddress: AddressNEW): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep5';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(shippingAddress, 'com.degineo.metier.address.entities.AddressNEW', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep6(token: string): RpcRequestBuilder<number[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep6';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  saveStep6(token: string, idPrinters: number[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep6';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idPrinters, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep7(token: string): RpcRequestBuilder<ProducerSignupStep7DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep7';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep7DTO');
  }

  saveStep7(token: string, idPlan: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep7';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idPlan, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  createFakeToken(): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.createFakeToken';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  getStep(token: string): RpcRequestBuilder<ProducerSignupStep> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getStep';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.user.entities.ProducerSignupStep');
  }

  getInfosStep1(token: string): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep1';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  saveStep1(token: string, email: string, password: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep1';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep8(token: string): RpcRequestBuilder<ProducerSignupStep8DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep8';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep8DTO');
  }

  saveStep8(token: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep8';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep9(token: string): RpcRequestBuilder<ProducerSignupStep9DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep9';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSignupStep9DTO');
  }

  saveStep9(token: string, iban: string, bic: string, stripePaymentMethodId: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep9';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(iban, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(bic, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(stripePaymentMethodId, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInfosStep10(token: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.getInfosStep10';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveStep10(token: string): RpcRequestBuilder<DegineoUserBundleV2> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSignupService.saveStep10';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.tools.DegineoUserBundleV2');
  }

}
