// ENTITY com.degineo.metier.invoice.dto.bo.InvoiceToDegineoBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { InvoiceRowToDegineoListItemBODTO } from './InvoiceRowToDegineoListItemBODTO';

export class InvoiceToDegineoBODTO {

  invoiceId: number = 0;
  paid: boolean = false;
  reference: string = '';
  idProducer: number = 0;
  producerName: string = '';
  price: number = 0;
  toPaid: number = 0;
  rows: InvoiceRowToDegineoListItemBODTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.bo.InvoiceToDegineoBODTO'] = InvoiceToDegineoBODTO;

reverseMapping[InvoiceToDegineoBODTO.name] = 'com.degineo.metier.invoice.dto.bo.InvoiceToDegineoBODTO';

fields['com.degineo.metier.invoice.dto.bo.InvoiceToDegineoBODTO']  = {
    invoiceId: 'java.lang.Integer',
    paid: 'java.lang.Boolean',
    reference: 'java.lang.String',
    idProducer: 'java.lang.Integer',
    producerName: 'java.lang.String',
    price: 'java.lang.Double',
    toPaid: 'java.lang.Double',
    rows: 'java.util.List<com.degineo.metier.invoice.dto.bo.InvoiceRowToDegineoListItemBODTO>'
};
