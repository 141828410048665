// ENTITY com.degineo.metier.producer_printer.dto.ProducerPrinterDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PrinterNEW } from './PrinterNEW';
import { ProducerPrinter } from './ProducerPrinter';

export class ProducerPrinterDTO {

  printer: PrinterNEW = null;
  producerPrinter: ProducerPrinter = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.ProducerPrinterDTO'] = ProducerPrinterDTO;

reverseMapping[ProducerPrinterDTO.name] = 'com.degineo.metier.producer_printer.dto.ProducerPrinterDTO';

fields['com.degineo.metier.producer_printer.dto.ProducerPrinterDTO']  = {
    printer: 'com.degineo.metier.metadata.entities.PrinterNEW',
    producerPrinter: 'com.degineo.metier.producer_printer.entities.ProducerPrinter'
};
