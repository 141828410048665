// com.degineo.metier.user.service.ResetPwdServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class ResetPwdService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  forgotPassword(email: string, producerLink: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ResetPwdService.forgotPassword';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(producerLink, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  changePassword(token: string, password: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ResetPwdService.changePassword';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
