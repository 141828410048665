// ENTITY com.degineo.metier.producer.dto.dashboard.DashboardPrintersDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class DashboardPrintersDTO {

  nbPrinterUsed: number = 0;
  nbAvailable: number = 0;
  nbUnavailable: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.dashboard.DashboardPrintersDTO'] = DashboardPrintersDTO;

reverseMapping[DashboardPrintersDTO.name] = 'com.degineo.metier.producer.dto.dashboard.DashboardPrintersDTO';

fields['com.degineo.metier.producer.dto.dashboard.DashboardPrintersDTO']  = {
    nbPrinterUsed: 'java.lang.Integer',
    nbAvailable: 'java.lang.Integer',
    nbUnavailable: 'java.lang.Integer'
};
