import { ModelData } from 'degineo-common';

export class SVUploadedFile {
  file: File;
  name: string;
  fileHash: string;
  progress: number;
  modelData: ModelData;
  good: boolean;
}
