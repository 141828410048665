// ENTITY com.degineo.metier.invoice.dto.ClientInvoiceDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DelayedOrderDTO } from './DelayedOrderDTO';

export class ClientInvoiceDTO {

  idInvoice: number = 0;
  idProducer: number = 0;
  articles: DelayedOrderDTO[] = null;
  totalTTC: number = 0;
  VATAmount: number = 0;
  reference: string = '';
  dateMoisFacture: Date = null;
  ibanProducer: string = '';
  bicProducer: string = '';
  paidByClient: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.ClientInvoiceDTO'] = ClientInvoiceDTO;

reverseMapping[ClientInvoiceDTO.name] = 'com.degineo.metier.invoice.dto.ClientInvoiceDTO';

fields['com.degineo.metier.invoice.dto.ClientInvoiceDTO']  = {
    idInvoice: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    articles: 'java.util.List<com.degineo.metier.invoice.dto.DelayedOrderDTO>',
    totalTTC: 'java.lang.Double',
    VATAmount: 'java.lang.Double',
    reference: 'java.lang.String',
    dateMoisFacture: 'java.time.LocalDate',
    ibanProducer: 'java.lang.String',
    bicProducer: 'java.lang.String',
    paidByClient: 'java.lang.Boolean'
};
