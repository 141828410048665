// ENTITY com.degineo.metier.producer.dto.ProducerCompanyInfosDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { LegalStatus } from './LegalStatus';
import { AddressNEW } from './AddressNEW';

export class ProducerCompanyInfosDTO {

  name: string = '';
  denomination: string = '';
  legalStatus: LegalStatus = null;
  siret: string = '';
  capital: number = 0;
  RNANumber: string = '';
  RCS: string = '';
  useVAT: boolean = false;
  billingAddress: AddressNEW = null;
  collecteAddress: AddressNEW = null;
  iban: string = '';
  bic: string = '';
  stripeIbanLast4Digits: string = '';
  productionDelay: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerCompanyInfosDTO'] = ProducerCompanyInfosDTO;

reverseMapping[ProducerCompanyInfosDTO.name] = 'com.degineo.metier.producer.dto.ProducerCompanyInfosDTO';

fields['com.degineo.metier.producer.dto.ProducerCompanyInfosDTO']  = {
    name: 'java.lang.String',
    denomination: 'java.lang.String',
    legalStatus: 'com.degineo.metier.producer.entities.LegalStatus',
    siret: 'java.lang.String',
    capital: 'java.lang.Double',
    RNANumber: 'java.lang.String',
    RCS: 'java.lang.String',
    useVAT: 'java.lang.Boolean',
    billingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    collecteAddress: 'com.degineo.metier.address.entities.AddressNEW',
    iban: 'java.lang.String',
    bic: 'java.lang.String',
    stripeIbanLast4Digits: 'java.lang.String',
    productionDelay: 'java.lang.Integer'
};
