// ENTITY com.degineo.metier.invoice.dto.bo.InvoiceToDegineoListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class InvoiceToDegineoListItemBODTO {

  invoiceId: number = 0;
  date: Date = null;
  reference: string = '';
  idProducer: number = 0;
  producerName: string = '';
  price: number = 0;
  nbPaid: number = 0;
  nbToPay: number = 0;
  paid: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.bo.InvoiceToDegineoListItemBODTO'] = InvoiceToDegineoListItemBODTO;

reverseMapping[InvoiceToDegineoListItemBODTO.name] = 'com.degineo.metier.invoice.dto.bo.InvoiceToDegineoListItemBODTO';

fields['com.degineo.metier.invoice.dto.bo.InvoiceToDegineoListItemBODTO']  = {
    invoiceId: 'java.lang.Integer',
    date: 'java.time.Instant',
    reference: 'java.lang.String',
    idProducer: 'java.lang.Integer',
    producerName: 'java.lang.String',
    price: 'java.lang.Double',
    nbPaid: 'java.lang.Integer',
    nbToPay: 'java.lang.Integer',
    paid: 'java.lang.Boolean'
};
