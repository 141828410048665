// com.degineo.metier.devis.service.ClientDevisServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ClientDevisDTO } from '../entities/ClientDevisDTO';
import { ClientDevisListItemDTO } from '../entities/ClientDevisListItemDTO';

@Injectable({
  providedIn: 'root',
})
export class ClientDevisService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getListDevis(): RpcRequestBuilder<ClientDevisListItemDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientDevisService.getListDevis';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getDevis(idDevis: number): RpcRequestBuilder<ClientDevisDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientDevisService.getDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idDevis, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.devis.dto.ClientDevisDTO');
  }

  ajouteDevisAuPanier(idDevis: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientDevisService.ajouteDevisAuPanier';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idDevis, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  acceptDevis(idDevis: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientDevisService.acceptDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idDevis, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  modificationDevis(idDevis: number, reason: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientDevisService.modificationDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idDevis, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(reason, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  refuseDevis(idDevis: number, reason: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientDevisService.refuseDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idDevis, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(reason, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  hideDevis(idDevis: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientDevisService.hideDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idDevis, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
