<div class="container-small mt-5">
  <div class="row mt-5">
    <div class="col text-center">
      <i class="fas fa-unlock-alt text-primary" style="font-size:9rem"></i>
    </div>
  </div>
  <ng-container *ngIf="!confirm">
    <div class="row mt-5">
      <div class="col text-center">
        <span class="fw-700 fs-4">RÃ©initialisation du mot de passe de votre compte Degineo</span>
      </div>
    </div>
    <form (submit)="onSubmit()" [formGroup]="form" [class.show-errors]="submitted && !form.valid">
      <div class="row mt-5">
        <div class="col">
          <label>Nouveau mot de passe</label>
          <input type="password" formControlName="mdp1" class="form-control" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <label>Confirmez votre nouveau mot de passe</label>
          <input type="password" formControlName="mdp2" class="form-control" />
        </div>
      </div>
      <div class="row mt-4" *ngIf="error">
        <div class="col">
          <div class="alert alert-danger">
            {{ error }}
          </div>
        </div>
      </div>
      <div class="row mt-4" *ngIf="tokenInvalid">
        <div class="col">
          <div class="alert alert-danger">Votre lien est pÃ©rimÃ©. <a class="text-white underline" (click)="openResetPopup()">En avoir un nouveau</a></div>
        </div>
      </div>
      <div class="row mt-4" *ngIf="submitted && form.invalid">
        <div class="col">
          <div class="alert alert-danger">
            <ul>
              <li *ngIf="form.hasError('required', 'mdp1') || form.hasError('required', 'mdp2')">Tout les champs sont requis.</li>
              <li *ngIf="form.hasError('minlength', 'mdp1')">Le mot de passe doit comporter au moins 6 caractÃ¨res.</li>
              <li *ngIf="form.hasError('notSame') && (form.get('mdp2').value !== null || submitted)">
                La confirmation du nouveau mot de passe et le nouveau mot de passe doivent Ãªtre identiques.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <button type="submit" class="btn btn-success text-uppercase w-100">
            Valider mon nouveau mot de passe <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
          </button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="confirm">
    <div class="row mt-5">
      <div class="col text-center">
        <span class="fw-700 fs-4">
          FÃ©licitation !<br />
          Votre mot de passe a Ã©tÃ© rÃ©initialisÃ©.<br />
          <br />
          Vous pouvez maintenant utiliser ce nouveau mot de passe pour vous connecter Ã  votre espace Degineo.
        </span>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <a class="btn btn-success text-uppercase w-100" routerLink="/connexion">
          Se connecter Ã  mon espace degineo
        </a>
      </div>
    </div>
  </ng-container>
</div>
