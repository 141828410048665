// DB ENUM com.degineo.metier.plan.entities.InvoiceTypePlan generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class InvoiceTypePlan {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly MANUEL = new InvoiceTypePlan(1, "Manuel", "MANUEL");
  static readonly SEMIAUTO = new InvoiceTypePlan(2, "Semi-auto", "SEMIAUTO");
  static readonly AUTO = new InvoiceTypePlan(3, "Automatique", "AUTO");

  static readonly values = [
    InvoiceTypePlan.MANUEL, 
    InvoiceTypePlan.SEMIAUTO, 
    InvoiceTypePlan.AUTO 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of InvoiceTypePlan.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of InvoiceTypePlan.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.plan.entities.InvoiceTypePlan'] = InvoiceTypePlan;
reverseMapping[InvoiceTypePlan.name] = 'com.degineo.metier.plan.entities.InvoiceTypePlan';
