// ENTITY com.degineo.metier.producer.dto.ProducerSignupStep5DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';

export class ProducerSignupStep5DTO {

  billingAddress: AddressNEW = null;
  shippingAddress: AddressNEW = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerSignupStep5DTO'] = ProducerSignupStep5DTO;

reverseMapping[ProducerSignupStep5DTO.name] = 'com.degineo.metier.producer.dto.ProducerSignupStep5DTO';

fields['com.degineo.metier.producer.dto.ProducerSignupStep5DTO']  = {
    billingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    shippingAddress: 'com.degineo.metier.address.entities.AddressNEW'
};
