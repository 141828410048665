<div class="container-fluid">
  <div class="row">
    <ng-container *ngIf="customerFormService.fromForm">
      <div class="col-12 col-lg-3 pl-0 side-menu" style="box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1)">
        <div class="sticky-top mb-3" style="top: 80px">
          <div class="ml-2" *ngIf="!producerView">
            <a class="text-primary fs-4" style="cursor: pointer" (click)="back()"><i class="fas fa-long-arrow-alt-left pl-3 mr-2"></i>Retour</a>
          </div>
          <div class="ml-2" *ngIf="producerView">
            <a class="text-primary fs-4" style="cursor: pointer" (click)="producerView = false"
              ><i class="fas fa-long-arrow-alt-left pl-3 mr-2"></i>Précédent</a
            >
          </div>
          <app-customer-area-form class="directory mb-3" (searched)="concealProducer()"> </app-customer-area-form>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!producerView">
      <ng-container *ngIf="!customerFormService.fromForm">
        <div class="container-fluid bg-primary py-2rem">
          <div class="row justify-content-center w-100">
            <h3 class="col mb-3 text-white text-center">Trouver directement votre imprimeur 3D ici</h3>
          </div>
          <div class="row justify-content-center">
            <div class="col-10 col-md-8 col-lg-6 mb-3" style="max-width: 37rem">
              <app-customer-area-search class="col-12 px-0"></app-customer-area-search>
            </div>
          </div>
        </div>

        <div class="text-primary bg-white p-2 fs-4 ml-2 mt-3">
          <a (click)="back()" style="cursor: pointer"><i class="fas fa-long-arrow-alt-left pl-3 mr-2"></i>Retour</a>
        </div>
      </ng-container>
      <div class="col-12 col-lg-9">
        <p
          class="mx-3 pb-2 pl-4 my-4 fw-500 text-medium"
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
          [class.w-75]="!customerFormService.fromForm"
          [class.mx-auto]="!customerFormService.fromForm"
        >
          {{ customerFormService.count }} imprimeur{{ customerFormService.count > 1 ? 's' : '' }}
          {{
            customerFormService.fromForm
              ? customerFormService.count > 1
                ? 'peuvent imprimer votre demande'
                : 'peut imprimer votre demande'
              : customerFormService.count > 1
              ? 'trouvés'
              : 'trouvé'
          }}
        </p>

        <div class="container-fluid">
          <div class="d-flex flex-column align-items-center">
            <div
              class="col-lg-6 col-md-8 col-11 shadow-2 rounded-lg mb-4 p-3"
              style="min-height: 10rem; max-width: 70rem"
              *ngFor="let producer of customerFormService.producers; let i = index"
            >
              <div class="container-fluid">
                <div class="row">
                  <img
                    class="col-12 col-sm-5 col-md-3 align-self-left px-0 rounded"
                    style="object-fit: scale-down; height: calc(10rem - 2rem)"
                    img-preloader="{{ rpcHost }}companylogo/{{ producer.logoToken }}/logo.png?size=large"
                    alt="Logo entreprise"
                  />
                  <!--[onErrorImage]="'assets/image-profil-default.svg'"-->
                  <!--1ère colonne-->
                  <div
                    class="col-12 col-md-4 col-md d-flex flex-column text-left ml-0 ml-sm-5 mt-3 mt-sm-0 order-2 order-md-1 pl-md-0 pl-3"
                    [class.col-12]="!customerFormService.fromForm"
                    [class.text-sm-left]="!customerFormService.fromForm"
                  >
                    <h2 class="mb-1 fw-500" style="font-size: 1.5rem">{{ producer.name }}</h2>
                    <h3 class="mb-1 fs-3 fw-400">
                      <span *ngIf="producer.businessType">{{ producer.businessType }}</span>
                    </h3>
                    <h3 class="mb-4 text-light fs-3">{{ producer.city }}</h3>
                    <ng-container *ngIf="customerFormService.fromForm">
                      <a class="fw-500 text-primary pointer" [routerLink]="null" (click)="showProducer(producer.id, i)">+ Voir infos sur&nbsp;l'imprimeur</a>
                    </ng-container>
                    <ng-container *ngIf="!customerFormService.fromForm">
                      <a class="fw-500 text-primary" [routerLink]="slugify(producer)">+ Voir infos sur&nbsp;l'imprimeur</a>
                    </ng-container>
                  </div>
                  <!--2ème colonne-->
                  <div
                    *ngIf="customerFormService.fromForm == true && customerFormService.selectedColour[i].price != null"
                    class="col-12 col-sm-7 col-md d-flex flex-column text-left text-sm-right px-0 mb-2 order-1 order-md-2 pl-3 mr-3"
                  >
                    <a class="text-success fs-4 fw-500 mb-3 pointer" (click)="addPrint(customerFormService.selectedColour[i], producer.id)"
                      ><i class="fas fa-shopping-cart mr-2"></i>Passer&nbsp;commande</a
                    >
                    <div class="align-self-sm-end align-self-start position-relative">
                      <button
                        class="align-self-end d-flex align-items-center py-1 pl-3 rounded border-light bg-white btn-colour"
                        style="width: 11.25rem; border: 1px solid"
                        (click)="customerFormService.menuOpened[i] = !customerFormService.menuOpened[i]"
                        (focusout)="customerFormService.menuOpened[i] = false"
                      >
                        <div class="color-box d-inline-block mr-3" [style.backgroundColor]="'#' + customerFormService.selectedColour[i].hexCode"></div>
                        <span class="pr-1">{{ customerFormService.selectedColour[i].name }}</span>
                        <span class="position-absolute text-medium" style="right: 0.5rem"><i class="fas fa-chevron-down fa-xs"></i></span>
                      </button>
                    </div>

                    <div *ngIf="customerFormService.menuOpened[i]" class="position-relative align-self-end" style="width: 11.25rem">
                      <div class="menu position-absolute border border-medium bg-white" style="width: 11.25rem">
                        <ul>
                          <li class="d-flex align-items-center py-1 disabled">
                            <div class="ml-3">-- Choisir couleur --</div>
                          </li>
                          <li
                            class="text-left d-flex align-items-center py-1"
                            [class.active]="customerFormService.selectedColour[i].id === color.id"
                            *ngFor="let color of producer.colors"
                            (mousedown)="updateColour(color, i)"
                          >
                            <div class="color-box d-inline-block mx-3" [style.backgroundColor]="'#' + color.hexCode"></div>
                            <span>{{ color.name }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p class="m-0 fs-4 mt-3 fw-500">
                      Prix&nbsp;: {{ customerFormService.selectedColour[i].price | number: '1.2-2' }}&nbsp;€&nbsp;<span class="fs-3">HT</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-8 col-md-10 col-11 shadow-2 rounded-lg mt-md-2 mt-3 p-3 mb-4"
              style="min-height: 10rem; max-width: 70rem"
              *ngIf="customerFormService.producers.length == 0 && customerFormService.nameSearch"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-8 col-12 d-flex flex-column">
                    <h3 class="text-left mb-3 mt-4">Vous êtes imprimeur&nbsp;3D et vous n'apparaissez pas dans cette recherche&nbsp;?</h3>
                    <p class="mb-0">
                      Prenez rendez-vous afin de nous contacter et obtenir gratuitement le référencement de votre page sur la plateforme Degineo.
                    </p>
                  </div>
                  <div class="col-md-4 col-12 align-self-center text-center">
                    <app-calendly [inputClass]="'btn btn-success mt-5 mb-5'"></app-calendly>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="customerFormService.count > nbPerPage" class="row justify-content-center">
            <ic2-pagination
              class="mt-3 mb-5"
              [routerLinkBase]="['/annuaire/']"
              [collectionSize]="customerFormService.count"
              [(page)]="pageForComp"
              [pageSize]="nbPerPage"
              [maxSize]="5"
              [rotate]="true"
              [disabled]="loading"
            >
            </ic2-pagination>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="producerView" class="col justify-content-center px-0">
      <lib-producer-display
        [editMode]="false"
        [rpc]="rpcHost"
        [idProducer]="idProducer"
        [colors]="colorsProd"
        [selectedColor]="selectedColorProd"
        [svUrl]="svUrl"
        [clientUrl]="clientUrl"
        [producerUrl]="producerUrl"
        (order)="addPrint($event, idProducer, selectedNameProd)"
      >
      </lib-producer-display>
    </div>
  </div>
</div>
