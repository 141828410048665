// ENTITY com.degineo.metier.invoice.dto.bo.InvoiceRowToDegineoListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class InvoiceRowToDegineoListItemBODTO {

  id: number = 0;
  idOrder: number = 0;
  date: Date = null;
  idUser: number = 0;
  idClient: number = 0;
  companyName: string = '';
  userName: string = '';
  price: number = 0;
  toPaid: number = 0;
  paid: boolean = false;
  datePaid: Date = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.bo.InvoiceRowToDegineoListItemBODTO'] = InvoiceRowToDegineoListItemBODTO;

reverseMapping[InvoiceRowToDegineoListItemBODTO.name] = 'com.degineo.metier.invoice.dto.bo.InvoiceRowToDegineoListItemBODTO';

fields['com.degineo.metier.invoice.dto.bo.InvoiceRowToDegineoListItemBODTO']  = {
    id: 'java.lang.Integer',
    idOrder: 'java.lang.Integer',
    date: 'java.time.Instant',
    idUser: 'java.lang.Integer',
    idClient: 'java.lang.Integer',
    companyName: 'java.lang.String',
    userName: 'java.lang.String',
    price: 'java.lang.Double',
    toPaid: 'java.lang.Double',
    paid: 'java.lang.Boolean',
    datePaid: 'java.time.Instant'
};
