// ENTITY com.degineo.metier.metadata.dto.PrinterAssociatedConsumableOptionBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class PrinterAssociatedConsumableOptionBODTO {

  idPrinter: number = 0;
  idConsumableOption: number = 0;
  idConsumable: number = 0;
  consumableName: string = '';
  brand: string = '';
  materialTypeId: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.dto.PrinterAssociatedConsumableOptionBODTO'] = PrinterAssociatedConsumableOptionBODTO;

reverseMapping[PrinterAssociatedConsumableOptionBODTO.name] = 'com.degineo.metier.metadata.dto.PrinterAssociatedConsumableOptionBODTO';

fields['com.degineo.metier.metadata.dto.PrinterAssociatedConsumableOptionBODTO']  = {
    idPrinter: 'java.lang.Integer',
    idConsumableOption: 'java.lang.Integer',
    idConsumable: 'java.lang.Integer',
    consumableName: 'java.lang.String',
    brand: 'java.lang.String',
    materialTypeId: 'java.lang.Integer'
};
