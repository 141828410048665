// com.degineo.metier.metadata.service.CharacteristicServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { CharacteristicNEW } from '../entities/CharacteristicNEW';

@Injectable({
  providedIn: 'root',
})
export class CharacteristicService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getAllCharacteristics(): RpcRequestBuilder<CharacteristicNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CharacteristicService.getAllCharacteristics';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
