// com.degineo.metier.user.service.ClientServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  changeLogo(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientService.changeLogo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateUserInfos(firstName: string, lastName: string, companyName: string, phoneNumber: string, email: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientService.updateUserInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(companyName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(phoneNumber, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  changeEmail(email: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientService.changeEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  resendValidationEmail(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientService.resendValidationEmail';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
