// ENTITY com.degineo.metier.order.dto.kanban.ReceivedOrderDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ReceivedOrderDTO {

  idProposalProducer: number = 0;
  idOrder: number = 0;
  exclusive: boolean = false;
  clientChoosedThisProducer: boolean = false;
  subcontractingTotalPriceTTC: number = 0;
  clientName: string = '';
  wishedDeliveryDate: Date = null;
  dateCreated: Date = null;
  printerName: string = '';
  printerBrand: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.ReceivedOrderDTO'] = ReceivedOrderDTO;

reverseMapping[ReceivedOrderDTO.name] = 'com.degineo.metier.order.dto.kanban.ReceivedOrderDTO';

fields['com.degineo.metier.order.dto.kanban.ReceivedOrderDTO']  = {
    idProposalProducer: 'java.lang.Integer',
    idOrder: 'java.lang.Integer',
    exclusive: 'java.lang.Boolean',
    clientChoosedThisProducer: 'java.lang.Boolean',
    subcontractingTotalPriceTTC: 'java.lang.Double',
    clientName: 'java.lang.String',
    wishedDeliveryDate: 'java.time.Instant',
    dateCreated: 'java.time.Instant',
    printerName: 'java.lang.String',
    printerBrand: 'java.lang.String'
};
