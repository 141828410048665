// com.degineo.metier.metadata.service.ColorServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ColorNEW } from '../entities/ColorNEW';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getAllColors(): RpcRequestBuilder<ColorNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ColorService.getAllColors';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
