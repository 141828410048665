// com.degineo.metier.order.service.kanban.WaitingCarrierShipmentServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { WaitingCarrierShipmentPanelDTO } from '../entities/WaitingCarrierShipmentPanelDTO';

@Injectable({
  providedIn: 'root',
})
export class WaitingCarrierShipmentService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  cancel(idShipment: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'WaitingCarrierShipmentService.cancel';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  transportOk(idShipment: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'WaitingCarrierShipmentService.transportOk';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getShipment(idShipment: number): RpcRequestBuilder<WaitingCarrierShipmentPanelDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'WaitingCarrierShipmentService.getShipment';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.kanban.panel.WaitingCarrierShipmentPanelDTO');
  }

}
