// DB ENUM com.degineo.metier.client.entities.BusinessTargetLevel1 generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class BusinessTargetLevel1 {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly AGRICULTURE = new BusinessTargetLevel1(1, "Agriculture", "AGRICULTURE");
  static readonly ARCHITECT = new BusinessTargetLevel1(2, "Architecte", "ARCHITECT");
  static readonly CONSTRUCTION = new BusinessTargetLevel1(3, "Construction", "CONSTRUCTION");
  static readonly BANQUE_ASSURANCE = new BusinessTargetLevel1(4, "Banque/Assurance", "BANQUE_ASSURANCE");
  static readonly COM_EDIT_MULTIMEDIA = new BusinessTargetLevel1(5, "Communication/Edition/MultimÃ©dia", "COM_EDIT_MULTIMEDIA");
  static readonly MANUFACTURING_INDUSTRY = new BusinessTargetLevel1(6, "Industrie manufacturiÃ¨re", "MANUFACTURING_INDUSTRY");
  static readonly BUSINESS_CONSULTING = new BusinessTargetLevel1(7, "Conseils/Service aux entreprises", "BUSINESS_CONSULTING");
  static readonly BUSINESS_TOURISM_CATERING = new BusinessTargetLevel1(8, "Commerce, Tourisme, Restauration", "BUSINESS_TOURISM_CATERING");
  static readonly EDUCATION = new BusinessTargetLevel1(9, "Enseignement", "EDUCATION");
  static readonly HEALTH = new BusinessTargetLevel1(10, "SantÃ©", "HEALTH");

  static readonly values = [
    BusinessTargetLevel1.AGRICULTURE, 
    BusinessTargetLevel1.ARCHITECT, 
    BusinessTargetLevel1.CONSTRUCTION, 
    BusinessTargetLevel1.BANQUE_ASSURANCE, 
    BusinessTargetLevel1.COM_EDIT_MULTIMEDIA, 
    BusinessTargetLevel1.MANUFACTURING_INDUSTRY, 
    BusinessTargetLevel1.BUSINESS_CONSULTING, 
    BusinessTargetLevel1.BUSINESS_TOURISM_CATERING, 
    BusinessTargetLevel1.EDUCATION, 
    BusinessTargetLevel1.HEALTH 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of BusinessTargetLevel1.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of BusinessTargetLevel1.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.client.entities.BusinessTargetLevel1'] = BusinessTargetLevel1;
reverseMapping[BusinessTargetLevel1.name] = 'com.degineo.metier.client.entities.BusinessTargetLevel1';
