// DB ENUM com.degineo.metier.metadata.entities.PrinterTechnology generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class PrinterTechnology {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly FDM = new PrinterTechnology(1, "FDM Â· DÃ©pÃ´t de Fil", "FDM");
  static readonly SLS = new PrinterTechnology(2, "SLS Â· Frittage de poudre", "SLS");
  static readonly SLA = new PrinterTechnology(3, "SLA Â· StÃ©rÃ©olitographie", "SLA");
  static readonly FTI = new PrinterTechnology(4, "FTI Â· Film Transfer Imaging", "FTI");
  static readonly MJP = new PrinterTechnology(5, "MJP Â· Impression Multijet", "MJP");
  static readonly CJP = new PrinterTechnology(6, "CJP Â· Impression ColorJet", "CJP");
  static readonly DMLS_SLM = new PrinterTechnology(7, "DMLS/SLM Â· Impression Fusion MÃ©tal", "DMLS_SLM");
  static readonly DMP = new PrinterTechnology(8, "DMP Â· Impression MÃ©tal Direct", "DMP");
  static readonly EBAM = new PrinterTechnology(9, "EBAM Â· Impression MÃ©tal dÃ©pÃ´t d'Ã©lectrons", "EBAM");
  static readonly CNC = new PrinterTechnology(10, "CNC Â· Usinage", "CNC");

  static readonly values = [
    PrinterTechnology.FDM, 
    PrinterTechnology.SLS, 
    PrinterTechnology.SLA, 
    PrinterTechnology.FTI, 
    PrinterTechnology.MJP, 
    PrinterTechnology.CJP, 
    PrinterTechnology.DMLS_SLM, 
    PrinterTechnology.DMP, 
    PrinterTechnology.EBAM, 
    PrinterTechnology.CNC 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of PrinterTechnology.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of PrinterTechnology.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.metadata.entities.PrinterTechnology'] = PrinterTechnology;
reverseMapping[PrinterTechnology.name] = 'com.degineo.metier.metadata.entities.PrinterTechnology';
