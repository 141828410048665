// ENTITY com.degineo.metier.devis.dto.producer.ProducerDevisDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DevisNEW } from './DevisNEW';

export class ProducerDevisDTO {

  devis: DevisNEW = null;
  clientLastName: string = '';
  clientFirstName: string = '';
  clientPhoneNumber: string = '';
  materialTypeName: string = '';
  colorName: string = '';
  finishName: string = '';
  token3DModel: string = '';
  filename: string = '';
  articleTypeName: string = '';
  advisedPrinterName: string = '';
  advisedConsumableName: string = '';
  idAdvisedCompanyPrinter: number = 0;
  idSelectedCompanyPrinter: number = 0;
  tokenPreview: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.devis.dto.producer.ProducerDevisDTO'] = ProducerDevisDTO;

reverseMapping[ProducerDevisDTO.name] = 'com.degineo.metier.devis.dto.producer.ProducerDevisDTO';

fields['com.degineo.metier.devis.dto.producer.ProducerDevisDTO']  = {
    devis: 'com.degineo.metier.devis.entities.DevisNEW',
    clientLastName: 'java.lang.String',
    clientFirstName: 'java.lang.String',
    clientPhoneNumber: 'java.lang.String',
    materialTypeName: 'java.lang.String',
    colorName: 'java.lang.String',
    finishName: 'java.lang.String',
    token3DModel: 'java.lang.String',
    filename: 'java.lang.String',
    articleTypeName: 'java.lang.String',
    advisedPrinterName: 'java.lang.String',
    advisedConsumableName: 'java.lang.String',
    idAdvisedCompanyPrinter: 'java.lang.Integer',
    idSelectedCompanyPrinter: 'java.lang.Integer',
    tokenPreview: 'java.lang.String'
};
