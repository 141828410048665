// ENTITY com.degineo.metier.invoice.dto.bo.InvoiceRowPaimentBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class InvoiceRowPaimentBODTO {

  invoiceRowId: number = 0;
  paiment: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.bo.InvoiceRowPaimentBODTO'] = InvoiceRowPaimentBODTO;

reverseMapping[InvoiceRowPaimentBODTO.name] = 'com.degineo.metier.invoice.dto.bo.InvoiceRowPaimentBODTO';

fields['com.degineo.metier.invoice.dto.bo.InvoiceRowPaimentBODTO']  = {
    invoiceRowId: 'java.lang.Integer',
    paiment: 'java.lang.Double'
};
