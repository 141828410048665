// ENTITY com.degineo.metier.client.dto.ClientBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { BusinessTargetLevel2 } from './BusinessTargetLevel2';
import { AddressNEW } from './AddressNEW';
import { ClientAssociatedProducerBODTO } from './ClientAssociatedProducerBODTO';

export class ClientBODTO {

  id: number = 0;
  companyName: string = '';
  firstName: string = '';
  lastName: string = '';
  target: BusinessTargetLevel2 = null;
  invited: boolean = false;
  inactived: boolean = false;
  dateCreacted: Date = null;
  dateLastConnexion: Date = null;
  adress: AddressNEW = null;
  phoneNumber: string = '';
  email: string = '';
  assocProducer: ClientAssociatedProducerBODTO[] = null;
  degineoComment: string = '';
  siret: string = '';
  autologToken: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.client.dto.ClientBODTO'] = ClientBODTO;

reverseMapping[ClientBODTO.name] = 'com.degineo.metier.client.dto.ClientBODTO';

fields['com.degineo.metier.client.dto.ClientBODTO']  = {
    id: 'java.lang.Integer',
    companyName: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    target: 'com.degineo.metier.client.entities.BusinessTargetLevel2',
    invited: 'java.lang.Boolean',
    inactived: 'java.lang.Boolean',
    dateCreacted: 'java.time.Instant',
    dateLastConnexion: 'java.time.Instant',
    adress: 'com.degineo.metier.address.entities.AddressNEW',
    phoneNumber: 'java.lang.String',
    email: 'java.lang.String',
    assocProducer: 'java.util.List<com.degineo.metier.client.dto.ClientAssociatedProducerBODTO>',
    degineoComment: 'java.lang.String',
    siret: 'java.lang.String',
    autologToken: 'java.lang.String'
};
