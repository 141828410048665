<div class="container" style="max-width: 800px;">
  <div class="row justify-content-center">
    <h1 class="col-12 text-center text-primary mb-5 mt-5">
      Mentions légales
    </h1>
    <div class="col-12">
      <h3>Éditeur&nbsp;:</h3>
      <p class="mt-2 mb-5">
        Les services internet Degineo sont édités et exploités par Degineo, SAS au capital de 10 000 euros, immatriculé au RCS de Châlons-en-Champagne sous le
        numéro 822&nbsp;096&nbsp;616 où le siège social est situé au 1&nbsp;place de la Comédie, 51&nbsp;000 Châlons‑en‑Champagne et dont le numéro de TVA
        intracommunautaire est FR&nbsp;64&nbsp;822&nbsp;096&nbsp;616.
      </p>
    </div>
    <div class="col-12">
      <h3>Vous pouvez nous joindre&nbsp;:</h3>
      <p class="mt-2 mb-5">
        En écrivant à l’adresse&nbsp;: <br />
        Degineo, 1&nbsp;place de la Comédie, 51&nbsp;000 Châlons‑en‑Champagne <br />
        Par téléphone au&nbsp;: +33&nbsp;1&nbsp;84&nbsp;25&nbsp;76&nbsp;34
      </p>
    </div>
    <div class="col-12">
      <h3>Directeur de la publication&nbsp;:</h3>
      <p class="mt-2 mb-5">Le site a pour directeur de la publication Monsieur Karim Keddache en sa qualité de président de la société Degineo.</p>
    </div>
    <div class="col-12">
      <h3>Hébergeur&nbsp;:</h3>
      <p class="mt-2 mb-5">
        Le site est hébergé par la société OVH SAS, dont le siège social est situé au 2&nbsp;rue Kellermann, 59&nbsp;053 Roubaix. Vous pouvez contacter notre
        hébergeur au +33&nbsp;9&nbsp;72&nbsp;10&nbsp;01&nbsp;11.
      </p>
    </div>
  </div>
</div>
