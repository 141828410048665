// ENTITY com.degineo.metier.producer.dto.dashboard.DashboardFinancialStatsDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class DashboardFinancialStatsDTO {

  date: Date = null;
  chiffreAffaire: number = 0;
  coutRevient: number = 0;
  marge: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.dashboard.DashboardFinancialStatsDTO'] = DashboardFinancialStatsDTO;

reverseMapping[DashboardFinancialStatsDTO.name] = 'com.degineo.metier.producer.dto.dashboard.DashboardFinancialStatsDTO';

fields['com.degineo.metier.producer.dto.dashboard.DashboardFinancialStatsDTO']  = {
    date: 'java.time.Instant',
    chiffreAffaire: 'java.lang.Double',
    coutRevient: 'java.lang.Double',
    marge: 'java.lang.Double'
};
