// DB ENUM com.degineo.metier.order.entities.OrderFileStatus generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class OrderFileStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly FILE_TO_CHECK = new OrderFileStatus(1, "Fichier 3D Ã  vÃ©rifier", "FILE_TO_CHECK");
  static readonly FILE_TO_CORRECT = new OrderFileStatus(2, "Fichier 3D Ã  corriger", "FILE_TO_CORRECT");
  static readonly FILE_CORRECTED = new OrderFileStatus(3, "Fichier 3D corrigÃ©", "FILE_CORRECTED");
  static readonly FILE_OK = new OrderFileStatus(4, "Fichier 3D ok", "FILE_OK");

  static readonly values = [
    OrderFileStatus.FILE_TO_CHECK, 
    OrderFileStatus.FILE_TO_CORRECT, 
    OrderFileStatus.FILE_CORRECTED, 
    OrderFileStatus.FILE_OK 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of OrderFileStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of OrderFileStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.order.entities.OrderFileStatus'] = OrderFileStatus;
reverseMapping[OrderFileStatus.name] = 'com.degineo.metier.order.entities.OrderFileStatus';
