// ENTITY com.degineo.metier.client.dto.LastPrintConfigDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class LastPrintConfigDTO {

  articleTypeName: string = '';
  producerSelected: boolean = false;
  idProducer: number = 0;
  idArticleType: number = 0;
  idColor: number = 0;
  idMaterialType: number = 0;
  idFinish: number = 0;
  fileHash: string = '';
  fileName: string = '';
  name: string = '';
  comment: string = '';
  orderSizeXmm: number = 0;
  orderSizeYmm: number = 0;
  orderSizeZmm: number = 0;
  fileSizeXmm: number = 0;
  fileSizeYmm: number = 0;
  fileSizeZmm: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.client.dto.LastPrintConfigDTO'] = LastPrintConfigDTO;

reverseMapping[LastPrintConfigDTO.name] = 'com.degineo.metier.client.dto.LastPrintConfigDTO';

fields['com.degineo.metier.client.dto.LastPrintConfigDTO']  = {
    articleTypeName: 'java.lang.String',
    producerSelected: 'java.lang.Boolean',
    idProducer: 'java.lang.Integer',
    idArticleType: 'java.lang.Integer',
    idColor: 'java.lang.Integer',
    idMaterialType: 'java.lang.Integer',
    idFinish: 'java.lang.Integer',
    fileHash: 'java.lang.String',
    fileName: 'java.lang.String',
    name: 'java.lang.String',
    comment: 'java.lang.String',
    orderSizeXmm: 'java.lang.Double',
    orderSizeYmm: 'java.lang.Double',
    orderSizeZmm: 'java.lang.Double',
    fileSizeXmm: 'java.lang.Double',
    fileSizeYmm: 'java.lang.Double',
    fileSizeZmm: 'java.lang.Double'
};
