// ENTITY com.degineo.metier.consumable.dto.bo.PrinterListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ProducerIntendedType } from './ProducerIntendedType';
import { PrinterTechnology } from './PrinterTechnology';

export class PrinterListItemBODTO {

  id: number = 0;
  brand: string = '';
  name: string = '';
  printerTechnology: PrinterTechnology = null;
  price: number = 0;
  nbCompanyLinked: number = 0;
  nbAvailable: number = 0;
  category: ProducerIntendedType = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.consumable.dto.bo.PrinterListItemBODTO'] = PrinterListItemBODTO;

reverseMapping[PrinterListItemBODTO.name] = 'com.degineo.metier.consumable.dto.bo.PrinterListItemBODTO';

fields['com.degineo.metier.consumable.dto.bo.PrinterListItemBODTO']  = {
    id: 'java.lang.Integer',
    brand: 'java.lang.String',
    name: 'java.lang.String',
    printerTechnology: 'com.degineo.metier.metadata.entities.PrinterTechnology',
    price: 'java.lang.Double',
    nbCompanyLinked: 'java.lang.Integer',
    nbAvailable: 'java.lang.Integer',
    category: 'com.degineo.metier.metadata.entities.ProducerIntendedType'
};
