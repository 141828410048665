// ENTITY com.degineo.metier.vitrine.dto.ProducerEmployeeSVDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerEmployeeSVDTO {

  id: number = 0;
  job: string = '';
  firstName: string = '';
  lastName: string = '';
  logoToken: string = '';
  logoExtension: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.vitrine.dto.ProducerEmployeeSVDTO'] = ProducerEmployeeSVDTO;

reverseMapping[ProducerEmployeeSVDTO.name] = 'com.degineo.metier.vitrine.dto.ProducerEmployeeSVDTO';

fields['com.degineo.metier.vitrine.dto.ProducerEmployeeSVDTO']  = {
    id: 'java.lang.Integer',
    job: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    logoToken: 'java.lang.String',
    logoExtension: 'java.lang.String'
};
