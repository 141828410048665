import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DegineoRight } from '../ic2/entities/DegineoRight';
import { Observable } from 'rxjs';

@Injectable()
export class AdminAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {
    return Observable.create((observer) => {
      this.authService.isLoggedIn().subscribe(
        (data) => {
          let loggedIn = data && this.authService.has(DegineoRight.ADMIN);
          observer.next(loggedIn);
          //console.log('login',loggedIn);
          if (!loggedIn) {
            // Store the attempted URL for redirecting
            this.authService.redirectUrl = url;

            // Navigate to the login page with extras
            this.router.navigate(['/connexion']);
          }
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
