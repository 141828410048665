// ENTITY com.degineo.metier.consumable.dto.bo.ConsumableListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PrinterTechnology } from './PrinterTechnology';

export class ConsumableListItemBODTO {

  id: number = 0;
  brand: string = '';
  name: string = '';
  color: string = '';
  nbPrinterLinked: number = 0;
  nbProducerLinked: number = 0;
  hasAnyProducerPrinterConsumableEnabled: boolean = false;
  printerTechnology: PrinterTechnology = null;
  materialTypeName: string = '';
  materialTypeId: number = 0;
  avgPrice: number = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.consumable.dto.bo.ConsumableListItemBODTO'] = ConsumableListItemBODTO;

reverseMapping[ConsumableListItemBODTO.name] = 'com.degineo.metier.consumable.dto.bo.ConsumableListItemBODTO';

fields['com.degineo.metier.consumable.dto.bo.ConsumableListItemBODTO']  = {
    id: 'java.lang.Integer',
    brand: 'java.lang.String',
    name: 'java.lang.String',
    color: 'java.lang.String',
    nbPrinterLinked: 'java.lang.Integer',
    nbProducerLinked: 'java.lang.Integer',
    hasAnyProducerPrinterConsumableEnabled: 'java.lang.Boolean',
    printerTechnology: 'com.degineo.metier.metadata.entities.PrinterTechnology',
    materialTypeName: 'java.lang.String',
    materialTypeId: 'java.lang.Integer',
    avgPrice: 'java.lang.Float'
};
