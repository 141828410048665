// com.degineo.metier.plan.service.ProducerPlanServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { LegalStatus } from '../entities/LegalStatus';
import { EvolutionPlanInfoDTO } from '../entities/EvolutionPlanInfoDTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerPlanService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  canAddPrinter(): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPlanService.canAddPrinter';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getLegalStatus(): RpcRequestBuilder<LegalStatus> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPlanService.getLegalStatus';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.entities.LegalStatus');
  }

  canAddUser(): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPlanService.canAddUser';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getInfosPlan(): RpcRequestBuilder<EvolutionPlanInfoDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPlanService.getInfosPlan';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.plan.dto.EvolutionPlanInfoDTO');
  }

  changePlanTo(idPlan: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPlanService.changePlanTo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPlan, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getNbPrinter(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPlanService.getNbPrinter';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

}
