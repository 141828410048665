// ENTITY com.degineo.metier.order.dto.kanban.panel.waitingcarrier.ShipmentWaybillDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ShipmentWaybillDTO {

  id: number = 0;
  pdfName: string = '';
  token: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.waitingcarrier.ShipmentWaybillDTO'] = ShipmentWaybillDTO;

reverseMapping[ShipmentWaybillDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.waitingcarrier.ShipmentWaybillDTO';

fields['com.degineo.metier.order.dto.kanban.panel.waitingcarrier.ShipmentWaybillDTO']  = {
    id: 'java.lang.Integer',
    pdfName: 'java.lang.String',
    token: 'java.lang.String'
};
