import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, args: any): string {
    if (!args) {
      return value;
    }
    args = args.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    let testValue = value.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    
    args = args.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    var re = new RegExp(args, 'gi');

    let match = re.exec(testValue);
    if(match)
      return value.slice(0,match.index)+'<mark>'+value.slice(match.index,re.lastIndex)+'</mark>'+value.slice(re.lastIndex);
    else
      return value;
  }
}
