import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit {
  @Input()
  icon = 'fa-cog';
  @Input()
  color = '#06c';
  @Input()
  iconBgColor = '#06c';
  @Input()
  panelTitle: string = null;

  openValue: boolean = false;
  @Output()
  openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private intercom: Intercom) {}

  ngOnInit() {}

  @Input()
  get open() {
    return this.openValue;
  }

  set open(val) {
    this.openValue = val;
    this.openChange.emit(this.openValue);
    if (val) this.intercom.update({ horizontal_padding: '672' });
    //672 = 42rem, car panel fait 40rem
    else this.intercom.update({ horizontal_padding: '20' }); //reset
  }

  get classes() {
    const cssClasses = {
      fa: true,
    };
    cssClasses[this.icon] = true;
    return cssClasses;
  }

  get sideStyle() {
    const style = {
      'border-left': '5px solid ' + this.color,
    };

    return style;
  }
  get sideIconStyle() {
    const style = {
      'background-color': this.iconBgColor,
    };

    return style;
  }
}
