// ENTITY com.degineo.metier.producer.dto.bo.ProducerLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerLiteBODTO {

  id: number = 0;
  name: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.bo.ProducerLiteBODTO'] = ProducerLiteBODTO;

reverseMapping[ProducerLiteBODTO.name] = 'com.degineo.metier.producer.dto.bo.ProducerLiteBODTO';

fields['com.degineo.metier.producer.dto.bo.ProducerLiteBODTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String'
};
