// DB ENUM com.degineo.metier.producer.entities.BusinessType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class BusinessType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly SOCIETE_IMPRESSION_3D = new BusinessType(1, "SociÃ©tÃ© d'impression 3D", "SOCIETE_IMPRESSION_3D");
  static readonly CONSEIL_ET_IMPRESSION_3D = new BusinessType(2, "Conseil & Impression 3D", "CONSEIL_ET_IMPRESSION_3D");
  static readonly ENTREPRISE_INDUSTRIELLE = new BusinessType(3, "Entreprise Industrielle", "ENTREPRISE_INDUSTRIELLE");
  static readonly CENTRE_DE_RECHERCHE = new BusinessType(4, "Centre de recherche", "CENTRE_DE_RECHERCHE");
  static readonly ENSEIGNEMENT = new BusinessType(5, "Enseignement", "ENSEIGNEMENT");
  static readonly FABLAB = new BusinessType(6, "FabLab", "FABLAB");

  static readonly values = [
    BusinessType.SOCIETE_IMPRESSION_3D, 
    BusinessType.CONSEIL_ET_IMPRESSION_3D, 
    BusinessType.ENTREPRISE_INDUSTRIELLE, 
    BusinessType.CENTRE_DE_RECHERCHE, 
    BusinessType.ENSEIGNEMENT, 
    BusinessType.FABLAB 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of BusinessType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of BusinessType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.producer.entities.BusinessType'] = BusinessType;
reverseMapping[BusinessType.name] = 'com.degineo.metier.producer.entities.BusinessType';
