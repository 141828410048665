import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'degineo-my-badge',
  templateUrl: './my-badge.component.html',
  styleUrls: ['./my-badge.component.scss'],
})
export class MyBadgeComponent implements OnInit {
  constructor() {}

  @Input()
  bgColor: string = 'primary';
  @Input()
  textColor: string = '#fff';
  @Input()
  size: number = 25;
  @Input()
  textSize: number = 15;

  private isBgHexa: boolean;
  private isTextHexa: boolean;

  ngOnInit() {
    if (this.bgColor.charAt(0) == '#') this.isBgHexa = true;
    else this.isBgHexa = false;

    if (this.textColor.charAt(0) == '#') this.isTextHexa = true;
    else this.isTextHexa = false;
  }

  getStyle() {
    let color = null;
    if (this.isBgHexa) color = this.bgColor;
    return { height: this.size + 'px', width: this.size + 'px', 'background-color': color };
  }
  getClass() {
    if (!this.isBgHexa) return 'bg-' + this.bgColor;
  }

  getTextStyle() {
    let color = null;
    if (this.isTextHexa) color = this.textColor;
    return { 'line-height': this.size + 'px', 'font-size': this.textSize + 'px', color: color };
  }

  getTextClass() {
    if (!this.isTextHexa) return 'text-' + this.textColor;
  }
}
