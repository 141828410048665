<header>
  <nav
    class="navbar navbar-expand-md navbar-light bg-white fixed-top justify-content-end p-0"
    style="box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); min-height: 3.75rem"
    (window:resize)="onResize($event)"
  >
    <a class="navbar-brand ml-3 align-items-center position-relative" style="min-height: 3.75rem" routerLink="/" (click)="removeCookies()">
      <img style="height: 2.5rem; position: absolute; top: 50%; transform: translateY(-50%)" src="assets/header/logo_degineo_bleu_vert.svg" alt="Degineo" />
    </a>
    <button class="navbar-toggler ml-auto" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse ml-auto flex-grow-0">
      <ul class="nav navbar-nav navbar-right" style="background-color: #1fe0">
        <li *ngIf="landingChoiceService.isClient()" class="nav-item">
          <a class="nav-link" [href]="landingChoiceService.urlRegister" class="btn text-primary fs-2" [ngClass]="isMenuCollapsed ? '' : 'w-100'"
            >Créer un compte</a
          >
        </li>
        <li *ngIf="landingChoiceService.isProd()" class="nav-item">
          <a class="nav-link" routerLink="/offre" class="btn text-primary fs-2" [ngClass]="isMenuCollapsed ? '' : 'w-100'">Tarifs</a>
        </li>
        <li *ngIf="landingChoiceService.isProd()" class="nav-item">
          <a class="nav-link" href="{{ producerUrl }}inscription" class="btn text-primary fs-2" [ngClass]="isMenuCollapsed ? '' : 'w-100'">S'inscrire</a>
        </li>
        <li class="nav-item mr-2">
          <a
            *ngIf="landingChoiceService.choice"
            class="nav-link"
            [href]="landingChoiceService.urlLogin"
            class="btn text-primary fs-2"
            [ngClass]="isMenuCollapsed ? '' : 'w-100'"
            >Connexion</a
          >
        </li>
      </ul>
    </div>
  </nav>
</header>
