import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr-FR');
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SvLayoutComponent } from './sv-layout/sv-layout.component';
import { IntercomModule, Intercom } from 'ng-intercom';
import { environment } from '../../../../environments/environment';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { BrowserCookiesModule } from 'ngx-utils-cookies-port';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ic2Module, IRPC, RpcError } from 'ic2-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DegineoCommonModule, AuthService } from 'degineo-common';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { HeaderComponent } from './sv-layout/header/header.component';
import { FooterComponent } from './sv-layout/footer/footer.component';
import { HomepageComponent } from './homepage/homepage.component';
import { OfferComponent } from './offer/offer.component';
import { CustomerAreaComponent } from './customer-area/customer-area.component';
import { CustomerAreaFormComponent } from './customer-area/customer-area-form/customer-area-form.component';
import { DirectoryComponent } from './directory/directory.component';
import { CustomerAreaSearchComponent } from './customer-area/customer-area-search/customer-area-search.component';
import { CalendlyComponent } from './offer/calendly/calendly.component';
import { Router } from '@angular/router';
import { CookiesService } from 'ngx-utils-cookies-port';
import { isPlatformBrowser } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GeneralTermsComponent } from './general-terms/general-terms.component';
import { LegalNoticesComponent } from './legal-notices/legal-notices.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { CookiesComponent } from './cookies/cookies.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ReferencementComponent } from './referencement/referencement.component';
import { CookieBarComponent } from './cookie-bar/cookie-bar.component';
registerLocaleData(localeFr, 'fr');
@NgModule({
  declarations: [
    AppComponent,
    SvLayoutComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    OfferComponent,
    CustomerAreaComponent,
    CustomerAreaFormComponent,
    DirectoryComponent,
    CustomerAreaSearchComponent,
    CalendlyComponent,
    GeneralTermsComponent,
    LegalNoticesComponent,
    PrivacyNoticeComponent,
    CookiesComponent,
    LandingPageComponent,
    ReferencementComponent,
    CookieBarComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'degineo-app' }),
    AngularMyDatePickerModule,
    BrowserCookiesModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgbModule,
    Ic2Module,
    FormsModule,
    ReactiveFormsModule,
    DegineoCommonModule,
    UiSwitchModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmapsApiKey,
    }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
    AppRoutingModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(IRPC) public irpc: IRPC,
    router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
    private cookiesService: CookiesService,
    public intercom: Intercom,
    authService: AuthService
  ) {
    if (isPlatformBrowser(this.platform)) {
      irpc.authToken = (<any>window).jwtToken;
      irpc.url = environment.rpcHost + 'rpc';
      if (this.irpc.authToken === null || this.irpc.authToken === undefined) {
        let token = cookiesService.get('token');
        //console.log(token);
        if (token === null) {
          return;
        }
        this.irpc.authToken = token;
      }
    } else {
      irpc.url = environment.ssrRpcHost + 'rpc';
    }
    //Auto load token from localStorage

    if (irpc.authToken !== null && irpc.authToken !== undefined) {
      const decodedToken = new JwtHelperService().decodeToken(irpc.authToken);
    }
    //console.log(decodedToken);
    irpc.log = environment.name !== 'PRODUCTION';

    irpc.interceptErrorHandlers.push((req, err) => {
      if (err.code === RpcError.NOT_LOGGED) {
        console.log('INTERCEPTED NOT LOGGED ERROR on ' + req.req.serviceDotMethod);
        authService.logout();
        router.navigate(['/connexion']);
        return true;
      }
      return false;
    });
    this.intercom.boot({
      app_id: environment.intercomAppId,
      /*
      alignment: 'left',
      horizontal_padding: 20,
      vertical_padding: 20,
      */
    });

    authService.loginEvent.subscribe((event) => {
      let name = event.userBundle.user.firstName + ' ' + event.userBundle.user.lastName;
      if (event.userBundle.user.firstName === null && event.userBundle.producerName !== null) {
        name = event.userBundle.producerName;
      }
      const data = {
        user_id: event.userBundle.user.idUser,
        email: event.userBundle.user.email,
        name: name,
        avatar: {
          type: 'avatar',
          image_url: environment.rpcHost + 'userLogo/' + event.userBundle.logoToken + '/logo.png',
        },
        company: {
          id: event.userBundle.client.id,
          name: event.userBundle.client.name,
        },
      };
      console.log('updating intercom as logged in user', data, this.intercom);
      this.intercom.update(data);
    });
  }
}
