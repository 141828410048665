import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Host } from '@angular/core';
import { FilterComponent } from '../../filter.component';
import { Filter } from '../../../../ic2/entities/Filter';

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}

@Component({
  selector: 'degineo-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent<T> implements OnInit {
  @Input()
  name: string;
  @Input()
  filterName: string;
  @Input()
  values: Array<T> = null;
  @Input()
  filterSetter: (item: T, filter: Filter) => void = (item, filter) => {
    if (typeof item === 'string') {
      filter.stringValues = [item];
      if (this.host.cache) this.host.cache[this.filterName] = item;
    } else {
      if ((<any>item).id === undefined) throw new Error('Impossible to set the filter value for ' + this.name + ' for item ' + JSON.stringify(item));
      filter.intValues = [parseInt((<any>item).id)];
      if (this.host.cache) this.host.cache[this.filterName] = item;
    }
  };
  @Input()
  presenter: (obj: T) => string = (obj) => obj.toString();
  @Input()
  Filter: Filter;

  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Output()
  change: EventEmitter<Filter> = new EventEmitter<Filter>();

  value: T = null;

  constructor(@Host() private host: FilterComponent) {}

  ngOnInit() {
    if (this.host.cache && this.host.cache[this.filterName] !== undefined && this.host.cache[this.filterName] !== null) {
      if (this.host.cache[this.filterName]) this.value = this.host.cache[this.filterName];
    } else if (this.host.default && this.host.default[this.filterName]) {
      this.value = this.host.default[this.filterName];
    }
    this.update();
  }

  clear() {
    this.value = null;
    this.update();
  }
  reset() {
    if (this.host.default && this.host.default[this.filterName]) {
      this.value = this.host.default[this.filterName];
    } else {
      this.value = null;
    }
    this.update();
  }

  update() {
    if (this.host.cache) {
      if (this.value) {
        this.host.cache[this.filterName] = this.value;
      } else this.host.cache[this.filterName] = null;
    }

    if (this.value) {
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(this.value, this.Filter);
    } else {
      this.Filter = null;
    }

    this.change.emit(this.Filter);
  }

  compareFn(compared1: T, compared2: T) {
    if (compared1 == null)
      if (compared2 == null) return true;
      else return false;
    if (compared2 == null) return false;
    else {
      if (typeof compared2 === 'string') {
        return compared1 === compared2;
      } else {
        return compared1['id'] === compared2['id'];
      }
    }
  }

  filterChanges(value: T) {
    if (value) {
      if (this.host.cache) {
        this.host.cache[this.filterName] = this.value;
      }
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.filterSetter(value, this.Filter);
    } else {
      this.Filter = null;
      if (this.host.cache) {
        this.host.cache[this.filterName] = null;
      }
    }
    this.change.emit(this.Filter);
  }
}
