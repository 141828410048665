// ENTITY com.degineo.metier.producer.dto.bo.ProducerUserBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { User } from './User';
import { RoleInCompany } from './RoleInCompany';

export class ProducerUserBODTO {

  user: User = null;
  role: RoleInCompany = null;
  phoneNumber: string = '';
  disabled: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.bo.ProducerUserBODTO'] = ProducerUserBODTO;

reverseMapping[ProducerUserBODTO.name] = 'com.degineo.metier.producer.dto.bo.ProducerUserBODTO';

fields['com.degineo.metier.producer.dto.bo.ProducerUserBODTO']  = {
    user: 'com.ic2.entity.User',
    role: 'com.degineo.metier.user.entities.RoleInCompany',
    phoneNumber: 'java.lang.String',
    disabled: 'java.lang.Boolean'
};
