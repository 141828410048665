// ENTITY com.degineo.metier.order.dto.kanban.panel.inprepshipment.InPrepShipmentOrderDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class InPrepShipmentOrderDTO {

  idOrder: number = 0;
  waitForIt: boolean = false;
  ready: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.inprepshipment.InPrepShipmentOrderDTO'] = InPrepShipmentOrderDTO;

reverseMapping[InPrepShipmentOrderDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.inprepshipment.InPrepShipmentOrderDTO';

fields['com.degineo.metier.order.dto.kanban.panel.inprepshipment.InPrepShipmentOrderDTO']  = {
    idOrder: 'java.lang.Integer',
    waitForIt: 'java.lang.Boolean',
    ready: 'java.lang.Boolean'
};
