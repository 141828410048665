import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'degineo-upload',
  templateUrl: './degineo-upload.component.html',
  styleUrls: ['./degineo-upload.component.scss'],
})
export class DegineoUploadComponent implements OnInit {
  @ViewChild('fileinput')
  fileinput: ElementRef;
  @Output()
  fileUploaded: EventEmitter<File> = new EventEmitter<File>();
  @Input()
  maxFileSizeMB: number = 0;
  @Input()
  accept: string;
  dragging: boolean = false;
  @Input()
  multi: boolean = false;
  @Input()
  text: string = '3D';

  constructor() {}

  ngOnInit() {}

  emitUpload(file: File) {
    let mb = file.size / 1024 / 1024;
    if (this.maxFileSizeMB !== 0 && mb > this.maxFileSizeMB) return;
    this.fileUploaded.emit(file);
  }

  fileUpload($event) {
    //console.log($event.target.files);
    for (const f of $event.target.files) {
      this.emitUpload(f);
    }
    this.fileinput.nativeElement.value = '';
  }

  drop(ev: DragEvent) {
    //console.log(ev);
    ev.preventDefault();
    this.dragging = false;

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
          this.emitUpload(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        this.emitUpload(ev.dataTransfer.files[i]);
      }
    }
  }
  dragover(ev: DragEvent) {
    //console.log('File(s) in drop zone', ev);
    ev.preventDefault();
  }
  dragexit(ev: Event) {
    //console.log('exit', ev);
    this.dragging = false;
  }
  dragenter(ev: DragEvent) {
    //console.log('dragenter', ev);
    this.dragging = true;
  }
}
