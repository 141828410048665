// com.degineo.metier.order.service.kanban.InPreparationShipmentServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ShipmentOrder } from '../entities/ShipmentOrder';
import { InPreparationShipmentPanelDTO } from '../entities/InPreparationShipmentPanelDTO';
import { ShipmentPackageDTO } from '../entities/ShipmentPackageDTO';

@Injectable({
  providedIn: 'root',
})
export class InPreparationShipmentService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getShipment(idShipment: number): RpcRequestBuilder<InPreparationShipmentPanelDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationShipmentService.getShipment';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.kanban.panel.InPreparationShipmentPanelDTO');
  }

  cancelShippingOrder(idOrder: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationShipmentService.cancelShippingOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idOrder, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateShipment(idShipment: number, producerDeliver: boolean, shipmentOrders: ShipmentOrder[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationShipmentService.updateShipment';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(producerDeliver, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(shipmentOrders, 'java.util.List<com.degineo.metier.shipping.entities.ShipmentOrder>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  passShipmentInPackaging(idShipment: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationShipmentService.passShipmentInPackaging';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  cancelShipmentInPackaging(idShipment: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationShipmentService.cancelShipmentInPackaging';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  passShipmentInTransport(idShipment: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationShipmentService.passShipmentInTransport';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  passShipmentInTransportWithPackages(idShipment: number, packages: ShipmentPackageDTO[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InPreparationShipmentService.passShipmentInTransportWithPackages';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShipment, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(packages, 'java.util.List<com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentPackageDTO>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
