// ENTITY com.degineo.metier.tools.filter.GenericFilter generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { Filter } from './Filter';
import { FilterOrder } from './FilterOrder';

export class GenericFilter {

  filters: Filter[] = null;
  orderFilters: FilterOrder[] = null;
  page: number = 0;
  nbPerPage: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.tools.filter.GenericFilter'] = GenericFilter;

reverseMapping[GenericFilter.name] = 'com.degineo.metier.tools.filter.GenericFilter';

fields['com.degineo.metier.tools.filter.GenericFilter']  = {
    filters: 'java.util.List<com.degineo.metier.tools.filter.Filter>',
    orderFilters: 'java.util.List<com.degineo.metier.tools.filter.FilterOrder>',
    page: 'java.lang.Integer',
    nbPerPage: 'java.lang.Integer'
};
