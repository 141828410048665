// ENTITY com.degineo.metier.consumable.dto.bo.ConsumableAssociatedProducerBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ConsumableAssociatedProducerBODTO {

  id: number = 0;
  name: string = '';
  zipCode: string = '';
  city: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.consumable.dto.bo.ConsumableAssociatedProducerBODTO'] = ConsumableAssociatedProducerBODTO;

reverseMapping[ConsumableAssociatedProducerBODTO.name] = 'com.degineo.metier.consumable.dto.bo.ConsumableAssociatedProducerBODTO';

fields['com.degineo.metier.consumable.dto.bo.ConsumableAssociatedProducerBODTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    zipCode: 'java.lang.String',
    city: 'java.lang.String'
};
