import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ResetPwdService } from '../../ic2/services/services';
import { UserEditValidators } from '../../validator/user-edit-validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPwdPopupComponent } from '../forgot-pwd-popup/forgot-pwd-popup.component';

@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.scss'],
})
export class ForgotPwdComponent implements OnInit {
  token: string = null;
  error: string = null;
  loading: boolean;
  confirm: boolean = false;
  submitted: boolean = false;
  tokenInvalid: boolean = false;
  form: FormGroup = this.fb.group(
    {
      mdp1: [null, [Validators.required, Validators.minLength(6)]],
      mdp2: [null, [Validators.required, Validators.minLength(6)]],
    },
    {
      validator: UserEditValidators.checkPasswords('mdp1', 'mdp2'),
    }
  );
  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) public router: Router,
    @Inject(ResetPwdService) public resetPwdService: ResetPwdService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params['token'] === undefined) return;

      this.token = params['token'];
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    if (this.token === null) {
      this.error = 'Token de crÃ©ation de mot de passe introuvable';
      return;
    }
    this.loading = true;
    this.resetPwdService.changePassword(this.token, this.form.value.mdp1).subscribe(
      (data) => {
        this.loading = false;
        this.confirm = true;
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError()) this.tokenInvalid = true;
        else this.error = err.message;
      }
    );
  }

  openResetPopup() {
    const modalRef = this.modalService.open(ForgotPwdPopupComponent, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return false;
      },
    });
  }
}
