// ENTITY com.degineo.metier.metadata.entities.Finish generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class Finish {

  id: number = 0;
  name: string = '';
  description: string = '';
  imageFileName: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.Finish'] = Finish;

reverseMapping[Finish.name] = 'com.degineo.metier.metadata.entities.Finish';

fields['com.degineo.metier.metadata.entities.Finish']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    description: 'java.lang.String',
    imageFileName: 'java.lang.String'
};
