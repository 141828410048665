// ENTITY com.degineo.metier.producer.dto.bo.ProducerPrinterConsumableOptionListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ProducerPrinterConsumableOptionArticleType } from './ProducerPrinterConsumableOptionArticleType';

export class ProducerPrinterConsumableOptionListItemBODTO {

  id: number = 0;
  idPrinter: number = 0;
  idProducerPrinterConsumableOption: number = 0;
  brand: string = '';
  name: string = '';
  currentProductionCost: number = 0;
  defaultProductionCost: number = 0;
  active: boolean = false;
  checked: boolean = false;
  colorName: string = '';
  capacity: number = 0;
  unit: string = '';
  producerTypes: ProducerPrinterConsumableOptionArticleType[] = null;
  idArticleTypeAdvised: number[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.bo.ProducerPrinterConsumableOptionListItemBODTO'] = ProducerPrinterConsumableOptionListItemBODTO;

reverseMapping[ProducerPrinterConsumableOptionListItemBODTO.name] = 'com.degineo.metier.producer.dto.bo.ProducerPrinterConsumableOptionListItemBODTO';

fields['com.degineo.metier.producer.dto.bo.ProducerPrinterConsumableOptionListItemBODTO']  = {
    id: 'java.lang.Integer',
    idPrinter: 'java.lang.Integer',
    idProducerPrinterConsumableOption: 'java.lang.Integer',
    brand: 'java.lang.String',
    name: 'java.lang.String',
    currentProductionCost: 'java.lang.Double',
    defaultProductionCost: 'java.lang.Double',
    active: 'java.lang.Boolean',
    checked: 'java.lang.Boolean',
    colorName: 'java.lang.String',
    capacity: 'java.lang.Double',
    unit: 'java.lang.String',
    producerTypes: 'java.util.List<com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOptionArticleType>',
    idArticleTypeAdvised: 'java.util.List<java.lang.Integer>'
};
