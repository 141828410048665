// com.degineo.metier.materialTypeCharacteristic.service.MaterialTypeCharacteristicBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { MaterialTypeCharacteristicBODTO } from '../entities/MaterialTypeCharacteristicBODTO';

@Injectable({
  providedIn: 'root',
})
export class MaterialTypeCharacteristicBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  add(newMTC: MaterialTypeCharacteristicBODTO): RpcRequestBuilder<MaterialTypeCharacteristicBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MaterialTypeCharacteristicBOService.add';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(newMTC, 'com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO');
  }

  remove(id: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MaterialTypeCharacteristicBOService.remove';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  update(newMTC: MaterialTypeCharacteristicBODTO): RpcRequestBuilder<MaterialTypeCharacteristicBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MaterialTypeCharacteristicBOService.update';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(newMTC, 'com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO');
  }

  getAll(): RpcRequestBuilder<MaterialTypeCharacteristicBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MaterialTypeCharacteristicBOService.getAll';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getMaterialTypeCharacteristic(id: number): RpcRequestBuilder<MaterialTypeCharacteristicBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MaterialTypeCharacteristicBOService.getMaterialTypeCharacteristic';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO');
  }

}
