import { IAngularMyDpOptions } from 'angular-mydatepicker';
export class DateStringFr {
  public static INgxMyDpOptions: IAngularMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    dayLabels: { su: 'Dim', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Jeu', fr: 'Ven', sa: 'Sam' },
    monthLabels: {
      '1': 'Jan',
      '2': 'Fev',
      '3': 'Mar',
      '4': 'Avr',
      '5': 'Mai',
      '6': 'Jun',
      '7': 'Jul',
      '8': 'Aou',
      '9': 'Sep',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec',
    },
    //todayBtnTxt: "Aujourd'hui",
  };
}
