// ENTITY com.degineo.metier.client.dto.ClientListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { BusinessTargetLevel2 } from './BusinessTargetLevel2';

export class ClientListItemBODTO {

  id: number = 0;
  idCompany: number = 0;
  companyName: string = '';
  firstName: string = '';
  lastName: string = '';
  target: BusinessTargetLevel2 = null;
  origin: string = '';
  dateCreated: Date = null;
  dateLastConnexion: Date = null;
  caProd: number = 0;
  caDegineo: number = 0;
  nbPay: number = 0;
  nbEndPay: number = 0;
  nbLatePay: number = 0;
  nbOrder: number = 0;
  invited: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.client.dto.ClientListItemBODTO'] = ClientListItemBODTO;

reverseMapping[ClientListItemBODTO.name] = 'com.degineo.metier.client.dto.ClientListItemBODTO';

fields['com.degineo.metier.client.dto.ClientListItemBODTO']  = {
    id: 'java.lang.Integer',
    idCompany: 'java.lang.Integer',
    companyName: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    target: 'com.degineo.metier.client.entities.BusinessTargetLevel2',
    origin: 'java.lang.String',
    dateCreated: 'java.time.Instant',
    dateLastConnexion: 'java.time.Instant',
    caProd: 'java.lang.Float',
    caDegineo: 'java.lang.Float',
    nbPay: 'java.lang.Integer',
    nbEndPay: 'java.lang.Integer',
    nbLatePay: 'java.lang.Integer',
    nbOrder: 'java.lang.Integer',
    invited: 'java.lang.Boolean'
};
