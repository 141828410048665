// ENTITY com.degineo.metier.producer.dto.bo.ProducerPrinterListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ProducerPrinterConsumableOptionListItemBODTO } from './ProducerPrinterConsumableOptionListItemBODTO';

export class ProducerPrinterListItemBODTO {

  id: number = 0;
  idProducerPrinter: number = 0;
  brand: string = '';
  name: string = '';
  currentProductionCost: number = 0;
  defaultProductionCost: number = 0;
  active: boolean = false;
  checked: boolean = false;
  consumables: ProducerPrinterConsumableOptionListItemBODTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.bo.ProducerPrinterListItemBODTO'] = ProducerPrinterListItemBODTO;

reverseMapping[ProducerPrinterListItemBODTO.name] = 'com.degineo.metier.producer.dto.bo.ProducerPrinterListItemBODTO';

fields['com.degineo.metier.producer.dto.bo.ProducerPrinterListItemBODTO']  = {
    id: 'java.lang.Integer',
    idProducerPrinter: 'java.lang.Integer',
    brand: 'java.lang.String',
    name: 'java.lang.String',
    currentProductionCost: 'java.lang.Double',
    defaultProductionCost: 'java.lang.Double',
    active: 'java.lang.Boolean',
    checked: 'java.lang.Boolean',
    consumables: 'java.util.List<com.degineo.metier.producer.dto.bo.ProducerPrinterConsumableOptionListItemBODTO>'
};
