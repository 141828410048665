import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { LandingChoiceService } from './landing-choice.service';
import { CookiesService } from 'ngx-utils-cookies-port';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  _routerSub: Subscription;
  constructor(
    @Inject(Router) private router: Router,
    @Inject(Title) private titleService: Title,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(Meta) private metaService: Meta,
    private landingChoiceService: LandingChoiceService,
    private cookiesService: CookiesService
  ) {
    this._routerSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        //console.log(this.cookiesService.get('landingChoice'), route);
        if (!this.cookiesService.get('landingChoice')) {
          if (route.data.landingChoice && route.data.landingChoice === 'prod') this.landingChoiceService.setProducer();
          else if (route.routeConfig.path !== '') this.landingChoiceService.setClient(); //tout sauf le landing est en mode client
        } else if (this.cookiesService.get('landingChoice') === 'client' && route.routeConfig.path !== '') {
          this.landingChoiceService.setClient();
        } else if (this.cookiesService.get('landingChoice') === 'prod' && route.routeConfig.path !== '') {
          this.landingChoiceService.setProducer();
        } else if (route.routeConfig.path === '') {
          this.cookiesService.remove('landingChoice');
          this.landingChoiceService.reset();
        }
        //console.log(route);
        if (route.data.title) this.titleService.setTitle('Degineo • ' + route.data.title);
        else this.titleService.setTitle('Degineo');
        if (route.data.metadesc) this.metaService.updateTag({ property: 'description', content: route.data.metadesc });
        else this.metaService.updateTag({ property: 'description', content: '' });
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }
}
