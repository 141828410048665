// ENTITY com.degineo.metier.order.dto.kanban.panel.InPreparationShipmentPanelDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ShipmentStatus } from './ShipmentStatus';
import { ShipmentOrderPanelDTO } from './ShipmentOrderPanelDTO';

export class InPreparationShipmentPanelDTO {

  idShipment: number = 0;
  shipmentCreationDate: Date = null;
  status: ShipmentStatus = null;
  producerDeliver: boolean = false;
  shipmentOrders: ShipmentOrderPanelDTO[] = null;
  minWishedDeliveryDate: Date = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.InPreparationShipmentPanelDTO'] = InPreparationShipmentPanelDTO;

reverseMapping[InPreparationShipmentPanelDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.InPreparationShipmentPanelDTO';

fields['com.degineo.metier.order.dto.kanban.panel.InPreparationShipmentPanelDTO']  = {
    idShipment: 'java.lang.Integer',
    shipmentCreationDate: 'java.time.Instant',
    status: 'com.degineo.metier.shipping.entities.ShipmentStatus',
    producerDeliver: 'java.lang.Boolean',
    shipmentOrders: 'java.util.List<com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentOrderPanelDTO>',
    minWishedDeliveryDate: 'java.time.Instant'
};
