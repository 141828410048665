import { Injectable, Inject } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '../ic2/services/LoginService';
import { IRPC, L } from 'ic2-lib';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment_ from 'moment';
const moment = moment_;
import { DegineoRight } from '../ic2/entities/DegineoRight';
import { DegineoUserBundleV2, UserRight } from '../ic2/entities/entities';
import { CookiesService } from 'ngx-utils-cookies-port';
import { RoleInCompany } from '../ic2/entities/RoleInCompany';

export class LoginEvent {
  userBundle: DegineoUserBundleV2;
  permitLogin: boolean = true;
  redirectUrl: string = null;
  onLoginAction: () => void;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userBundle: DegineoUserBundleV2 = null;
  // store the URL so we can redirect after logging in
  redirectUrl: string = null;
  loginEvent: Subject<LoginEvent> = new Subject<LoginEvent>();
  logoutEvent: Subject<void> = new Subject<void>();

  static has(bundle: DegineoUserBundleV2, ...roles: DegineoRight[]) {
    if (!bundle) return false;
    for (const role of roles) {
      for (const possessedRole of bundle.rights) {
        if (role.id === possessedRole.idRight) return true;
      }
    }
    return false;
  }

  constructor(private router: Router, public loginService: LoginService, public irpc: IRPC, private cookiesService: CookiesService) {}

  isLoggedIn(): Observable<boolean> {
    return new Observable((observer) => {
      if (this.userBundle !== undefined && this.userBundle !== null) {
        observer.next(true);
        observer.complete();
        return;
      }
      //console.log('COOKIES :: ', this.cookiesService.getAll());
      //let token = localStorage.getItem('token');
      let token = this.cookiesService.get('token');
      //console.log(token);
      if (token === null || token === undefined || token === 'undefined') {
        console.log('token not found');
        observer.next(false);
        observer.complete();
        return;
      }
      this.irpc.authToken = token;

      //check que le token est tjr valable
      const decodedToken = new JwtHelperService().decodeToken(token);
      console.log(decodedToken);
      if (decodedToken && decodedToken.exp) {
        const expires = new Date(decodedToken.exp * 1000);
        const compareTo = moment().add(30, 'm').toDate();
        if (compareTo > expires) {
          console.log('token expired');
          this.irpc.authToken = null;
          observer.next(false);
          observer.complete();
          return;
        }
      }
      //removed else block because token now hos no expiration
      /*else {
        this.irpc.authToken = null;
        observer.next(false);
        observer.complete();
        return;
      }*/

      //Recuperer le degineo bundle
      this.loginService.getBundle().subscribe(
        (data) => {
          const event = new LoginEvent();
          event.userBundle = data;
          event.redirectUrl = this.redirectUrl;
          this.loginEvent.next(event);
          if (event.permitLogin) {
            this.bundleReceived(data);
            observer.next(true);
            observer.complete();
            if (event.redirectUrl !== null) {
              console.log('auto redirect to ' + event.redirectUrl);
              this.router.navigate([event.redirectUrl]);
            }
            if (event.onLoginAction) {
              event.onLoginAction();
            }
          } else {
            L.v('AuthService', 'Login aborted because of the observer');
          }
        },
        (error) => {
          console.error(error);
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  loginWith(data: DegineoUserBundleV2, onLoginAction: () => void = null) {
    const event = new LoginEvent();
    event.userBundle = data;
    event.redirectUrl = this.redirectUrl;
    event.onLoginAction = onLoginAction;
    this.loginEvent.next(event);
    if (event.permitLogin) {
      this.bundleReceived(data);
      if (event.redirectUrl !== null) {
        console.log('auto redirect to ' + event.redirectUrl);
        this.router.navigate([event.redirectUrl]);
      }
      if (event.onLoginAction) {
        event.onLoginAction();
      }
    } else {
      L.v('AuthService', 'Login aborted because of the observer');
    }
  }

  private bundleReceived(data: DegineoUserBundleV2) {
    this.userBundle = data;
    this.irpc.authToken = data.token;
    //localStorage.setItem('token', data.token);
    if (data.token === undefined) {
      console.error('token is undefined !!!', data, new Error());
      return;
    }
    this.cookiesService.put('token', data.token);
  }

  login(email: string, password: string): Observable<LoginEvent> {
    return new Observable((observer) => {
      this.loginService.login(email, password).subscribe(
        (data) => {
          const event = new LoginEvent();
          event.userBundle = data;
          event.redirectUrl = this.redirectUrl;
          observer.next(event);
          observer.complete();
          this.loginEvent.next(event);
          if (event.permitLogin) {
            this.bundleReceived(data);
            if (event.redirectUrl !== null) {
              console.log('auto redirect to ' + event.redirectUrl);
              this.router.navigate([event.redirectUrl]);
            }
            if (event.onLoginAction) {
              event.onLoginAction();
            }
          } else {
            L.v('AuthService', 'Login aborted because of the observer');
          }
        },
        (error) => {
          //console.error(error);
          observer.error(error);
        }
      );
    });
  }

  has(...roles: DegineoRight[]) {
    return AuthService.has(this.userBundle, ...roles);
  }

  isAtLeast(role: RoleInCompany) {
    return this.userBundle.role.id <= role.id;
  }

  logout(): void {
    //localStorage.removeItem('token');
    this.cookiesService.remove('token');
    this.irpc.authToken = null;
    this.userBundle = null;
    console.log('logged out');
    this.logoutEvent.next();
    //this.router.navigate(['connexion']);
  }
}
