import { Injectable, Inject } from '@angular/core';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PrinterSizeDTO, SizeDTO, ProducerSVListDTO, ColorPriceSVDTO, BusinessTargetLevel1 } from 'degineo-common';
import { SVUploadedFile } from './SVUploadedFile';
@Injectable({
  providedIn: 'root',
})
export class CustomerFormService {
  form: FormGroup = this.fb.group({
    target: [{ value: null, disabled: true }, Validators.required],
    types: [{ value: null, disabled: true }, Validators.required],
    file: [{ value: null, disabled: true }, Validators.required],
    sizeX: [{ value: null, disabled: true }, Validators.required],
    sizeY: [{ value: null, disabled: true }, Validators.required],
    sizeZ: [{ value: null, disabled: true }, Validators.required],
    materials: [{ value: null, disabled: true }, Validators.required],
    colours: [{ value: null, disabled: true }],
  });

  printerSizes: PrinterSizeDTO[] = [];
  files: SVUploadedFile[] = [];

  size: SizeDTO = null;
  defaultSizeDTO: SizeDTO = null;

  fromForm = false;

  producers: ProducerSVListDTO[] = [];
  nameSearch: string = null;

  // key = entreprise
  selectedColour: ColorPriceSVDTO[] = [];
  menuOpened: boolean[] = [];

  count: number = 0;
  constructor(@Inject(FormBuilder) private fb: FormBuilder) {}

  loadColour(colour: ColorPriceSVDTO) {
    for (let index = 0; index < this.producers.length; index++) {
      this.selectedColour[index] = colour;
      this.menuOpened[index] = false;
    }
  }

  loadColourLowestPrice() {
    let prices: number[] = [];

    for (let i = 0; i < this.producers.length; i++) {
      for (let j = 0; j < this.producers[i].colors.length; j++) {
        prices.push(this.producers[i].colors[j].price);
      }
      this.selectedColour[i] = this.producers[i].colors[prices.indexOf(Math.min(...prices))];
      prices = [];
    }
  }
}
