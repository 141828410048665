// ENTITY com.degineo.metier.producer.dto.bo.newProducerBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class newProducerBODTO {

  producerName: string = '';
  producerNote: string = '';
  addressLine: string = '';
  city: string = '';
  country: string = '';
  zipCode: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.bo.newProducerBODTO'] = newProducerBODTO;

reverseMapping[newProducerBODTO.name] = 'com.degineo.metier.producer.dto.bo.newProducerBODTO';

fields['com.degineo.metier.producer.dto.bo.newProducerBODTO']  = {
    producerName: 'java.lang.String',
    producerNote: 'java.lang.String',
    addressLine: 'java.lang.String',
    city: 'java.lang.String',
    country: 'java.lang.String',
    zipCode: 'java.lang.String'
};
