// ENTITY com.degineo.metier.client.dto.CPADTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class CPADTO {

  id: number = 0;
  producerName: string = '';
  logoToken: string = '';
  needToAcceptOrRefuse: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.client.dto.CPADTO'] = CPADTO;

reverseMapping[CPADTO.name] = 'com.degineo.metier.client.dto.CPADTO';

fields['com.degineo.metier.client.dto.CPADTO']  = {
    id: 'java.lang.Integer',
    producerName: 'java.lang.String',
    logoToken: 'java.lang.String',
    needToAcceptOrRefuse: 'java.lang.Boolean'
};
