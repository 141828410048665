// ENTITY com.degineo.metier.producer_printer.dto.ConsumableOptionBrandChoiceDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ConsumableOptionConsumableNameChoice } from './ConsumableOptionConsumableNameChoice';

export class ConsumableOptionBrandChoiceDTO {

  brand: string = '';
  names: ConsumableOptionConsumableNameChoice[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.ConsumableOptionBrandChoiceDTO'] = ConsumableOptionBrandChoiceDTO;

reverseMapping[ConsumableOptionBrandChoiceDTO.name] = 'com.degineo.metier.producer_printer.dto.ConsumableOptionBrandChoiceDTO';

fields['com.degineo.metier.producer_printer.dto.ConsumableOptionBrandChoiceDTO']  = {
    brand: 'java.lang.String',
    names: 'java.util.List<com.degineo.metier.producer_printer.dto.ConsumableOptionConsumableNameChoice>'
};
