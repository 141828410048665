// ENTITY com.degineo.metier.metadata.entities.ConsumableOption generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ConsumableOption {

  id: number = 0;
  idConsumable: number = 0;
  idColor: number = 0;
  capacity: number = 0;
  unit: string = '';
  supplierReference: string = '';
  diameter: number = 0;
  stillSold: boolean = false;
  price: number = 0;
  oldIdConsumable: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.ConsumableOption'] = ConsumableOption;

reverseMapping[ConsumableOption.name] = 'com.degineo.metier.metadata.entities.ConsumableOption';

fields['com.degineo.metier.metadata.entities.ConsumableOption']  = {
    id: 'java.lang.Integer',
    idConsumable: 'java.lang.Integer',
    idColor: 'java.lang.Integer',
    capacity: 'java.lang.Float',
    unit: 'java.lang.String',
    supplierReference: 'java.lang.String',
    diameter: 'java.lang.Double',
    stillSold: 'java.lang.Boolean',
    price: 'java.lang.Double',
    oldIdConsumable: 'java.lang.Integer'
};
