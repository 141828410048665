// ENTITY com.degineo.metier.invoice.dto.DelayedOrderDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { SizeDTO } from './SizeDTO';

export class DelayedOrderDTO {

  name: string = '';
  articleTypeName: string = '';
  dentaire: boolean = false;
  sizeInMm: SizeDTO = null;
  materialName: string = '';
  colorName: string = '';
  quantity: number = 0;
  unitVATAmount: number = 0;
  unitPriceTTC: number = 0;
  tokenModelPng: string = '';
  orderDate: Date = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.DelayedOrderDTO'] = DelayedOrderDTO;

reverseMapping[DelayedOrderDTO.name] = 'com.degineo.metier.invoice.dto.DelayedOrderDTO';

fields['com.degineo.metier.invoice.dto.DelayedOrderDTO']  = {
    name: 'java.lang.String',
    articleTypeName: 'java.lang.String',
    dentaire: 'java.lang.Boolean',
    sizeInMm: 'com.degineo.metier.order.dto.SizeDTO',
    materialName: 'java.lang.String',
    colorName: 'java.lang.String',
    quantity: 'java.lang.Integer',
    unitVATAmount: 'java.lang.Double',
    unitPriceTTC: 'java.lang.Double',
    tokenModelPng: 'java.lang.String',
    orderDate: 'java.time.Instant'
};
