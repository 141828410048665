// DB ENUM com.degineo.metier.plan.entities.ProducerTarget generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class ProducerTarget {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ENTREPRISE = new ProducerTarget(1, "une Entreprise", "ENTREPRISE");
  static readonly MICRO_ENTREPRISE = new ProducerTarget(2, "une Micro-entreprise", "MICRO_ENTREPRISE");
  static readonly FABLAB_ET_ECOLES = new ProducerTarget(3, "un FabLab ou une Ãcole", "FABLAB_ET_ECOLES");

  static readonly values = [
    ProducerTarget.ENTREPRISE, 
    ProducerTarget.MICRO_ENTREPRISE, 
    ProducerTarget.FABLAB_ET_ECOLES 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ProducerTarget.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ProducerTarget.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.plan.entities.ProducerTarget'] = ProducerTarget;
reverseMapping[ProducerTarget.name] = 'com.degineo.metier.plan.entities.ProducerTarget';
