import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { MaxValidator } from './directives/max.directive';
import { MinValidator } from './directives/min.directive';
import { NonZeroValidatorDirective } from './directives/non-zero.directive';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { LogPipe } from './pipes/log.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { PreviewModeleComponent } from './components/preview-modele/preview-modele.component';
import { MyBadgeComponent } from './components/my-badge/my-badge.component';
import { MyTableComponent } from './components/my-table/my-table.component';
import { FilterComponent } from './components/filter/filter.component';
import { TextFilterComponent } from './components/filter/filters/text-filter/text-filter.component';
import { SelectFilterComponent } from './components/filter/filters/select-filter/select-filter.component';
import { TextassistFilterComponent } from './components/filter/filters/textassist-filter/textassist-filter.component';
import { DateFilterComponent } from './components/filter/filters/date-filter/date-filter.component';
import { DaterangeFilterComponent } from './components/filter/filters/daterange-filter/daterange-filter.component';
import { CheckboxFilterComponent } from './components/filter/filters/checkbox-filter/checkbox-filter.component';
import { RoundCheckboxFilterComponent } from './components/filter/filters/round-checkbox-filter/round-checkbox-filter.component';
import { CheckboxGroupFilterComponent } from './components/filter/filters/checkbox-group-filter/checkbox-group-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Ic2Pagination } from './components/ic2-pagination/ic2-pagination.component';
import { AuthGuard } from './guard/auth-guard.service';
import { MyColumn } from './components/my-table/my-column/my-column.component';
import { MyFooter } from './components/my-table/my-footer/my-footer.component';
import { MyColumnValue } from './components/my-table/my-column-value/my-column-value.component';
import { AdminAuthGuard } from './guard/admin-auth-guard.service';
import { ImagePreloader } from './directives/image-preloader.directive';
import { ForgotPwdPopupComponent } from './components/forgot-pwd-popup/forgot-pwd-popup.component';
import { ForgotPwdComponent } from './components/forgot-pwd/forgot-pwd.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { SrcOverDirective } from './directives/src-over.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AgmCoreModule } from '@agm/core';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgxMaskModule } from 'ngx-mask';
import { DegineoUploadComponent } from './components/degineo-upload/degineo-upload.component';
import { ProducerDisplayComponent } from './components/producer-display/producer-display.component';
import { ProducerEditComponent } from './components/producer-display/producer-edit/producer-edit.component';
import { SubscribedAuthGuard } from './guard/subscribed-auth-guard.service';
import { AutologComponent } from './components/autolog/autolog.component';
import { Ic2Module } from 'ic2-lib';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule,
    UiSwitchModule,
    AngularMyDatePickerModule,
    Ic2Module,
  ],
  declarations: [
    NotFoundComponent,
    SidePanelComponent,
    ProgressBarComponent,
    PreviewModeleComponent,
    MyBadgeComponent,
    MyTableComponent,
    MyColumn,
    MyFooter,
    MyColumnValue,
    FilterComponent,
    TextFilterComponent,
    SelectFilterComponent,
    TextassistFilterComponent,
    DateFilterComponent,
    DaterangeFilterComponent,
    CheckboxFilterComponent,
    RoundCheckboxFilterComponent,
    CheckboxGroupFilterComponent,
    Ic2Pagination,
    ForgotPwdPopupComponent,
    ForgotPwdComponent,

    // Â§!!!! !! ! !! !
    //ATTENTION NE PAS OUBLIER DE L'AJOUTER AUSSI DANS LES EXPORTS PLUS BAS !!!!!
    // Â§Â§:Â§Â§! !! !! !

    MaxValidator,
    MinValidator,
    NonZeroValidatorDirective,
    ImagePreloader,
    SrcOverDirective,

    LogPipe,
    HighlightPipe,
    DegineoUploadComponent,
    ProducerDisplayComponent,
    ProducerEditComponent,
    AutologComponent,
  ],
  exports: [
    AngularMyDatePickerModule,
    NotFoundComponent,
    SidePanelComponent,
    ProgressBarComponent,
    PreviewModeleComponent,
    MyBadgeComponent,
    MyTableComponent,
    MyColumn,
    MyFooter,
    MyColumnValue,
    FilterComponent,
    TextFilterComponent,
    SelectFilterComponent,
    TextassistFilterComponent,
    DateFilterComponent,
    DaterangeFilterComponent,
    CheckboxFilterComponent,
    RoundCheckboxFilterComponent,
    CheckboxGroupFilterComponent,
    Ic2Pagination,
    ForgotPwdPopupComponent,
    ForgotPwdComponent,
    ImagePreloader,
    SrcOverDirective,
    DegineoUploadComponent,
    LogPipe,
    HighlightPipe,
    ProducerDisplayComponent,
    ProducerEditComponent,
    AutologComponent,
  ],
  providers: [LogPipe, HighlightPipe, AuthGuard, AdminAuthGuard, SubscribedAuthGuard],
})
export class DegineoCommonModule {}
