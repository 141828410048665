// com.degineo.metier.order.service.PriceServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { SizeDTO } from '../entities/SizeDTO';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPriceClient(idProducer: number, idArticleType: number, idMaterial: number, idColor: number, multicolor: boolean, idFinish: number, sizeInMm: SizeDTO): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PriceService.getPriceClient';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(multicolor, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Double');
  }

  getPriceClientMarqueBlanche(idProducer: number, idArticleType: number, idMaterial: number, idColor: number, multicolor: boolean, idFinish: number, sizeInMm: SizeDTO): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PriceService.getPriceClientMarqueBlanche';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(multicolor, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Double');
  }

}
