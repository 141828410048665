// ENTITY com.degineo.metier.user.dto.ProducerUserProfilDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerUserProfilDTO {

  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  email: string = '';
  job: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.user.dto.ProducerUserProfilDTO'] = ProducerUserProfilDTO;

reverseMapping[ProducerUserProfilDTO.name] = 'com.degineo.metier.user.dto.ProducerUserProfilDTO';

fields['com.degineo.metier.user.dto.ProducerUserProfilDTO']  = {
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    phoneNumber: 'java.lang.String',
    email: 'java.lang.String',
    job: 'java.lang.String'
};
