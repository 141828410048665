// DB ENUM com.degineo.metier.metadata.entities.CharacteristicDisplayType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class CharacteristicDisplayType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly MIN = new CharacteristicDisplayType(1, "Min", "MIN");
  static readonly MAX = new CharacteristicDisplayType(2, "Max", "MAX");
  static readonly INTERVALLE = new CharacteristicDisplayType(3, "Intervalle", "INTERVALLE");

  static readonly values = [
    CharacteristicDisplayType.MIN, 
    CharacteristicDisplayType.MAX, 
    CharacteristicDisplayType.INTERVALLE 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of CharacteristicDisplayType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of CharacteristicDisplayType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.metadata.entities.CharacteristicDisplayType'] = CharacteristicDisplayType;
reverseMapping[CharacteristicDisplayType.name] = 'com.degineo.metier.metadata.entities.CharacteristicDisplayType';
