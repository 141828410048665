import { Component, OnInit, Inject } from '@angular/core';
import { ProducerSVService } from '../../../../../../dist/degineo-common';
import { Router } from '@angular/router';
import { CustomerFormService } from '../../customer-form.service';
import { L } from 'ic2-lib';
import { Intercom } from 'ng-intercom';
import { LandingChoiceService } from '../../landing-choice.service';
import { CookiesService } from 'ngx-utils-cookies-port';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  private readonly PAGE = 1;
  private readonly ITEMPERPAGE = 20;
  year: number = new Date().getFullYear();
  constructor(
    @Inject(ProducerSVService) private producerSVService: ProducerSVService,
    private customerFormService: CustomerFormService,
    private router: Router,
    public intercom: Intercom,
    public landingChoiceService: LandingChoiceService,
    private cookiesService: CookiesService
  ) {}

  ngOnInit() {}

  searchAll() {
    this.producerSVService.getProducersByName(this.PAGE, this.ITEMPERPAGE, '').subscribe(
      (data) => {
        this.customerFormService.producers = data.data;
        this.customerFormService.count = data.count;
        this.customerFormService.fromForm = false;
        this.customerFormService.nameSearch = '';
        if (this.router.url === '/annuaire') {
        } else this.router.navigate(['/annuaire']);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  removeCookies() {
    this.landingChoiceService.choice = null;
    this.cookiesService.remove('landingChoice');
    this.router.navigate(['/']);
  }

  openChat() {
    this.intercom.show('Bonjour, ');
  }
}
