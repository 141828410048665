// ENTITY com.degineo.metier.producer.dto.ImportedClientDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ImportedClientDTO {

  name: string = '';
  email: string = '';
  message: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ImportedClientDTO'] = ImportedClientDTO;

reverseMapping[ImportedClientDTO.name] = 'com.degineo.metier.producer.dto.ImportedClientDTO';

fields['com.degineo.metier.producer.dto.ImportedClientDTO']  = {
    name: 'java.lang.String',
    email: 'java.lang.String',
    message: 'java.lang.String'
};
