<div class="container-fluid bg-primary landing">
  <div class="d-flex flex-column flex-nowrap h-100">
    <div class="row justify-content-center">
      <img class="mt-5 mx-3 mb-5 mb-lg-0" style="height: 7rem" src="assets/landing/logo_degineo_blanc_vert.svg" alt="Logo Degineo" />
    </div>

    <!--Centre de la page-->
    <div class="space row justify-content-center align-items-center my-3">
      <div class="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-center mb-lg-0 mb-4">
        <div class="bg-white rounded text-center p-5 mx-lg-4 shadow-2 d-flex flex-column flex-nowrap" style="height: 23rem; width: 19rem">
          <h2 class="fs-3 fw-700" style="line-height: 1.5rem">Vous cherchez le meilleur Imprimeur&nbsp;3D pour imprimer un Fichier&nbsp;3D en ligne&nbsp;?</h2>
          <div class="space row justify-content-center align-items-center">
            <img style="height: 8rem" src="assets/landing/recherche-imprimeur-3d.png" alt="Imprimer en 3D un fichier" />
          </div>
          <button class="btn btn-success w-100" style="padding: 0.75em 0.25em; line-height: 1.2rem" (click)="setClient()">Trouver un Imprimeur&nbsp;3D</button>
        </div>
      </div>

      <div class="col-12 col-lg-6 d-flex justify-content-lg-start justify-content-center my-lg-0">
        <div class="bg-white rounded text-center p-5 mx-lg-4 shadow-2 d-flex flex-column flex-nowrap" style="height: 23rem; width: 19rem">
          <h2 class="fs-3 fw-700" style="line-height: 1.5rem">Vous êtes imprimeur&nbsp;3D et souhaitez découvrir notre Logiciel de Gestion&nbsp;?</h2>
          <div class="space row justify-content-center align-items-center">
            <img style="height: 8rem" src="assets/landing/logiciel-de-gestion.png" alt="Logiciel de gestion pour les producteurs 3D" />
          </div>
          <button class="btn btn-success w-100" style="padding: 0.75em 0.25em; line-height: 1.2rem" (click)="setProducer()">
            Découvrir&nbsp;notre Logiciel
          </button>
        </div>
      </div>
    </div>

    <!-- Bas de page -->
    <div class="text-white text-center">
      <h1 class="fs-4 fw-400 mb-5" style="line-height: 1.7rem">
        Degineo réinvente le monde de la production. <br />
        L’impression&nbsp;3D professionnelle accessible à tous les métiers.
      </h1>
      <div class="d-flex flex-column flex-sm-row justify-content-center mb-3">
        <a [href]="urlClient" class="pointer text-white">
          <u> Espace Client Degineo </u>
        </a>
        <div class="mx-4">&nbsp;-&nbsp;</div>
        <a [href]="urlProducer" class="pointer text-white">
          <u> Espace Imprimeur 3D </u>
        </a>
      </div>
      <div class="mb-3 fs-2 copyright">Copyright © {{ year }} Degineo - Tous droits réservés</div>
    </div>
  </div>
</div>
