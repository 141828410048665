// ENTITY com.degineo.metier.order.dto.kanban.InPreparationShipmentDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { InPrepShipmentOrderDTO } from './InPrepShipmentOrderDTO';

export class InPreparationShipmentDTO {

  idShipment: number = 0;
  clientChoosedThisProducer: boolean = false;
  clientName: string = '';
  orders: InPrepShipmentOrderDTO[] = null;
  shipmentCreationDate: Date = null;
  minWishedDeliveryDate: Date = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.InPreparationShipmentDTO'] = InPreparationShipmentDTO;

reverseMapping[InPreparationShipmentDTO.name] = 'com.degineo.metier.order.dto.kanban.InPreparationShipmentDTO';

fields['com.degineo.metier.order.dto.kanban.InPreparationShipmentDTO']  = {
    idShipment: 'java.lang.Integer',
    clientChoosedThisProducer: 'java.lang.Boolean',
    clientName: 'java.lang.String',
    orders: 'java.util.List<com.degineo.metier.order.dto.kanban.panel.inprepshipment.InPrepShipmentOrderDTO>',
    shipmentCreationDate: 'java.time.Instant',
    minWishedDeliveryDate: 'java.time.Instant'
};
