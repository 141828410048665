// com.degineo.metier.producer.service.ProducerMarqueBlancheManagmentServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { MarqueBlancheManagmentDTO } from '../entities/MarqueBlancheManagmentDTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerMarqueBlancheManagmentService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getInfos(): RpcRequestBuilder<MarqueBlancheManagmentDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerMarqueBlancheManagmentService.getInfos';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.MarqueBlancheManagmentDTO');
  }

  updateMarqueBlancheDevis(devis: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerMarqueBlancheManagmentService.updateMarqueBlancheDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(devis, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateMarqueBlanchePrint(print: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerMarqueBlancheManagmentService.updateMarqueBlanchePrint';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(print, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
