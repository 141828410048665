// ENTITY com.degineo.metier.producer_printer.dto.PPCOFullDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class PPCOFullDTO {

  idProducerPrinterConsumableOption: number = 0;
  idConsumableOption: number = 0;
  brand: string = '';
  name: string = '';
  color: string = '';
  defaultMaterialCost: number = 0;
  currentMaterialCost: number = 0;
  active: boolean = false;
  checked: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.PPCOFullDTO'] = PPCOFullDTO;

reverseMapping[PPCOFullDTO.name] = 'com.degineo.metier.producer_printer.dto.PPCOFullDTO';

fields['com.degineo.metier.producer_printer.dto.PPCOFullDTO']  = {
    idProducerPrinterConsumableOption: 'java.lang.Integer',
    idConsumableOption: 'java.lang.Integer',
    brand: 'java.lang.String',
    name: 'java.lang.String',
    color: 'java.lang.String',
    defaultMaterialCost: 'java.lang.Double',
    currentMaterialCost: 'java.lang.Double',
    active: 'java.lang.Boolean',
    checked: 'java.lang.Boolean'
};
