// ENTITY com.degineo.metier.metadata.entities.PrinterBusinessTarget generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { BusinessTargetLevel2 } from './BusinessTargetLevel2';

export class PrinterBusinessTarget {

  idPrinter: number = 0;
  target: BusinessTargetLevel2 = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.PrinterBusinessTarget'] = PrinterBusinessTarget;

reverseMapping[PrinterBusinessTarget.name] = 'com.degineo.metier.metadata.entities.PrinterBusinessTarget';

fields['com.degineo.metier.metadata.entities.PrinterBusinessTarget']  = {
    idPrinter: 'java.lang.Integer',
    target: 'com.degineo.metier.client.entities.BusinessTargetLevel2'
};
