// ENTITY com.degineo.metier.plan.entities.Functionality generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class Functionality {

  id: number = 0;
  name: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.plan.entities.Functionality'] = Functionality;

reverseMapping[Functionality.name] = 'com.degineo.metier.plan.entities.Functionality';

fields['com.degineo.metier.plan.entities.Functionality']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String'
};
