// com.degineo.metier.producer.service.ProducerTakeControlServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { DegineoUserBundleV2 } from '../entities/DegineoUserBundleV2';
import { BusinessType } from '../entities/BusinessType';
import { ProducerTakeControlStep4DTO } from '../entities/ProducerTakeControlStep4DTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerTakeControlService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getBusinessType(idProducer: number): RpcRequestBuilder<BusinessType> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerTakeControlService.getBusinessType';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.entities.BusinessType');
  }

  getSiret(idProducer: number): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerTakeControlService.getSiret';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  getInfos(siret: string): RpcRequestBuilder<ProducerTakeControlStep4DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerTakeControlService.getInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerTakeControlStep4DTO');
  }

  producerTakeControl(siret: string, email: string, password: string, businessType: BusinessType, dto: ProducerTakeControlStep4DTO, idPrinters: number[]): RpcRequestBuilder<DegineoUserBundleV2> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerTakeControlService.producerTakeControl';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(businessType, 'com.degineo.metier.producer.entities.BusinessType', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(dto, 'com.degineo.metier.producer.dto.ProducerTakeControlStep4DTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idPrinters, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.tools.DegineoUserBundleV2');
  }

}
