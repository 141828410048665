// com.degineo.metier.producer.service.ProducerUserSignupServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { DegineoUserBundleV2 } from '../entities/DegineoUserBundleV2';
import { ProducerUserSignupDTO } from '../entities/ProducerUserSignupDTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerUserSignupService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  register(token: string, firstName: string, lastName: string, email: string, phoneNumber: string, password: string): RpcRequestBuilder<DegineoUserBundleV2> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerUserSignupService.register';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(phoneNumber, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.tools.DegineoUserBundleV2');
  }

  getInfos(token: string): RpcRequestBuilder<ProducerUserSignupDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerUserSignupService.getInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerUserSignupDTO');
  }

  emailAvailable(token: string, email: string): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerUserSignupService.emailAvailable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

}
