// ENTITY com.degineo.metier.producer.dto.MarqueBlancheManagmentDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class MarqueBlancheManagmentDTO {

  marqueBlancheDevis: boolean = false;
  marqueBlanchePrint: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.MarqueBlancheManagmentDTO'] = MarqueBlancheManagmentDTO;

reverseMapping[MarqueBlancheManagmentDTO.name] = 'com.degineo.metier.producer.dto.MarqueBlancheManagmentDTO';

fields['com.degineo.metier.producer.dto.MarqueBlancheManagmentDTO']  = {
    marqueBlancheDevis: 'java.lang.Boolean',
    marqueBlanchePrint: 'java.lang.Boolean'
};
