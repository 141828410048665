// ENTITY com.degineo.metier.order.dto.kanban.InPreparationOrderDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { OrderFileStatus } from './OrderFileStatus';

export class InPreparationOrderDTO {

  idOrder: number = 0;
  clientChoosedThisProducer: boolean = false;
  clientName: string = '';
  wishedDeliveryDate: Date = null;
  dateAccepted: Date = null;
  printerName: string = '';
  printerBrand: string = '';
  producerFileStatus: OrderFileStatus = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.InPreparationOrderDTO'] = InPreparationOrderDTO;

reverseMapping[InPreparationOrderDTO.name] = 'com.degineo.metier.order.dto.kanban.InPreparationOrderDTO';

fields['com.degineo.metier.order.dto.kanban.InPreparationOrderDTO']  = {
    idOrder: 'java.lang.Integer',
    clientChoosedThisProducer: 'java.lang.Boolean',
    clientName: 'java.lang.String',
    wishedDeliveryDate: 'java.time.Instant',
    dateAccepted: 'java.time.Instant',
    printerName: 'java.lang.String',
    printerBrand: 'java.lang.String',
    producerFileStatus: 'com.degineo.metier.order.entities.OrderFileStatus'
};
