import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

export function nonZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let value = control.value;
        if (typeof value === 'string') value = +value;
        return value === 0 ? { 'nonZero': { value: value } } : null;
    };
}

@Directive({
    selector: '[nonZero]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NonZeroValidatorDirective, multi: true }]
})
export class NonZeroValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } | null {
        return nonZeroValidator()(control);
    }
}

