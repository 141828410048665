// ENTITY com.degineo.metier.devis.dto.producer.ProducerDevisListItemDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DevisStatus } from './DevisStatus';

export class ProducerDevisListItemDTO {

  idDevis: number = 0;
  clientCompanyRS: string = '';
  creationDate: Date = null;
  status: DevisStatus = null;
  numDevisProducteur: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.devis.dto.producer.ProducerDevisListItemDTO'] = ProducerDevisListItemDTO;

reverseMapping[ProducerDevisListItemDTO.name] = 'com.degineo.metier.devis.dto.producer.ProducerDevisListItemDTO';

fields['com.degineo.metier.devis.dto.producer.ProducerDevisListItemDTO']  = {
    idDevis: 'java.lang.Integer',
    clientCompanyRS: 'java.lang.String',
    creationDate: 'java.time.LocalDateTime',
    status: 'com.degineo.metier.devis.entities.DevisStatus',
    numDevisProducteur: 'java.lang.Integer'
};
