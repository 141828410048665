// ENTITY com.degineo.metier.basket.dto.AssociatedProducerDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class AssociatedProducerDTO {

  idProducer: number = 0;
  name: string = '';
  logoToken: string = '';
  idArticlesAvailable: number[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.basket.dto.AssociatedProducerDTO'] = AssociatedProducerDTO;

reverseMapping[AssociatedProducerDTO.name] = 'com.degineo.metier.basket.dto.AssociatedProducerDTO';

fields['com.degineo.metier.basket.dto.AssociatedProducerDTO']  = {
    idProducer: 'java.lang.Integer',
    name: 'java.lang.String',
    logoToken: 'java.lang.String',
    idArticlesAvailable: 'java.util.List<java.lang.Integer>'
};
