// ENTITY com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOption generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerPrinterConsumableOption {

  id: number = 0;
  idProducerPrinter: number = 0;
  idProducer: number = 0;
  idPrinter: number = 0;
  idConsumableOption: number = 0;
  defaultMaterialCost: number = 0;
  currentMaterialCost: number = 0;
  creationDate: Date = null;
  deactivationDate: Date = null;
  updateDate: Date = null;
  active: boolean = false;
  checked: boolean = false;
  producerDeleted: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOption'] = ProducerPrinterConsumableOption;

reverseMapping[ProducerPrinterConsumableOption.name] = 'com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOption';

fields['com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOption']  = {
    id: 'java.lang.Integer',
    idProducerPrinter: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    idPrinter: 'java.lang.Integer',
    idConsumableOption: 'java.lang.Integer',
    defaultMaterialCost: 'java.lang.Double',
    currentMaterialCost: 'java.lang.Double',
    creationDate: 'java.time.Instant',
    deactivationDate: 'java.time.Instant',
    updateDate: 'java.time.Instant',
    active: 'java.lang.Boolean',
    checked: 'java.lang.Boolean',
    producerDeleted: 'java.lang.Boolean'
};
