// ENTITY com.degineo.metier.address.entities.CodeNaf generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { BusinessTargetLevel2 } from './BusinessTargetLevel2';

export class CodeNaf {

  id: number = 0;
  section: string = '';
  division: string = '';
  groupe: string = '';
  classe: string = '';
  sousClasse: string = '';
  libelle: string = '';
  typeLie: BusinessTargetLevel2 = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.address.entities.CodeNaf'] = CodeNaf;

reverseMapping[CodeNaf.name] = 'com.degineo.metier.address.entities.CodeNaf';

fields['com.degineo.metier.address.entities.CodeNaf']  = {
    id: 'java.lang.Integer',
    section: 'java.lang.String',
    division: 'java.lang.String',
    groupe: 'java.lang.String',
    classe: 'java.lang.String',
    sousClasse: 'java.lang.String',
    libelle: 'java.lang.String',
    typeLie: 'com.degineo.metier.client.entities.BusinessTargetLevel2'
};
