// ENTITY com.degineo.metier.metadata.entities.FinishMaterialType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class FinishMaterialType {

  idFinish: number = 0;
  idMaterialType: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.FinishMaterialType'] = FinishMaterialType;

reverseMapping[FinishMaterialType.name] = 'com.degineo.metier.metadata.entities.FinishMaterialType';

fields['com.degineo.metier.metadata.entities.FinishMaterialType']  = {
    idFinish: 'java.lang.Integer',
    idMaterialType: 'java.lang.Integer'
};
