// ENTITY com.degineo.metier.consumable.dto.bo.ConsumableAssociatedPrinterBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ConsumableAssociatedPrinterBODTO {

  id: number = 0;
  name: string = '';
  brandName: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.consumable.dto.bo.ConsumableAssociatedPrinterBODTO'] = ConsumableAssociatedPrinterBODTO;

reverseMapping[ConsumableAssociatedPrinterBODTO.name] = 'com.degineo.metier.consumable.dto.bo.ConsumableAssociatedPrinterBODTO';

fields['com.degineo.metier.consumable.dto.bo.ConsumableAssociatedPrinterBODTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    brandName: 'java.lang.String'
};
