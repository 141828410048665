// ENTITY com.degineo.metier.plan.dto.PlanDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { Plan } from './Plan';
import { FunctionalityDTO } from './FunctionalityDTO';

export class PlanDTO {

  plan: Plan = null;
  functionalities: FunctionalityDTO[] = null;
  nbSubTotal: number = 0;
  nbSubMonth: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.plan.dto.PlanDTO'] = PlanDTO;

reverseMapping[PlanDTO.name] = 'com.degineo.metier.plan.dto.PlanDTO';

fields['com.degineo.metier.plan.dto.PlanDTO']  = {
    plan: 'com.degineo.metier.plan.entities.Plan',
    functionalities: 'java.util.List<com.degineo.metier.plan.dto.FunctionalityDTO>',
    nbSubTotal: 'java.lang.Integer',
    nbSubMonth: 'java.lang.Integer'
};
