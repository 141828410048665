// ENTITY com.degineo.metier.consumable.dto.bo.ConsumableOptionBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ConsumableOption } from './ConsumableOption';

export class ConsumableOptionBODTO {

  co: ConsumableOption = null;
  colorName: string = '';
  hexCode: string = '';
  linked: boolean = false;
  nbAssocPrinter: number = 0;
  nbAssocProducer: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.consumable.dto.bo.ConsumableOptionBODTO'] = ConsumableOptionBODTO;

reverseMapping[ConsumableOptionBODTO.name] = 'com.degineo.metier.consumable.dto.bo.ConsumableOptionBODTO';

fields['com.degineo.metier.consumable.dto.bo.ConsumableOptionBODTO']  = {
    co: 'com.degineo.metier.metadata.entities.ConsumableOption',
    colorName: 'java.lang.String',
    hexCode: 'java.lang.String',
    linked: 'java.lang.Boolean',
    nbAssocPrinter: 'java.lang.Integer',
    nbAssocProducer: 'java.lang.Integer'
};
