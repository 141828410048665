// DB ENUM com.degineo.metier.invoice.entities.InvoiceType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class InvoiceType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly INVOICE_DEGINEO_TO_CLIENT = new InvoiceType(1, "Facture client Degineo", "INVOICE_DEGINEO_TO_CLIENT");
  static readonly INVOICE_PRODUCER_TO_DEGINEO = new InvoiceType(2, "Facture producteur Degineo", "INVOICE_PRODUCER_TO_DEGINEO");
  static readonly INVOICE_PRODUCER_TO_CLIENT_DELAYED_ARTICLES = new InvoiceType(3, "Facture FdM client producteur", "INVOICE_PRODUCER_TO_CLIENT_DELAYED_ARTICLES");
  static readonly INVOICE_DEGINEO_TO_PRODUCER_SUBSCRIPTION = new InvoiceType(4, "Facture abonnement Degineo", "INVOICE_DEGINEO_TO_PRODUCER_SUBSCRIPTION");
  static readonly INVOICE_DEGINEO_TO_PRODUCER_COMMISSION = new InvoiceType(5, "Facture comissions Degineo producteur", "INVOICE_DEGINEO_TO_PRODUCER_COMMISSION");

  static readonly values = [
    InvoiceType.INVOICE_DEGINEO_TO_CLIENT, 
    InvoiceType.INVOICE_PRODUCER_TO_DEGINEO, 
    InvoiceType.INVOICE_PRODUCER_TO_CLIENT_DELAYED_ARTICLES, 
    InvoiceType.INVOICE_DEGINEO_TO_PRODUCER_SUBSCRIPTION, 
    InvoiceType.INVOICE_DEGINEO_TO_PRODUCER_COMMISSION 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of InvoiceType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of InvoiceType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.invoice.entities.InvoiceType'] = InvoiceType;
reverseMapping[InvoiceType.name] = 'com.degineo.metier.invoice.entities.InvoiceType';
