// ENTITY com.degineo.metier.shipping.entities.ShipmentOrder generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ShipmentOrder {

  idShipment: number = 0;
  idOrder: number = 0;
  waitForIt: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.shipping.entities.ShipmentOrder'] = ShipmentOrder;

reverseMapping[ShipmentOrder.name] = 'com.degineo.metier.shipping.entities.ShipmentOrder';

fields['com.degineo.metier.shipping.entities.ShipmentOrder']  = {
    idShipment: 'java.lang.Integer',
    idOrder: 'java.lang.Integer',
    waitForIt: 'java.lang.Boolean'
};
