// ENTITY com.degineo.metier.invoice.dto.producer.InvoiceProducerDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { InvoiceProducerStatus } from './InvoiceProducerStatus';
import { InvoiceProducerOrderDTO } from './InvoiceProducerOrderDTO';

export class InvoiceProducerDTO {

  invoiceId: number = 0;
  reference: string = '';
  producerSelected: boolean = false;
  client: string = '';
  status: InvoiceProducerStatus = null;
  totalHT: number = 0;
  totalTTC: number = 0;
  dateCreated: Date = null;
  dueDate: Date = null;
  datePaid: Date = null;
  invoiceToken: string = '';
  cancelInvoiceToken: string = '';
  idCancelledInvoice: number = 0;
  cancelInvoiceReference: string = '';
  dateCreatedCancelInvoice: Date = null;
  virementType: string = '';
  orders: InvoiceProducerOrderDTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.producer.InvoiceProducerDTO'] = InvoiceProducerDTO;

reverseMapping[InvoiceProducerDTO.name] = 'com.degineo.metier.invoice.dto.producer.InvoiceProducerDTO';

fields['com.degineo.metier.invoice.dto.producer.InvoiceProducerDTO']  = {
    invoiceId: 'java.lang.Integer',
    reference: 'java.lang.String',
    producerSelected: 'java.lang.Boolean',
    client: 'java.lang.String',
    status: 'com.degineo.metier.invoice.dto.producer.InvoiceProducerStatus',
    totalHT: 'java.lang.Double',
    totalTTC: 'java.lang.Double',
    dateCreated: 'java.time.Instant',
    dueDate: 'java.time.Instant',
    datePaid: 'java.time.Instant',
    invoiceToken: 'java.lang.String',
    cancelInvoiceToken: 'java.lang.String',
    idCancelledInvoice: 'java.lang.Integer',
    cancelInvoiceReference: 'java.lang.String',
    dateCreatedCancelInvoice: 'java.time.Instant',
    virementType: 'java.lang.String',
    orders: 'java.util.List<com.degineo.metier.invoice.dto.producer.InvoiceProducerOrderDTO>'
};
