// DB ENUM com.degineo.metier.metadata.entities.CharacteristicValueType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class CharacteristicValueType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly NUMBER = new CharacteristicValueType(1, "Nombre", "NUMBER");
  static readonly STRING = new CharacteristicValueType(2, "Texte", "STRING");

  static readonly values = [
    CharacteristicValueType.NUMBER, 
    CharacteristicValueType.STRING 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of CharacteristicValueType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of CharacteristicValueType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.metadata.entities.CharacteristicValueType'] = CharacteristicValueType;
reverseMapping[CharacteristicValueType.name] = 'com.degineo.metier.metadata.entities.CharacteristicValueType';
