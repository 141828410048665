// com.degineo.metier.shipping.service.ShippingBoxServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ShippingBox } from '../entities/ShippingBox';

@Injectable({
  providedIn: 'root',
})
export class ShippingBoxService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  createShippingBox(s: ShippingBox): RpcRequestBuilder<ShippingBox> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ShippingBoxService.createShippingBox';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(s, 'com.degineo.metier.shipping.entities.ShippingBox', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.shipping.entities.ShippingBox');
  }

  updateStock(idShippingBox: number, quantity: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ShippingBoxService.updateStock';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idShippingBox, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(quantity, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteShippingBox(idsShippingBox: number[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ShippingBoxService.deleteShippingBox';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idsShippingBox, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getMyShippingBoxes(): RpcRequestBuilder<ShippingBox[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ShippingBoxService.getMyShippingBoxes';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
