// ENTITY com.degineo.metier.producer.dto.dashboard.DashboardCounterDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class DashboardCounterDTO {

  nbOrderReceived: number = 0;
  nbDevisWaiting: number = 0;
  nbRevisionDevis: number = 0;
  nbLateOrder: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.dashboard.DashboardCounterDTO'] = DashboardCounterDTO;

reverseMapping[DashboardCounterDTO.name] = 'com.degineo.metier.producer.dto.dashboard.DashboardCounterDTO';

fields['com.degineo.metier.producer.dto.dashboard.DashboardCounterDTO']  = {
    nbOrderReceived: 'java.lang.Integer',
    nbDevisWaiting: 'java.lang.Integer',
    nbRevisionDevis: 'java.lang.Integer',
    nbLateOrder: 'java.lang.Integer'
};
