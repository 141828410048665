import { Directive, Input, ContentChild } from '@angular/core';
import { OrderBy } from '../../../ic2/entities/OrderBy';
import { MyColumnValue } from '../my-column-value/my-column-value.component';

@Directive({
  selector: 'degineo-my-column',
})
export class MyColumn<T> {
  constructor() {}

  _orderDisabled: boolean = false;
  public sortOrder: OrderBy = OrderBy.DESC;
  public selected = false;
  public orderIndex: number = 1;

  @Input() orderName: string;
  @Input() name: string;
  @Input() align: 'left' | 'center' | 'right' = 'center';
  @Input() set defaultOrder(val: OrderBy) {
    this.selected = true;
    this.sortOrder = val;
  }
  @Input() hideHeader: boolean = false;
  @Input() set disableOrder(val) {
    this._orderDisabled = true;
  }
  get disableOrder() {
    return this._orderDisabled;
  }
  @ContentChild(MyColumnValue) cv: MyColumnValue<T>;

  toggleSortOrder() {
    if (this.sortOrder === OrderBy.ASC) this.sortOrder = OrderBy.DESC;
    else this.sortOrder = OrderBy.ASC;
  }
}
