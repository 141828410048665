// ENTITY com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PrinterTechnology } from './PrinterTechnology';
import { CharacteristicNEW } from './CharacteristicNEW';

export class MaterialTypeCharacteristicBODTO {

  idMaterial: number = 0;
  materialName: string = '';
  parentName: string = '';
  idParent: number = 0;
  printerTechnology: PrinterTechnology = null;
  used: boolean = false;
  characts: CharacteristicNEW[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO'] = MaterialTypeCharacteristicBODTO;

reverseMapping[MaterialTypeCharacteristicBODTO.name] = 'com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO';

fields['com.degineo.metier.materialTypeCharacteristic.dto.MaterialTypeCharacteristicBODTO']  = {
    idMaterial: 'java.lang.Integer',
    materialName: 'java.lang.String',
    parentName: 'java.lang.String',
    idParent: 'java.lang.Integer',
    printerTechnology: 'com.degineo.metier.metadata.entities.PrinterTechnology',
    used: 'java.lang.Boolean',
    characts: 'java.util.List<com.degineo.metier.metadata.entities.CharacteristicNEW>'
};
