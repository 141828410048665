import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-referencement',
  templateUrl: './referencement.component.html',
  styleUrls: ['./referencement.component.scss'],
})
export class ReferencementComponent implements OnInit {
  showNotFound: boolean = false;
  producerUrl: string = environment.producerUrl;
  constructor() {}

  ngOnInit(): void {}

  search = () => {
    //arrow function because we don't bind(this) and we use this
    this.showNotFound = true;
  };
}
