// com.degineo.metier.producer.service.ProducerDashboardServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { DashboardStatsDTO } from '../entities/DashboardStatsDTO';
import { DashboardWarningsDTO } from '../entities/DashboardWarningsDTO';
import { DashboardFinancialStatsDTO } from '../entities/DashboardFinancialStatsDTO';
import { DashboardCounterDTO } from '../entities/DashboardCounterDTO';
import { DashboardPrintersDTO } from '../entities/DashboardPrintersDTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerDashboardService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getStats(): RpcRequestBuilder<DashboardStatsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDashboardService.getStats';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.dashboard.DashboardStatsDTO');
  }

  getWarnings(): RpcRequestBuilder<DashboardWarningsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDashboardService.getWarnings';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.dashboard.DashboardWarningsDTO');
  }

  getCounters(): RpcRequestBuilder<DashboardCounterDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDashboardService.getCounters';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.dashboard.DashboardCounterDTO');
  }

  getPrintersStats(): RpcRequestBuilder<DashboardPrintersDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDashboardService.getPrintersStats';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.dashboard.DashboardPrintersDTO');
  }

  getFinancialStats(): RpcRequestBuilder<DashboardFinancialStatsDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerDashboardService.getFinancialStats';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
