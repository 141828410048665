import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CookiesService } from 'ngx-utils-cookies-port';

@Injectable({
  providedIn: 'root',
})
export class LandingChoiceService {
  urlLogin: string;
  urlRegister: string;
  choice: string = null;

  constructor(private cookiesService: CookiesService) {}

  setClient() {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    this.cookiesService.put('landingChoice', 'client', { expires: date });
    this.urlLogin = environment.clientUrl + 'connexion';
    this.urlRegister = environment.clientUrl + 'inscription';
    this.choice = this.cookiesService.get('landingChoice');
  }

  setProducer() {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    this.cookiesService.put('landingChoice', 'prod', { expires: date });
    this.urlLogin = environment.producerUrl + 'connexion';
    this.urlRegister = null;
    this.choice = this.cookiesService.get('landingChoice');
  }

  isProd() {
    return this.choice === 'prod';
  }

  isClient() {
    return this.choice === 'client';
  }

  reset() {
    this.choice = null;
  }
}
