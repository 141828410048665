// com.degineo.metier.producer.service.ProducerPageServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerOpenDays } from '../entities/ProducerOpenDays';
import { BusinessTargetLevel2 } from '../entities/BusinessTargetLevel2';
import { PrinterTechnology } from '../entities/PrinterTechnology';

@Injectable({
  providedIn: 'root',
})
export class ProducerPageService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateProducerPresentation(idProducer: number, presentation: string, website: string, fb: string, twitter: string, linkedin: string, yt: string, insta: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPageService.updateProducerPresentation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(presentation, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(website, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(fb, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(twitter, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(linkedin, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(yt, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(insta, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateProducerApplicationDomains(idProducer: number, applicationsDomains: BusinessTargetLevel2[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPageService.updateProducerApplicationDomains';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(applicationsDomains, 'java.util.List<com.degineo.metier.client.entities.BusinessTargetLevel2>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateProducerImpressionTechnologies(idProducer: number, printerTechnologies: PrinterTechnology[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPageService.updateProducerImpressionTechnologies';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(printerTechnologies, 'java.util.List<com.degineo.metier.metadata.entities.PrinterTechnology>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateProducerSchedule(idProducer: number, openDays: ProducerOpenDays[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPageService.updateProducerSchedule';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(openDays, 'java.util.List<com.degineo.metier.producer.entities.ProducerOpenDays>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateProducerContact(idProducer: number, mail: string, phone: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPageService.updateProducerContact';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(mail, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(phone, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateProducerVisibility(idProducer: number, visible: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPageService.updateProducerVisibility';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(visible, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  uploadPicture(idProducer: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerPageService.uploadPicture';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
