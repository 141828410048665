// ENUM com.ic2.db.entityManager.utils.OrderBy generated by ic2 3.0.0-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class OrderBy {
  static readonly enum: boolean = true;
  
  constructor(public name: string) {
  }
  
  static readonly ASC = new OrderBy("ASC");
  static readonly DESC = new OrderBy("DESC");

  static readonly values = [
    OrderBy.ASC,
    OrderBy.DESC
  ];

  public toString(): string {
    return this.name;
  }
  public static map(name: string) {
    for (const e of OrderBy.values)
      if (e.name === name)
        return e;
    return null;
  }

}

mapping['com.ic2.db.entityManager.utils.OrderBy'] = OrderBy;
reverseMapping[OrderBy.name] = 'com.ic2.db.entityManager.utils.OrderBy';

