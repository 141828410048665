// ENTITY com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentOrderPanelDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';
import { ClientInfosForKanbanPopupDTO } from './ClientInfosForKanbanPopupDTO';

export class ShipmentOrderPanelDTO {

  ready: boolean = false;
  waitForIt: boolean = false;
  alreadyInAnotherShipment: boolean = false;
  idShipmentAlreadyIn: number = 0;
  idOrder: number = 0;
  clientChoosedThisProducer: boolean = false;
  client: ClientInfosForKanbanPopupDTO = null;
  wishedDeliveryDate: Date = null;
  dateShippingPreparation: Date = null;
  colorName: string = '';
  colorHex: string = '';
  comment: string = '';
  noteDegineo: string = '';
  quantity: number = 0;
  finishName: string = '';
  sizeXMm: number = 0;
  sizeYMm: number = 0;
  sizeZMm: number = 0;
  token3DModelFinal: string = '';
  filenameFinal: string = '';
  shippingAddress: AddressNEW = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentOrderPanelDTO'] = ShipmentOrderPanelDTO;

reverseMapping[ShipmentOrderPanelDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentOrderPanelDTO';

fields['com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentOrderPanelDTO']  = {
    ready: 'java.lang.Boolean',
    waitForIt: 'java.lang.Boolean',
    alreadyInAnotherShipment: 'java.lang.Boolean',
    idShipmentAlreadyIn: 'java.lang.Integer',
    idOrder: 'java.lang.Integer',
    clientChoosedThisProducer: 'java.lang.Boolean',
    client: 'com.degineo.metier.order.dto.kanban.panel.ClientInfosForKanbanPopupDTO',
    wishedDeliveryDate: 'java.time.Instant',
    dateShippingPreparation: 'java.time.Instant',
    colorName: 'java.lang.String',
    colorHex: 'java.lang.String',
    comment: 'java.lang.String',
    noteDegineo: 'java.lang.String',
    quantity: 'java.lang.Integer',
    finishName: 'java.lang.String',
    sizeXMm: 'java.lang.Double',
    sizeYMm: 'java.lang.Double',
    sizeZMm: 'java.lang.Double',
    token3DModelFinal: 'java.lang.String',
    filenameFinal: 'java.lang.String',
    shippingAddress: 'com.degineo.metier.address.entities.AddressNEW'
};
