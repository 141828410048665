// DB ENUM com.degineo.metier.shipping.entities.ShipmentStatus generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class ShipmentStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly IN_PREPARATION = new ShipmentStatus(1, "En prÃ©paration", "IN_PREPARATION");
  static readonly PACKAGE_PREPARATION = new ShipmentStatus(2, "En colisage", "PACKAGE_PREPARATION");
  static readonly CALL_CARRIER_PROGRESS = new ShipmentStatus(3, "Appel transporteur en cours", "CALL_CARRIER_PROGRESS");
  static readonly CALL_CARRIER_ERROR = new ShipmentStatus(4, "Erreur appel transporteur", "CALL_CARRIER_ERROR");
  static readonly WAITING_CARRIER = new ShipmentStatus(5, "En attente transporteur", "WAITING_CARRIER");
  static readonly TRANSPORT = new ShipmentStatus(6, "En cours de transport", "TRANSPORT");
  static readonly RECEIVED = new ShipmentStatus(7, "ReÃ§u", "RECEIVED");

  static readonly values = [
    ShipmentStatus.IN_PREPARATION, 
    ShipmentStatus.PACKAGE_PREPARATION, 
    ShipmentStatus.CALL_CARRIER_PROGRESS, 
    ShipmentStatus.CALL_CARRIER_ERROR, 
    ShipmentStatus.WAITING_CARRIER, 
    ShipmentStatus.TRANSPORT, 
    ShipmentStatus.RECEIVED 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ShipmentStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ShipmentStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.shipping.entities.ShipmentStatus'] = ShipmentStatus;
reverseMapping[ShipmentStatus.name] = 'com.degineo.metier.shipping.entities.ShipmentStatus';
