import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { L } from 'ic2-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ResetPwdService } from '../../ic2/services/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-forgot-pwd-popup',
  templateUrl: './forgot-pwd-popup.component.html',
  styleUrls: ['./forgot-pwd-popup.component.scss'],
})
export class ForgotPwdPopupComponent implements OnInit {
  confirm: boolean = false;
  email: string = null;
  submitted: boolean = false;
  form: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });
  producer: boolean = false;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) private router: Router,
    @Inject(ResetPwdService) private resetPwdService: ResetPwdService,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.authService.isLoggedIn().subscribe((data) => {
      if (data) this.form.patchValue({ email: this.authService.userBundle.user.email });
    });
  }

  close() {
    this.activeModal.close();
    /*
    this.authService.isLoggedIn().subscribe((data) => {
      if (data) this.router.navigate(['/']);
      else this.router.navigate(['/connexion']);
    });
    */
  }

  reinitializePwd() {
    this.submitted = true;
    if (this.form.invalid) {
      console.log(this.form);
      return;
    }
    this.resetPwdService.forgotPassword(this.form.value.email, this.producer).subscribe(
      (data) => {
        this.confirm = true;
      },
      (error) => {
        if (error.isBusinessError()) this.form.get('email').setErrors({ 'non-existant': true });
        else L.e(error);
      }
    );
  }
}
