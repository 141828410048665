export const environment = {
  production: true,
  name: 'RECETTE',
  rpcHost: 'https://api.recette.degineo.com/',
  ssrRpcHost: 'http://rct_api:8080/',
  webappUrl: 'https://recette.degineo.com',
  countries: ['France', 'Belgique', 'Suisse', 'Luxembourg'],
  gmapsApiKey: 'AIzaSyDGb4b9a_qHVtAezYnzgXQfSZZ0Hz1KBBw',
  tva: 20,
  clientUrl: 'https://client.recette.degineo.com/',
  boUrl: 'https://bo.recette.degineo.com/',
  producerUrl: 'https://producteur.recette.degineo.com/',
  svUrl: 'https://recette.degineo.com/',
  stripePublishableKey: 'pk_test_aZFCcrqnvmjGajCjklOEz2WB',
  paypalClientId: 'AdK6mlpbsWJx2XYuWABg8D-vCpRhJP8scnZ5t5qahqn5lyfbC71Avbs3bPDXM0FRIzdiehJCaD4UCFVr',
  intercomAppId: 'w04g0icr',
};
