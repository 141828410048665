// ENTITY com.degineo.metier.metadata.entities.MaterialTypeNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PrinterTechnology } from './PrinterTechnology';

export class MaterialTypeNEW {

  id: number = 0;
  name: string = '';
  idParent: number = 0;
  order: number = 0;
  printerTechnology: PrinterTechnology = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.entities.MaterialTypeNEW'] = MaterialTypeNEW;

reverseMapping[MaterialTypeNEW.name] = 'com.degineo.metier.metadata.entities.MaterialTypeNEW';

fields['com.degineo.metier.metadata.entities.MaterialTypeNEW']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    idParent: 'java.lang.Integer',
    order: 'java.lang.Integer',
    printerTechnology: 'com.degineo.metier.metadata.entities.PrinterTechnology'
};
