// ENTITY com.degineo.metier.invoice.dto.BillerDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class BillerDTO {

  idCompany: number = 0;
  name: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.BillerDTO'] = BillerDTO;

reverseMapping[BillerDTO.name] = 'com.degineo.metier.invoice.dto.BillerDTO';

fields['com.degineo.metier.invoice.dto.BillerDTO']  = {
    idCompany: 'java.lang.Integer',
    name: 'java.lang.String'
};
