// DB ENUM com.degineo.metier.DegineoRight generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class DegineoRight {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ADMIN = new DegineoRight(8, "Admin", "ADMIN");
  static readonly CLIENT = new DegineoRight(6, "Client", "CLIENT");
  static readonly PRODUCER = new DegineoRight(7, "Producteur", "PRODUCER");

  static readonly values = [
    DegineoRight.ADMIN, 
    DegineoRight.CLIENT, 
    DegineoRight.PRODUCER 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of DegineoRight.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of DegineoRight.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.DegineoRight'] = DegineoRight;
reverseMapping[DegineoRight.name] = 'com.degineo.metier.DegineoRight';
