// ENTITY com.degineo.metier.basket.dto.BasketDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { BasketArticleClientDTO } from './BasketArticleClientDTO';

export class BasketDTO {

  totalTTC: number = 0;
  VATAmount: number = 0;
  articles: BasketArticleClientDTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.basket.dto.BasketDTO'] = BasketDTO;

reverseMapping[BasketDTO.name] = 'com.degineo.metier.basket.dto.BasketDTO';

fields['com.degineo.metier.basket.dto.BasketDTO']  = {
    totalTTC: 'java.lang.Double',
    VATAmount: 'java.lang.Double',
    articles: 'java.util.List<com.degineo.metier.basket.dto.BasketArticleClientDTO>'
};
