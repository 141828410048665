// com.degineo.metier.basket.service.PaymentServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { CreditCardNEW } from '../entities/CreditCardNEW';
import { IbanBicDTO } from '../entities/IbanBicDTO';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  paiementStripeOldCard(idBasketArticles: number[], idShippingAddress: number, idBillingShipping: number, idCreditCard: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaymentService.paiementStripeOldCard';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBasketArticles, 'java.util.List<java.lang.Integer>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idShippingAddress, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idBillingShipping, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idCreditCard, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  canDelayPayment(idBasketArticles: number[]): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaymentService.canDelayPayment';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBasketArticles, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getCustomerCreditCards(): RpcRequestBuilder<CreditCardNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaymentService.getCustomerCreditCards';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  disableCard(idCard: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaymentService.disableCard';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCard, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getDegineoBankInfos(): RpcRequestBuilder<IbanBicDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaymentService.getDegineoBankInfos';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.basket.dto.IbanBicDTO');
  }

  paiementStripeNewCard(idBasketArticles: number[], idShippingAddress: number, idBillingShipping: number, stripeToken: string, cardOwner: string, saveNewCard: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaymentService.paiementStripeNewCard';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBasketArticles, 'java.util.List<java.lang.Integer>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idShippingAddress, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idBillingShipping, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(stripeToken, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(cardOwner, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(saveNewCard, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  paiementVirement(idBasketArticles: number[], idShippingAddress: number, idBillingShipping: number, delayed: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaymentService.paiementVirement';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBasketArticles, 'java.util.List<java.lang.Integer>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idShippingAddress, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idBillingShipping, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(delayed, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  paiementPaypal(idBasketArticles: number[], idShippingAddress: number, idBillingShipping: number, paypalData: any): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PaymentService.paiementPaypal';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBasketArticles, 'java.util.List<java.lang.Integer>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idShippingAddress, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idBillingShipping, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(paypalData, 'com.ic2.json.JSONObject', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
