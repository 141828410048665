// ENTITY com.degineo.metier.notif.dto.NotifDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { NotifType } from './NotifType';

export class NotifDTO {

  type: NotifType = null;
  data: any = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.notif.dto.NotifDTO'] = NotifDTO;

reverseMapping[NotifDTO.name] = 'com.degineo.metier.notif.dto.NotifDTO';

fields['com.degineo.metier.notif.dto.NotifDTO']  = {
    type: 'com.degineo.metier.notif.dto.NotifType',
    data: 'com.ic2.json.JSONObject'
};
