import { TemplateRef, Directive } from '@angular/core';

interface ColumnValueContext<T> {
  $implicit: T;
}

@Directive({
  selector: '[cv]',
})
export class MyColumnValue<T> {
  constructor(public columnValueTemplate: TemplateRef<ColumnValueContext<T>>) {}
}
