import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { L } from 'ic2-lib';
import { LoginService } from '../../ic2/services/services';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'lib-autolog',
  templateUrl: './autolog.component.html',
  styleUrls: ['./autolog.component.scss'],
})
export class AutologComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService, private route: ActivatedRoute, private loginService: LoginService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['token']) {
        this.authService.irpc.authToken = params['token'];
        this.loginService.getBundle().subscribe(
          (data) => {
            this.authService.loginWith(data, () => {
              this.router.navigate(['/']);
            });
          },
          (err) => {
            L.e(err);
            this.router.navigate(['/']);
          }
        );
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
