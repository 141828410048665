// ENTITY com.degineo.metier.producer.dto.bo.ProducerCompanyListItemBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';

export class ProducerCompanyListItemBODTO {

  id: number = 0;
  name: string = '';
  producerLogoToken: string = '';
  address: AddressNEW = null;
  dateCreacted: Date = null;
  dateLastConnexion: Date = null;
  subscribed: boolean = false;
  dateSub: Date = null;
  unsub: boolean = false;
  register: boolean = false;
  nbPrinter: number = 0;
  nbCli: number = 0;
  nbPrinterConsumableWarn: number = 0;
  caTotal: number = null;
  logoToken: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.bo.ProducerCompanyListItemBODTO'] = ProducerCompanyListItemBODTO;

reverseMapping[ProducerCompanyListItemBODTO.name] = 'com.degineo.metier.producer.dto.bo.ProducerCompanyListItemBODTO';

fields['com.degineo.metier.producer.dto.bo.ProducerCompanyListItemBODTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    producerLogoToken: 'java.lang.String',
    address: 'com.degineo.metier.address.entities.AddressNEW',
    dateCreacted: 'java.time.Instant',
    dateLastConnexion: 'java.time.Instant',
    subscribed: 'java.lang.Boolean',
    dateSub: 'java.time.Instant',
    unsub: 'java.lang.Boolean',
    register: 'java.lang.Boolean',
    nbPrinter: 'java.lang.Integer',
    nbCli: 'java.lang.Integer',
    nbPrinterConsumableWarn: 'java.lang.Integer',
    caTotal: 'java.lang.Float',
    logoToken: 'java.lang.String'
};
