import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LandingChoiceService } from '../landing-choice.service';

@Component({
  selector: 'app-sv-layout',
  templateUrl: './sv-layout.component.html',
  styleUrls: ['./sv-layout.component.scss'],
})
export class SvLayoutComponent implements OnInit, OnDestroy {
  _routerSub: Subscription;
  displayFooter: boolean = true;
  displayHeader: boolean = true;
  constructor(private landingChoiceService: LandingChoiceService, private router: Router, private route: ActivatedRoute) {
    this._routerSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.displayFooter = true;
        if (route.data && route.data.hasOwnProperty('displayFooter')) {
          this.displayFooter = route.data.displayFooter;
        }
        this.displayHeader = true;
        if (route.data && route.data.hasOwnProperty('displayHeader')) {
          this.displayHeader = route.data.displayHeader;
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }

  getLanding() {
    if (this.landingChoiceService.choice !== null) return true;
    else return false;
  }
}
