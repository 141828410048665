// ENTITY com.degineo.metier.producer.dto.ProducerSignupStep9DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerSignupStep9DTO {

  stripeSetupIntentClientSecret: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  iban: string = '';
  bic: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerSignupStep9DTO'] = ProducerSignupStep9DTO;

reverseMapping[ProducerSignupStep9DTO.name] = 'com.degineo.metier.producer.dto.ProducerSignupStep9DTO';

fields['com.degineo.metier.producer.dto.ProducerSignupStep9DTO']  = {
    stripeSetupIntentClientSecret: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    email: 'java.lang.String',
    iban: 'java.lang.String',
    bic: 'java.lang.String'
};
