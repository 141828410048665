// com.degineo.metier.articleType.service.ArticleServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ArticleTypeNEW } from '../entities/ArticleTypeNEW';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getAllArticleTypes(): RpcRequestBuilder<ArticleTypeNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ArticleService.getAllArticleTypes';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getAllActiveArticleTypes(): RpcRequestBuilder<ArticleTypeNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ArticleService.getAllActiveArticleTypes';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
