// ENTITY com.degineo.metier.tools.DegineoUserBundleV2 generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { User } from './User';
import { UserRight } from './UserRight';
import { DegineoUser } from './DegineoUser';
import { RoleInCompany } from './RoleInCompany';
import { Client } from './Client';

export class DegineoUserBundleV2 {

  user: User = null;
  rights: UserRight[] = null;
  token: string = '';
  du: DegineoUser = null;
  logoToken: string = '';
  client: Client = null;
  idProducer: number = 0;
  producerName: string = '';
  role: RoleInCompany = null;
  subscribed: boolean = false;
  useVAT: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.tools.DegineoUserBundleV2'] = DegineoUserBundleV2;

reverseMapping[DegineoUserBundleV2.name] = 'com.degineo.metier.tools.DegineoUserBundleV2';

fields['com.degineo.metier.tools.DegineoUserBundleV2']  = {
    user: 'com.ic2.entity.User',
    rights: 'java.util.List<com.ic2.entity.UserRight>',
    token: 'java.lang.String',
    du: 'com.degineo.metier.user.entities.DegineoUser',
    logoToken: 'java.lang.String',
    client: 'com.degineo.metier.client.entities.Client',
    idProducer: 'java.lang.Integer',
    producerName: 'java.lang.String',
    role: 'com.degineo.metier.user.entities.RoleInCompany',
    subscribed: 'java.lang.Boolean',
    useVAT: 'java.lang.Boolean'
};
