// ENTITY com.degineo.metier.producer.entities.ProducerOpenDays generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerOpenDays {

  id: number = 0;
  idProducer: number = 0;
  day: number = 0;
  open: boolean = false;
  schedule1Open: string = '';
  schedule1End: string = '';
  schedule2Open: string = '';
  schedule2End: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.entities.ProducerOpenDays'] = ProducerOpenDays;

reverseMapping[ProducerOpenDays.name] = 'com.degineo.metier.producer.entities.ProducerOpenDays';

fields['com.degineo.metier.producer.entities.ProducerOpenDays']  = {
    id: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    day: 'java.lang.Integer',
    open: 'java.lang.Boolean',
    schedule1Open: 'java.lang.String',
    schedule1End: 'java.lang.String',
    schedule2Open: 'java.lang.String',
    schedule2End: 'java.lang.String'
};
