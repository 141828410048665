// com.degineo.metier.producer_subscription.service.ProducerSubscriptionServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerSubscriptionInfosDTO } from '../entities/ProducerSubscriptionInfosDTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { SubscriptionInvoiceDTO } from '../entities/SubscriptionInvoiceDTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerSubscriptionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getInfos(): RpcRequestBuilder<ProducerSubscriptionInfosDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionService.getInfos';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.producer.dto.ProducerSubscriptionInfosDTO');
  }

  getSubscriptionInvoices(filter: GenericFilter): RpcRequestBuilder<ListWithCount<SubscriptionInvoiceDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSubscriptionService.getSubscriptionInvoices';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
