// ENTITY com.degineo.metier.producer.dto.ProducerSignupStep4DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { LegalStatus } from './LegalStatus';
import { AddressNEW } from './AddressNEW';

export class ProducerSignupStep4DTO {

  siret: string = '';
  name: string = '';
  legalStatus: LegalStatus = null;
  capital: number = 0;
  RCS: string = '';
  RNANumber: string = '';
  billingAddress: AddressNEW = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerSignupStep4DTO'] = ProducerSignupStep4DTO;

reverseMapping[ProducerSignupStep4DTO.name] = 'com.degineo.metier.producer.dto.ProducerSignupStep4DTO';

fields['com.degineo.metier.producer.dto.ProducerSignupStep4DTO']  = {
    siret: 'java.lang.String',
    name: 'java.lang.String',
    legalStatus: 'com.degineo.metier.producer.entities.LegalStatus',
    capital: 'java.lang.Double',
    RCS: 'java.lang.String',
    RNANumber: 'java.lang.String',
    billingAddress: 'com.degineo.metier.address.entities.AddressNEW'
};
