// ENTITY com.degineo.metier.order.dto.PrinterSizeDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class PrinterSizeDTO {

  minimalPrintingSize: number = 0;
  sizeXmm: number = 0;
  sizeYmm: number = 0;
  sizeZmm: number = 0;
  volume: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.PrinterSizeDTO'] = PrinterSizeDTO;

reverseMapping[PrinterSizeDTO.name] = 'com.degineo.metier.order.dto.PrinterSizeDTO';

fields['com.degineo.metier.order.dto.PrinterSizeDTO']  = {
    minimalPrintingSize: 'java.lang.Double',
    sizeXmm: 'java.lang.Double',
    sizeYmm: 'java.lang.Double',
    sizeZmm: 'java.lang.Double',
    volume: 'java.lang.Double'
};
