import { Component, OnInit, Inject, ViewEncapsulation, PLATFORM_ID, Input } from '@angular/core';
import { CustomerFormService } from '../../customer-form.service';
import { Router } from '@angular/router';
import { ProducerSVService, ProducerSVListDTO, SlugService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-customer-area-search',
  templateUrl: './customer-area-search.component.html',
  styleUrls: ['./customer-area-search.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)',
  },
  encapsulation: ViewEncapsulation.None,
})
export class CustomerAreaSearchComponent implements OnInit {
  private readonly PAGE = 1;
  private readonly ITEMPERPAGE = 20;

  @Input()
  placeholder: string = 'Écrivez le Nom de votre Imprimeur 3D ou sa Ville';
  searchText: string = null;
  producersSearch: ProducerSVListDTO[] = [];
  err: string = '';
  maxItems: number = 10;
  rpcHost = environment.rpcHost;
  innerHeight: any;
  focused: boolean = false;
  presenterName = (obj: ProducerSVListDTO) => obj.name;

  constructor(
    public customerFormService: CustomerFormService,
    private router: Router,
    @Inject(ProducerSVService) private producerSVService: ProducerSVService,
    @Inject(SlugService) public slugService: SlugService,
    @Inject(PLATFORM_ID) private platform: Object
  ) {}

  onResize(event) {
    this.innerHeight = event.target.innerHeight;
    if (this.innerHeight > 1000) {
      this.maxItems = 10;
    } else this.maxItems = Math.floor(this.innerHeight / 100);
    if (this.maxItems < 3) this.maxItems = 3;
  }

  ngOnInit() {
    if (this.searchText !== '' && this.searchText != null) this.entrepriseTextChange(this.searchText);
    if (!isPlatformBrowser(this.platform)) {
      this.maxItems = 10;
    } else {
      this.innerHeight = window.innerHeight;
      if (this.innerHeight > 1000) {
        this.maxItems = 10;
      } else this.maxItems = Math.floor(this.innerHeight / 100);
      if (this.maxItems < 3) this.maxItems = 3;
    }
  }

  @Input()
  searchEmpty = () => {
    if (this.producersSearch && this.searchText)
      if (this.producersSearch.length === 0)
        if (this.searchText.length > 0) {
          let name: string = this.searchText;
          if (name != null) {
            name = name.trim();
            name = name.replace(/\s+/g, ' ');
          }
          if (name != null) {
            name = name.trim();
            name = name.replace(/\s+/g, ' ');
            this.producerSVService.getProducersByName(this.PAGE, this.ITEMPERPAGE, name).subscribe(
              (data) => {
                if (data.data.length === 0) {
                  this.customerFormService.producers = data.data;
                  this.customerFormService.count = data.count;
                  this.customerFormService.fromForm = false;
                  this.customerFormService.nameSearch = name;
                  this.router.navigate(['/annuaire']);
                }
              },
              (err) => {
                L.e(err);
              }
            );
          }
        }
  };

  entrepriseTextChange(producer) {
    console.log('text :', producer);
    this.searchText = producer;
    let prod: string;
    if (producer != null && producer.name !== '') {
      if (typeof producer === 'string') {
        this.customerFormService.nameSearch = producer;
        prod = producer;
      } else {
        this.customerFormService.nameSearch = producer.name;
        prod = producer.name;
      }
      this.err = '';
      this.producerSVService.searchProducer(prod).subscribe(
        (data) => {
          this.producersSearch = data;
          console.log(data);
        },
        (error) => {
          L.e(error);
        }
      );
    } else {
      this.err = '';
      this.searchText = null;
      this.producersSearch = [];
    }
  }

  entrepriseChanged(producer: ProducerSVListDTO) {
    if (producer !== null) {
      //Redirection vers page producteur
      this.router.navigate([
        '/' +
          (producer.businessType === null ? 'type' : this.slugService.string_to_slug(producer.businessType.lib)) +
          '/' +
          this.slugService.string_to_slug(producer.city) +
          '/' +
          this.slugService.string_to_slug(producer.name) +
          '/' +
          producer.id,
      ]);
      // Redirection vers annuaire
      /*this.producerSVService.getProducersByName(this.PAGE, this.ITEMPERPAGE, producer.name).subscribe(
        (data) => {
          this.customerFormService.producers = data.data;
          this.customerFormService.count = data.count;
          this.customerFormService.fromForm = false;
          this.customerFormService.nameSearch = producer.name;
          this.searchText = producer.name;
          if (this.router.url === '/annuaire') {
          } else this.router.navigate(['/annuaire']);
        },
        (error) => {
          L.e(error);
        }
      );*/
    } else {
      this.searchEmpty();
    }
  }
}
