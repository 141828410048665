<div class="container" style="max-width: 800px">
  <div class="row justify-content-center">
    <h1 class="col-12 text-center text-primary mb-5 mt-5">Engagement de confidentialité</h1>

    <div class="col-12">
      <p class="mb-4">Degineo fait de la protection et de la confidentialité de vos informations utilisateurs, une priorité.</p>
      <p class="mb-4">L’ensemble de vos données est entièrement crypté et stocké sur nos serveurs sécurisés.</p>
      <p class="mb-4">
        Toutes les informations utilisateurs que nous vous demandons ont comme unique but d’assurer le bon fonctionnement de nos services afin de répondre au
        mieux à vos besoins. Elles ne seront absolument jamais utilisées à des fins commerciales ou divulguées à quelques autres entités que ce soit ou toutes
        autres personnes extérieures, et leur consultation sont uniquement et strictement restreintes aux personnes de Degineo concernées.
      </p>
      <p class="mb-5">
        L’utilisation des services Degineo n’affecte en rien la propriété intellectuelle ainsi que la confidentialité des contenus que vous nous transmettez.
        Vos données ainsi que vos contenus restent dans leur entièreté votre propriété.
      </p>
    </div>

    <div class="col-12">
      <div class="container-fluid">
        <div class="row px-0 mb-5">
          <img class="col-6 col-sm-4 col-md-2 p-0 align-self-center" height="100%" src="assets/privacy-notice/logo_cnil.png" alt="Logo CNIL" />
          <div class="col">
            <p class="text-secondary mb-0 fs-2">
              Le service Degineo a fait l’objet d’une déclaration concernant les traitements automatisés de données à caractère personnelles relatifs à la
              gestion de clients et de prospects auprès de la Commission Nationale Informatique et Libertés (CNIL) sous le n° 2038346 v 0 le 23 février 2017.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
