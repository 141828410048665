import { Directive, ContentChildren, QueryList } from '@angular/core';
import { MyColumn } from '../my-column/my-column.component';

@Directive({
  selector: 'degineo-my-footer',
})
export class MyFooter<T> {
  @ContentChildren(MyColumn) columns: QueryList<MyColumn<T>>;

  constructor() {}
}
