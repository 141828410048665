// com.degineo.metier.basket.service.PrintServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ModelData } from '../entities/ModelData';
import { CharacteristicsDisplayDTO } from '../entities/CharacteristicsDisplayDTO';
import { SizeDTO } from '../entities/SizeDTO';
import { MarqueBlancheDateDTO } from '../entities/MarqueBlancheDateDTO';
import { ColorListDTO } from '../entities/ColorListDTO';
import { BusinessTargetLevel1 } from '../entities/BusinessTargetLevel1';
import { PrinterSizeDTO } from '../entities/PrinterSizeDTO';
import { PrintStep1DTO } from '../entities/PrintStep1DTO';
import { Finish } from '../entities/Finish';
import { MaterialTypeNEW } from '../entities/MaterialTypeNEW';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFileName(fileHash: string): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getFileName';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fileHash, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  getPossibleMaterials(idProducteur: number, target: BusinessTargetLevel1, idArticleType: number, sizeInMm: SizeDTO): RpcRequestBuilder<MaterialTypeNEW[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getPossibleMaterials';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducteur, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getProducers(): RpcRequestBuilder<PrintStep1DTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getProducers';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.basket.dto.PrintStep1DTO');
  }

  deleteFile(fileHash: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.deleteFile';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fileHash, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  uploadFile(): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.uploadFile';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  getFileDimensions(fileHash: string): RpcRequestBuilder<ModelData> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getFileDimensions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fileHash, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.ModelData');
  }

  getPrinterSizes(idProducer: number, target: BusinessTargetLevel1, idArticleType: number): RpcRequestBuilder<PrinterSizeDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getPrinterSizes';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getPossibleColors(idProducteur: number, target: BusinessTargetLevel1, idArticleType: number, idMaterial: number, sizeInMm: SizeDTO): RpcRequestBuilder<ColorListDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getPossibleColors';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducteur, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.ColorListDTO');
  }

  getPossibleFinish(idProducteur: number, target: BusinessTargetLevel1, idArticleType: number, idMaterial: number, idColor: number, sizeInMm: SizeDTO): RpcRequestBuilder<Finish[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getPossibleFinish';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducteur, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getCharacteristics(idProducteur: number, target: BusinessTargetLevel1, idArticleType: number, idMaterial: number, idColor: number, idFinish: number, sizeInMm: SizeDTO): RpcRequestBuilder<CharacteristicsDisplayDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getCharacteristics';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducteur, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticleType, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getNextAvailableDeliveryDate(idProducer: number): RpcRequestBuilder<MarqueBlancheDateDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PrintService.getNextAvailableDeliveryDate';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.MarqueBlancheDateDTO');
  }

}
