// ENTITY com.degineo.metier.plan.dto.EvolutionPlanInfoDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { LegalStatus } from './LegalStatus';

export class EvolutionPlanInfoDTO {

  lastNameHolder: string = '';
  firstNameHolder: string = '';
  currentPlanName: string = '';
  legalStatus: LegalStatus = null;
  idPlan: number = 0;
  currentNbPrinter: number = 0;
  currentNbUser: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.plan.dto.EvolutionPlanInfoDTO'] = EvolutionPlanInfoDTO;

reverseMapping[EvolutionPlanInfoDTO.name] = 'com.degineo.metier.plan.dto.EvolutionPlanInfoDTO';

fields['com.degineo.metier.plan.dto.EvolutionPlanInfoDTO']  = {
    lastNameHolder: 'java.lang.String',
    firstNameHolder: 'java.lang.String',
    currentPlanName: 'java.lang.String',
    legalStatus: 'com.degineo.metier.producer.entities.LegalStatus',
    idPlan: 'java.lang.Integer',
    currentNbPrinter: 'java.lang.Integer',
    currentNbUser: 'java.lang.Integer'
};
