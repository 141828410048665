// ENTITY com.degineo.metier.client.entities.CreditCardNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class CreditCardNEW {

  id: number = 0;
  idClient: number = 0;
  cardLast4Digits: string = '';
  expMonth: number = 0;
  expYear: number = 0;
  brand: string = '';
  fingerPrint: string = '';
  stripeCardId: string = '';
  cardOwner: string = '';
  del: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.client.entities.CreditCardNEW'] = CreditCardNEW;

reverseMapping[CreditCardNEW.name] = 'com.degineo.metier.client.entities.CreditCardNEW';

fields['com.degineo.metier.client.entities.CreditCardNEW']  = {
    id: 'java.lang.Integer',
    idClient: 'java.lang.Integer',
    cardLast4Digits: 'java.lang.String',
    expMonth: 'java.lang.Integer',
    expYear: 'java.lang.Integer',
    brand: 'java.lang.String',
    fingerPrint: 'java.lang.String',
    stripeCardId: 'java.lang.String',
    cardOwner: 'java.lang.String',
    del: 'java.lang.Boolean'
};
