import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as THREE from 'three-full';

@Component({
  selector: 'degineo-preview-modele',
  templateUrl: './preview-modele.component.html',
  styleUrls: ['./preview-modele.component.scss'],
})
export class PreviewModeleComponent implements OnInit, OnChanges, AfterViewInit {
  private canEleId = 'renderCanvas';
  private canvas: HTMLCanvasElement;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private light: THREE.AmbientLight;
  private dlight: THREE.PointLight;
  private controls;
  public onSceneCreated: () => void = null;
  @Input()
  meshColor = '#eee';
  backgroundColor = '#515151';
  @Input()
  modelUrl: string;
  @ViewChild('canvas')
  theCanvas: ElementRef;
  loaded: boolean = false;
  constructor() {}

  WEBGL = {
    isWebGLAvailable: function () {
      try {
        var canvas = document.createElement('canvas');
        return !!((<any>window).WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
      } catch (e) {
        return false;
      }
    },

    isWebGL2Available: function () {
      try {
        var canvas = document.createElement('canvas');
        return !!((<any>window).WebGL2RenderingContext && canvas.getContext('webgl2'));
      } catch (e) {
        return false;
      }
    },

    getWebGLErrorMessage: function () {
      return this.getErrorMessage(1);
    },

    getWebGL2ErrorMessage: function () {
      return this.getErrorMessage(2);
    },

    getErrorMessage: function (version) {
      var names = {
        1: 'WebGL',
        2: 'WebGL 2',
      };

      var contexts = {
        1: (<any>window).WebGLRenderingContext,
        2: (<any>window).WebGL2RenderingContext,
      };

      var message = 'Your $0 does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color:#000">$1</a>';

      var element = document.createElement('div');
      element.id = 'webglmessage';
      element.style.fontFamily = 'monospace';
      element.style.fontSize = '13px';
      element.style.fontWeight = 'normal';
      element.style.textAlign = 'center';
      element.style.background = '#fff';
      element.style.color = '#000';
      element.style.padding = '1.5em';
      element.style.width = '400px';
      element.style.margin = '5em auto 0';

      if (contexts[version]) {
        message = message.replace('$0', 'graphics card');
      } else {
        message = message.replace('$0', 'browser');
      }

      message = message.replace('$1', names[version]);

      element.innerHTML = message;

      return element;
    },
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modelUrl && this.loaded) {
      console.log(changes);
      this.load3DModel(this.modelUrl);
    }
    if (changes.meshColor && this.loaded) {
      console.log(changes);
      this.setColor(this.meshColor);
    }
  }

  ngAfterViewInit() {
    this.canvas = <HTMLCanvasElement>this.theCanvas.nativeElement;

    if (this.WEBGL.isWebGLAvailable()) {
      // Initiate function or other initializations here
      this.createScene(this.canEleId);
      this.animate();
    } else {
      const warning = this.WEBGL.getWebGLErrorMessage();
      document.getElementById('error').appendChild(warning);
    }
    this.loaded = true;
    console.log('ngAfterViewInit', this);
    if (this.modelUrl) this.load3DModel(this.modelUrl);
  }

  ngOnInit() {}

  createScene(elementId: string): void {
    this.canvas.parentElement.addEventListener('resize', (evt) => {
      this.renderer.setSize(this.canvas.parentElement.offsetWidth, this.canvas.parentElement.offsetWidth, false); //volontairement un carrÃ© en se basant que sur la width
    });

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true, // transparent background
      antialias: true, // smooth edges
    });
    this.renderer.setSize(this.canvas.parentElement.offsetWidth, this.canvas.parentElement.offsetWidth, false); //volontairement un carrÃ© en se basant que sur la width
    this.renderer.setClearColor(new THREE.Color(this.backgroundColor), 1);

    //TODO Remove test stuff
    /*let md = {
      class: "com.ic2.jsonrpc.JSONRpcResponse",
      result: {
        class: "com.degineo.metier.client.order.dto.ModelData",
        triangleCount: 6004,
        verticeCount: 6081,
        meshCount: 2,
        x: 3,
        y: 5,
        z: 10,
        xl: 0,
        yl: 0,
        zl: 0
      },
      id: 5,
      jsonrpc: "2.0"
    };*/

    //this.load3DModel("assets/Model3D/Cubox10cm.stl", JSONSerializer.fromJSON(md).result, "#ff0000");
    //this.load3DModel("assets/Model3D/Comode.3ds",JSONSerializer.fromJSON(md).result, "#ff0000");
    //this.load3DModel("assets/Model3D/Clocher.obj", JSONSerializer.fromJSON(md).result,"#ff0000");
    //this.load3DModel("assets/Model3D/low-poly-mill.fbx", JSONSerializer.fromJSON(md).result,"#ff0000");
    //this.load3DModel("assets/Model3D/Trex.obj", JSONSerializer.fromJSON(md).result,"#1b5dc6");

    //(this.camera as THREE.Object3D).lookat(modelData.xl , modelData.yl, modelData.zl);
  }

  animate(): void {
    console.log('rendering', this);
    this.render();

    window.addEventListener('resize', () => {
      this.resize();
    });
  }

  render() {
    requestAnimationFrame(() => this.render());
    if (this.controls) this.controls.update();
    if (this.renderer.resetGLState) this.renderer.resetGLState();
    if (this.scene && this.camera) this.renderer.render(this.scene, this.camera);
  }

  resize() {
    const width = this.canvas.parentElement.offsetWidth;
    const height = this.canvas.parentElement.offsetWidth;

    if (this.camera) {
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }

    this.renderer.setSize(width, height, false);
  }

  clearPreview() {
    // create the scene
    this.scene = new THREE.Scene();
    /*
    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
    */
    this.camera = new THREE.PerspectiveCamera(75, this.canvas.offsetWidth / this.canvas.offsetHeight, 0.1, 100000000);
    this.camera.position.z = 5;
    this.scene.add(this.camera);

    // soft white light
    this.light = new THREE.AmbientLight(0xffffff);
    this.light.position.z = 10;
    this.scene.add(this.light);

    this.dlight = new THREE.PointLight(0xffffff);
    this.dlight.position.z = 10;
    this.scene.add(this.dlight);

    this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
    this.controls.autoRotate = true;

    console.log('CREATED', this);
    if (this.onSceneCreated) this.onSceneCreated();
  }

  load3DModel(pathString: string): void {
    this.clearPreview();
    const ext = pathString.split('.').pop();
    switch (ext.toLowerCase()) {
      case 'obj':
        this.loadOBJ(pathString);
        break;
      case 'stl':
        this.loadSTL(pathString);
        break;
      case '3ds':
        this.load3DS(pathString);
        break;
    }

    /*if (modelData != null) {
      let distanceCamera = 0;

      distanceCamera = modelData.x;
      if (modelData.y > distanceCamera) distanceCamera = modelData.y;
      if (modelData.z > distanceCamera) distanceCamera = modelData.z;
      let distance = 1.5;
      this.dlight.position.z = distanceCamera * distance;
      this.light.position.z = distanceCamera * distance;
      this.camera.position.z = distanceCamera * distance;
    }*/
    //console.log(distanceCamera);
  }

  load3DModel2(pathString: string, modelData: any): void {
    this.clearPreview();
    var ext = pathString.split('.').pop();
    switch (ext.toLowerCase()) {
      case 'obj':
        this.loadOBJ(pathString);
        break;
      case 'stl':
        this.loadSTL(pathString);
        break;
      case '3ds':
        this.load3DS(pathString);
        break;
    }
    let distanceCamera = 0;

    distanceCamera = modelData.x;
    if (modelData.y > distanceCamera) distanceCamera = modelData.y;
    if (modelData.z > distanceCamera) distanceCamera = modelData.z;
    let distance = 1.5;
    this.dlight.position.z = distanceCamera * distance;
    this.light.position.z = distanceCamera * distance;
    this.camera.position.z = distanceCamera * distance;
    //console.log(distanceCamera);
  }

  setCamera(size: THREE.Vector3) {
    let distanceCamera = 0;

    distanceCamera = size.x;
    if (size.y > distanceCamera) distanceCamera = size.y;
    if (size.z > distanceCamera) distanceCamera = size.z;
    const distance = 1.5;
    console.log(distanceCamera);
    this.dlight.position.z = distanceCamera * distance;
    this.light.position.z = distanceCamera * distance;
    this.camera.position.z = distanceCamera * distance;
  }

  getCompoundBoundingBox(object3D) {
    let box = null;
    object3D.traverse(function (obj3D) {
      const geometry = obj3D.geometry;
      if (geometry === undefined) return;
      geometry.computeBoundingBox();
      if (box === null) {
        box = geometry.boundingBox;
      } else {
        box.union(geometry.boundingBox);
      }
    });
    return box;
  }

  setMultiColor() {
    //TODO mesh.geometry.faces not available for THREE.BufferGeometry (loadSTL and probably others)
    for (const mesh of this.scene.children) {
      if (mesh.type === 'Mesh') {
        mesh.material = new THREE.MeshPhongMaterial({ color: 0x000000 });
        if (mesh.geometry.faces)
          for (const face of mesh.geometry.faces) face.vertexColors.push(new THREE.Color().setRGB(Math.random(), Math.random(), Math.random()));
      }
    }
  }

  setColor(hexColor: string) {
    this.setColorRecursif(hexColor, this.scene);
  }

  private setColorRecursif(hexColor: string, object) {
    if (object.type === 'Scene' || object.type === 'Group') {
      for (const mesh of object.children) {
        this.setColorRecursif(hexColor, mesh);
      }
    } else if (object.type === 'Mesh') {
      object.material = new THREE.MeshPhongMaterial({
        color: hexColor,
        wireframe: false,
      });
    }
  }

  private loadSTL(pathString: string): void {
    const loader = new THREE.STLLoader();

    loader.load(
      pathString,
      (geometry: THREE.BufferGeometry) => {
        const mesh = new THREE.Mesh(geometry);
        const size = new THREE.Vector3();
        this.getCompoundBoundingBox(mesh).getSize(size);
        this.setCamera(size);
        //mesh.position.set(-center.x, -center.y, -center.z);
        /*mesh.material = new THREE.MeshPhongMaterial({
          color: '#FF0000',
          wireframe: false,
        });*/
        this.scene.add(mesh);
        this.setColorRecursif(this.meshColor, this.scene);
      },
      // called when loading is in progresses
      function (xhr) {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      // called when loading has errors
      function (error) {
        console.error('An error happened ', error);
      }
    );
  }

  private loadOBJ(pathString: string): void {
    const objLoader = new THREE.OBJLoader();
    objLoader.load(
      // resource URL
      pathString,
      // called when resource is loaded
      (geometry: THREE.Group) => {
        const size = new THREE.Vector3();
        this.getCompoundBoundingBox(geometry).getSize(size);
        this.setCamera(size);
        this.scene.add(geometry);
        this.setColorRecursif(this.meshColor, this.scene);
      },
      // called when loading is in progresses
      function (xhr) {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      // called when loading has errors
      function (error) {
        console.error('An error happened ', error);
      }
    );
  }

  private load3DS(pathString: string): void {
    const loader = new THREE.TDSLoader();
    loader.load(
      // resource URL
      pathString,
      // called when resource is loaded
      (geometry: THREE.Group) => {
        const size = new THREE.Vector3();
        this.getCompoundBoundingBox(geometry).getSize(size);
        this.setCamera(size);
        this.scene.add(geometry);
        this.setColorRecursif(this.meshColor, this.scene);
      },
      // called when loading is in progresses
      function (xhr) {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      // called when loading has errors
      function (error) {
        console.error('An error happened ', error);
      }
    );
  }
}
