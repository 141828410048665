import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookiesService } from 'ngx-utils-cookies-port';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss'],
})
export class CookieBarComponent implements OnInit, OnDestroy {
  showConsent: boolean;
  _routerSub: Subscription;
  cookieName: string = 'cookieAccept';
  cookieNbMois: number = 12;
  showAtBottom: boolean;
  constructor(private cookiesService: CookiesService, private router: Router) {}

  ngOnInit(): void {
    this.showConsent = this.cookiesService.get(this.cookieName) === undefined;
    this._routerSub = this.router.events //
      .pipe(filter((event) => event instanceof NavigationEnd)) //
      .subscribe((event: NavigationEnd) => {
        if (event.id !== 1) {
          this.showConsent = false;
          this._routerSub.unsubscribe();
          const date = new Date();
          date.setMonth(date.getMonth() + this.cookieNbMois);
          this.cookiesService.put(this.cookieName, 'true', {
            expires: date,
          });
        } else {
          //console.log(event.urlAfterRedirects);
          this.showAtBottom = event.urlAfterRedirects !== '/';
        }
      });
  }
  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }
  hide() {
    this.showConsent = false;
    this._routerSub.unsubscribe();
    const date = new Date();
    date.setMonth(date.getMonth() + this.cookieNbMois);
    this.cookiesService.put(this.cookieName, 'true', {
      expires: date,
    });
  }
}
