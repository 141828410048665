import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SvLayoutComponent } from './sv-layout/sv-layout.component';
import { NotFoundComponent } from 'degineo-common';
import { HomepageComponent } from './homepage/homepage.component';
import { OfferComponent } from './offer/offer.component';
import { CustomerAreaComponent } from './customer-area/customer-area.component';
import { DirectoryComponent } from './directory/directory.component';
import { LegalNoticesComponent } from './legal-notices/legal-notices.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { GeneralTermsComponent } from './general-terms/general-terms.component';
import { CookiesComponent } from './cookies/cookies.component';
import { ProducerDisplayComponent, ProducerResolverService } from 'degineo-common';
import { environment } from 'environments/environment';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ReferencementComponent } from './referencement/referencement.component';

const routes: Routes = [
  {
    path: '',
    component: SvLayoutComponent,
    children: [
      {
        path: '',
        component: LandingPageComponent,
        data: {
          title: "L'impression 3D connectée",
          metadesc:
            "Entrez dans une nouvelle ère de production grâce à l'impression 3D. Degineo est le 1er service en ligne qui connecte les Imprimeurs 3D avec leurs Clients.",
          displayFooter: false,
          displayHeader: false,
        },
      },
      {
        path: 'imprimeur-3D',
        component: HomepageComponent,
        data: {
          title: "Le logiciel de gestion dédié à l'impression 3D",
          metadesc:
            'Rejoignez Degineo et optimisez votre production en utilisant notre logiciel de gestion full web destinée aux professionnels de la fabrication additive.',
          landingChoice: 'prod',
        },
      },
      {
        path: 'chat-producteur',
        component: HomepageComponent,
        data: {
          title: "Le logiciel de gestion dédié à l'impression 3D",
          metadesc:
            'Rejoignez Degineo et optimisez votre production en utilisant notre logiciel de gestion full web destinée aux professionnels de la fabrication additive.',
          landingChoice: 'prod',
        },
      },
      {
        path: 'offre',
        component: OfferComponent,
        data: {
          title: "Découvrir l'offre",
          metadesc: "Trouvez l'offre qui correspond à votre entreprise d'impression 3D. Les offres Degineo s'adaptent à vos besoins.",
          landingChoice: 'prod',
        },
      },
      {
        path: 'espace-client',
        component: CustomerAreaComponent,
        data: {
          title: 'Trouvez votre imprimeur 3D',
          metadesc:
            'Travaillez avec des producteurs 3D adaptés à votre métier et qui correspondent à vos besoins. Et passez vos commandes en impression 3D directement en ligne.',
        },
      },
      {
        path: 'chat-client',
        component: CustomerAreaComponent,
        data: {
          title: 'Trouvez votre imprimeur 3D',
          metadesc:
            'Travaillez avec des producteurs 3D adaptés à votre métier et qui correspondent à vos besoins. Et passez vos commandes en impression 3D directement en ligne.',
        },
      },
      {
        path: 'annuaire',
        component: DirectoryComponent,
        data: {
          title: 'Trouvez votre imprimeur 3D',
          metadesc:
            'Travaillez avec des producteurs 3D adaptés à votre métier et qui correspondent à vos besoins. Et passez vos commandes en impression 3D directement en ligne.',
        },
      },
      {
        path: 'annuaire/:page',
        component: DirectoryComponent,
        data: {
          title: 'Trouvez votre imprimeur 3D',
          metadesc:
            'Travaillez avec des producteurs 3D adaptés à votre métier et qui correspondent à vos besoins. Et passez vos commandes en impression 3D directement en ligne.',
        },
      },
      {
        path: 'mentions-legales',
        component: LegalNoticesComponent,
        data: {
          title: 'Mentions légales',
          metadesc:
            "Entrez dans une nouvelle ère de production grâce à l'impression 3D. Degineo est le 1er service en ligne qui connecte les Imprimeurs 3D avec leurs Clients.",
        },
      },
      {
        path: 'confidentialite',
        component: PrivacyNoticeComponent,
        data: {
          title: 'Engagement de confidentialité',
          metadesc:
            "Entrez dans une nouvelle ère de production grâce à l'impression 3D. Degineo est le 1er service en ligne qui connecte les Imprimeurs 3D avec leurs Clients.",
        },
      },
      {
        path: 'conditions-generales',
        component: GeneralTermsComponent,
        data: {
          title: 'Conditions générales',
          metadesc:
            "Entrez dans une nouvelle ère de production grâce à l'impression 3D. Degineo est le 1er service en ligne qui connecte les Imprimeurs 3D avec leurs Clients.",
        },
      },
      {
        path: 'cookies',
        component: CookiesComponent,
        data: {
          title: 'Utilisation des cookies',
          metadesc:
            "Entrez dans une nouvelle ère de production grâce à l'impression 3D. Degineo est le 1er service en ligne qui connecte les Imprimeurs 3D avec leurs Clients.",
        },
      },
      {
        path: ':business/:city/:name/:id',
        component: ProducerDisplayComponent,
        data: {
          editMode: false,
          rpcHost: environment.rpcHost,
          svUrl: environment.svUrl,
          clientUrl: environment.clientUrl,
          producerUrl: environment.producerUrl,
        },
        resolve: { producer: ProducerResolverService },
      },
      {
        path: 'referencement',
        component: ReferencementComponent,
        data: {
          displayFooter: false,
          title: 'Êtes-vous référencé ?',
          metadesc:
            'Rejoignez Degineo et optimisez votre production en utilisant notre logiciel de gestion full web destinée aux professionnels de la fabrication additive.',
          landingChoice: 'prod',
        },
      },
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
