// com.degineo.metier.user.service.LoginServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { DegineoUserBundleV2 } from '../entities/DegineoUserBundleV2';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getBundle(): RpcRequestBuilder<DegineoUserBundleV2> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'LoginService.getBundle';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.tools.DegineoUserBundleV2');
  }

  login(email: string, password: string): RpcRequestBuilder<DegineoUserBundleV2> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'LoginService.login';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.tools.DegineoUserBundleV2');
  }

}
