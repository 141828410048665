import { Component, OnInit, Input, Output, EventEmitter, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { Filter } from '../../../../ic2/entities/Filter';

@Component({
  selector: 'degineo-checkbox-group-filter',
  templateUrl: './checkbox-group-filter.component.html',
  styleUrls: ['./checkbox-group-filter.component.scss'],
})
export class CheckboxGroupFilterComponent implements OnInit, AfterContentInit {
  @Input()
  filterName: string;
  @Input()
  Filter: Filter;
  @Output()
  change: EventEmitter<Filter> = new EventEmitter<Filter>();
  @ContentChildren('cb') checkBoxs: QueryList<any>;

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    let tab = [];
    this.checkBoxs.map((c) => {
      if (c.Filter) tab.push(c.filterName);
      c.change.subscribe((value) => this.changes());
    });

    if (tab.length > 0) {
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.Filter.stringValues = tab;
    }
  }

  clear() {
    this.checkBoxs.map((c) => {
      c.clear();
    });
    this.Filter = null;
    this.change.emit(this.Filter);
  }
  reset() {
    this.checkBoxs.map((c) => {
      c.reset();
    });
    this.changes();
  }

  changes() {
    this.Filter = null;
    let tab = [];
    this.checkBoxs.map((c) => {
      if (c.Filter) tab.push(c.filterName);
    });
    if (tab.length > 0) {
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
      this.Filter.stringValues = tab;
    }
    this.change.emit(this.Filter);
  }
}
