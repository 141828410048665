// ENTITY com.degineo.metier.user.dto.UserWithDUDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { User } from './User';
import { DegineoUser } from './DegineoUser';

export class UserWithDUDTO {

  user: User = null;
  du: DegineoUser = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.user.dto.UserWithDUDTO'] = UserWithDUDTO;

reverseMapping[UserWithDUDTO.name] = 'com.degineo.metier.user.dto.UserWithDUDTO';

fields['com.degineo.metier.user.dto.UserWithDUDTO']  = {
    user: 'com.ic2.entity.User',
    du: 'com.degineo.metier.user.entities.DegineoUser'
};
