// com.degineo.metier.producer.service.ProducerClientServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerClientDTO } from '../entities/ProducerClientDTO';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { ImportedClientDTO } from '../entities/ImportedClientDTO';

@Injectable({
  providedIn: 'root',
})
export class ProducerClientService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  inviteNewClient(companyName: string, email: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerClientService.inviteNewClient';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(companyName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getClients(filter: GenericFilter): RpcRequestBuilder<ListWithCount<ProducerClientDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerClientService.getClients';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  updateClientCompanyName(idClient: number, companyName: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerClientService.updateClientCompanyName';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idClient, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(companyName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateClientEmail(idClient: number, email: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerClientService.updateClientEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idClient, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  inviteNewClientFromFile(): RpcRequestBuilder<ImportedClientDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerClientService.inviteNewClientFromFile';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  inviteNewClients(clients: ImportedClientDTO[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerClientService.inviteNewClients';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(clients, 'java.util.List<com.degineo.metier.producer.dto.ImportedClientDTO>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateAllowedDelayedPayment(idClient: number, allowedDelayedPayment: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerClientService.updateAllowedDelayedPayment';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idClient, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(allowedDelayedPayment, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteClients(idClients: number[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerClientService.deleteClients';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idClients, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
