// ENTITY com.degineo.metier.tools.filter.Filter generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class Filter {

  name: string = '';
  intValues: number[] = null;
  doubleValues: number[] = null;
  stringValues: string[] = null;
  dateValues: Date[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.tools.filter.Filter'] = Filter;

reverseMapping[Filter.name] = 'com.degineo.metier.tools.filter.Filter';

fields['com.degineo.metier.tools.filter.Filter']  = {
    name: 'java.lang.String',
    intValues: 'java.util.List<java.lang.Double>',
    doubleValues: 'java.util.List<java.lang.Double>',
    stringValues: 'java.util.List<java.lang.String>',
    dateValues: 'java.util.List<java.time.Instant>'
};
