// ENTITY com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentPackageDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ShipmentPackageHasOrder } from './ShipmentPackageHasOrder';

export class ShipmentPackageDTO {

  id: number = 0;
  sizeXCm: number = 0;
  sizeYCm: number = 0;
  sizeZCm: number = 0;
  weightInGr: number = 0;
  orders: ShipmentPackageHasOrder[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentPackageDTO'] = ShipmentPackageDTO;

reverseMapping[ShipmentPackageDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentPackageDTO';

fields['com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentPackageDTO']  = {
    id: 'java.lang.Integer',
    sizeXCm: 'java.lang.Double',
    sizeYCm: 'java.lang.Double',
    sizeZCm: 'java.lang.Double',
    weightInGr: 'java.lang.Double',
    orders: 'java.util.List<com.degineo.metier.shipping.entities.ShipmentPackageHasOrder>'
};
