import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  constructor(private router: Router, public intercom: Intercom) {}

  ngOnInit() {
    if (this.router.url == '/chat-producteur') this.intercom.show('');
  }
}
