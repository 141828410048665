// ENTITY com.degineo.metier.producer.dto.ProducerSubscriptionInfosDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { AddressNEW } from './AddressNEW';

export class ProducerSubscriptionInfosDTO {

  degineoAddress: AddressNEW = null;
  producerBillingAddress: AddressNEW = null;
  baseSubscriptionPrice: number = 0;
  printerSubscriptionPrice: number = 0;
  dateContractSigned: Date = null;
  subscribed: boolean = false;
  dateDiscountEnd: Date = null;
  discountPercent: number = 0;
  nbPrinters: number = 0;
  totalHT: number = 0;
  totalTTC: number = 0;
  name: string = '';
  degineoSepaIdentifier: string = '';
  stripeIbanLast4Digits: string = '';
  stripeMandateRef: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerSubscriptionInfosDTO'] = ProducerSubscriptionInfosDTO;

reverseMapping[ProducerSubscriptionInfosDTO.name] = 'com.degineo.metier.producer.dto.ProducerSubscriptionInfosDTO';

fields['com.degineo.metier.producer.dto.ProducerSubscriptionInfosDTO']  = {
    degineoAddress: 'com.degineo.metier.address.entities.AddressNEW',
    producerBillingAddress: 'com.degineo.metier.address.entities.AddressNEW',
    baseSubscriptionPrice: 'java.lang.Double',
    printerSubscriptionPrice: 'java.lang.Double',
    dateContractSigned: 'java.time.Instant',
    subscribed: 'java.lang.Boolean',
    dateDiscountEnd: 'java.time.Instant',
    discountPercent: 'java.lang.Double',
    nbPrinters: 'java.lang.Integer',
    totalHT: 'java.lang.Double',
    totalTTC: 'java.lang.Double',
    name: 'java.lang.String',
    degineoSepaIdentifier: 'java.lang.String',
    stripeIbanLast4Digits: 'java.lang.String',
    stripeMandateRef: 'java.lang.String'
};
