// ENTITY com.degineo.metier.articleType.dto.ArticleTypeBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ArticleTypeNEW } from './ArticleTypeNEW';
import { ArticleTypeBusinessTarget } from './ArticleTypeBusinessTarget';

export class ArticleTypeBODTO {

  at: ArticleTypeNEW = null;
  targets: ArticleTypeBusinessTarget[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.articleType.dto.ArticleTypeBODTO'] = ArticleTypeBODTO;

reverseMapping[ArticleTypeBODTO.name] = 'com.degineo.metier.articleType.dto.ArticleTypeBODTO';

fields['com.degineo.metier.articleType.dto.ArticleTypeBODTO']  = {
    at: 'com.degineo.metier.articleType.entities.ArticleTypeNEW',
    targets: 'java.util.List<com.degineo.metier.articleType.entities.ArticleTypeBusinessTarget>'
};
