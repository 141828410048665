// ENTITY com.degineo.metier.producer.dto.ProducerSignupStep7DTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { LegalStatus } from './LegalStatus';

export class ProducerSignupStep7DTO {

  idPlan: number = 0;
  nbPrinter: number = 0;
  legalStatus: LegalStatus = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.ProducerSignupStep7DTO'] = ProducerSignupStep7DTO;

reverseMapping[ProducerSignupStep7DTO.name] = 'com.degineo.metier.producer.dto.ProducerSignupStep7DTO';

fields['com.degineo.metier.producer.dto.ProducerSignupStep7DTO']  = {
    idPlan: 'java.lang.Integer',
    nbPrinter: 'java.lang.Integer',
    legalStatus: 'com.degineo.metier.producer.entities.LegalStatus'
};
