// com.degineo.metier.devis.service.DevisBOServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ListWithCount } from '../entities/ListWithCount';
import { GenericFilter } from '../entities/GenericFilter';
import { DevisListItemBODTO } from '../entities/DevisListItemBODTO';

@Injectable({
  providedIn: 'root',
})
export class DevisBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getProducerDevis(idProducer: number, filter: GenericFilter): RpcRequestBuilder<ListWithCount<DevisListItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DevisBOService.getProducerDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.degineo.metier.tools.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  restoreDevis(idDevis: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DevisBOService.restoreDevis';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idDevis, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
