// DB ENUM com.degineo.metier.user.entities.RoleInCompany generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class RoleInCompany {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly PRODUCER_HOLDER = new RoleInCompany(1, "Titulaire", "PRODUCER_HOLDER");
  static readonly PRODUCER_ADMIN = new RoleInCompany(10, "Admin", "PRODUCER_ADMIN");
  static readonly PRODUCER_ACCOUNTING = new RoleInCompany(20, "Comptable", "PRODUCER_ACCOUNTING");
  static readonly PRODUCER_SUPERVISER = new RoleInCompany(30, "Superviseur", "PRODUCER_SUPERVISER");
  static readonly PRODUCER_COLLABORATOR = new RoleInCompany(40, "Collaborateur", "PRODUCER_COLLABORATOR");

  static readonly values = [
    RoleInCompany.PRODUCER_HOLDER, 
    RoleInCompany.PRODUCER_ADMIN, 
    RoleInCompany.PRODUCER_ACCOUNTING, 
    RoleInCompany.PRODUCER_SUPERVISER, 
    RoleInCompany.PRODUCER_COLLABORATOR 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of RoleInCompany.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of RoleInCompany.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.user.entities.RoleInCompany'] = RoleInCompany;
reverseMapping[RoleInCompany.name] = 'com.degineo.metier.user.entities.RoleInCompany';
