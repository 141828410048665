<div class="container">
  <h1 class="text-center mb-5 mt-5 text-primary">Le logiciel de gestion dédié à l'impression&nbsp;3D</h1>

  <div class="row justify-content-center align-items-center my-5">
    <div class="col-md-12 col-lg-6 order-1 order-lg-0">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/YVTFPgb2XAU"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 order-0 order-lg-1">
      <h3 class="mb-4">Degineo est la solution la plus complète pour gérer et augmenter votre productivité</h3>
      <p>Rejoignez Degineo et produisez différemment en utilisant notre solution full web pensée et faite pour les imprimeurs&nbsp;3D professionnels.</p>
      <p>De la prise de commande à la livraison, simplifiez-vous la vie et optimisez votre production grâce à un seul outil complet et facile d'utilisation.</p>
      <div class="d-flex justify-content-center">
        <a routerLink="/offre" class="btn btn-success mt-3 mb-lg-3 mb-5" style="min-width: 14.5rem">Découvrir l'offre</a>
      </div>
    </div>
  </div>

  <div class="row justify-content-center align-items-center my-6">
    <div class="col-md-12 col-lg-6 order-1">
      <h3 class="mb-4">Donnez à vos clients la possibilité de vous passer commande en ligne depuis votre site internet</h3>
      <p>Gagnez du temps en automatisant vos prises de commmande.</p>
      <p>Degineo vous propose d'installer notre module de commande en ligne directement sur votre site internet.</p>
      <p>Vous pourrez l’installer vous-même ou nous pouvons le faire gratuitement pour vous.</p>
    </div>
    <div class="col-md-12 col-lg-6 order-1 d-flex justify-content-center">
      <img class="img-producer" src="assets/producer-homepage/passer-commande-en-ligne.png" alt="Passer commande en ligne" />
    </div>
  </div>

  <div class="row justify-content-center align-items-center my-6">
    <div class="col-md-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center">
      <img class="img-producer" src="assets/producer-homepage/systeme-de-paiement.png" alt="Système de paiement en ligne" />
    </div>
    <div class="col-md-12 col-lg-6 order-0 order-lg-1">
      <h3 class="mb-4">Disposez d'un système de paiement complet et entièrement sécurisé</h3>
      <p>
        Degineo s'occupe des transactions financières. Vous recevez la somme due dès la commande achevée. Plus aucun délai à attendre et plus de stress avec les
        retards de paiement.
      </p>
      <p>Tout en pouvant toujours différer les paiements pour les clients souhaités.</p>
    </div>
  </div>

  <div class="row justify-content-center align-items-center my-6">
    <div class="col-md-12 col-lg-6 order-0">
      <h3 class="mb-4">Profitez du service de livraison Degineo pour livrer vos clients</h3>
      <p>
        Appuyez simplement sur un bouton pour appeler un transporteur qui viendra chercher vos commandes terminées afin de les livrer directement à vos clients.
      </p>
      <p>Et ceci gratuitement si vos clients passent eux aussi par la plateforme Degineo.</p>
    </div>
    <div class="col-md-12 col-lg-6 order-1 d-flex justify-content-center">
      <img class="img-producer" src="assets/producer-homepage/service-de-livraison.png" alt="Service de livraison Degineo" />
    </div>
  </div>

  <div class="row justify-content-center align-items-center my-6">
    <div class="col-md-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center">
      <img class="img-producer" src="assets/producer-homepage/maitrisez-votre-production.png" alt="Production Kanban" />
    </div>

    <div class="col-md-12 col-lg-6 order-0 order-lg-1">
      <h3 class="mb-4">Améliorez et maîtrisez votre production de A à Z</h3>
      <p>
        Degineo vous aide à mieux maîtriser votre ingénierie financière en vous conseillant sur les meilleurs prix de production à mettre en place en fonction
        des marchés actuels.
      </p>
      <p>Degineo priorise vos demandes d'impressions et vous avertit quand vous approchez d’une deadline.</p>
      <p>Optimisez vos flux de production grâce à la méthode Kanban.</p>
    </div>
  </div>

  <div class="row justify-content-center align-items-center my-6">
    <div class="col-md-12 col-lg-6 order-0 mb-3">
      <h3 class="mb-4">Échangez plus efficacement avec vos clients par le biais de leur espace personnel</h3>
      <p>Recevez les demandes et les fichiers 3D de vos clients directement dans votre logiciel Degineo.</p>
      <p>Recevez et révisez vos devis tout en gardant une traçabilité claire et facilement accessible.</p>
      <p>Libérez-vous l'esprit et du temps avec l'envoi et la génération automatique des factures à vos clients.</p>
      <p>Votre client peut suivre en temps réel l'avancement de sa commande.</p>
      <p>Faites bénéficier à vos clients d'un espace personnel simple et intuitif pour faire et gérer ces commandes</p>
      <a class="fw-500" routerLink="/espace-client" fragment="avantages"
        >En savoir plus sur les bénéfices apportés à vos clients<i class="fas fa-long-arrow-alt-right fa-lg ml-2"></i
      ></a>
    </div>
    <div class="col-md-12 col-lg-6 order-1 d-flex justify-content-center">
      <img class="img-producer" src="assets/producer-homepage/echangez-plus-efficacement.png" alt="Espace client" />
    </div>
  </div>

  <div class="row justify-content-center align-items-center my-6">
    <div class="col-md-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center">
      <img class="img-producer" src="assets/producer-homepage/simplifiez-votre-administratif.png" alt="Gestion administrative" />
    </div>
    <div class="col-md-12 col-lg-6 order-0 order-lg-1">
      <h3 class="mb-4">Simplifiez et stockez tout votre administratif dans un unique espace centralisé</h3>
      <p>Libérez-vous des tâches administratives : gagnez du temps et évitez les erreurs.</p>
      <p>
        Degineo génère pour vous : les ordres de fabrications, les devis, les bordereaux de transport, les factures pour vos clients et votre rapport financier.
      </p>
    </div>
  </div>

  <div class="row justify-content-center align-items-center my-5">
    <div class="col-md-12 col-lg-6 order-0 mb-3">
      <h3 class="mb-4">Augmentez votre visibilité sur internet</h3>
      <p>
        Degineo met à votre disposition une page personnelle, référencée sur Google et sur notre moteur de recherche Degineo, où vous pouvez y informer vos
        clients de vos savoir-faire.
      </p>
      <p>Ainsi que d'y faire figurer tous les moyens de communication que vous souhaitez (téléphone, adresse, e-mail, site internet, réseaux sociaux).</p>
      <p>
        Vos clients auront aussi la possibilité de vous passer commande directement depuis cette page comme s'ils le faisaient depuis le module de commande de
        votre site internet.
      </p>
      <p>La majorité des imprimeurs&nbsp;3D de France sont déjà référencés dans notre moteur de recherche.</p>
      <a class="fw-500" routerLink="/referencement">Voir si nous avons déjà référencé votre entreprise<i class="fas fa-long-arrow-alt-right fa-lg ml-2"></i></a>
    </div>
    <div class="col-md-12 col-lg-6 order-1 d-flex justify-content-center">
      <img class="img-producer" src="assets/producer-homepage/augmentez-votre-visibilite.png" alt="Page de référencement" />
    </div>
  </div>
</div>

<div class="border bg-primary w-100 text-center text-white py-2rem">
  <h2 class="mb-2 fw-400 bannerText">Vous souhaitez entrer dans une nouvelle ère de production&nbsp;?</h2>
  <p class="fw-100 bannerText">Essayez notre outil de gestion online entièrement dédié à l'impression&nbsp;3D</p>
  <a routerLink="/offre" class="btn-offre fs-4">Découvrir l'offre</a>
</div>
