// ENTITY com.degineo.metier.articleType.entities.ArticleTypeNEW generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ArticleTypeNEW {

  id: number = 0;
  averageMatterQuantity: number = 0;
  active: boolean = false;
  name: string = '';
  dental: boolean = false;
  defaultPrice: number = 0;
  order: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.articleType.entities.ArticleTypeNEW'] = ArticleTypeNEW;

reverseMapping[ArticleTypeNEW.name] = 'com.degineo.metier.articleType.entities.ArticleTypeNEW';

fields['com.degineo.metier.articleType.entities.ArticleTypeNEW']  = {
    id: 'java.lang.Integer',
    averageMatterQuantity: 'java.lang.Double',
    active: 'java.lang.Boolean',
    name: 'java.lang.String',
    dental: 'java.lang.Boolean',
    defaultPrice: 'java.lang.Double',
    order: 'java.lang.Integer'
};
