// com.degineo.metier.vitrine.service.SiteVitrineServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ArticleSVDTO } from '../entities/ArticleSVDTO';
import { BusinessTargetLevel1 } from '../entities/BusinessTargetLevel1';

@Injectable({
  providedIn: 'root',
})
export class SiteVitrineService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getArticles(target: BusinessTargetLevel1): RpcRequestBuilder<ArticleSVDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SiteVitrineService.getArticles';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getBusinessTargets(): RpcRequestBuilder<BusinessTargetLevel1[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SiteVitrineService.getBusinessTargets';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
