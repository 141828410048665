import { Component, OnInit, forwardRef, Input, Output, EventEmitter, Host } from '@angular/core';
import { FilterComponent } from '../../filter.component';
import { Filter } from '../../../../ic2/entities/Filter';

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}

@Component({
  selector: 'degineo-round-checkbox-filter',
  templateUrl: './round-checkbox-filter.component.html',
  styleUrls: ['./round-checkbox-filter.component.scss'],
})
export class RoundCheckboxFilterComponent implements OnInit {
  RoundCheckboxFilterComponent: typeof RoundCheckboxFilterComponent = RoundCheckboxFilterComponent;
  @Input()
  name: string;
  @Input()
  filterName: string;

  @Input()
  color: string;
  @Input()
  size: number = 25;

  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input()
  Filter: Filter;
  @Output()
  change: EventEmitter<Filter> = new EventEmitter<Filter>();

  value: boolean = false;
  private isHexa: boolean;

  constructor(@Host() private host: FilterComponent) {}

  ngOnInit() {
    if (this.host.cache && this.host.cache[this.filterName] !== undefined && this.host.cache[this.filterName] !== null) {
      this.value = this.host.cache[this.filterName];
    } else if (this.host.default && this.host.default[this.filterName]) {
      this.value = this.host.default[this.filterName];
    }
    this.update();

    if (!this.color) this.color = 'primary';
    if (this.color.charAt(0) == '#') this.isHexa = true;
    else this.isHexa = false;
  }

  clear() {
    this.value = false;
    this.update();
  }
  reset() {
    if (this.host.default && this.host.default[this.filterName]) {
      this.value = this.host.default[this.filterName];
    } else {
      this.value = false;
    }
    this.update();
  }

  update() {
    if (this.host.cache) {
      this.host.cache[this.filterName] = this.value;
    }

    if (this.value) {
      this.Filter = new Filter();
      this.Filter.name = this.filterName;
    } else {
      this.Filter = null;
    }

    this.change.emit(this.Filter);
  }

  onClick() {
    if (this.disabled) return;
    this.value = !this.value;
    this.update();
  }

  getStyle() {
    let opacityval = 1;
    let border = null;

    if (this.isHexa) border = this.color;
    if (this.disabled) opacityval = 0.5;

    return { height: this.size + 'px', width: this.size + 'px', opacity: opacityval, 'border-color': border };
  }

  getLabelStyle() {
    let opacityval = 1;
    if (this.disabled) opacityval = 0.5;
    return { opacity: opacityval };
  }

  getClass() {
    if (!this.isHexa) return 'border-' + this.color + ' round';
    else return 'round';
  }

  getInnerStyle() {
    if (this.isHexa) return { 'background-color': this.color };
  }

  getInnerClass() {
    if (!this.isHexa) return 'bg-' + this.color + ' innnerRound';
    else return 'innnerRound';
  }
}
