<div class="container-fluid">
  <div class="row justify-content-center text-center">
    <h1 class="col-12 text-primary mb-5 mt-5">Trouvez le meilleur imprimeur&nbsp;3D</h1>
    <p class="col-md-6 col-12 fs-4 fw-500 text-center mx-auto mb-5">
      Indiquez vos besoins ci&#8209;dessous et nous vous trouverons les imprimeurs qu'il vous faut afin de réaliser vos impressions&nbsp;3D.
    </p>

    <app-customer-area-form class="col-12"></app-customer-area-form>
    <div class="mt-4 col-12 px-0" style="margin-bottom: 6rem">
      <div class="container-fluid bg-primary py-2rem">
        <div class="row justify-content-center w-100">
          <h3 class="col mb-3 text-white text-center">Trouver directement votre imprimeur 3D ici</h3>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 col-md-8 col-lg-6 mb-3" style="max-width: 37rem">
            <app-customer-area-search class="col-12 px-0"></app-customer-area-search>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <h2 class="mb-5">Comment ça marche&nbsp;?</h2>
      <ol class="row p-0">
        <div class="col-lg-3 col-md-6 col-12 d-flex flex-column">
          <img class="w-75 align-self-center" src="assets/customer/comment-ca-marche-1.svg" alt="Rechercher un imprimeur" />
          <li class="text-left ml-4 mt-3 mb-5">Indiquez vos besoins en haut de cette page, puis lancez votre recherche.</li>
        </div>

        <div class="col-lg-3 col-md-6 col-12 d-flex flex-column">
          <img class="w-75 align-self-center mr-3" src="assets/customer/comment-ca-marche-2.svg" alt="Trouver un imprimeur" />
          <li class="text-left ml-4 mt-3 mb-5">Nous vous trouvons les imprimeurs&nbsp;3D qualifiés et habilités à répondre à vos demandes.</li>
        </div>

        <div class="col-lg-3 col-md-6 col-12 d-flex flex-column">
          <img class="w-75 align-self-center mr-4" src="assets/customer/comment-ca-marche-3.svg" alt="Choisissez un imprimeur" />
          <li class="text-left ml-4 mt-3 mb-5">Choisissez l'imprimeur&nbsp;3D qui vous convient le mieux, puis passez-lui directement commande en ligne.</li>
        </div>

        <div class="col-lg-3 col-md-6 col-12 d-flex flex-column">
          <img class="w-75 align-self-center mr-4" src="assets/customer/comment-ca-marche-4.svg" alt="Recevez vite votre impression" />
          <li class="text-left ml-4 mt-3 mb-5">Et recevez tout simplement votre impression&nbsp;3D là où vous le souhaitez sans frais de port.</li>
        </div>
      </ol>

      <div id="avantages" class="row justify-content-around align-items-center my-5">
        <div class="col-md-12 col-lg-6 text-left">
          <h3 class="mb-4">Les avantages d'utiliser Degineo</h3>
          <p>Commandez rapidement vos impressions&nbsp;3D, en ligne avec Degineo.</p>
          <p>Suivez en temps réel l'avancement de vos commandes.</p>
          <p>Imprimez plus facilement chez votre imprimeur&nbsp;3D habituel, ou trouvez celui qu'il vous faut si vous n'en avez pas.</p>
          <p>Passez commande dans un environnement qui prend en compte les spécificités et les normes de votre métier.</p>
          <p>Stockez dans un espace sécurisé, vos fichiers 3D pour les imprimer dès que vous le souhaitez.</p>
          <p>Si vous commandez vos impressions&nbsp;3D en utilisant Degineo, vous ne payez aucuns frais de port.</p>
        </div>
        <img class="col-md-12 col-lg-6" src="assets/customer/les-avantages-d-utiliser-degineo.png" alt="Les avantages" />
      </div>
    </div>
  </div>
</div>
