// ENTITY com.degineo.metier.shipping.entities.ShipmentPackageHasOrder generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ShipmentPackageHasOrder {

  idShipmentPackage: number = 0;
  idOrder: number = 0;
  quantity: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.shipping.entities.ShipmentPackageHasOrder'] = ShipmentPackageHasOrder;

reverseMapping[ShipmentPackageHasOrder.name] = 'com.degineo.metier.shipping.entities.ShipmentPackageHasOrder';

fields['com.degineo.metier.shipping.entities.ShipmentPackageHasOrder']  = {
    idShipmentPackage: 'java.lang.Integer',
    idOrder: 'java.lang.Integer',
    quantity: 'java.lang.Integer'
};
