// ENTITY com.degineo.metier.invoice.dto.ProducerDelayedOrdersDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DelayedOrderDTO } from './DelayedOrderDTO';

export class ProducerDelayedOrdersDTO {

  producerName: string = '';
  articles: DelayedOrderDTO[] = null;
  dueDate: Date = null;
  totalTTC: number = 0;
  VATAmount: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.invoice.dto.ProducerDelayedOrdersDTO'] = ProducerDelayedOrdersDTO;

reverseMapping[ProducerDelayedOrdersDTO.name] = 'com.degineo.metier.invoice.dto.ProducerDelayedOrdersDTO';

fields['com.degineo.metier.invoice.dto.ProducerDelayedOrdersDTO']  = {
    producerName: 'java.lang.String',
    articles: 'java.util.List<com.degineo.metier.invoice.dto.DelayedOrderDTO>',
    dueDate: 'java.time.LocalDate',
    totalTTC: 'java.lang.Double',
    VATAmount: 'java.lang.Double'
};
