// ENTITY com.degineo.metier.order.dto.kanban.panel.WaitingCarrierShipmentPanelDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ShipmentStatus } from './ShipmentStatus';
import { ShipmentOrderPanelDTO } from './ShipmentOrderPanelDTO';
import { ShipmentPackageDTO } from './ShipmentPackageDTO';
import { AddressNEW } from './AddressNEW';
import { ClientInfosForKanbanPopupDTO } from './ClientInfosForKanbanPopupDTO';
import { ShipmentWaybillDTO } from './ShipmentWaybillDTO';

export class WaitingCarrierShipmentPanelDTO {

  idShipment: number = 0;
  dateWaitingCarrier: Date = null;
  status: ShipmentStatus = null;
  producerDeliver: boolean = false;
  clientChoosedThisProducer: boolean = false;
  client: ClientInfosForKanbanPopupDTO = null;
  minWishedDeliveryDate: Date = null;
  collectionLabel: string = '';
  collectionDate: Date = null;
  shipmentOrders: ShipmentOrderPanelDTO[] = null;
  shipmentPackages: ShipmentPackageDTO[] = null;
  waybills: ShipmentWaybillDTO[] = null;
  shippingAddress: AddressNEW = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.panel.WaitingCarrierShipmentPanelDTO'] = WaitingCarrierShipmentPanelDTO;

reverseMapping[WaitingCarrierShipmentPanelDTO.name] = 'com.degineo.metier.order.dto.kanban.panel.WaitingCarrierShipmentPanelDTO';

fields['com.degineo.metier.order.dto.kanban.panel.WaitingCarrierShipmentPanelDTO']  = {
    idShipment: 'java.lang.Integer',
    dateWaitingCarrier: 'java.time.Instant',
    status: 'com.degineo.metier.shipping.entities.ShipmentStatus',
    producerDeliver: 'java.lang.Boolean',
    clientChoosedThisProducer: 'java.lang.Boolean',
    client: 'com.degineo.metier.order.dto.kanban.panel.ClientInfosForKanbanPopupDTO',
    minWishedDeliveryDate: 'java.time.Instant',
    collectionLabel: 'java.lang.String',
    collectionDate: 'java.time.LocalDate',
    shipmentOrders: 'java.util.List<com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentOrderPanelDTO>',
    shipmentPackages: 'java.util.List<com.degineo.metier.order.dto.kanban.panel.inprepshipment.ShipmentPackageDTO>',
    waybills: 'java.util.List<com.degineo.metier.order.dto.kanban.panel.waitingcarrier.ShipmentWaybillDTO>',
    shippingAddress: 'com.degineo.metier.address.entities.AddressNEW'
};
