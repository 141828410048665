// ENTITY com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOptionArticleType generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class ProducerPrinterConsumableOptionArticleType {

  id: number = 0;
  idArticleType: number = 0;
  idProducerPrinterConsumableOption: number = 0;
  idProducer: number = 0;
  idPrinter: number = 0;
  idConsumableOption: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOptionArticleType'] = ProducerPrinterConsumableOptionArticleType;

reverseMapping[ProducerPrinterConsumableOptionArticleType.name] = 'com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOptionArticleType';

fields['com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOptionArticleType']  = {
    id: 'java.lang.Integer',
    idArticleType: 'java.lang.Integer',
    idProducerPrinterConsumableOption: 'java.lang.Integer',
    idProducer: 'java.lang.Integer',
    idPrinter: 'java.lang.Integer',
    idConsumableOption: 'java.lang.Integer'
};
