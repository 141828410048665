// ENTITY com.degineo.metier.producer_printer.dto.PrinterBrandChoiceDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PrinterNEW } from './PrinterNEW';

export class PrinterBrandChoiceDTO {

  brand: string = '';
  printers: PrinterNEW[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.PrinterBrandChoiceDTO'] = PrinterBrandChoiceDTO;

reverseMapping[PrinterBrandChoiceDTO.name] = 'com.degineo.metier.producer_printer.dto.PrinterBrandChoiceDTO';

fields['com.degineo.metier.producer_printer.dto.PrinterBrandChoiceDTO']  = {
    brand: 'java.lang.String',
    printers: 'java.util.List<com.degineo.metier.metadata.entities.PrinterNEW>'
};
