import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { CustomerFormService } from '../customer-form.service';
import { ProducerSVService, ColorPriceSVDTO, ProducerSVListDTO, SlugService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DirectoryComponent implements OnInit {
  private sub: any;

  count = 0;
  nbPerPage = 20; //ne pas changer ici sans modifier le java car ça influance la génération du sitemap !
  page = 1;
  pageForComp = 1;
  loaded: boolean = false;
  loading: boolean = false;
  rpcHost: string = environment.rpcHost;
  svUrl: string = environment.svUrl;
  clientUrl: string = environment.clientUrl;
  producerUrl: string = environment.producerUrl;

  producerView: boolean = false;
  idProducer: number = null;
  selectedColorProd: ColorPriceSVDTO = null;
  selectedNameProd: string = null;
  colorsProd: ColorPriceSVDTO[] = [];

  constructor(
    private location: Location,
    public customerFormService: CustomerFormService,
    @Inject(ProducerSVService) private producerSVService: ProducerSVService,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(SlugService) public slugService: SlugService
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params['page'] === undefined) {
        this.page = 1;
        this.count = this.customerFormService.count;
        this.pageForComp = 1;
        this.loaded = true;
        this.loading = false;
      } else this.page = +params['page'];
      if (this.customerFormService.fromForm === false) {
        this.loadSearchPage();
      } else this.loadFormPage();
    });
  }

  loadSearchPage() {
    this.loading = true;
    this.producerSVService.getProducersByName(this.page, this.nbPerPage, this.customerFormService.nameSearch).subscribe(
      (data) => {
        this.customerFormService.producers = data.data;
        this.customerFormService.count = data.count;
        this.count = data.count;
        this.pageForComp = this.page;
        this.loaded = true;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  loadFormPage() {
    this.loading = true;
    var colour = null;
    if (+this.customerFormService.form.get('colours').value >= 0) {
      colour = +this.customerFormService.form.get('colours').value;
    } else colour = 0;
    this.producerSVService
      .getProducers(
        this.page,
        this.nbPerPage,
        this.customerFormService.form.get('target').value,
        this.customerFormService.form.get('types').value,
        this.customerFormService.form.get('materials').value,
        colour,
        false,
        this.customerFormService.size
      )
      .subscribe(
        (data) => {
          this.customerFormService.producers = data.data;
          this.count = data.count;
          this.pageForComp = this.page;
          this.loaded = true;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
  }

  updateColour(colour: ColorPriceSVDTO, i: number) {
    if (colour.id != 0) {
      this.customerFormService.selectedColour[i] = colour;
    }
    this.customerFormService.menuOpened[i] = false;
  }

  showProducer(idProducer: number, i: number) {
    this.producerView = true;
    this.idProducer = idProducer;
    this.colorsProd = this.customerFormService.producers.find((x) => x.id == idProducer).colors;
    this.selectedNameProd = this.customerFormService.producers.find((x) => x.id == idProducer).name;
    this.selectedColorProd = this.customerFormService.selectedColour[i];
  }
  concealProducer() {
    this.producerView = false;
  }

  addPrint(color: ColorPriceSVDTO, idProducer: number, nameProducer: string) {
    console.log(color);
    this.producerSVService
      .addPrint(
        this.customerFormService.files[0].fileHash,
        this.customerFormService.form.get('target').value,
        this.customerFormService.form.get('types').value,
        this.customerFormService.form.get('materials').value,
        color.id,
        idProducer,
        nameProducer,
        this.customerFormService.size,
        color.idFinish
      )
      .subscribe(
        (data) => {
          console.log('success');
          window.location.href = environment.clientUrl + 'impression/etape-3/' + this.customerFormService.files[0].fileHash;
        },
        (err) => {
          L.e(err);
        }
      );
  }

  slugify(producer: ProducerSVListDTO) {
    //TOUT CHANGEMENT FAIT ICI DOIT ETRE REPERCUTE COTE SITEMAP JAVA
    return (
      '/' +
      (producer.businessType ? this.slugService.string_to_slug(producer.businessType.name) : 'type-non-defini') +
      '/' +
      (producer.city ? this.slugService.string_to_slug(producer.city) : 'ville-non-definie') +
      '/' +
      (producer.name ? this.slugService.string_to_slug(producer.name) : 'nom-non-defini') +
      '/' +
      producer.id
    );
  }

  back() {
    this.location.back();
  }
}
