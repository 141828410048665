// com.degineo.metier.order.service.kanban.ReceivedOrderServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ReceivedOrderPanelDTO } from '../entities/ReceivedOrderPanelDTO';

@Injectable({
  providedIn: 'root',
})
export class ReceivedOrderService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getReceivedOrderInfos(idProposalProducer: number): RpcRequestBuilder<ReceivedOrderPanelDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReceivedOrderService.getReceivedOrderInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProposalProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.order.dto.kanban.panel.ReceivedOrderPanelDTO');
  }

  acceptOrder(idProposalProducer: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReceivedOrderService.acceptOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProposalProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  refuseOrder(idProposalProducer: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReceivedOrderService.refuseOrder';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProposalProducer, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
