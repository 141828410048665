// ENTITY com.degineo.metier.client.dto.ClientAssociatedProducerBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ClientProducerAssociationStatus } from './ClientProducerAssociationStatus';

export class ClientAssociatedProducerBODTO {

  id: number = 0;
  name: string = '';
  status: ClientProducerAssociationStatus = null;
  dateCreated: Date = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.client.dto.ClientAssociatedProducerBODTO'] = ClientAssociatedProducerBODTO;

reverseMapping[ClientAssociatedProducerBODTO.name] = 'com.degineo.metier.client.dto.ClientAssociatedProducerBODTO';

fields['com.degineo.metier.client.dto.ClientAssociatedProducerBODTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    status: 'com.degineo.metier.client.entities.ClientProducerAssociationStatus',
    dateCreated: 'java.time.Instant'
};
