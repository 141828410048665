// ENTITY com.degineo.metier.metadata.dto.PrinterBODTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { PrinterBusinessTarget } from './PrinterBusinessTarget';
import { PrinterNEW } from './PrinterNEW';
import { PrinterAssociatedCompanyBODTO } from './PrinterAssociatedCompanyBODTO';
import { PrinterAssociatedConsumableOptionBODTO } from './PrinterAssociatedConsumableOptionBODTO';

export class PrinterBODTO {

  printer: PrinterNEW = null;
  businessTargets: PrinterBusinessTarget[] = null;
  assocConsumable: PrinterAssociatedConsumableOptionBODTO[] = null;
  assocCompany: PrinterAssociatedCompanyBODTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.metadata.dto.PrinterBODTO'] = PrinterBODTO;

reverseMapping[PrinterBODTO.name] = 'com.degineo.metier.metadata.dto.PrinterBODTO';

fields['com.degineo.metier.metadata.dto.PrinterBODTO']  = {
    printer: 'com.degineo.metier.metadata.entities.PrinterNEW',
    businessTargets: 'java.util.List<com.degineo.metier.metadata.entities.PrinterBusinessTarget>',
    assocConsumable: 'java.util.List<com.degineo.metier.metadata.dto.PrinterAssociatedConsumableOptionBODTO>',
    assocCompany: 'java.util.List<com.degineo.metier.metadata.dto.PrinterAssociatedCompanyBODTO>'
};
