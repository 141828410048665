// com.degineo.metier.user.service.RegisterServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerInvitationInfoDTO } from '../entities/ProducerInvitationInfoDTO';
import { DegineoUserBundleV2 } from '../entities/DegineoUserBundleV2';
import { RegisterDTO } from '../entities/RegisterDTO';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  emailAvailable(email: string): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.emailAvailable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  signup(email: string, password: string, prenom: string, nom: string, companyName: string, phoneNumber: string, siret: string, token: string): RpcRequestBuilder<RegisterDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.signup';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(prenom, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nom, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(companyName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(phoneNumber, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.user.dto.RegisterDTO');
  }

  createClientMarqueBlanche(idProducer: number, email: string, password: string, lastName: string, firstName: string, phoneNumber: string, companyName: string, siret: string): RpcRequestBuilder<DegineoUserBundleV2> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.createClientMarqueBlanche';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(phoneNumber, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(companyName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(siret, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.tools.DegineoUserBundleV2');
  }

  getInvitationInformation(invitationToken: string): RpcRequestBuilder<ProducerInvitationInfoDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.getInvitationInformation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(invitationToken, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.user.dto.ProducerInvitationInfoDTO');
  }

  confirmEmail(token: string): RpcRequestBuilder<DegineoUserBundleV2> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.confirmEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.tools.DegineoUserBundleV2');
  }

}
