// ENTITY com.degineo.metier.user.dto.RegisterDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { DegineoUserBundleV2 } from './DegineoUserBundleV2';

export class RegisterDTO {

  userBundle: DegineoUserBundleV2 = null;
  hasPendingProducerInvitations: boolean = false;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.user.dto.RegisterDTO'] = RegisterDTO;

reverseMapping[RegisterDTO.name] = 'com.degineo.metier.user.dto.RegisterDTO';

fields['com.degineo.metier.user.dto.RegisterDTO']  = {
    userBundle: 'com.degineo.metier.tools.DegineoUserBundleV2',
    hasPendingProducerInvitations: 'java.lang.Boolean'
};
