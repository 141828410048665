// ENTITY com.degineo.metier.producer.dto.SubscriptionInvoiceDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class SubscriptionInvoiceDTO {

  datePrelevement: Date = null;
  refInvoice: string = '';
  montantTTC: number = 0;
  invoiceToken: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer.dto.SubscriptionInvoiceDTO'] = SubscriptionInvoiceDTO;

reverseMapping[SubscriptionInvoiceDTO.name] = 'com.degineo.metier.producer.dto.SubscriptionInvoiceDTO';

fields['com.degineo.metier.producer.dto.SubscriptionInvoiceDTO']  = {
    datePrelevement: 'java.time.Instant',
    refInvoice: 'java.lang.String',
    montantTTC: 'java.lang.Double',
    invoiceToken: 'java.lang.String'
};
