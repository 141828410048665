// com.degineo.metier.vitrine.service.ProducerSVServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerSVDTO } from '../entities/ProducerSVDTO';
import { ProducerSVListDTO } from '../entities/ProducerSVListDTO';
import { SizeDTO } from '../entities/SizeDTO';
import { ListWithCount } from '../entities/ListWithCount';
import { BusinessTargetLevel1 } from '../entities/BusinessTargetLevel1';

@Injectable({
  providedIn: 'root',
})
export class ProducerSVService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getProducers(page: number, nbPerPage: number, target: BusinessTargetLevel1, idArticle: number, idMaterial: number, idColor: number, multicolor: boolean, sizeInMm: SizeDTO): RpcRequestBuilder<ListWithCount<ProducerSVListDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSVService.getProducers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticle, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(multicolor, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getProducer(idProducer: number, editMode: boolean): RpcRequestBuilder<ProducerSVDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSVService.getProducer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(editMode, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.vitrine.dto.ProducerSVDTO');
  }

  newVisitOrClick(idProducer: number, userAgent: string, visit: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSVService.newVisitOrClick';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(userAgent, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(visit, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  addPrint(token: string, target: BusinessTargetLevel1, idArticle: number, idMaterial: number, idColor: number, idProducer: number, nameProducer: string, sizeInMm: SizeDTO, idFinish: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSVService.addPrint';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(target, 'com.degineo.metier.client.entities.BusinessTargetLevel1', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idArticle, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idMaterial, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idColor, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idProducer, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nameProducer, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sizeInMm, 'com.degineo.metier.order.dto.SizeDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFinish, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  searchProducer(search: string): RpcRequestBuilder<ProducerSVListDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSVService.searchProducer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(search, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getProducersByName(page: number, nbPerPage: number, producerName: string): RpcRequestBuilder<ListWithCount<ProducerSVListDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProducerSVService.getProducersByName';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(producerName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
