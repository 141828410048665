// com.degineo.metier.producer_printer.service.ConsumableServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerPrinterConsumableOptionDTO } from '../entities/ProducerPrinterConsumableOptionDTO';

@Injectable({
  providedIn: 'root',
})
export class ConsumableService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCompanyConsumable(idProducerPrinter: number): RpcRequestBuilder<ProducerPrinterConsumableOptionDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ConsumableService.getCompanyConsumable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getCompanyConsumableUnfiltered(idProducerPrinter: number): RpcRequestBuilder<ProducerPrinterConsumableOptionDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ConsumableService.getCompanyConsumableUnfiltered';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idProducerPrinter, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
