// DB ENUM com.degineo.metier.client.entities.BusinessTargetLevel2 generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class BusinessTargetLevel2 {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly AGRICULTURE = new BusinessTargetLevel2(1, "Agriculture", "AGRICULTURE");
  static readonly ARCHITECTE = new BusinessTargetLevel2(2, "Architecte", "ARCHITECTE");
  static readonly CABINET_D_ARCHITECTE = new BusinessTargetLevel2(3, "Cabinet dâarchitecte", "CABINET_D_ARCHITECTE");
  static readonly MAITRE_D_OEUVRE = new BusinessTargetLevel2(4, "Maitre dâÅuvre", "MAITRE_D_OEUVRE");
  static readonly BUREAU_D_ETUDE_TECHNIQUE = new BusinessTargetLevel2(5, "Bureau dâÃ©tude technique", "BUREAU_D_ETUDE_TECHNIQUE");
  static readonly PROMOTEUR_IMMOBILIER = new BusinessTargetLevel2(6, "Promoteur immobilier", "PROMOTEUR_IMMOBILIER");
  static readonly SOCIETE_CIVILE = new BusinessTargetLevel2(7, "SociÃ©tÃ© Civile", "SOCIETE_CIVILE");
  static readonly ENTREPRISE_GENERALE = new BusinessTargetLevel2(8, "Entreprise gÃ©nerale", "ENTREPRISE_GENERALE");
  static readonly ENTREPRISE_SOUS_TRAITANTE = new BusinessTargetLevel2(9, "Entreprise sous-traitante", "ENTREPRISE_SOUS_TRAITANTE");
  static readonly FOURNISSEUR_DU_BTP = new BusinessTargetLevel2(10, "Fournisseur du BTP", "FOURNISSEUR_DU_BTP");
  static readonly BANQUE_ASSURANCE = new BusinessTargetLevel2(11, "Banque / Assurance", "BANQUE_ASSURANCE");
  static readonly AGENCE_DE_COMMUNICATION = new BusinessTargetLevel2(12, "Agence de communication", "AGENCE_DE_COMMUNICATION");
  static readonly AERONAUTIQUE_FERROVIAIRE_NAVALES = new BusinessTargetLevel2(13, "Aeronautique, fÃ©rroviaire et navales", "AERONAUTIQUE_FERROVIAIRE_NAVALES");
  static readonly ELECTRONIQUE = new BusinessTargetLevel2(14, "Electronique", "ELECTRONIQUE");
  static readonly AGRO_ALIMENTAIRE = new BusinessTargetLevel2(15, "Agro-alimentaire", "AGRO_ALIMENTAIRE");
  static readonly CHIMIE = new BusinessTargetLevel2(16, "Chimie", "CHIMIE");
  static readonly MECANIQUE = new BusinessTargetLevel2(17, "MÃ©canique", "MECANIQUE");
  static readonly MODE_TEXTILE = new BusinessTargetLevel2(18, "Mode et textile", "MODE_TEXTILE");
  static readonly VERRE_BETON_CERAMIQUE = new BusinessTargetLevel2(19, "Verre, beton, ceramique", "VERRE_BETON_CERAMIQUE");
  static readonly BUREAU_D_ETUDE_TECHNIQUE_CONSEIL = new BusinessTargetLevel2(21, "Bureau dâÃ©tude technique", "BUREAU_D_ETUDE_TECHNIQUE_CONSEIL");
  static readonly CONSEIL_AUDIT = new BusinessTargetLevel2(22, "Conseil et Audit", "CONSEIL_AUDIT");
  static readonly BUSINESS_TOURISM_CATERING = new BusinessTargetLevel2(23, "Commerce, Tourisme, Restauration", "BUSINESS_TOURISM_CATERING");
  static readonly ENSEIGNEMENT = new BusinessTargetLevel2(24, "Enseignement", "ENSEIGNEMENT");
  static readonly SANTE = new BusinessTargetLevel2(25, "SantÃ©", "SANTE");
  static readonly MEDICALE = new BusinessTargetLevel2(20, "MÃ©dical", "MEDICALE");

  static readonly values = [
    BusinessTargetLevel2.AGRICULTURE, 
    BusinessTargetLevel2.ARCHITECTE, 
    BusinessTargetLevel2.CABINET_D_ARCHITECTE, 
    BusinessTargetLevel2.MAITRE_D_OEUVRE, 
    BusinessTargetLevel2.BUREAU_D_ETUDE_TECHNIQUE, 
    BusinessTargetLevel2.PROMOTEUR_IMMOBILIER, 
    BusinessTargetLevel2.SOCIETE_CIVILE, 
    BusinessTargetLevel2.ENTREPRISE_GENERALE, 
    BusinessTargetLevel2.ENTREPRISE_SOUS_TRAITANTE, 
    BusinessTargetLevel2.FOURNISSEUR_DU_BTP, 
    BusinessTargetLevel2.BANQUE_ASSURANCE, 
    BusinessTargetLevel2.AGENCE_DE_COMMUNICATION, 
    BusinessTargetLevel2.AERONAUTIQUE_FERROVIAIRE_NAVALES, 
    BusinessTargetLevel2.ELECTRONIQUE, 
    BusinessTargetLevel2.AGRO_ALIMENTAIRE, 
    BusinessTargetLevel2.CHIMIE, 
    BusinessTargetLevel2.MECANIQUE, 
    BusinessTargetLevel2.MODE_TEXTILE, 
    BusinessTargetLevel2.VERRE_BETON_CERAMIQUE, 
    BusinessTargetLevel2.BUREAU_D_ETUDE_TECHNIQUE_CONSEIL, 
    BusinessTargetLevel2.CONSEIL_AUDIT, 
    BusinessTargetLevel2.BUSINESS_TOURISM_CATERING, 
    BusinessTargetLevel2.ENSEIGNEMENT, 
    BusinessTargetLevel2.SANTE, 
    BusinessTargetLevel2.MEDICALE 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of BusinessTargetLevel2.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of BusinessTargetLevel2.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.client.entities.BusinessTargetLevel2'] = BusinessTargetLevel2;
reverseMapping[BusinessTargetLevel2.name] = 'com.degineo.metier.client.entities.BusinessTargetLevel2';
