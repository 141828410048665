// DB ENUM com.degineo.metier.order.entities.OrderNEWStatus generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class OrderNEWStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly WAITING_PAYMENT = new OrderNEWStatus(1, "Paiement en attente de validation", "WAITING_PAYMENT");
  static readonly WAITING_MODEL_CHECK = new OrderNEWStatus(2, "Fichier en attente de vÃ©rification", "WAITING_MODEL_CHECK");
  static readonly AVAILABLE_TO_PRODUCERS = new OrderNEWStatus(3, "En cours de traitement", "AVAILABLE_TO_PRODUCERS");
  static readonly READY_TO_PRINT = new OrderNEWStatus(5, "PrÃªt Ã  Ãªtre imprimÃ©", "READY_TO_PRINT");
  static readonly MODEL_PRINTING = new OrderNEWStatus(4, "En cours d'impression", "MODEL_PRINTING");
  static readonly SHIPPING_PREPARATION = new OrderNEWStatus(6, "En prÃ©paration pour envoi", "SHIPPING_PREPARATION");
  static readonly PACKAGE_PREPARATION = new OrderNEWStatus(7, "En prÃ©paration pour envoi", "PACKAGE_PREPARATION");
  static readonly WAITING_CARRIER = new OrderNEWStatus(8, "En cours de livraison", "WAITING_CARRIER");
  static readonly CARRIER_TRANSPORT = new OrderNEWStatus(11, "En cours de livraison", "CARRIER_TRANSPORT");
  static readonly ARTICLE_RECEIVED = new OrderNEWStatus(9, "Article reÃ§u", "ARTICLE_RECEIVED");
  static readonly CANCELED = new OrderNEWStatus(10, "AnnulÃ©e", "CANCELED");

  static readonly values = [
    OrderNEWStatus.WAITING_PAYMENT, 
    OrderNEWStatus.WAITING_MODEL_CHECK, 
    OrderNEWStatus.AVAILABLE_TO_PRODUCERS, 
    OrderNEWStatus.READY_TO_PRINT, 
    OrderNEWStatus.MODEL_PRINTING, 
    OrderNEWStatus.SHIPPING_PREPARATION, 
    OrderNEWStatus.PACKAGE_PREPARATION, 
    OrderNEWStatus.WAITING_CARRIER, 
    OrderNEWStatus.CARRIER_TRANSPORT, 
    OrderNEWStatus.ARTICLE_RECEIVED, 
    OrderNEWStatus.CANCELED 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of OrderNEWStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of OrderNEWStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.order.entities.OrderNEWStatus'] = OrderNEWStatus;
reverseMapping[OrderNEWStatus.name] = 'com.degineo.metier.order.entities.OrderNEWStatus';
