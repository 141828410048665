// ENTITY com.degineo.metier.order.dto.kanban.WaitingCarrierShipmentDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ShipmentStatus } from './ShipmentStatus';

export class WaitingCarrierShipmentDTO {

  idShipment: number = 0;
  status: ShipmentStatus = null;
  clientChoosedThisProducer: boolean = false;
  clientName: string = '';
  producerDeliver: boolean = false;
  dateWaitingCarrier: Date = null;
  minWishedDeliveryDate: Date = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.kanban.WaitingCarrierShipmentDTO'] = WaitingCarrierShipmentDTO;

reverseMapping[WaitingCarrierShipmentDTO.name] = 'com.degineo.metier.order.dto.kanban.WaitingCarrierShipmentDTO';

fields['com.degineo.metier.order.dto.kanban.WaitingCarrierShipmentDTO']  = {
    idShipment: 'java.lang.Integer',
    status: 'com.degineo.metier.shipping.entities.ShipmentStatus',
    clientChoosedThisProducer: 'java.lang.Boolean',
    clientName: 'java.lang.String',
    producerDeliver: 'java.lang.Boolean',
    dateWaitingCarrier: 'java.time.Instant',
    minWishedDeliveryDate: 'java.time.Instant'
};
