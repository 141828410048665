import { BusinessTargetLevel1, BusinessTargetLevel2 } from '../ic2/entities/entities';

class BusinessTarget {
  level1: BusinessTargetLevel1;
  children: BusinessTargetLevel2[];
}
const BusinessTargets: BusinessTarget[] = [];
BusinessTargets.push({ level1: BusinessTargetLevel1.AGRICULTURE, children: [BusinessTargetLevel2.AGRICULTURE] });
BusinessTargets.push({
  level1: BusinessTargetLevel1.ARCHITECT,
  children: [
    BusinessTargetLevel2.ARCHITECTE,
    BusinessTargetLevel2.CABINET_D_ARCHITECTE,
    BusinessTargetLevel2.MAITRE_D_OEUVRE,
    BusinessTargetLevel2.BUREAU_D_ETUDE_TECHNIQUE,
    BusinessTargetLevel2.PROMOTEUR_IMMOBILIER,
    BusinessTargetLevel2.SOCIETE_CIVILE,
  ],
});
BusinessTargets.push({
  level1: BusinessTargetLevel1.CONSTRUCTION,
  children: [BusinessTargetLevel2.ENTREPRISE_GENERALE, BusinessTargetLevel2.ENTREPRISE_SOUS_TRAITANTE, BusinessTargetLevel2.FOURNISSEUR_DU_BTP],
});
BusinessTargets.push({ level1: BusinessTargetLevel1.BANQUE_ASSURANCE, children: [BusinessTargetLevel2.BANQUE_ASSURANCE] });
BusinessTargets.push({ level1: BusinessTargetLevel1.COM_EDIT_MULTIMEDIA, children: [BusinessTargetLevel2.AGENCE_DE_COMMUNICATION] });
BusinessTargets.push({
  level1: BusinessTargetLevel1.MANUFACTURING_INDUSTRY,
  children: [
    BusinessTargetLevel2.AERONAUTIQUE_FERROVIAIRE_NAVALES,
    BusinessTargetLevel2.ELECTRONIQUE,
    BusinessTargetLevel2.AGRO_ALIMENTAIRE,
    BusinessTargetLevel2.CHIMIE,
    BusinessTargetLevel2.MECANIQUE,
    BusinessTargetLevel2.MODE_TEXTILE,
    BusinessTargetLevel2.VERRE_BETON_CERAMIQUE,
  ],
});
BusinessTargets.push({
  level1: BusinessTargetLevel1.BUSINESS_CONSULTING,
  children: [BusinessTargetLevel2.BUREAU_D_ETUDE_TECHNIQUE_CONSEIL, BusinessTargetLevel2.CONSEIL_AUDIT],
});
BusinessTargets.push({ level1: BusinessTargetLevel1.BUSINESS_TOURISM_CATERING, children: [BusinessTargetLevel2.BUSINESS_TOURISM_CATERING] });
BusinessTargets.push({ level1: BusinessTargetLevel1.EDUCATION, children: [BusinessTargetLevel2.ENSEIGNEMENT] });
BusinessTargets.push({ level1: BusinessTargetLevel1.HEALTH, children: [BusinessTargetLevel2.SANTE, BusinessTargetLevel2.MEDICALE] });

export { BusinessTarget, BusinessTargets };
