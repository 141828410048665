// com.degineo.metier.notif.service.ClientNotifServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { NotifDTO } from '../entities/NotifDTO';

@Injectable({
  providedIn: 'root',
})
export class ClientNotifService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getNotifs(): RpcRequestBuilder<NotifDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ClientNotifService.getNotifs';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
