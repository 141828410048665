// com.degineo.metier.user.service.UserProducerServiceI generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:44 UTC 2021
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from 'ic2-lib';
import { ProducerUserProfilDTO } from '../entities/ProducerUserProfilDTO';

@Injectable({
  providedIn: 'root',
})
export class UserProducerService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateEmail(email: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserProducerService.updateEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateJob(job: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserProducerService.updateJob';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(job, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getProfil(): RpcRequestBuilder<ProducerUserProfilDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserProducerService.getProfil';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.degineo.metier.user.dto.ProducerUserProfilDTO');
  }

  updateFirstName(firstName: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserProducerService.updateFirstName';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateLastName(lastName: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserProducerService.updateLastName';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updatePhoneNumber(phoneNumber: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserProducerService.updatePhoneNumber';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(phoneNumber, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
