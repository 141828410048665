// ENTITY com.ic2.entity.UserRight generated by ic2 3.0.0-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class UserRight {

  idUserRight: number = 0;
  idUser: number = 0;
  idRight: number = 0;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.entity.UserRight'] = UserRight;

reverseMapping[UserRight.name] = 'com.ic2.entity.UserRight';

fields['com.ic2.entity.UserRight']  = {
    idUserRight: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    idRight: 'java.lang.Integer'
};
