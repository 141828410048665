// ENTITY com.degineo.metier.user.entities.DegineoUser generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:47 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ProducerSignupStep } from './ProducerSignupStep';

export class DegineoUser {

  idUser: number = 0;
  creationDate: Date = null;
  lastConnectionDate: Date = null;
  confirmationToken: string = '';
  emailConfirmed: boolean = false;
  resetPwdToken: string = '';
  producerCreationToken: string = '';
  producerSignupStep: ProducerSignupStep = null;
  phoneNumber: string = '';
  phoneNumberConfirmed: boolean = false;
  origin: string = '';
  degineoComment: string = '';
  logoExtension: string = '';

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.user.entities.DegineoUser'] = DegineoUser;

reverseMapping[DegineoUser.name] = 'com.degineo.metier.user.entities.DegineoUser';

fields['com.degineo.metier.user.entities.DegineoUser']  = {
    idUser: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    lastConnectionDate: 'java.time.Instant',
    confirmationToken: 'java.lang.String',
    emailConfirmed: 'java.lang.Boolean',
    resetPwdToken: 'java.lang.String',
    producerCreationToken: 'java.lang.String',
    producerSignupStep: 'com.degineo.metier.user.entities.ProducerSignupStep',
    phoneNumber: 'java.lang.String',
    phoneNumberConfirmed: 'java.lang.Boolean',
    origin: 'java.lang.String',
    degineoComment: 'java.lang.String',
    logoExtension: 'java.lang.String'
};
