<ic2-textassist
  [disabled]="disabled"
  [required]="required"
  class="d-block w-100"
  [placeholder]="name"
  [values]="values"
  [presenter]="presenter"
  (textChanges)="textChanged($event)"
  (objectChange)="changed($event)"
  [(ngModel)]="text"
  required
>
</ic2-textassist>
