<footer style="min-height: 14rem;">
  <div class="container-fluid bg-light-30p">
    <div class="row">
      <div class="col-12 mb-3">
        <h3 class="text-center mt-4">Suivez-nous</h3>
      </div>

      <div class="col-12 text-center mb-4">
        <a class="icons" href="https://fr.linkedin.com/company/degineo" target="_blank">
          <span class="fa-stack mx-2 linkedin-icon" style="width: 2rem; height: 2rem;">
            <i class="fa fa-circle fa-stack-2x" style="font-size: 2rem;"></i>
            <i class="fab fa-linkedin-in fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <a class="icons" href="https://twitter.com/Degineo" target="_blank">
          <span class="fa-stack mx-3 twitter-icon" style="width: 2rem; height: 2rem;">
            <i class="fa fa-circle fa-stack-2x" style="font-size: 2rem;"></i>
            <i class="fab fa-twitter fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <a class="icons" href="https://www.facebook.com/degineo/" target="_blank">
          <span class="fa-stack mx-2 facebook-icon" style="width: 2rem; height: 2rem;">
            <i class="fa fa-circle fa-stack-2x" style="font-size: 2rem;"></i>
            <i class="fab fa-facebook-f fa-stack-1x fa-inverse"></i>
          </span>
        </a>
      </div>

      <div class="col-12 mb-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-1 text-center text-md-right mb-2 card-logo pointer" (click)="removeCookies()"></div>
            <div class="col-md col-12 text-md-left text-center">
              <ul class="list-unstyled">
                <li><a class="text-decoration-none" routerLink="/conditions-generales">Conditions générales</a></li>
                <li><a class="text-decoration-none" routerLink="/mentions-legales">Mentions légales</a></li>
              </ul>
            </div>
            <div class="col-md col-12 text-md-left text-center">
              <ul class="list-unstyled">
                <li><a class="text-decoration-none" routerLink="/confidentialite">Engagement de confidentialité</a></li>
                <li><a class="text-decoration-none" routerLink="/cookies">Utilisation des cookies</a></li>
              </ul>
            </div>
            <div class="col-md col-12 text-md-left text-center">
              <ul class="list-unstyled">
                <li><a class="text-decoration-none" routerLink="." (click)="searchAll()">Recherche d'imprimeurs&nbsp;3D</a></li>
                <li><a class="text-decoration-none" routerLink="." (click)="openChat()">Nous contacter</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-center">
        <p class="text-secondary mb-1">
          Copyright&nbsp;©&nbsp;{{ year }}&nbsp;Degineo
          <span class="d-none d-sm-inline">-</span>
          <span class="d-block d-sm-none"></span>
          Tous&nbsp;droits&nbsp;réservés
        </p>
      </div>
    </div>
  </div>
</footer>
