// DB ENUM com.degineo.metier.producer.dto.ProducerClientPaymentStatus generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class ProducerClientPaymentStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly LATE_PAYMENT = new ProducerClientPaymentStatus(1, "Paiement non rÃ©gularisÃ©", "LATE_PAYMENT");
  static readonly DELAYED_PAYMENT_WAITING = new ProducerClientPaymentStatus(2, "Paiement fin de mois en attente", "DELAYED_PAYMENT_WAITING");
  static readonly ALL_OK = new ProducerClientPaymentStatus(3, "Paiement Ã  jour", "ALL_OK");

  static readonly values = [
    ProducerClientPaymentStatus.LATE_PAYMENT, 
    ProducerClientPaymentStatus.DELAYED_PAYMENT_WAITING, 
    ProducerClientPaymentStatus.ALL_OK 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ProducerClientPaymentStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ProducerClientPaymentStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.producer.dto.ProducerClientPaymentStatus'] = ProducerClientPaymentStatus;
reverseMapping[ProducerClientPaymentStatus.name] = 'com.degineo.metier.producer.dto.ProducerClientPaymentStatus';
