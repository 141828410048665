// ENTITY com.degineo.metier.vitrine.dto.ProducerSVListDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ColorPriceSVDTO } from './ColorPriceSVDTO';
import { BusinessType } from './BusinessType';

export class ProducerSVListDTO {

  id: number = 0;
  name: string = '';
  logoToken: string = '';
  businessType: BusinessType = null;
  city: string = '';
  colors: ColorPriceSVDTO[] = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.vitrine.dto.ProducerSVListDTO'] = ProducerSVListDTO;

reverseMapping[ProducerSVListDTO.name] = 'com.degineo.metier.vitrine.dto.ProducerSVListDTO';

fields['com.degineo.metier.vitrine.dto.ProducerSVListDTO']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    logoToken: 'java.lang.String',
    businessType: 'com.degineo.metier.producer.entities.BusinessType',
    city: 'java.lang.String',
    colors: 'java.util.List<com.degineo.metier.vitrine.dto.ColorPriceSVDTO>'
};
