// ENTITY com.degineo.metier.order.dto.MarqueBlancheDateDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';

export class MarqueBlancheDateDTO {

  wishedDate: Date = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.order.dto.MarqueBlancheDateDTO'] = MarqueBlancheDateDTO;

reverseMapping[MarqueBlancheDateDTO.name] = 'com.degineo.metier.order.dto.MarqueBlancheDateDTO';

fields['com.degineo.metier.order.dto.MarqueBlancheDateDTO']  = {
    wishedDate: 'java.time.Instant'
};
