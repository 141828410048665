// ENTITY com.degineo.metier.producer_printer.dto.ProducerPrinterConsumableOptionDTO generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping, fields } from 'ic2-lib';
import { ProducerPrinterConsumableOption } from './ProducerPrinterConsumableOption';
import { ConsumableOption } from './ConsumableOption';
import { ConsumableNEW } from './ConsumableNEW';

export class ProducerPrinterConsumableOptionDTO {

  consumable: ConsumableNEW = null;
  consumableOption: ConsumableOption = null;
  producerPrinterConsumableOption: ProducerPrinterConsumableOption = null;

  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.degineo.metier.producer_printer.dto.ProducerPrinterConsumableOptionDTO'] = ProducerPrinterConsumableOptionDTO;

reverseMapping[ProducerPrinterConsumableOptionDTO.name] = 'com.degineo.metier.producer_printer.dto.ProducerPrinterConsumableOptionDTO';

fields['com.degineo.metier.producer_printer.dto.ProducerPrinterConsumableOptionDTO']  = {
    consumable: 'com.degineo.metier.metadata.entities.ConsumableNEW',
    consumableOption: 'com.degineo.metier.metadata.entities.ConsumableOption',
    producerPrinterConsumableOption: 'com.degineo.metier.producer_printer.entities.ProducerPrinterConsumableOption'
};
