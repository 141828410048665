// DB ENUM com.degineo.metier.devis.entities.DevisStatus generated by ic2 0.0.1-SNAPSHOT at Fri May 21 15:00:48 UTC 2021

import { mapping, reverseMapping } from 'ic2-lib';

export class DevisStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly DEMANDE_ENVOYEE = new DevisStatus(1, "Demande envoyÃ©e", "DEMANDE_ENVOYEE");
  static readonly NOUVEAU_DEVIS = new DevisStatus(2, "Nouveau devis", "NOUVEAU_DEVIS");
  static readonly DEVIS_EN_COURS_REVISION = new DevisStatus(3, "Devis en rÃ©vision", "DEVIS_EN_COURS_REVISION");
  static readonly DEVIS_REVISE = new DevisStatus(4, "Devis rÃ©visÃ©", "DEVIS_REVISE");
  static readonly DEMANDE_REVISION_REFUSEE = new DevisStatus(5, "Demande de rÃ©vision refusÃ©e", "DEMANDE_REVISION_REFUSEE");
  static readonly DEMANDE_DEVIS_REFUSEE = new DevisStatus(6, "Demande de devis refusÃ©e par le producteur", "DEMANDE_DEVIS_REFUSEE");
  static readonly DEVIS_ACCEPTE = new DevisStatus(7, "Devis acceptÃ©", "DEVIS_ACCEPTE");
  static readonly DEVIS_REFUSE_PAR_CLIENT = new DevisStatus(8, "Demande de devis refusÃ©e par le client", "DEVIS_REFUSE_PAR_CLIENT");
  static readonly DEVIS_PAYE = new DevisStatus(9, "Devis payÃ©", "DEVIS_PAYE");

  static readonly values = [
    DevisStatus.DEMANDE_ENVOYEE, 
    DevisStatus.NOUVEAU_DEVIS, 
    DevisStatus.DEVIS_EN_COURS_REVISION, 
    DevisStatus.DEVIS_REVISE, 
    DevisStatus.DEMANDE_REVISION_REFUSEE, 
    DevisStatus.DEMANDE_DEVIS_REFUSEE, 
    DevisStatus.DEVIS_ACCEPTE, 
    DevisStatus.DEVIS_REFUSE_PAR_CLIENT, 
    DevisStatus.DEVIS_PAYE 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of DevisStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of DevisStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['com.degineo.metier.devis.entities.DevisStatus'] = DevisStatus;
reverseMapping[DevisStatus.name] = 'com.degineo.metier.devis.entities.DevisStatus';
